import React, { useState } from "react";
import "../../common/Common.css";
import "./Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const NriPanCardForm = () => {
    const [planFee, setPlanFee] = useState();
    const [data, setData] = useState({});
    const [userId, setUserId] = useState();
    const [steps, setSteps] = useState();
    const [properties, setProperties] = useState();
    const [number, setNumber] = useState(1);
    const [count, setCount] = useState();
    const [IsEnable, setIsEnable] = useState(false);
    const [isLastName, setIsLastName] = useState();
    const [isFirstName, setIsFirstName] = useState();
    const [isMiddleName, setIsMiddleName] = useState();
    const [isEmail, setIsEmail] = useState();
    const [isMobile, setIsMobile] = useState();
    const [isPassport, setIsPassport] = useState();
    const [passportValue, setIsPassportValue] = useState("");
    const [isPan, setIsPan] = useState();
    const [panValue, setIsPanValue] = useState("");
    const [isPanDocument, setPanIsDocument] = useState();
    const [isAdhar, setIsAdhar] = useState();
    const [isAdharDocument, setIsAdharDocument] = useState();
    const [ToastCount, setToastCount] = useState(0);
    const [isRadio1Selected, setIsRadio1Selected] = useState(true);
    const [documentId, setDocumentId] = useState();
    const [token, setToken] = useState();
    const [isForm16File, setIsForm16File] = useState();
    const [isanyOtherExemptionToBeClaimed, setIsanyOtherExemptionToBeClaimed] =
        useState();

    const [isexemptionRelatedDocuments, setIsexemptionRelatedDocuments] =
        useState();
    const [isRemarks, setIsRemarks] = useState();
    const [isAnnualSalary, setIsAnnualSalary] = useState();
    const [isNameOfEmployer, setIsNameOfEmployer] = useState();
    const [isPanOfEmployer, setIsPanOfEmployer] = useState();
    const [isRemarks2, setIsRemarks2] = useState();

    const [isSavingBankInterest, setIsSavingBankInterest] = useState();
    const [isInterestOnFD, setIsInterestOnFD] = useState();
    const [isInterestOnFDFile, setIsInterestOnFDFile] = useState();
    const [isInterestOnRD, setIsInterestOnRD] = useState();
    const [isInterestOnRDFile, setIsInterestOnRDFile] = useState();
    const [isExpenseIfAnyInRespectOfIncome, setIsExpenseIfAnyInRespectOfIncome] =
        useState();
    const [
        isExpenseIfAnyInRespectOfAboveIncome,
        setIsExpenseIfAnyInRespectOfAboveIncome,
    ] = useState();
    const [isAnotherDiv, setIsAnotherDiv] = useState(false);
    const [isAnotherIncomeDocument, setIsAnotherIncomeDocument] = useState();
    const [isRemarks3, setIsRemarks3] = useState();

    const [housePropertyData, setHousePropertyData] = useState({});
    const [isRadio1Step4Selected, setIsRadio1Step4Selected] = useState(true);
    const [isBtn, setBtn] = useState();
    const [housePropertyMapCount, setHousePropertyMapCount] = useState();
    const [inputHouseProperty, setInputHouseProperty] = useState(0);
    const [housePropertyCount, setHousePropertyCount] = useState(1);
    const [isHouseProperty, setIsHouseProperty] = useState(false);
    const [isOpenDialogue, setIsOpenDialogue] = useState(false);

    const [bankAccountData, setBankAccountData] = useState({});
    const [bankAccountMapCount, setBankAccountMapCount] = useState(0);
    const [isIFSC, setIsIFSC] = useState();

    const [isExcelFile, setIsExcelFile] = useState();

    const [showMakePaymentBTN, setShowMakePaymentBTN] = useState(false)


    const navigate = useNavigate();

    const Location = useLocation();
    const stepName = Location.state;
    console.log(stepName);
    useEffect(() => {
        window.scrollTo(0, 0);
        setPlanFee(sessionStorage.getItem("planFee"));
        getStepsData();
        setDocumentId(localStorage.getItem("documentId"));
        setToken(sessionStorage.getItem("token"));
    }, [number, IsEnable, isHouseProperty]);

    // GET 6 STEPS
    const getStepsData = () => {
        const planId = stepName?.data.planId
            ? stepName?.data.planId
            : sessionStorage.getItem("planId");
        const subServiceId = stepName?.subServiceIds
            ? stepName?.subServiceIds
            : sessionStorage.getItem("subServiceIds");

        // console.log(planId, subServiceId)

        const data = localStorage.getItem("userId");
        setUserId(data);
        const token = localStorage.getItem("token");
        // console.log("token : ", token)
        const method = "steps/";
        axios
            .get(`${REACT_APP_BASE_URL}steps/${subServiceId}/${planId}`)
            .then((response) => {
                console.log("steps : ", response.data.data);
                setSteps(response.data.data.steps);
                setCount(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const NameTitle = [
        { value: "Shri", label: "Shri" },
        { value: "Smt", label: "Smt" },
        { value: "Kumar", label: "Kumar" },
        { value: "Kumari", label: "Kumari" },
    ]
    const IdentityProof = [
        { value: "copy of Passport", label: "copy of Passport" },
        { value: "copy of person of Indian Origin card issued by the Government of India", label: "copy of person of Indian Origin card issued by the Government of India" },
        { value: "copy of Overseas Citizenship of India Card issued by Government of India", label: "copy of Overseas Citizenship of India Card issued by Government of India" },
        { value: "copy of other national or citizenship Identification Number", label: "copy of other national or citizenship Identification Number" },
    ]
    const AddressProof = [
        { value: "copy of Passport", label: "copy of Passport" },
        { value: "copy of person of Indian Origin card issued by the Government of India", label: "copy of person of Indian Origin card issued by the Government of India" },
        { value: "copy of Overseas Citizenship of India Card issued by Government of India", label: "copy of Overseas Citizenship of India Card issued by Government of India" },
        { value: "copy of other national or citizenship Identification Number", label: "copy of other national or citizenship Identification Number" },
        { value: "copy of bank account statement", label: "copy of bank account statement" },
        { value: "copy of Non-resident External bank account statement", label: "copy of Non-resident External bank account statement" },
        { value: "copy of certificate of residence in India by the State Police Authority", label: "copy of certificate of residence in India by the State Police Authority" },
        { value: "copy of the registration certificate issued by the Foreigner's Registration Office", label: "copy of the registration certificate issued by the Foreigner's Registration Office" },
        { value: "copy of Visa granted and copy of appointment letter", label: "copy of Visa granted and copy of appointment letter" },

    ]
    const dateofbirthProof = [
        { value: "birth certificate", label: "birth certificate" },
        { value: "pension payment order", label: "pension payment order" },
        { value: "marriage certificate", label: "marriage certificate" },
        { value: "matriculation certificate", label: "matriculation certificate" },
        { value: "passport", label: "passport" },
        { value: "driving licence", label: "driving licence" },
        { value: "domicile certificate", label: "domicile certificate" },
        { value: "affidavit sworn", label: "affidavit sworn" },
    ]

    const LLPregistered = [
        { value: "Copy of Certificate of Registration issued in the country where the applicant is located, duly attested by Apostille (in respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High Commission or Consulate in the country where the applicant is located or authorised officials of overseas branches of Scheduled Banks registered in India", label: "Copy of Certificate of Registration issued in the country where the applicant is located, duly attested by Apostille (in respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High Commission or Consulate in the country where the applicant is located or authorised officials of overseas branches of Scheduled Banks registered in India" },
        { value: "copy of registration certificate issued in India or of  approval granted to set up office in India by Indian Authorities.", label: "copy of registration certificate issued in India or of approval granted to set up office in India by Indian Authorities." },
    ]

    const companyRegistration = [
        { value: "Copy of Certificate of Registration issued in the country where the applicant is located, duly attested by Apostille (in respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High  Commission or Consulate in the country where the applicant is located or authorised officials of overseas branches of Scheduled Banks registered in India", label: "domicile certificate" },
        { value: "copy of registration certificate issued in India or of  approval granted to set up office in India by Indian Authorities.", label: "copy of registration certificate issued in India or of approval granted to set up office in India by Indian Authorities." },
    ]

    const FirmFormed = [
        { value: "Copy of Certificate of Registration issued in the country where the applicant is located, duly attested by postille (in respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High  Commission or Consulate in the country where the applicant is  located or authorised officials of overseas branches of Scheduled Banks registered in India", label: "Copy of Certificate of Registration issued in the country where the applicant is located, duly attested by postille (in respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High  Commission or Consulate in the country where the applicant is  located or authorised officials of overseas branches of Scheduled Banks registered in India" },
        { value: "copy of registration certificate issued in India or of approval granted to set up office in India by Indian  Authorities.", label: "copy of registration certificate issued in India or of approval granted to set up office in India by Indian Authorities." },
    ]
    const AssociationOutsideIndia = [
        { value: "Copy of Certificate of Registration issued in the country where the applicant is located, duly attested by Apostille (in  respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High Commission or Consulate in the country where the applicant is located or authorised officials of overseas branches of Scheduled Banks registered in India", label: "Copy of Certificate of Registration issued in the country  where the applicant is located, duly attested by Apostille (in  respect of countries which are signatories to the Hague Apostille Convention of 1961) or by Indian embassy or High  Commission or Consulate in the country where the applicant is located or authorised officials of overseas branches of  Scheduled Banks registered in India" },
        { value: "affidavit sworn", label: "affidavit sworn" },
    ]

    // const handleRadioChange = (event) => {
    //     console.log(event.target.value);
    //     if (event.target.value === "radio1") {
    //         setIsRadio1Selected(true);
    //         setIsAnnualSalary();
    //         setIsNameOfEmployer();
    //         setIsPanOfEmployer();
    //         setIsRemarks2();
    //         data.annualSalary = "";
    //         data.nameOfEmployer = "";
    //         data.panOfEmployer = "";
    //         data.remarks2 = "";
    //     } else {
    //         setIsRadio1Selected(false);
    //         setIsForm16File();
    //         setIsanyOtherExemptionToBeClaimed();
    //         setIsexemptionRelatedDocuments();
    //         setIsRemarks();
    //         data.form16File = "";
    //         data.anyOtherExemptionToBeClaimed = "";
    //         data.exemptionRelatedDocuments = "";
    //         data.remarks = "";
    //     }
    // };

    const handleRadioStep4Change = (event) => {
        if (event.target.value === "radio1Step4") {
            setBtn(true);
            setIsRadio1Step4Selected(true);
        } else {
            setBtn(false);
            setIsRadio1Step4Selected(false);
        }
    };

    // $(document).ready(function () {
    $(".nm1").on("click", function () {
        setBtn(false);
        $(".nm1").addClass("active");
        $(".nm1,.nm2,.nm3,.nm4,.nm5,.nm6").removeClass("done");
        $(".nm2,.nm3,.nm4,.nm5,.nm6").removeClass("active");
        $(".step-1").removeClass("d-none");
        $(".step-2, .step-3, .step-4, .step-5, .step-6").addClass("d-none");
    });

    $(".nm2").on("click", function () {
        setIsEnable(false);
        setBtn(false);

        // if (
        //   isEmail &&
        //   isLastName &&
        //   isMobile &&
        //   isPassport &&
        //   isPanDocument &&
        //   isAdharDocument
        // ) {
        $(".nm2").addClass("active");
        $(".nm1").addClass("done");
        $(".nm2,.nm3,.nm4,.nm5,.nm6").removeClass("done");
        $(".nm1,.nm3,.nm4,.nm5,.nm6").removeClass("active");

        $(".step-2").removeClass("d-none");
        $(".step-1, .step-3, .step-4, .step-5, .step-6").addClass("d-none");
        // }
        // else{
        //   toast.warn("Please fill all empty field..!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }
    }
    );

    // $(".nm3").on("click", function () {
    //   setBtn(false)
    //   console.log("isRadioSelected : ", isRadio1Selected);
    //   console.log(
    //     "isanyOtherExemptionToBeClaimed : ",
    //     isanyOtherExemptionToBeClaimed
    //   );
    //   console.log("isAnnualSalary : ", isAnnualSalary);
    //   if (isRadio1Selected) {
    //     if (
    //       isanyOtherExemptionToBeClaimed &&
    //       isanyOtherExemptionToBeClaimed !== undefined
    //     ) {
    //       if (data.anyOtherExemptionToBeClaimed > 0) {
    //         console.log("In 1 claimed");
    //         if (
    //           isForm16File &&
    //           isanyOtherExemptionToBeClaimed &&
    //           isexemptionRelatedDocuments &&
    //           isRemarks
    //         ) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       } else {
    //         if (isForm16File && isanyOtherExemptionToBeClaimed && isRemarks) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       }
    //     }
    //   } else {
    //     if (isAnnualSalary && isAnnualSalary !== undefined) {
    //       if (data.annualSalary > 0) {
    //         console.log("in greater than 0 condition")
    //         if (
    //           isAnnualSalary &&
    //           isNameOfEmployer &&
    //           isPanOfEmployer &&
    //           isRemarks2
    //         ) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       } else {
    //         console.log("in equal to 0 condition")

    //         if (isAnnualSalary === 0) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       }
    //     }
    //   }
    // });
    $(".nm3").on("click", function () {

        $(".nm3").addClass("active");
        $(".nm1, .nm2").addClass("done");
        $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
        $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

        $(".step-3").removeClass("d-none");
        $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
            "d-none"
        );
    });

    $(".nm4").on("click", function () {
        // if (
        //     isSavingBankInterest &&
        //     isInterestOnFD &&
        //     isInterestOnFDFile &&
        //     isInterestOnRD &&
        //     isInterestOnRDFile &&
        //     isExpenseIfAnyInRespectOfIncome &&
        //     isExpenseIfAnyInRespectOfAboveIncome &&
        //     data.addOtherIncome &&
        //     isAnotherIncomeDocument &&
        //     isRemarks3
        // ) {
        setBtn(true);
        $(".nm4").addClass("active");
        $(".nm1, .nm2, .nm3").addClass("done");
        $(".nm4,.nm5,.nm6").removeClass("done");
        $(".nm1,.nm2,.nm3,.nm5,.nm6").removeClass("active");

        $(".step-4").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-5, .step-6").addClass("d-none");
        // } else {
        //     if (
        //         data.addOtherIncome === undefined ||
        //         data.addOtherIncome === null ||
        //         data.addOtherIncome === "None"
        //     ) {
        //         if (
        //             isSavingBankInterest &&
        //             isInterestOnFD &&
        //             isInterestOnFDFile &&
        //             isInterestOnRD &&
        //             isInterestOnRDFile &&
        //             isExpenseIfAnyInRespectOfIncome &&
        //             isExpenseIfAnyInRespectOfAboveIncome &&
        //             isRemarks3
        //         ) {
        setBtn(true);
        $(".nm4").addClass("active");
        $(".nm1, .nm2, .nm3").addClass("done");
        $(".nm4,.nm5,.nm6").removeClass("done");
        $(".nm1,.nm2,.nm3,.nm5,.nm6").removeClass("active");

        $(".step-4").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-5, .step-6").addClass("d-none");
        //         }
        //     }
        // }
    });

    $(".nm5").on("click", function () {
        setBtn(false);
        setIsRadio1Step4Selected(true);
        console.log("housePropertyData : ", housePropertyMapCount);
        // if (
        //     isRadio1Step4Selected ||
        //     housePropertyData === undefined ||
        //     housePropertyData === null
        // ) {
        $(".nm5").addClass("active");
        $(".nm1, .nm2, .nm3, .nm4").addClass("done");
        $(".nm5,.nm6").removeClass("done");
        $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

        $(".step-5").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
        // } else if (!isRadio1Step4Selected || housePropertyMapCount > 0) {
        $(".nm5").addClass("active");
        $(".nm1, .nm2, .nm3, .nm4").addClass("done");
        $(".nm5,.nm6").removeClass("done");
        $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

        $(".step-5").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
        // }
    });

    $(".nm6").on("click", function () {
        setBtn(false);

        console.log("isExcel : ", isExcelFile);
        // if (
        //     isExcelFile &&
        //     isExcelFile !== undefined &&
        //     data?.otherDeductions === "Yes"
        // ) {
        $(".nm6").addClass("active");
        $(".nm1, .nm2, .nm3, .nm4, .nm5").addClass("done");
        $(".nm6").removeClass("done");
        $(".nm1,.nm2,.nm3,.nm4,.nm5").removeClass("active");

        $(".step-6").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass("d-none");
        // } else if (data?.otherDeductions === "No") {
        $(".nm6").addClass("active");
        $(".nm1, .nm2, .nm3, .nm4, .nm5").addClass("done");
        $(".nm6").removeClass("done");
        $(".nm1,.nm2,.nm3,.nm4,.nm5").removeClass("active");

        $(".step-6").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass("d-none");
        // }
    });

    $(".step-1n, .step-3p").on("click", function () {
        $(".step-2").removeClass("d-none");
        $(".step-1, .step-3, .step-4, .step-5, .step-6").addClass("d-none");

        $(".nm1").removeClass("active");
        $(".nm1").addClass("done");

        $(".nm2").addClass("active");

        $(".nm3").removeClass("active");
        $(".nm2").removeClass("done");
        $(".nm2").addClass("active");
    });
    $(".step-2n, .step-4p").on("click", function () {
        $(".step-3").removeClass("d-none");
        $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");

        $(".nm2").removeClass("active");
        $(".nm2").addClass("done");

        $(".nm3").addClass("active");

        $(".nm4").removeClass("active");
        $(".nm3").removeClass("done");
        $(".nm3").addClass("active");
    });
    $(".step-3n, .step-5p").on("click", function () {
        $(".step-4").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-5, .step-6").addClass("d-none");

        $(".nm3").removeClass("active");
        $(".nm3").addClass("done");

        $(".nm4").addClass("active");

        $(".nm5").removeClass("active");
        $(".nm4").removeClass("done");
        $(".nm4").addClass("active");
    });
    $(".step-4n").on("click", function () {
        $(".step-5").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");

        $(".nm4").removeClass("active");
        $(".nm4").addClass("done");

        $(".nm5").addClass("active");
    });
    $(".step-5n").on("click", function () {
        $(".step-6").removeClass("d-none");
        $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass("d-none");

        $(".nm5").removeClass("active");
        $(".nm5").addClass("done");

        $(".nm6").addClass("active");
    });
    $(".step-6p").on("click", function () {
        if (data.otherDeductions === "Yes") {
            if (data.excelFile) {
                $(".step-5").removeClass("d-none");
                $(".step-1, .step-2, .step-3 .step-4, .step-6").addClass("d-none");

                $(".nm6").removeClass("active");
                $(".nm5").removeClass("done");
                $(".nm5").addClass("active");
            }
        } else {
            $(".step-5").removeClass("d-none");
            $(".step-1, .step-2, .step-3 .step-4, .step-6").addClass("d-none");

            $(".nm6").removeClass("active");
            $(".nm5").removeClass("done");
            $(".nm5").addClass("active");
        }
    });

    $(".step-2p").on("click", function () {
        $(".step-1").removeClass("d-none");
        $(".step-2, .step-3 .step-4, .step-5, .step-6").addClass("d-none");

        $(".nm2").removeClass("active");
        $(".nm1").removeClass("done");
        $(".nm1").addClass("active");
    });
    $("a.form-title").on("click", function () {
        $(this).next(".form-details").slideToggle();
        $(this).toggleClass("imgtoggle");
        return false;
    });
    // });

    const inputHandle = (e) => {
        const { name, value, type, files } = e.target;

        console.log("radio : ", name, value.toUpperCase(), type);
        if (type == "file") {
            console.log(files);
            console.log(files[0].type);
            const fileType = files[0].type.split("/")[1];
            const method = `document/upload/${fileType}`;
            const formData = new FormData();
            formData.append("file", files[0]);
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            setData({ ...data, [name]: res.data.data.key });

                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            if (name === "panNumber") {
                setIsPanValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            if (name === "passportNumber") {
                setIsPassportValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            setData({ ...data, [name]: value });
        }
    };

    const inputHandleHouseProperty = (e) => {
        const { name, value, type, files } = e.target;

        console.log("radio : ", name, value, type);
        if (type == "file") {
            console.log(files[0]);
            const method = "document/upload";
            const formData = new FormData();
            formData.append("file", files[0]);
            // setData({ ...data, [name]: files[0] });
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                            setHousePropertyData({
                                                ...housePropertyData,
                                                [name]: response.config.url,
                                            });
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            setHousePropertyData({ ...housePropertyData, [name]: value });
        }
    };


    const inputValidation = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passportPattern =
            /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
        const { name, value, type, files } = e.target;
        const isValidEmail = emailPattern.test(value);
        const isValidPassportNumber = passportPattern.test(value);
        if (name === "namePanCard") {
            if (value.length > 1) {
                setIsLastName(true);
            } else {
                setIsLastName(false);
            }
        }
        if (name === "middleName") {
            if (value.length > 1) {
                setIsMiddleName(true);
            } else {
                setIsMiddleName(false)
            }
        }
        if (name === "firstName") {
            if (value.length > 1) {
                setIsFirstName(true);
            } else {
                setIsFirstName(false);
            }
        }
        if (type === "email") {
            if (isValidEmail) {
                setIsEmail(true);
            } else {
                setIsEmail(false);
            }
        }
        if (name === "mobileNumber") {
            if (value.length === 10) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        if (name === "passportNumber") {
            if (isValidPassportNumber) {
                setIsPassport(true);
            } else {
                setIsPassport(false);
            }
        }
        if (name === "panNumber") {
            if (value.length === 10) {
                setIsPan(true);
            } else {
                setIsPan(false);
            }
        }
        if (name === "panCardDocument") {
            if (files.length > 0) {
                setPanIsDocument(true);
            } else {
                setPanIsDocument(false);
            }
        }
        if (name === "aadharNumber") {
            if (value.length === 12) {
                setIsAdhar(true);
            } else {
                setIsAdhar(false);
            }
        }
        if (name === "adharCardDocument") {
            if (files.length > 0) {
                setIsAdharDocument(true);
            } else {
                setIsAdharDocument(false);
            }
        }
        if (name === "form16File") {
            if (files.length > 0) {
                setIsForm16File(true);
            } else {
                setIsForm16File(false);
            }
        }
        if (name === "anyOtherExemptionToBeClaimed") {
            if (value.length > 0) {
                setIsanyOtherExemptionToBeClaimed(true);
            } else {
                setIsanyOtherExemptionToBeClaimed(false);
            }
        }
        if (name === "exemptionRelatedDocuments") {
            if (files.length > 0 && data.anyOtherExemptionToBeClaimed > 0) {
                setIsexemptionRelatedDocuments(true);
            } else {
                setIsexemptionRelatedDocuments(false);
            }
        }
        if (name === "remarks") {
            if (value.length > 0) {
                setIsRemarks(true);
            } else {
                setIsRemarks(false);
            }
        }
        if (name === "annualSalary") {
            if (value.length > 0) {
                setIsAnnualSalary(true);
            } else {
                setIsAnnualSalary(false);
            }
        }
        if (name === "nameOfEmployer") {
            if (value.length > 1) {
                setIsNameOfEmployer(true);
            } else {
                setIsNameOfEmployer(false);
            }
        }
        if (name === "panOfEmployer") {
            if (files.length > 0 && data.annualSalary > 0) {
                setIsPanOfEmployer(true);
            } else {
                setIsPanOfEmployer(false);
            }
        }
        if (name === "remarks2") {
            if (value.length > 0) {
                setIsRemarks2(true);
            } else {
                setIsRemarks2(false);
            }
        }
        if (name === "savingBankInterest") {
            if (value.length > 0) {
                setIsSavingBankInterest(true);
            } else {
                setIsSavingBankInterest(false);
            }
        }
        if (name === "interestOnFD") {
            if (value.length > 0) {
                setIsInterestOnFD(true);
            } else {
                setIsInterestOnFD(false);
            }
        }
        if (name === "interestOnFDFile") {
            if (files.length > 0) {
                setIsInterestOnFDFile(true);
            } else {
                setIsInterestOnFDFile(false);
            }
        }
        if (name === "interestOnRD") {
            if (value.length > 0) {
                setIsInterestOnRD(true);
            } else {
                setIsInterestOnRD(false);
            }
        }
        if (name === "interestOnRDFile") {
            if (files.length > 0) {
                setIsInterestOnRDFile(true);
            } else {
                setIsInterestOnRDFile(false);
            }
        }
        if (name === "expenseIfAnyInRespectOfIncome") {
            if (value.length > 0) {
                setIsExpenseIfAnyInRespectOfIncome(true);
            } else {
                setIsExpenseIfAnyInRespectOfIncome(false);
            }
        }
        if (name === "expenseIfAnyInRespectOfAboveIncome") {
            if (value.length > 0) {
                setIsExpenseIfAnyInRespectOfAboveIncome(true);
            } else {
                setIsExpenseIfAnyInRespectOfAboveIncome(false);
            }
        }
        if (name === "anotherIncomeDocument") {
            if (files.length > 0) {
                setIsAnotherIncomeDocument(true);
            } else {
                setIsAnotherIncomeDocument(false);
            }
        }
        if (name === "remarks3") {
            if (value.length > 0) {
                setIsRemarks3(true);
            } else {
                setIsRemarks3(false);
            }
        }
        if (name === "excelFile") {
            if (files.length > 0) {
                setIsExcelFile(true);
            } else {
                setIsExcelFile(false);
            }
        }
    };

    const checkValidationStep1 = () => {
        if (
            isEmail &&
            isLastName &&
            isMobile &&
            isPassport &&
            isPanDocument &&
            isAdharDocument
        ) {
            //Post API for sending step's data
            const method = "document/add/";
            const body = {
                docs: [
                    {
                        propertyName: "Name (as per PAN card)",
                        value: data.namePanCard,
                    },
                    {
                        propertyName: "Email Address",
                        value: data.email,
                    },
                    {
                        propertyName: "Mobile Number",
                        value: data.mobileNumber,
                    },
                    {
                        propertyName: "Passport Number",
                        value: data.passportNumber,
                    },
                    {
                        propertyName: "Pan Card",
                        value: data.panCardDocument,
                    },
                    {
                        propertyName: "Adhar Card",
                        value: data.adharCardDocument,
                    },
                ],
            };

            console.log("adhar card : ", data);

            axios
                .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "1", body, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (res) => {
                        console.log("response : ", res);
                        if (res) {
                            toast.success("Your Information saved successfully..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            window.scrollTo(0, 0);
                        }
                    },
                    (error) => {
                        toast.error("Something went wrong..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                );
        } else {
            toast.error("Please fill all empty field..!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const checkValidationStep2 = () => {
        console.log("radio is checked or not : ", isRadio1Selected);
        if (isRadio1Selected) {
            //First radio button is selected logic
            if (data.anyOtherExemptionToBeClaimed > 0) {
                console.log("IN greater than zero condition");
                if (
                    isForm16File &&
                    isanyOtherExemptionToBeClaimed &&
                    isexemptionRelatedDocuments &&
                    isRemarks
                ) {
                    //Post API for sending step's data
                    const method = "document/add/";
                    const body = {
                        docs: [
                            {
                                propertyName: "Form 16",
                                value: data.form16File,
                            },
                            {
                                propertyName: "Any other Exemption to be claimed",
                                value: data.anyOtherExemptionToBeClaimed,
                            },
                            {
                                propertyName: "Exemption related documentary",
                                value: data.exemptionRelatedDocuments
                                    ? data.exemptionRelatedDocuments
                                    : "null",
                            },
                            {
                                propertyName: "Remarks if any",
                                value: data.passportNumber,
                            },
                        ],
                    };

                    axios
                        .post(
                            REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                            body,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then(
                            (res) => {
                                console.log("response : ", res);
                                if (res) {
                                    toast.success("Your Information saved successfully..!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    window.scrollTo(0, 0);
                                    $(".nm3").addClass("active");
                                    $(".nm1, .nm2").addClass("done");
                                    $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                    $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                    $(".step-3").removeClass("d-none");
                                    $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                        "d-none"
                                    );
                                }
                            },
                            (error) => {
                                toast.error("Something went wrong..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        );
                } else {
                    toast.error("Please fill all empty field..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                console.log("In value is zero condition");
                //Post API for sending step's data
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Form 16",
                            value: data.form16File,
                        },
                        {
                            propertyName: "Any other Exemption to be claimed",
                            value: data.anyOtherExemptionToBeClaimed,
                        },
                        {
                            propertyName: "Exemption related documentary",
                            value: "null",
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks,
                        },
                    ],
                };

                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                                $(".nm3").addClass("active");
                                $(".nm1, .nm2").addClass("done");
                                $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                $(".step-3").removeClass("d-none");
                                $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                    "d-none"
                                );
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            }
        } else {
            //Second radio button is selected logic
            if (data.annualSalary > 0) {
                if (
                    isAnnualSalary &&
                    isNameOfEmployer &&
                    isPanOfEmployer &&
                    isRemarks2
                ) {
                    //Post API for sending step's data
                    const method = "document/add/";
                    const body = {
                        docs: [
                            {
                                propertyName: "Annual Salary/Pension",
                                value: data.annualSalary,
                            },
                            {
                                propertyName: "Name of the Employer",
                                value: data.nameOfEmployer,
                            },
                            {
                                propertyName: "Pan of the Employer",
                                value: data.panOfEmployer,
                            },
                            {
                                propertyName: "Remarks if any",
                                value: data.remarks2,
                            },
                        ],
                    };

                    axios
                        .post(
                            REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                            body,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then(
                            (res) => {
                                console.log("response : ", res);
                                if (res) {
                                    toast.success("Your Information saved successfully..!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    $(".nm3").addClass("active");
                                    $(".nm1, .nm2").addClass("done");
                                    $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                    $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                    $(".step-3").removeClass("d-none");
                                    $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                        "d-none"
                                    );
                                }
                            },
                            (error) => {
                                toast.error("Something went wrong..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        );
                } else {
                    toast.error("Please fill all empty field..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Annual Salary/Pension",
                            value: data.annualSalary,
                        },
                        {
                            propertyName: "Name of the Employer",
                            value: data.nameOfEmployer ? data.nameOfEmployer : "null",
                        },
                        {
                            propertyName: "Pan of the Employer",
                            value: data.panOfEmployer ? data.panOfEmployer : "null",
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks2 ? data.remarks2 : "null",
                        },
                    ],
                };

                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                                $(".nm3").addClass("active");
                                $(".nm1, .nm2").addClass("done");
                                $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                $(".step-3").removeClass("d-none");
                                $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                    "d-none"
                                );
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            }
        }
    };

    const checkValidationStep3 = () => {
        if (data.addOtherIncome !== "None") {
            if (
                isSavingBankInterest &&
                isInterestOnFD &&
                isInterestOnFDFile &&
                isInterestOnRD &&
                isInterestOnRDFile &&
                isExpenseIfAnyInRespectOfIncome &&
                isExpenseIfAnyInRespectOfAboveIncome &&
                data.addOtherIncome !== null &&
                isAnotherIncomeDocument &&
                isRemarks3
            ) {
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Saving Bank Interest",
                            value: data.savingBankInterest,
                        },
                        {
                            propertyName: "Interest on fixed deposit",
                            value: data.interestOnFD,
                        },
                        {
                            propertyName: "Interest on fixed deposit file",
                            value: data.interestOnFDFile,
                        },
                        {
                            propertyName: "Interest on recurring deposit",
                            value: data.interestOnRD,
                        },
                        {
                            propertyName: "Interest on recurring deposit file",
                            value: data.interestOnRDFile,
                        },
                        {
                            propertyName: "Expense if any in respect of income",
                            value: data.expenseIfAnyInRespectOfIncome,
                        },
                        {
                            propertyName: "Expense if any in respect of above income",
                            value: data.expenseIfAnyInRespectOfAboveIncome,
                        },
                        {
                            propertyName: "Add Other Income",
                            value: data.addOtherIncome,
                        },
                        {
                            propertyName: "Add Other Income file",
                            value: data.anotherIncomeDocument,
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks3,
                        },
                    ],
                };
                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "3",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            } else {
                toast.error("Please fill empty fields..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            if (
                isSavingBankInterest &&
                isInterestOnFD &&
                isInterestOnFDFile &&
                isInterestOnRD &&
                isInterestOnRDFile &&
                isExpenseIfAnyInRespectOfIncome &&
                isExpenseIfAnyInRespectOfAboveIncome &&
                isRemarks3
            ) {
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Saving Bank Interest",
                            value: data.savingBankInterest,
                        },
                        {
                            propertyName: "Interest on fixed deposit",
                            value: data.interestOnFD,
                        },
                        {
                            propertyName: "Interest on fixed deposit file",
                            value: data.interestOnFDFile,
                        },
                        {
                            propertyName: "Interest on recurring deposit",
                            value: data.interestOnRD,
                        },
                        {
                            propertyName: "Interest on recurring deposit file",
                            value: data.interestOnRDFile,
                        },
                        {
                            propertyName: "Expense if any in respect of income",
                            value: data.expenseIfAnyInRespectOfIncome,
                        },
                        {
                            propertyName: "Expense if any in respect of above income",
                            value: data.expenseIfAnyInRespectOfAboveIncome,
                        },
                        {
                            propertyName: "Add Other Income",
                            value: "None",
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks3,
                        },
                    ],
                };
                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "3",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            } else {
                toast.error("Please fill empty fields..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const checkValidationStep4 = (id) => {
        console.log("data : ", housePropertyData);
        const method = "document/add/";
        const body = {
            docs: [
                Object.entries(housePropertyData).map(([propertyName, value]) => ({
                    propertyName: propertyName,
                    value: value,
                })),
            ],
        };

        // const filterData = body.docs[0].map((data)=>{
        //   return data;
        // })

        const bodyData = {
            docs: body.docs[0],
        };
        axios
            .post(
                REACT_APP_BASE_URL + method + stepName.docId + "/" + "4",
                bodyData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(
                (res) => {
                    console.log("response : ", res);
                    if (res) {
                        toast.success("Your Information saved successfully..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setBtn(false);
                        setIsRadio1Step4Selected(true);
                        window.scrollTo(0, 0);
                        $(".nm5").addClass("active");
                        $(".nm1, .nm2, .nm3, .nm4").addClass("done");
                        $(".nm5,.nm6").removeClass("done");
                        $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

                        $(".step-5").removeClass("d-none");
                        $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
                    }
                },
                (error) => {
                    toast.error("Something went wrong..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            );

        if (id) {
            window.scrollTo(0, 0);
            $(".nm5").addClass("active");
            $(".nm1, .nm2, .nm3, .nm4").addClass("done");
            $(".nm5,.nm6").removeClass("done");
            $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

            $(".step-5").removeClass("d-none");
            $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
        }
    };

    const checkValidationStep5 = () => {
        const method = "document/add/";
        const body = {
            docs: [
                {
                    propertyName: "otherDeductions",
                    value: data?.otherDeductions,
                },
                {
                    propertyName: "excelFile",
                    value: data?.excelFile ? data?.excelFile : "Null",
                },
            ],
        };

        if (data.otherDeductions === "Yes") {
            if (data.excelFile) {
                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "5",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            } else {
                toast.error("Please upload file..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            axios
                .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "5", body, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (res) => {
                        console.log("response : ", res);
                        if (res) {
                            toast.success("Your Information saved successfully..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            $(".step-6").removeClass("d-none");
                            $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass(
                                "d-none"
                            );

                            $(".nm5").removeClass("active");
                            $(".nm5").addClass("done");

                            $(".nm6").addClass("active");
                            setShowMakePaymentBTN(true)
                            window.scrollTo(0, 0);
                        }
                    },
                    (error) => {
                        toast.error("Something went wrong..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                );
        }
    };

    const checkValidationStep6 = () => {
        console.log("data : ", bankAccountData);
        const method = "document/add/";
        const body = {
            docs: [
                Object.entries(bankAccountData).map(([propertyName, value]) => ({
                    propertyName: propertyName,
                    value: value,
                })),
            ],
        };

        const bodyData = {
            docs: body.docs[0],
        };

        console.log("bodyData : ", bodyData);

        axios
            .post(
                REACT_APP_BASE_URL + method + stepName.docId + "/" + "6",
                bodyData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(
                (res) => {
                    console.log("response : ", res);
                    if (res) {
                        toast.success("Your Information saved successfully..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        window.scrollTo(0, 0);
                        displayRazorpay();
                    }
                },
                (error) => {
                    toast.error("Something went wrong..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            );
    };

    const handleDropdown = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
            setIsAnotherDiv(false);
        } else {
            setIsAnotherDiv(true);
        }
        setData({ ...data, [name]: value });
    };

    const handleBankAccountDropdown = (e, { name }) => {
        setBankAccountData({ ...bankAccountData, [name]: e.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        newFormData.append("namePanCard", data.namePanCard);
        newFormData.append("passportNumber", data.passportNumber);
        newFormData.append("email", data.email);
        newFormData.append("mobileNumber", data.mobileNumber);
        newFormData.append("panCardDocument", data.panCardDocument);
        newFormData.append("aadharCardDocument", data.aadharCardDocument);
        newFormData.append("form16File", data.form16File);
        newFormData.append(
            "anyOtherExemptionToBeClaimed",
            data.anyOtherExemptionToBeClaimed
        );
        newFormData.append(
            "exemptionRelatedDocuments",
            data.exemptionRelatedDocuments
        );
        newFormData.append("remarks", data.remarks);
        newFormData.append("annualSalary", data.annualSalary);
        newFormData.append("nameOfEmployer", data.nameOfEmployer);
        newFormData.append("panOfEmployer", data.panOfEmployer);
        newFormData.append("otherAttachments", data.otherAttachments);
        newFormData.append("remarks2", data.remarks2);
        newFormData.append("savingBankInterest", data.savingBankInterest);
        newFormData.append("interestOnFD", data.interestOnFD);
        newFormData.append("interestOnFDFile", data.interestOnFDFile);
        newFormData.append("interestOnRD", data.interestOnRD);
        newFormData.append("interestOnRDFile", data.interestOnRDFile);
        newFormData.append("remarks3", data.remarks3);
        newFormData.append(
            "expenseIfAnyInRespectOfIncome",
            data.expenseIfAnyInRespectOfIncome
        );
        newFormData.append(
            "expenseIfAnyInRespectOfAboveIncome",
            data.expenseIfAnyInRespectOfAboveIncome
        );
        newFormData.append("addOtherIncome", data.addOtherIncome);
        newFormData.append("address", data.address);
        newFormData.append("coOwned", data.coOwned);
        newFormData.append("typeOfHouseProperty", data.typeOfHouseProperty);
        newFormData.append("nameOfTenant", data.nameOfTenant);
        newFormData.append("panOfTenant", data.panOfTenant);
        newFormData.append("annualRent", data.annualRent);
        newFormData.append("municipalTax", data.municipalTax);
        newFormData.append("municipalTaxPaidFile", data.municipalTaxPaidFile);
        newFormData.append("interestOnHousingLoan", data.interestOnHousingLoan);
        newFormData.append(
            "interestOnHousingLoanFile",
            data.interestOnHousingLoanFile
        );
        newFormData.append("princiaplRepaid", data.princiaplRepaid);
        newFormData.append("principalRepaidFile", data.principalRepaidFile);
        newFormData.append("remarks4", data.remarks4);
        newFormData.append("otherDeductions", data.otherDeductions);
        newFormData.append("excelFile", data.excelFile);
        newFormData.append("bank", data.bank);
        newFormData.append("bankAccountNumber", data.bankAccountNumber);
        newFormData.append("ifscode", data.ifscode);
        newFormData.append(
            "governedByPortugueseCivil",
            data.governedByPortugueseCivil
        );
        newFormData.append("directorAtAnyCompany", data.directorAtAnyCompany);
        newFormData.append("claimBenefits", data.claimBenefits);
        newFormData.append("heldUnlisted", data.heldUnlisted);

        const url = "https://apibase.taxespro.in/";
        const method = `user/generate/income-tax/document/${userId}`;
        try {
            const response = await axios.post(url + method, newFormData);
            console.log(response.data); // Output: Response data from the server
            toast.success("Information saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    const handleNumber = (i) => {
        setNumber(i);
    };

    const addHouseProperty = () => {
        setIsHouseProperty(true);
    };


    //Razor Pay Integration Loader(Pre-requisition)
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resolve(true);
            };

            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    //Razor Pay Integration (main)

    const displayRazorpay = async (amt) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("you r offline... Failed to load");
            return;
        }

        const options = {
            key: "rzp_test_snn65WkJ8E1lJr",
            currency: "INR",
            amount: parseInt(planFee) * 100,
            name: "Techgigs",
            description: "Thanks For Purchasing",

            handler: function (response) {
                const body = {
                    razorpayOrderId: response.razorpay_payment_id,
                };
                console.log(body);
                const methods = `document/payment/${stepName.docId}`;
                axios
                    .post(REACT_APP_BASE_URL + methods, body, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        console.log("RazerPay Response", res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                toast.success("Your Payment is successfull..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/incometaxreturn");
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <>
            <DemoHeader />
            <div className="bannertopstep">
                <div className="container text-left">
                    <div className="banner-vhmian1 itr-breadcrumb">



                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            HOME
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            // to="/incometaxreturn"
                            onClick={() => window.history.go(-1)}
                        >
                            Pan Services
                        </Link>
                        <Typography color="text.primary">Form</Typography>
                    </Breadcrumbs>
                    <div className="">
                        <div className="top-space-section step-gap">
                            <section className="step-box clearfix">
                                <div className="container">
                                    <div className="step-number-row1"></div>
                                    {/* steps name */}
                                    <ul className="nav nav-fill step-number row gutter-0 d-flex">
                                        {/* {steps &&
                      steps.map((data, i) => {
                        return (
                          <li className="nav-item col-2" key={i}>
                            <a
                              className={`nav-link nm${data?.number} ${i === 0 ? 'active' : '' }`}
                              
                              data-toggle="tooltip"
                              data-placement="top"
                              // title="General Information"
                              onClick={()=>{handleNumber(i+1)}}
                            >
                              <span>
                                {data?.number}
                                <img src="images/check-w.png" alt="" />
                              </span>
                              <p className="d-none d-sm-block">{data?.title}</p>
                            </a>
                          </li>
                        );
                      })} */}

                                        {/* nav-link nm1 active */}
                                        {/* step 1 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm1 active"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="General Information"
                                            >
                                                <span>
                                                    1<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    Applicant
                                                    <br />
                                                    Information
                                                </p>
                                            </a>
                                        </li>
                                        {/* step 2 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm2"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Income from salary"
                                            >
                                                <span>
                                                    2<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    Documents for
                                                    <br />
                                                    Proof
                                                </p>
                                            </a>
                                        </li>
                                    </ul>

                                    <form onSubmit={handleSubmit}>
                                        <div className="step-details step-form">
                                            {/*Step-1  */}
                                            <div className={`step-${number}`}>
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                                        {/* name field */}
                                                        {/* Title field */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                Title
                                                            </label>
                                                            <Select
                                                                name="title"
                                                                options={NameTitle}
                                                                onChange={handleDropdown}
                                                                onBlur={inputValidation}
                                                                styles={{ width: "100%" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                                        {/* name field */}
                                                        {/* Name as per Aadhar */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                Last Name/Surname
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="lastName"
                                                                className="form-control"
                                                                // className={`form-control ${isLastName !== undefined
                                                                //     ? isLastName
                                                                //       ? "is-valid"
                                                                //       : "is-invalid"
                                                                //     : ""
                                                                //   }`
                                                                // }
                                                                placeholder="Enter your Last name"
                                                                onChange={inputHandle}
                                                                // onBlur={inputValidation}
                                                                autoComplete="off"
                                                            />
                                                            <div className="invalid-feedback">
                                                                Name should not be null
                                                            </div>
                                                        </div>
                                                        {/* Birth Date field */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                Date Of Birth
                                                            </label>
                                                            <input
                                                                type="date"
                                                                name="birthdate"
                                                                className="form-control"
                                                                // className={`form-control ${isEmail !== undefined
                                                                //     ? isEmail
                                                                //       ? "is-valid"
                                                                //       : "is-invalid"
                                                                //     : ""
                                                                //   }`}
                                                                required
                                                                onChange={inputHandle}
                                                                // onBlur={inputValidation}
                                                                autoComplete="off"
                                                            />
                                                            <div className="invalid-feedback">
                                                                Incorrect email format
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                                                        {/* First Name */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                First Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="firstName"
                                                                className="form-control"
                                                                // className={`form-control ${isFirstName !== undefined
                                                                //     ? isFirstName
                                                                //       ? "is-valid"
                                                                //       : "is-invalid"
                                                                //     : ""
                                                                //   }`}
                                                                placeholder="Enter your First name"
                                                                onChange={inputHandle}
                                                                // onBlur={inputValidation}
                                                                autoComplete="off"
                                                            />
                                                            <div className="invalid-feedback">
                                                                Name should not be null
                                                            </div>
                                                        </div>
                                                        {/* email field */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                Email address
                                                            </label>
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                className="form-control"
                                                                // className={`form-control ${isEmail !== undefined
                                                                //     ? isEmail
                                                                //       ? "is-valid"
                                                                //       : "is-invalid"
                                                                //     : ""
                                                                //   }`}
                                                                required
                                                                onChange={inputHandle}
                                                                // onBlur={inputValidation}
                                                                autoComplete="off"
                                                            />
                                                            <div className="invalid-feedback">
                                                                Incorrect email format
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                                                        {/* Middle Name */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                Middle Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="middleName"
                                                                className="form-control"
                                                                // className={`form-control ${isMiddleName !== undefined
                                                                //     ? isMiddleName
                                                                //       ? "is-valid"
                                                                //       : "is-invalid"
                                                                //     : ""
                                                                //   }`}
                                                                placeholder="Enter your Middle name"
                                                                onChange={inputHandle}
                                                                // onBlur={inputValidation}
                                                                autoComplete="off"
                                                            />
                                                            <div className="invalid-feedback">
                                                                Name should not be null
                                                            </div>
                                                        </div>
                                                        {/* mobile number field */}
                                                        <div className="form-group bottom-space">
                                                            <label className="label-style">
                                                                Mobile Number
                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="mobileNumber"
                                                                className="form-control"
                                                                // className={`form-control ${isMobile !== undefined
                                                                //     ? isMobile
                                                                //       ? "is-valid"
                                                                //       : "is-invalid"
                                                                //     : ""
                                                                //   }`}
                                                                required
                                                                onChange={inputHandle}
                                                            // onBlur={inputValidation}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Incorrect Mobile number
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                                                {/* {isLastName !== undefined ||
                          isEmail !== undefined ||
                          isMobile !== undefined ||
                          isPassport !== undefined ||
                          isPanDocument !== undefined ||
                          isAdharDocument !== undefined ? (
                          !isLastName ||
                            !isEmail ||
                            !isMobile ||
                            !isPassport ||
                            !isPanDocument ||
                            !isAdharDocument ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Please fill all epmty fileds...!
                            </p>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )} */}
                                                <Button
                                                    className="nm2"
                                                    id="saveInformation"
                                                    variant="contained"
                                                    style={{
                                                        borderRadius: "25px",
                                                        background: "#AE0721",
                                                    }}
                                                    //   onClick={() => checkValidationStep1()}
                                                    endIcon={<SendIcon />}
                                                    disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                >
                                                    Save & Next
                                                </Button>
                                            </div>
                                        </div>

                                        {/*Step-2 */}
                                        <div className="step-2 d-none  step-form">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-4 col-xl-4">
                                                    <div className="row">
                                                        <div className="col-xl-9">
                                                            {/* Type of identity field */}
                                                            <div className="form-group bottom-space">
                                                                <label className="label-style">
                                                                    Select Identity Proof
                                                                </label>
                                                                <Select
                                                                    name="bank1"
                                                                    options={IdentityProof}
                                                                    onChange={handleBankAccountDropdown}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-5">
                                                    <div className="row">
                                                        <div className="col-xl-9">
                                                            {/* Identity Proof field */}
                                                            <div
                                                                className="form-group bottom-space"
                                                            // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                            //     }`}
                                                            >
                                                                <label className="label-style">Identity Proof</label>
                                                                <input
                                                                    type="file"
                                                                    name="identity-proof"
                                                                    className="form-control"
                                                                    // className={`form-control ${isForm16File !== undefined
                                                                    //     ? isForm16File
                                                                    //         ? "is-valid"
                                                                    //         : "is-invalid"
                                                                    //     : ""
                                                                    //     }`}
                                                                    placeholder="From 16"
                                                                    onChange={inputHandle}
                                                                    // onBlur={inputValidation}
                                                                    autoComplete="off"
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Identity Proof is required
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-xl-4">
                                                    <div className="row">
                                                        <div className="col-xl-9">
                                                            {/* Type of address field */}
                                                            <div className="form-group bottom-space">
                                                                <label className="label-style">
                                                                    Select Address Proof
                                                                </label>
                                                                <Select
                                                                    name="title"
                                                                    options={AddressProof}
                                                                    onChange={handleDropdown}
                                                                    // onBlur={inputValidation}
                                                                    styles={{ width: "100%", height: "max-content" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-5">
                                                    <div className="row">
                                                        <div className="col-xl-9">
                                                            {/* Identity Proof field */}
                                                            <div
                                                                className="form-group bottom-space"
                                                            // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                            //     }`}
                                                            >
                                                                <label className="label-style">Address Proof</label>
                                                                <input
                                                                    type="file"
                                                                    name="identity-proof"
                                                                    className="form-control"
                                                                    // className={`form-control ${isForm16File !== undefined
                                                                    //     ? isForm16File
                                                                    //         ? "is-valid"
                                                                    //         : "is-invalid"
                                                                    //     : ""
                                                                    //     }`}
                                                                    placeholder="From 16"
                                                                    onChange={inputHandle}
                                                                    // onBlur={inputValidation}
                                                                    autoComplete="off"
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Identity Proof is required
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-xl-4">
                                                    <div className="row">
                                                        <div className="col-xl-9">
                                                            {/* Type of address field */}
                                                            <div className="form-group bottom-space">
                                                                <label className="label-style">
                                                                    Select Proof of date of birth
                                                                </label>
                                                                <Select
                                                                    name="title"
                                                                    options={dateofbirthProof}
                                                                    onChange={handleDropdown}
                                                                    // onBlur={inputValidation}
                                                                    styles={{ width: "100%", height: "max-content" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-5">
                                                    <div className="row">
                                                        <div className="col-xl-9">
                                                            {/* Identity Proof field */}
                                                            <div
                                                                className="form-group bottom-space"
                                                            // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                            //     }`}
                                                            >
                                                                <label className="label-style">Proof of date of birth</label>
                                                                <input
                                                                    type="file"
                                                                    name="identity-proof"
                                                                    className="form-control"
                                                                    // className={`form-control ${isForm16File !== undefined
                                                                    //     ? isForm16File
                                                                    //         ? "is-valid"
                                                                    //         : "is-invalid"
                                                                    //     : ""
                                                                    //     }`}
                                                                    placeholder="From 16"
                                                                    onChange={inputHandle}
                                                                    // onBlur={inputValidation}
                                                                    autoComplete="off"
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Identity Proof is required
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                LLP registered
                                                                outside India
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                {/* Type of identity field */}
                                                                <div className="form-group bottom-space">
                                                                    <label className="label-style">
                                                                        Select  LLP registered
                                                                        outside India
                                                                    </label>
                                                                    <Select
                                                                        name="bank1"
                                                                        options={LLPregistered}
                                                                        onChange={handleBankAccountDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                //     }`}
                                                                >
                                                                    <label className="label-style">Choose File</label>
                                                                    <input
                                                                        type="file"
                                                                        name="identity-proof"
                                                                        className="form-control"
                                                                        // className={`form-control ${isForm16File !== undefined
                                                                        //     ? isForm16File
                                                                        //         ? "is-valid"
                                                                        //         : "is-invalid"
                                                                        //     : ""
                                                                        //     }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        // onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Identity Proof is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Company registered
                                                                outside India
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                {/* Type of identity field */}
                                                                <div className="form-group bottom-space">
                                                                    <label className="label-style">
                                                                        Select Company registered
                                                                        outside India
                                                                    </label>
                                                                    <Select
                                                                        name="bank1"
                                                                        options={companyRegistration}
                                                                        onChange={handleBankAccountDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                //     }`}
                                                                >
                                                                    <label className="label-style">Choose File</label>
                                                                    <input
                                                                        type="file"
                                                                        name="identity-proof"
                                                                        className="form-control"
                                                                        // className={`form-control ${isForm16File !== undefined
                                                                        //     ? isForm16File
                                                                        //         ? "is-valid"
                                                                        //         : "is-invalid"
                                                                        //     : ""
                                                                        //     }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        // onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Identity Proof is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Firm formed or
                                                                registered outside
                                                                India
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                {/* Type of identity field */}
                                                                <div className="form-group bottom-space">
                                                                    <label className="label-style">
                                                                        Select Firm Formed
                                                                    </label>
                                                                    <Select
                                                                        name="bank1"
                                                                        options={FirmFormed}
                                                                        onChange={handleBankAccountDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                //     }`}
                                                                >
                                                                    <label className="label-style">Firm Formed</label>
                                                                    <input
                                                                        type="file"
                                                                        name="identity-proof"
                                                                        className="form-control"
                                                                        // className={`form-control ${isForm16File !== undefined
                                                                        //     ? isForm16File
                                                                        //         ? "is-valid"
                                                                        //         : "is-invalid"
                                                                        //     : ""
                                                                        //     }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        // onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Identity Proof is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Association of
                                                                persons (Trusts)
                                                                formed outside India
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                {/* Type of identity field */}
                                                                <div className="form-group bottom-space">
                                                                    <label className="label-style">
                                                                        Select Firm Formed
                                                                    </label>
                                                                    <Select
                                                                        name="bank1"
                                                                        options={AssociationOutsideIndia}
                                                                        onChange={handleBankAccountDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                //     }`}
                                                                >
                                                                    <label className="label-style">Firm Formed</label>
                                                                    <input
                                                                        type="file"
                                                                        name="identity-proof"
                                                                        className="form-control"
                                                                        // className={`form-control ${isForm16File !== undefined
                                                                        //     ? isForm16File
                                                                        //         ? "is-valid"
                                                                        //         : "is-invalid"
                                                                        //     : ""
                                                                        //     }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        // onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Identity Proof is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Association of
                                                                persons (other than
                                                                Trusts) or body of
                                                                individuals or local
                                                                authority or person
                                                                formed or any other
                                                                entity (by whatever
                                                                name called)
                                                                registered outside
                                                                India
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                {/* Type of identity field */}
                                                                <div className="form-group bottom-space">
                                                                    <label className="label-style">
                                                                        Select Firm Formed
                                                                    </label>
                                                                    <Select
                                                                        name="bank1"
                                                                        options={AssociationOutsideIndia}
                                                                        onChange={handleBankAccountDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                //     }`}
                                                                >
                                                                    <label className="label-style">Firm Formed</label>
                                                                    <input
                                                                        type="file"
                                                                        name="identity-proof"
                                                                        className="form-control"
                                                                        // className={`form-control ${isForm16File !== undefined
                                                                        //     ? isForm16File
                                                                        //         ? "is-valid"
                                                                        //         : "is-invalid"
                                                                        //     : ""
                                                                        //     }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        // onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Identity Proof is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             {/* for display none use this class -> d-none */}
                                             {<div className="step-nav-box">
                                                <Button
                                                    id="saveInformation"
                                                    variant="contained"
                                                    style={{
                                                        borderRadius: "25px",
                                                        background: "#AE0721",
                                                    }}
                                                    onClick={() => checkValidationStep6()}
                                                    endIcon={<SendIcon />}
                                                    disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                >
                                                    Save & Make Payment
                                                </Button>
                                            </div>}
                                        </div>
                                    </form>
                                </div>
                            </section>

                            {/* Footer part */}
                            <section className="grayad">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                                            <span>
                                                <b>Don’t have Information or documents?</b>
                                            </span>
                                            <span className="clearfix d-block">
                                                No worries! You can edit/submit the info and document
                                                later as well
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                                            <div className="pl-0 pl-lg-1 pl-xl-4">
                                                <span className="blue-text">
                                                    <b>{stepName?.data?.planName}</b>
                                                </span>
                                                <span className="clearfix d-block smalltext">
                                                    Made for Immovable property purchasers
                                                </span>
                                            </div>
                                        </div>
                                        {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                                            <a
                                                className="btn btn-dark regbtn"
                                                onClick={() => checkValidationStep6()}
                                            >
                                                Make Payment
                                            </a>
                                        </div> : ''}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NriPanCardForm;