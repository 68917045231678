import axios from "axios";
import React, { useState } from "react";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StayTuned = () => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleSubscribe = (e) => {
    // console.log('Subscribe',email)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    const data = {
      email: email,
    };
    const method = "newsLetterSubscription/subscribe";
    axios
      .post(REACT_APP_BASE_URL + method, data)
      .then((res) => {
        console.log(res.data);
        toast.success("Email is subscribed!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        console.log(err);
        if (
          err.response.data.message ===
          "Email already exists and subscribed to newsLetterSubscription"
        ) {
          toast.warn(
            "Email already exists and subscribed to newsLetterSubscription!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      });
  };
  return (
    <>
      <section className="signup-box stay-tuned">
        <div className="container">
          <h4 className="black-title">STAY TUNED</h4>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <p className="black-smalltext mbp0 mtp10">
                Sign up for our awesome newsletter to get the latest news
              </p>
            </div>
            <div className="col-lg-6 text-left text-lg-right">
              <div className="input-group mtp10">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ padding: "10px" }}
                  >
                    <i className="far fa-envelope"></i>
                  </span>
                </div>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter email address"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    onClick={handleSubscribe}
                    className="btn btn-primary"
                    id="basic-addon2"
                  >
                    SUBSCRIBE
                  </button>
                </div>
              </div>
              {emailError && <p className="text-danger">{emailError}</p>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StayTuned;
