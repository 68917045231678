import React from 'react'
import '../../common/Common.css'
import Header from '../../header/Header'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'
import { Link } from 'react-router-dom'
import DemoHeader from '../../demoHeader/DemoHeader'

const PlanF = () => {
  return (
    <>
        {/* <div className="position-relative">
            <div className="owl-carousel owl-theme banner">
                <div className="item purpal-nav-bg">
                    <div className="d-none d-md-block" style="background: url(images/plan-img.png) no-repeat bottom right; opacity: 0.8; position:absolute;  z-index:0; right:0; bottom:0; height:100%; width: 50%; background-size: 100% 100%;"></div>
              
                    <div className="container-fluid">
                        <div className="row align-items-center vh-100">
                            <div className="col-sm-12 col-md-10  col-lg-8">
                                <div className="banner-text banner-text-xl">
                                    <h2>Plan F – Business Return</h2>
                                    <div className="banner-text-bottom">
                                        <span>Key Highlights of Plan</span>
                                        <ul>
                                            <li>Designed for all types of business entities who are required to maintain books of accounts</li>
                                            <li>Preparation of Financial Statement (Balance Sheet and Profit & Loss A/c)</li>
                                            <li>Dedicated CA assigned on your case</li>
                                            <li>Suggestions on New Tax Saving avenues for next year</li>
                                            <li>Tracking and updating the status of refund, if any</li>
                                            <li>Advisory on queries pertaining to the Income tax return filing</li>
                                        </ul>
                                        <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     

                </div>
            </div>
        </div> */}
    <DemoHeader/>

    <div className="container-fluid" style={{backgroundColor:'#5136e6'}}>
                        <div className="row align-items-center vh-100 headerbackground">
                            <div className="col-sm-12 col-md-10  col-lg-8">
                                <div className="banner-text banner-text-xl">
                                    <h2>Plan F – Business Return</h2>
                                    <div className="banner-text-bottom">
                                        <span>Key Highlights of Plan</span>
                                        <ul>
                                            <li>Designed for all types of business entities who are required to maintain books of accounts</li>
                                            <li>Preparation of Financial Statement (Balance Sheet and Profit & Loss A/c)</li>
                                            <li>Dedicated CA assigned on your case</li>
                                            <li>Suggestions on New Tax Saving avenues for next year</li>
                                            <li>Tracking and updating the status of refund, if any</li>
                                            <li>Advisory on queries pertaining to the Income tax return filing</li>
                                        </ul>
                                        <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
        <div className="planbox" id="Header2">
            <div className="planbox-title">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-9 col-md-8">
                            <h4>Plan F - Business Return</h4>
                        </div>
                        <div className="col-3 col-md-4 text-right" style={{display:'flex'}}>
                            <div className="dropdown d-inline-block arrowbtn d-sm-none">
                                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="images/down-arrow.svg"/>
                                </a>

                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <a className="dropdown-item" href="#aboutplan">About the Plan</a>
                                    <a className="dropdown-item" href="#howitdone">How it’s done</a>
                                    <a className="dropdown-item" href="#docreq">Documents required</a>
                                    <a className="dropdown-item" href="#faqs">FAQs</a>
                                </div>
                            </div>
                            <div className="priceboxin">
                                <span>Just for</span>
                                <h6>₹1499</h6>
                            </div>
                            <Link to="/step">

                            <a className="btn btn-dark btnblack float-right" href="step.html">BUY NOW</a>
                            </Link>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="plannav d-none d-sm-block mtp5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 text-left fixnav">
                            <ul className="nav nav-pills nav-fill" id="top-menu">
                                <li className="nav-item">
                                    <a className="nav-link" href="#aboutplan">About the Plan</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#howitdone">How it’s done</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#docreq">Documents required</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#faqs">FAQs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <section className="tb-space" id="aboutplan">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h5 className="main-title red-row mbp15 d-inline-block">About the Plan</h5>
                    </div>
                </div>
                <div className="row mtp15">
                    <div className="col-sm-6 col-xl-7">
                        <h5 className="sub-title-xs mbp10">Plan details</h5>
                        <p className="black-smalltext">This tax product is designed to provide a complete taxation service to individuals who have income from any business or profession. There are lot of provisions under income tax laws wherein an individual having business/professional income can claim deductions or benefits. In case relevant benefits are not availed then one may end up paying extra taxes. Any mistake while reporting income may invite future litigations and excess payment in terms of tax, penalty and interest.
                        A dedicated expert shall ensure that your income tax return is filed after considering all the provisions of Income tax law updated time to time. Therefore, an individual shall be guided to best of his/her interest and be made aware of tax implications applicable to him/her.
                        </p>
                        <p className="text-xs"> <i>This plan covers the Income tax return filing for an individual who has source of income from any one, two or all of the following heads of income. </i></p>
                        <ul className="list01 mbp15">
                            <li>Income from business or profession where books of accounts are maintained</li>
                            <li>Income from sale of any asset like land, property, shares etc.(Capital gain)</li>
                            <li>Income from Salary, House Rent or any other source</li>
                        </ul>
                        <p className="text-xs"> <i>Note: The package does not include professional charges for DSC and Audit Fees. The same can be provided upon request on separate terms.</i></p>



                        <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
                        <p className="black-smalltext">1. An individual who has income from business or profession and has maintained books of accounts.</p>
                        <p className="black-smalltext">2. Partnership Firm whose turnover exceeds Rs 2 crore in case of business or Rs 50 lakhs in case of profession.</p>
                        <p className="black-smalltext">3. Limited Liability Partnership (LLP) or Companies irrespective of turnover.</p>
                        <p className="black-smalltext">4. Any business person who requires financial statements (Balance Sheet and Profit & Loss A/c) irrespective of turnover.</p>
                       </div>
                    <div className="col-sm-6  col-xl-5">
                        <img src="images/about-img.png" alt="" className="img-fluid m-auto float-md-right"/>
                    </div>
                </div>
            </div>
        </section>

        <section className="tb-space graybg" id="howitdone">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h5 className="main-title red-row mbp15 d-inline-block">How it’s Done</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="hid-list-main">
                            <ul className="hid-list">
                                <li>
                                    <div>
                                        <div className="hid-img"><img src="images/cart-icon.png"/></div>
                                        <div className="hid-text">Purchase of Plan</div>
                                    </div>
                                </li>
                               
                                <li>
                                    <div>
                                        <div className="hid-img"><img src="images/upload-icon.png"/></div>
                                        <div className="hid-text">Upload Documents</div>
                                    </div>
                                </li>
                               
                                <li>
                                    <div>
                                        <div className="hid-img"><img src="images/file-search-icon.png"/></div>
                                        <div className="hid-text">Case overview with expert</div>
                                    </div>
                                </li>
                                <li>
                                <div>
                                <div className="hid-img"><img src="images/file-search-icon.png"/></div>
                                <div className="hid-text">Preparation of Financial Statement</div>
                                </div>
                                </li>
                             
                                <li>
                                    <div>
                                        <div className="hid-img"><img src="images/filling-form-icon.png"/></div>
                                        <div className="hid-text">Review & Approval of Computation of Income</div>
                                    </div>
                                </li>
                              
                                <li>
                                    <div>
                                        <div className="hid-img"><img src="images/generation-form-icon.png"/></div>
                                        <div className="hid-text">Filling of Income Tax Return with Acknowledgment</div>
                                    </div>
                                </li>
                               
                                <li>
                                    <div>
                                        <div className="hid-img"><img src="images/filling-form-icon.png"/></div>
                                        <div className="hid-text">Feedback</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="estimated-row">
                            <span>7 Days Estimated</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="tb-space blackbg" id="docreq">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h5 className="main-title white-row mbp15 d-inline-block white-color">Documents required</h5>
                    </div>
                </div>
                <div className="row mtp25 gutter-20">
                    <div className="col-sm-6 col-md-3">
                        <div className="doc-box">
                            <div className="number-box">01</div>
                            <div className="doc-details">PAN & Aadhar Card</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="doc-box">
                            <div className="number-box">02</div>
                            <div className="doc-details">Bank Statement</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="doc-box">
                            <div className="number-box">03</div>
                            <div className="doc-details">Income and Expense Details</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="doc-box">
                            <div className="number-box">04</div>
                            <div className="doc-details">Investment Details</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                    <div className="doc-box">
                    <div className="number-box">04</div>
                    <div className="doc-details">Other Documents, if any</div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <FaqSection/>
        <Footer/>
    </>
  )
}

export default PlanF