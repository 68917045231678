import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import "./Sidebar.css";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import PhoneInput from "react-phone-input-2";
import "./Profiles.css";
import { toast } from "react-toastify";

const UserProfile = (props) => {
  console.log("props : ", props);
  const [phone, setPhone] = useState("+91");
  const [phoneLastTenDigits, setPhoneLastTenDigits] = useState("");
  const [phoneRemainingDigits, setPhoneRemainingDigits] = useState("");
  const [imageObjectURL, setImageObjectURL] = useState();
  const [getData, setGetData] = useState({});
  const [editData, setEditData] = useState();
  useEffect(() => {
    getAllData();
  }, [editData]);

  const getAllData = () => {
    const token = sessionStorage.getItem("token");

    const method = "user/profile";
    axios
      .get(REACT_APP_BASE_URL + method, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("User Profile :", res.data);
        setGetData(res.data.data);
      })
      .catch((err) => {
        console.log("User Profile :", err);
      });
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    // console.log(image)
    // const imageObjectURL = URL.createObjectURL(image);
    setImageObjectURL(image);
  };
  const handlePhoneChange = (value) => {
    // Remove any non-digit characters from the phone number
    const digitsOnly = value.replace(/\D/g, "");

    // Extract the last 10 digits
    const lastTenDigits = digitsOnly.slice(-10);

    // Extract the remaining digits (if any)
    const remainingDigits = digitsOnly.slice(0, -10);

    setPhoneLastTenDigits(lastTenDigits);
    setPhoneRemainingDigits(
      remainingDigits.length > 0 ? `+${remainingDigits}` : ""
    );
    console.log(lastTenDigits);
    console.log(remainingDigits);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    const token = sessionStorage.getItem("token");

    // console.log(getData)
    const data = {
      firstName: getData.firstName,
      lastName: getData.lastName,
      email: getData.email,
      countryCode: phoneRemainingDigits ? phoneRemainingDigits : "+91",
      phoneNumber: phoneLastTenDigits
        ? phoneLastTenDigits
        : getData.phoneNumber,
      avatar: imageObjectURL ? imageObjectURL : getData.avatar,
    };

    const newData = new FormData();

    newData.append("firstName", getData.firstName);
    newData.append("lastName", getData.lastName);
    newData.append("email", getData.email);
    newData.append("countryCode", phoneRemainingDigits);
    newData.append("phoneNumber", phoneLastTenDigits);
    newData.append("avatar", imageObjectURL);
    console.group(data);

    const method = "user/profile";
    axios
      .patch(REACT_APP_BASE_URL + method, newData, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res);
        setEditData(res.data);
        toast.success("Data Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.handleRefreshChange();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Container maxWidth="xl" style={{ marginTop: "7rem" }} className="customUserProfile">
        <Card sx={{ paddingBottom: 3 }}>
          <CardContent>
            <div className="row">
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 customUserProfileHeading">
                <Typography sx={{ fontSize: 45 }} color="text.secondary">
                  User Profile
                </Typography>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 userProfileEdit">
                <button
                  type="button"
                  className="btn btn-dark regbtn md "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ backgroundColor: "#3d4d65" }}
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="container-flex mt-5 UP-main_cont dataContainer">
              <div className="row dataContainerRow">
                {/* <div className="col-md-12 col-sm-12">
                  <div className="" style={{ display: "flex" }}>
                    <label className="user-fontSize">First Name</label>
                    <span className="ms-3 user-fontSize">
                      :&nbsp;&nbsp;{getData?.firstName}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-3">
                  <div className="UP-lastName" style={{ display: "flex" }}>
                    <label className="user-fontSize">Last Name</label>
                    <span className="ms-3 user-fontSize">
                      :&nbsp;&nbsp;{getData?.lastName}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-3">
                  <div style={{ display: "flex" }}>
                    <label className="user-fontSize">Mobile No</label>
                    <span className="ms-3 user-fontSize">
                      :&nbsp;&nbsp;{getData?.phoneNumber}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-3">
                  <div style={{ display: "flex" }}>
                    <label className="user-fontSize">
                      Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <span className="ms-3 user-fontSize">
                      :&nbsp;&nbsp;{getData?.email}{" "}
                    </span>
                  </div>
                </div> */}
                  <div class="main-table-div">
        <div class="table-content-div">
          <table>
            
            <tr>
              <th>First Name</th>
              <td>{getData?.firstName}</td>
            </tr>

            <tr>
              <th>Last Name</th>
              <td>{getData?.lastName}</td>
            </tr>
            <tr>
              <th>Mobile No</th>
              <td>{getData?.phoneNumber}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{getData?.email}</td>
            </tr>
          </table>
        </div>
      </div>
              </div>
            </div>

            <div
              class="modal fade mt-3"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Edit Details
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {/* Edit Modal Div */}
                  <div class="modal-body">
                    <label>Change Profile Picture :</label>
                    <div className="profile-pic">
                      <label className="-label" htmlFor="file">
                        <span className="glyphicon glyphicon-camera"></span>
                        <span>Change Image</span>
                      </label>
                      <input
                        id="file"
                        name="image"
                        type="file"
                        onChange={handleImageChange}
                      />
                      <img
                        // src={
                        //   `data:image/jpeg;base64,${getData?.avatar}` ? `data:image/jpeg;base64,${getData?.avatar}`:
                        //   "../../images/user.png"
                        // }
                        src={
                          imageObjectURL
                            ? URL.createObjectURL(imageObjectURL)
                            : `data:image/jpeg;base64,${getData?.avatar}`
                        }
                        id="output"
                        width="200"
                        alt="Profile Pic"
                      />
                    </div>

                    <div>
                      <label>First Name</label>
                      <input
                        className="form-control"
                        name="firstName"
                        // style={{ width: "90%" }}
                        value={getData?.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        value={getData?.lastName}
                        // style={{ width: "90%" }}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <label>Email Address</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      onChange={handleInputChange}
                      value={getData?.email}
                      style={{ width: "96%" }}
                    />
                  </div>
                  <div className="ms-3 mt-2">
                    <label>Mobile Number</label>

                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "96%",
                      }}
                    >
                      <PhoneInput
                        fullWidth
                        defaultCountry={"in"}
                        value={
                          getData.phoneNumber
                            ? getData.countryCode + getData.phoneNumber
                            : phoneRemainingDigits + phoneLastTenDigits
                        }
                        onChange={handlePhoneChange}
                        inputComponent={TextField}
                        inputProps={{
                          id: "outlined-basic",
                          label: "Phone Number",
                          variant: "outlined",
                        }}
                        disableDropdown
                        placeholder="Mobile Number"
                        countryCodeEditable={false}
                      />
                    </span>
                  </div>

                  <div class="modal-footer mt-2">
                    <button
                      type="button"
                      class="btn btn-dark regbtn md"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleSubmit}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default UserProfile;
