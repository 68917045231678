import "./FAQ.css";
import { Link,NavLink } from "react-router-dom";

const FaqSection = () => {
  return (
    <>
      <div className="container-flex back-clr Faq-outer" id="faqs" >
        <div className="row" style={{marginTop:'4rem'}}>
          <div className="col-md-12">
            <h1 className="faq-h1">FAQ's</h1>
          </div>
        </div>
        <div className="web-view">
        <div className="row">
          <div className="col-6 col-md-6 col-sm-12 col-xs-12">
            <div className="accordion" id="accordionPlusMinus">
              < div className="accordion-item col-sm-12 col-xs-12">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What are the advantages of Income Tax Filling?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item col-sm-12 col-xs-12">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Ok got it! What are the documents required to file My taxes?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item col-sm-12 col-xs-12">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Are you a tax consultant near me?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-sm-12 col-xs-12">
            <div className="accordion" id="accordionPlusMinus">
              <div className="accordion-item col-sm-12 col-xs-12">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Disadvantages of not filing income tax returns?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item col-sm-12 col-xs-12">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    But my employer want to file on my behalf for nominal Fee,
                    what am I missing?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Well, its law, but that wasn’t enforced in full force in
                      India until recently. Post Nov 8th 2016 gov of India took
                      serious measures in taking the tax filing compliance to
                      new heights.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item col-sm-12 col-xs-12">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    What about if I am an NRI in USA?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <Link to="/morefaq">
            <button type="submit" class="btn500" >
          More FAQ's
        </button>
            </Link> */}
          </div>
          <div className="col-10 col-md-6 col-sm-12 col-xs-12" style={{justifyContent:'end',display:'flex'}}>
            <Link to="/morefaq">
            <button type="submit" class="btn500" >
          More FAQ's
        </button>
            </Link>
          </div>
        </div>
        </div>
        <div className="mob-view">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 col-xs-12">
            <div className="accordion" id="accordionPlusMinus">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What are the advantages of Income Tax Filling?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Ok got it! What are the documents required to file My taxes?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Are you a tax consultant near me?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 col-xs-12">
            <div className="accordion" id="accordionPlusMinus">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Disadvantages of not filing income tax returns?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    But my employer want to file on my behalf for nominal Fee,
                    what am I missing?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Well, its law, but that wasn’t enforced in full force in
                      India until recently. Post Nov 8th 2016 gov of India took
                      serious measures in taking the tax filing compliance to
                      new heights.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What about if I am an NRI in USA?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionPlusMinus"
                >
                  <div className="accordion-body text-bidgray">
                    <p>
                      Past decades have observed low income tax filling
                      compliances in India, during recent years, Govt of India
                      took stringent measures in enforcing the Income Tax Law By
                      linking various benefits for prompt tax fillers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <NavLink to='/MoreFaq'>
            <button type="submit" className="more-faq" >
          More FAQ's
        </button>
        </NavLink>
          </div>
          
        </div>
        </div>
        
      </div>
    </>
  );
};
export default FaqSection;

// import "./FAQ.css";
// import { NavLink } from "react-router-dom";
// const FaqSection = () => {
//   return (
//     <>
//       <div className="container-flex back-clr" >
//         <div className="row">
//           <div className="col-md-12">
//             <h1 className="faq-h1">FAQ's</h1>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-12 col-md-6 col-sm-12 col-xs-12">
//             <div className="accordion" id="accordionPlusMinus">
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button
//                     className="accordion-button collapsed"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseOne"
//                     aria-expanded="true"
//                     aria-controls="collapseOne"
//                   >
//                     What are the advantages of Income Tax Filling?
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseOne"
//                   className="accordion-collapse collapse"
//                   aria-labelledby="headingOne"
//                   data-bs-parent="#accordionPlusMinus"
//                 >
//                   <div className="accordion-body text-bidgray">
//                     <p>
//                       Past decades have observed low income tax filling
//                       compliances in India, during recent years, Govt of India
//                       took stringent measures in enforcing the Income Tax Law By
//                       linking various benefits for prompt tax fillers.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingTwo">
//                   <button
//                     className="accordion-button collapsed"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseTwo"
//                     aria-expanded="false"
//                     aria-controls="collapseTwo"
//                   >
//                     Ok got it! What are the documents required to file My taxes?
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseTwo"
//                   className="accordion-collapse collapse"
//                   aria-labelledby="headingTwo"
//                   data-bs-parent="#accordionPlusMinus"
//                 >
//                   <div className="accordion-body text-bidgray">
//                     <p>
//                       Past decades have observed low income tax filling
//                       compliances in India, during recent years, Govt of India
//                       took stringent measures in enforcing the Income Tax Law By
//                       linking various benefits for prompt tax fillers.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingThree">
//                   <button
//                     className="accordion-button collapsed"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseThree"
//                     aria-expanded="false"
//                     aria-controls="collapseTwo"
//                   >
//                     Are you a tax consultant near me?
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseThree"
//                   className="accordion-collapse collapse"
//                   aria-labelledby="headingThree"
//                   data-bs-parent="#accordionPlusMinus"
//                 >
//                   <div className="accordion-body text-bidgray">
//                     <p>
//                       Past decades have observed low income tax filling
//                       compliances in India, during recent years, Govt of India
//                       took stringent measures in enforcing the Income Tax Law By
//                       linking various benefits for prompt tax fillers.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-md-6 col-sm-12 col-xs-12">
//             <div className="accordion" id="accordionPlusMinus">
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingFour">
//                   <button
//                     className="accordion-button collapsed"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseFour"
//                     aria-expanded="false"
//                     aria-controls="collapseTwo"
//                   >
//                     Disadvantages of not filing income tax returns?
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseFour"
//                   className="accordion-collapse collapse"
//                   aria-labelledby="headingFour"
//                   data-bs-parent="#accordionPlusMinus"
//                 >
//                   <div className="accordion-body text-bidgray">
//                     <p>
//                       Past decades have observed low income tax filling
//                       compliances in India, during recent years, Govt of India
//                       took stringent measures in enforcing the Income Tax Law By
//                       linking various benefits for prompt tax fillers.
//                     </p>
//                   </div>
//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingFive">
//                   <button
//                     className="accordion-button collapsed"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseFive"
//                     aria-expanded="false"
//                     aria-controls="collapseTwo"
//                   >
//                     But my employer want to file on my behalf for nominal Fee,
//                     what am I missing?
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseFive"
//                   className="accordion-collapse collapse"
//                   aria-labelledby="headingFive"
//                   data-bs-parent="#accordionPlusMinus"
//                 >
//                   <div className="accordion-body text-bidgray">
//                     <p>
//                       Well, its law, but that wasn’t enforced in full force in
//                       India until recently. Post Nov 8th 2016 gov of India took
//                       serious measures in taking the tax filing compliance to
//                       new heights.
//                     </p>
//                   </div>
//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingSix">
//                   <button
//                     className="accordion-button collapsed"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseSix"
//                     aria-expanded="false"
//                     aria-controls="collapseTwo"
//                   >
//                     What about if I am an NRI in USA?
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseSix"
//                   className="accordion-collapse collapse"
//                   aria-labelledby="headingSix"
//                   data-bs-parent="#accordionPlusMinus"
//                 >
//                   <div className="accordion-body text-bidgray">
//                     <p>
//                       Past decades have observed low income tax filling
//                       compliances in India, during recent years, Govt of India
//                       took stringent measures in enforcing the Income Tax Law By
//                       linking various benefits for prompt tax fillers.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <NavLink to='/MoreFaq'>
//             <button type="submit" className="more-faq" >
//           More FAQ's
//         </button>
//         </NavLink>
//           </div>
          
//         </div>
        
//       </div>
//     </>
//   );
// };

// export default FaqSection;
