import React from "react";
import "../../components/common/Common.css";
import "../../components/common/owl.carousel.css";
// import $ from 'jquery'
import { Carousel } from "3d-react-carousal";

const Testimonial = () => {
  let slides = [
    <div className="item custom-3d-carousel">
      <div className="testimonialbox box-3d">
        My fellow Earthicans, as I have explained in my book 'Earth in the
        Balance'', and the much more popular ”Harry Potter and the Balance of
        Earth”, we need to defend our planet against pollution.
      </div>
      <span className="span-3d">Marie Ramos</span>
    </div>,
    <div className="item custom-3d-carousel">
      <div className="testimonialbox box-3d">
        My fellow Earthicans, as I have explained in my book 'Earth in the
        Balance'', and the much more popular ”Harry Potter and the Balance of
        Earth”, we need to defend our planet against pollution.
      </div>
      <span className="span-3d">Marie Ramos</span>
    </div>,
    <div className="item custom-3d-carousel">
      <div className="testimonialbox box-3d">
        My fellow Earthicans, as I have explained in my book 'Earth in the
        Balance'', and the much more popular ”Harry Potter and the Balance of
        Earth”, we need to defend our planet against pollution.
      </div>
      <span className="span-3d">Marie Ramos</span>
    </div>,
    <div className="item custom-3d-carousel">
      <div className="testimonialbox box-3d">
        <span>My fellow Earthicans, as I have explained in my book 'Earth in the
        Balance'', and the much more popular ”Harry Potter and the Balance of
        Earth”, we need to defend our planet against pollution.
        </span>
      </div>
      <span className="span-3d">Marie Ramos</span>
    </div>,
    <div className="item custom-3d-carousel">
      <div className="testimonialbox box-3d">
      <span>My fellow Earthicans, as I have explained in my book 'Earth in the
        Balance'', and the much more popular ”Harry Potter and the Balance of
        Earth”, we need to defend our planet against pollution.
        </span>
      </div>
      <span className="span-3d">Marie Ramos</span>
    </div>,
    <div className="item custom-3d-carousel">
      <div className="testimonialbox box-3d">
      <span>My fellow Earthicans, as I have explained in my book 'Earth in the
        Balance'', and the much more popular ”Harry Potter and the Balance of
        Earth”, we need to defend our planet against pollution.
        </span>
      </div>
      <span className="span-3d">Marie Ramos</span>
    </div>,
  ];

  return (
    <section className="tb-space testimonial-outer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5 className="main-title red-row mbp15 d-inline-block">
              Testimonial
            </h5>
          </div>
          <div className="col-sm-12 text-center mtp30">
            <div className="testimonial-bg">
              <h5 className="d-none d-md-block">300+ HAPPY CUSTOMERS</h5>
              <div className="row" style={{ marginTop: "45px" }}>
                <div className="col-sm-12">
                  <Carousel
                    slides={slides}
                    autoplay={true}
                    interval={100000}
                    arrows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
