import "./Footer.css";
import UnderLine from "./UnderLine";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Footer = () => {

    const openDesclaimer = () => {
        const pdfUrl = `${process.env.PUBLIC_URL}/assets/DISCLAIMER.docx`;
        window.open(pdfUrl, '_blank');
    }
    const openTerms = () => {
        const pdfUrl = `${process.env.PUBLIC_URL}/assets/TERMS & CONDITION.docx`;
        window.open(pdfUrl, '_blank');
    }
    const openPrivacy = () => {
        const pdfUrl = `${process.env.PUBLIC_URL}/assets/PRIVACY POLICY.docx`;
        window.open(pdfUrl, '_blank');
    }
    return (
        <>
            <footer className="footer-outer">
                <div className="ft-black-bg">
                    <div className="container showResp">
                        <div className="accordion" id="footeraccordion">
                            <div className="row">
                                <div className="col">
                                    <div className="FTaccordion-style">
                                        <div id="headingFTOne">
                                            <a
                                                // href="#"
                                                className="d-block FTacc-title collapsed"
                                                data-toggle="collapse"
                                                data-target="#collapseFTOne"
                                                aria-expanded="false"
                                                aria-controls="collapseFTOne"
                                            >
                                                <img src="images/minus.png" alt="" className="minus" />
                                                <img src="images/plus.png" alt="" className="plus" />
                                                <span className="footer-head">Tax Savvy</span>
                                                <UnderLine />
                                            </a>
                                        </div>
                                        <div
                                            id="collapseFTOne"
                                            className="collapse"
                                            aria-labelledby="headingFTOne"
                                            data-parent="#footeraccordion">
                                            <div className="FTaccordion-body">
                                                <ul>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>About us</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Contact us</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>careers</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>User reviews</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Blog</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Trust & Safety</a></li>
                                                    <li onClick={openPrivacy}><a href="#" className="footer-list"><span><ArrowRightIcon color="error" /></span>PRIVACY POLICY</a></li>
                                                    <li onClick={openTerms}><a href="#" className="footer-list"><span><ArrowRightIcon color="error" /></span>TERMS AND CONDITIONS</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Return Policy</a></li>
                                                    <li onClick={openDesclaimer}><a href="#" className="footer-list"><span><ArrowRightIcon color="error" /></span>DISCLAIMER</a></li>

                                                    {/* <li><a href="#">Media &  Press</a></li>
                                            <li><a href="#">User Reviews</a></li>
                                            <li><a href="#">Taxcloud India</a></li>
                                            <li><a href="#">Clear TDS</a></li>
                                            <li><a href="#">Engineering Blogs</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="FTaccordion-style">
                                        <div id="headingFTTwo">
                                            <a href="#" className="d-block FTacc-title collapsed" data-toggle="collapse" data-target="#collapseFTTwo" aria-expanded="false" aria-controls="collapseFTTwo"> <img src="images/minus.png" alt="" className="minus" /> <img src="images/plus.png" alt="" className="plus" /> <span className="footer-head">Start a Business</span></a>
                                            <UnderLine />

                                        </div>

                                        <div id="collapseFTTwo" className="collapse" aria-labelledby="headingFTTwo" data-parent="#footeraccordion">
                                            <div className="FTaccordion-body">
                                                <ul>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Private Limited Company</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Limited Liability Partnership</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>One Person Company</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Partnership Firm</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Proprietorship Firm</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="FTaccordion-style">
                                        <div id="headingFTThree">
                                            <a href="#" className="d-block FTacc-title collapsed" data-toggle="collapse" data-target="#collapseFTThree" aria-expanded="false" aria-controls="collapseFTThree"> <img src="images/minus.png" alt="" className="minus" /> <img src="images/plus.png" alt="" className="plus" /> <span className="footer-head">Trademark & Copyright</span></a>
                                            <UnderLine />

                                        </div>
                                        <div id="collapseFTThree" className="collapse" aria-labelledby="headingFTThree" data-parent="#footeraccordion">
                                            <div className="FTaccordion-body">
                                                <ul>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Trademark Registration</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Trademark Objection</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Trademark Assignment</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Trademark Renewal</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Copyright Registration</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col">
                                    <div className="FTaccordion-style">
                                        <div id="headingFTFour">
                                            <a href="#" className="d-block FTacc-title collapsed" data-toggle="collapse" data-target="#collapseFTFour" aria-expanded="false" aria-controls="collapseFTFour"> <img src="images/minus.png" alt="" className="minus" /> <img src="images/plus.png" alt="" className="plus" /> <span className="footer-head">Tax tools by ClearTax</span></a>
                                        </div>
                                        <div id="collapseFTFour" className="collapse" aria-labelledby="headingFTFour" data-parent="#footeraccordion">
                                            <div className="FTaccordion-body">
                                                <ul>
                                                    <li><a href="#">GST Software</a></li>
                                                    <li><a href="#">New GST Returns</a></li>
                                                    <li><a href="#">E-Invoicing</a></li>
                                                    <li><a href="#">Input Tax Credit</a></li>
                                                    <li><a href="#">GST Returns</a></li>
                                                    <li><a href="#">GST Invoice</a></li>
                                                    <li><a href="#">GST Services</a></li>
                                                    <li><a href="#">GST Training</a></li>
                                                    <li><a href="#">GST Calendar</a></li>
                                                    <li><a href="#">GST News</a></li>
                                                    <li><a href="#">Tax tools bny ClearTax</a></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col">
                                    <div className="FTaccordion-style">
                                        <div id="headingFTOne">
                                            <a href="#" className="d-block FTacc-title collapsed" data-toggle="collapse" data-target="#collapseFTOne" aria-expanded="false" aria-controls="collapseFTOne"><img src="images/minus.png" alt="" className="minus" /> <img src="images/plus.png" alt="" className="plus" /> <span className="footer-head">Taxation</span></a>
                                            <UnderLine />

                                        </div>
                                        <div id="collapseFTOne" className="collapse" aria-labelledby="headingFTOne" data-parent="#footeraccordion">
                                            <div className="FTaccordion-body">
                                                <ul>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Income Tax Return</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>TDS Return</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>GST Registration</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>Input Tax Credit</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>GST Return</a></li>
                                                    <li className="footer-list"><a href="#"><span><ArrowRightIcon color="error" /></span>GST Software</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="ft-social-icon text-center">
                            {/* <div className="clearfix">
                        <a href="#"><img src="images/twitter-icon.png" alt=""/></a>
                        <a href="#"><img src="images/facebook-icon.png" alt=""/></a>
                        <a href="#"><img src="images/google-plus-icon.png" alt=""/></a>
                        <a href="#"><img src="images/instagram-icon.png" alt=""/></a>
                    </div> */}
                            <div className="sm">
                                {/* <a href="#"><img className="fa-brands fa-twitter" src="images/twitter-icon.png" alt="" /></a>
                                <a href="#"><img className="fa-brands fa-facebook-f" src="images/facebook-icon.png" alt="" /></a>
                                
                                <a href="#"><img className="fa-brands fa-instagram" src="images/instagram-icon.png" alt="" /></a>
                                <a href="#"><img className="fa-brands fa-linkedin" src="images/linkedinpng.png" alt="" height={'40px'} /></a> */}

                                <ul class="wrapper">
                                    <li class="icon facebook">
                                        <span class="tooltip">Facebook</span>
                                        <span><i class="fab fa-facebook-f"></i></span>
                                    </li>
                                    <li class="icon twitter">
                                        <span class="tooltip">Twitter</span>
                                        <span><i class="fab fa-twitter"></i></span>
                                    </li>
                                    <li class="icon instagram">
                                        <span class="tooltip">Instagram</span>
                                        <span><i class="fab fa-instagram"></i></span>
                                    </li>
                                    <li class="icon linkedin">
                                        <span class="tooltip">LinkedIn</span>
                                        <span><i class="fab fa-linkedin"></i></span>
                                    </li>
                                </ul>
                            </div>
                            <p className="copyright-msg">© 2023 Copyright tax-savvy.in</p>
                        </div>
                    </div>
                </div>

                <div className="mob-view">
                    <div className="row showOn-resonsive">
                        <div className="col-12 col-md-6 col-sm-12 col-xs-12">
                            <div className="accordion" id="accordionPlusMinus">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed showrevert"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFOne"
                                            aria-expanded="true"
                                            aria-controls="collapseFOne"
                                        >
                                            Tax Savvy
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionPlusMinus"
                                    >
                                        <div className="accordion-body text-bidgray">
                                            <ul>
                                                <li><a href="#">About us</a></li>
                                                <li><a href="#">Contact us</a></li>
                                                <li><a href="#">careers</a></li>
                                                <li><a href="#">User reviews</a></li>
                                                <li><a href="#">Blog</a></li>
                                                <li><a href="#">Trust & Safety</a></li>
                                                <li onClick={openPrivacy}><a style={{ color: '#0d6efd' }}>Privacy Policy</a></li>
                                                <li onClick={openTerms}><a style={{ color: '#0d6efd' }}>Terms And Conditions</a></li>
                                                <li><a href="#">Return Policy</a></li>
                                                <li onClick={openDesclaimer}><a style={{ color: '#0d6efd' }}>Disclaimer</a></li>

                                                {/* <li><a href="#">Media &  Press</a></li>
                                            <li><a href="#">User Reviews</a></li>
                                            <li><a href="#">Taxcloud India</a></li>
                                            <li><a href="#">Clear TDS</a></li>
                                            <li><a href="#">Engineering Blogs</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed showrevert"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseFTwo"
                                        >
                                            Start a Business
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionPlusMinus"
                                    >
                                        <div className="accordion-body text-bidgray">
                                            <ul>
                                                <li><a href="#">Private Limited Company</a></li>
                                                <li><a href="#">Limited Liability Partnership</a></li>
                                                <li><a href="#">One Person Company</a></li>
                                                <li><a href="#">Partnership Firm</a></li>
                                                <li><a href="#">Proprietorship Firm</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed showrevert"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFThree"
                                            aria-expanded="false"
                                            aria-controls="collapseFTwo"
                                        >
                                            Trademark & Copyright
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionPlusMinus"
                                    >
                                        <div className="accordion-body text-bidgray">
                                            <ul>
                                                <li><a href="#">Trademark Registration</a></li>
                                                <li><a href="#">Trademark Objection</a></li>
                                                <li><a href="#">Trademark Assignment</a></li>
                                                <li><a href="#">Trademark Renewal</a></li>
                                                <li><a href="#">Copyright Registration</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed showrevert"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFTwo"
                                        >
                                            Taxation
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionPlusMinus"
                                    >
                                        <div className="accordion-body text-bidgray">
                                            <ul>
                                                <li><a href="#">Income Tax Return</a></li>
                                                <li><a href="#">TDS Return</a></li>
                                                <li><a href="#">GST Registration</a></li>
                                                <li><a href="#">Input Tax Credit</a></li>
                                                <li><a href="#">GST Return</a></li>
                                                <li><a href="#">GST Software</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ft-social-icon text-center">
                            {/* <div className="clearfix">
                        <a href="#"><img src="images/twitter-icon.png" alt=""/></a>
                        <a href="#"><img src="images/facebook-icon.png" alt=""/></a>
                        <a href="#"><img src="images/google-plus-icon.png" alt=""/></a>
                        <a href="#"><img src="images/instagram-icon.png" alt=""/></a>
                    </div> */}
                            <div className="sm">
                                {/* <a href="#"><img className="fa-brands fa-twitter" src="images/twitter-icon.png" alt="" /></a>
                                <a href="#"><img className="fa-brands fa-facebook-f" src="images/facebook-icon.png" alt="" /></a>
                              
                                <a href="#"><img className="fa-brands fa-instagram" src="images/instagram-icon.png" alt="" /></a>
                                <a href="#"><img className="fa-brands fa-linkedin" src="images/linkedinpng.png" alt="" height={'40px'} /></a> */}

                                <ul class="wrapper">
                                    <li class="icon facebook">
                                        <span class="tooltip">Facebook</span>
                                        <span><i class="fab fa-facebook-f"></i></span>
                                    </li>
                                    <li class="icon twitter">
                                        <span class="tooltip">Twitter</span>
                                        <span><i class="fab fa-twitter"></i></span>
                                    </li>
                                    <li class="icon instagram">
                                        <span class="tooltip">Instagram</span>
                                        <span><i class="fab fa-instagram"></i></span>
                                    </li>
                                    <li class="icon linkedin">
                                        <span class="tooltip">LinkedIn</span>
                                        <span><i class="fab fa-linkedin"></i></span>
                                    </li>
                                </ul>
                            </div>
                            <p className="copyright-msg">© 2023 Copyright tax-savvy.in</p>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
