import React, { useState } from "react";
import "../../../common/Common.css";
import "../../incometax/Step.css";
import Header from "../../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import DemoHeader from "../../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../../loader/Loader";

const LlpReturnForm = () => {
    const [planFee, setPlanFee] = useState();
    const [data, setData] = useState({});
    const [userId, setUserId] = useState();
    const [steps, setSteps] = useState();
    const [properties, setProperties] = useState();
    const [number, setNumber] = useState(1);
    const [count, setCount] = useState();
    const [IsEnable, setIsEnable] = useState(false);
    const [isLastName, setIsLastName] = useState();
    const [isFirstName, setIsFirstName] = useState();
    const [isMiddleName, setIsMiddleName] = useState();
    const [isEmail, setIsEmail] = useState();
    const [isMobile, setIsMobile] = useState();
    const [isPassport, setIsPassport] = useState();
    const [passportValue, setIsPassportValue] = useState("");
    const [isPan, setIsPan] = useState();
    const [panValue, setIsPanValue] = useState("");
    const [isPanDocument, setPanIsDocument] = useState();
    const [isAdhar, setIsAdhar] = useState();
    const [isAdharDocument, setIsAdharDocument] = useState();
    const [documentId, setDocumentId] = useState();
    const [token, setToken] = useState();
    const [isHouseProperty, setIsHouseProperty] = useState(false);

    const [isBalanceSheet, setIsBalanceSheet] = useState();
    const [isProfitLossStatement, setIsProfitLossStatement] = useState();
    const [isAnnualReport,setIsAnnualReport] = useState();
    const [isShareHolderName, setIsShareHolderName] = useState();
    const [isAuditorReport, setIsAuditorReport] = useState();
    const [isComplianceCertificate, setIsComplianceCertificate] = useState();
    const [isTaxReturnFile, setIsTaxReturnFile] = useState();
    const [isDirectorName, setIsDirectorName] = useState();
    const [isResolutionType, setIsResolutionType] = useState();
    const [isResolutionTypeFile, setIsResolutionTypeFile] = useState();
    const [isResolutionDate, setIsResolutionDate] = useState();
    const [isResolutionDescription, setIsResolutionDescription] = useState();
    const [isStartdDate, setIsStartdDate] = useState();
    const [isEndDate, setIsEndDate] = useState();
    const [isNumberOfShare, setIsNumberOfShare] = useState();
    const [isShareHolderPercentage, setIsShareHolderPercentage] = useState();

    const navigate = useNavigate();

    const Location = useLocation();
    // const stepName = Location.state;
    // console.log(stepName);
    useEffect(() => {
        window.scrollTo(0, 0);
        setPlanFee(sessionStorage.getItem("planFee"));
        setDocumentId(localStorage.getItem("documentId"));
        setToken(sessionStorage.getItem("token"));
    }, [number, IsEnable, isHouseProperty]);

 

    const ResolutionType = [
        { value: "Aadhar Card", label: "Aadhar Card" },
        { value: "PAN Card", label: "PAN Card" },
        { value: "Passport", label: "Passport" },
        { value: "Driving License", label: "Driving License" },
        { value: "Voter ID", label: "Voter ID" },
    ]

    // const handleRadioChange = (event) => {
    //     console.log(event.target.value);
    //     if (event.target.value === "radio1") {
    //         setIsRadio1Selected(true);
    //         setIsAnnualSalary();
    //         setIsNameOfEmployer();
    //         setIsPanOfEmployer();
    //         setIsRemarks2();
    //         data.annualSalary = "";
    //         data.nameOfEmployer = "";
    //         data.panOfEmployer = "";
    //         data.remarks2 = "";
    //     } else {
    //         setIsRadio1Selected(false);
    //         setIsForm16File();
    //         setIsanyOtherExemptionToBeClaimed();
    //         setIsexemptionRelatedDocuments();
    //         setIsRemarks();
    //         data.form16File = "";
    //         data.anyOtherExemptionToBeClaimed = "";
    //         data.exemptionRelatedDocuments = "";
    //         data.remarks = "";
    //     }
    // };

    // $(document).ready(function () {
  
    // });

    const inputHandle = (e) => {
        const { name, value, type, files } = e.target;
        if (type == "file") {
            console.log(files);
            console.log(files[0].type);
            const fileType = files[0].type.split("/")[1];
            const method = `document/upload/${fileType}`;
            const formData = new FormData();
            formData.append("file", files[0]);
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            setData({ ...data, [name]: res.data.data.key });

                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            if (name === "panNumber") {
                setIsPanValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            if (name === "passportNumber") {
                setIsPassportValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            setData({ ...data, [name]: value });
        }
    };

    const handleResolutionType = (e, { name }) => {
        setIsResolutionType({ ...isResolutionType, [name]: e.value });
      };

    const inputValidation = (e) => {
        console.log("data : " , data)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passportPattern =
            /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
        const { name, value, type, files } = e.target;
        console.log("name, value, type, files : " , name, value, type, files)
        const isValidEmail = emailPattern.test(value);
        const isValidPassportNumber = passportPattern.test(value);
       
        if(name === "BalanceSheets"){
            if(files.length>0){
                setIsBalanceSheet(true);
            }else{
                setIsBalanceSheet(false)
            }
        }
        if(name === "ProfitLossStatement"){
            if(files.length>0){
                setIsProfitLossStatement(true);
            }else{
                setIsProfitLossStatement(false)
            }
        }
        if(name === "AnnualReport"){
            if(files.length>0){
                setIsAnnualReport(true);
            }else{
                setIsAnnualReport(false)
            }
        }
        if (name === "Director-Name") {
            if (value.length > 1) {
                setIsDirectorName(true);
            } else {
                setIsDirectorName(false);
            }
        }
        if (name === "Resolution-Date") {
            const inputDate = new Date(value);
        
            if (isNaN(inputDate.getTime())) {
                setIsResolutionDate(false);
            } else {
                setIsResolutionDate(true);
            }
        }
        if(name === "Description"){
            if(value.length > 1){
                setIsResolutionDescription(true)
            }else{
                setIsResolutionDescription(false);  
            }
        }
        if (name == "shareholderName") {
            if (value.length > 1) {
                setIsShareHolderName(true);
            } else {
                setIsShareHolderName(false);
            }
        }
        if (name === "Number-of-Shares") {
            if (value > 0) {
                setIsNumberOfShare(true);
            } else {
                setIsNumberOfShare(false);
            }
        }
        if (name === "ShareholderPercentage") {
            if (value > 0) {
                setIsShareHolderPercentage(true);
            } else {
                setIsShareHolderPercentage(false);
            }
        }
        if (name === "Director-Start-Date") {
            const inputDate = new Date(value);
        
            if (isNaN(inputDate.getTime())) {
                setIsStartdDate(false);
            } else {
                setIsStartdDate(true);
            }
        }
        if (name === "Director-End-Date") {
            const inputDate = new Date(value);
        
            if (isNaN(inputDate.getTime())) {
                setIsEndDate(false);
            } else {
                setIsEndDate(true);
            }
        }
        if(name === "AuditorReport"){
            if(files.length>0){
                setIsAuditorReport(true);
            }else{
                setIsAuditorReport(false)
            }
        }
        if(name === "compliancecertificates"){
            if(files.length>0){
                setIsComplianceCertificate(true);
            }else{
                setIsComplianceCertificate(false)
            }
        }
        if(name === "TaxReturns"){
            if(files.length>0){
                setIsTaxReturnFile(true);
            }else{
                setIsTaxReturnFile(false)
            }
        }
        if(name === "resolutionTypeFile"){
            if(files.length>0){
                setIsResolutionTypeFile(true);
            }else{
                setIsResolutionTypeFile(false)
            }
        }
    };

    const handleDropdown = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
          setIsResolutionType(false);
        } else {
          setIsResolutionType(true);
        }
        setData({ ...data, [name]: value });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        newFormData.append("namePanCard", data.namePanCard);
        newFormData.append("passportNumber", data.passportNumber);
        newFormData.append("email", data.email);
        newFormData.append("mobileNumber", data.mobileNumber);
        newFormData.append("panCardDocument", data.panCardDocument);
        newFormData.append("aadharCardDocument", data.aadharCardDocument);
        newFormData.append("form16File", data.form16File);
        newFormData.append("heldUnlisted", data.heldUnlisted);

        const url = "https://apibase.taxespro.in/";
        const method = `user/generate/income-tax/document/${userId}`;
        try {
            const response = await axios.post(url + method, newFormData);
            console.log(response.data); // Output: Response data from the server
            toast.success("Information saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <>
            <DemoHeader />
            <div className="bannertopstep">
                <div className="container text-left">
                    <div className="banner-vhmian1 itr-breadcrumb">
                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            // to="/incometaxreturn"
                            onClick={() => window.history.go(-1)}
                        >
                            ROC Filling
                        </Link>
                        <Typography color="text.primary">ROC Filling Form</Typography>
                    </Breadcrumbs>
                    <div className="">
                        <div className="top-space-section step-gap">
                            <section className="step-box clearfix">
                                <div className="container">
                                    <div
                                    // className="step-number-row1"
                                    ></div>
                                    {/* steps name */}
                                    <ul className="nav nav-fill step-number row gutter-0 d-flex" style={{ justifyContent: 'flex-start' }}>
                                        {/* {steps &&
                      steps.map((data, i) => {
                        return (
                          <li className="nav-item col-2" key={i}>
                            <a
                              className={`nav-link nm${data?.number} ${i === 0 ? 'active' : '' }`}
                              
                              data-toggle="tooltip"
                              data-placement="top"
                              // title="General Information"
                              onClick={()=>{handleNumber(i+1)}}
                            >
                              <span>
                                {data?.number}
                                <img src="images/check-w.png" alt="" />
                              </span>
                              <p className="d-none d-sm-block">{data?.title}</p>
                            </a>
                          </li>
                        );
                      })} */}

                                        {/* nav-link nm1 active */}
                                        {/* step 1 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm1 active"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="General Information"
                                            >
                                                <span>
                                                    1<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    ROC filing Form
                                                </p>
                                            </a>
                                        </li>

                                    </ul>

                                    <form onSubmit={handleSubmit}>
                                        <div className="step-details step-form">
                                            {/*Step-1  */}
                                            <div className={`step-${number}`}>
                                                <div className="row">


                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Financial Statements and Documents
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Balance Sheets</label>
                                                                        <input
                                                                            type="file"
                                                                            name="BalanceSheets"
                                                                            className={`form-control ${isBalanceSheet !== undefined
                                                                                ? isBalanceSheet
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Balance Sheets"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Balance Sheets is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space" >
                                                                        <label className="label-style">Profit and Loss Statement </label>
                                                                        <input
                                                                            type="file"
                                                                            name="ProfitLossStatement"
                                                                            className={`form-control ${isProfitLossStatement !== undefined
                                                                                ? isProfitLossStatement
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Profit and Loss Statement "
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Profit and Loss Statement  is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                    //     }`}
                                                                    >
                                                                        <label className="label-style">Annual Report</label>
                                                                        <input
                                                                            type="file"
                                                                            name="AnnualReport"
                                                                            className={`form-control ${isAnnualReport !== undefined
                                                                                ? isAnnualReport
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Annual Report"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Annual Report is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Tax Returns</label>
                                                                        <input
                                                                            type="file"
                                                                            name="TaxReturns"
                                                                            className={`form-control ${isTaxReturnFile !== undefined
                                                                                ? isTaxReturnFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Tax Returns"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Tax Returns is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Company Resolutions
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6 col-lg-6 col-xl-6">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div className="form-group bottom-space">
                                                                            <label className="label-style">
                                                                                Resolution Type
                                                                            </label>
                                                                            <Select
                                                                                name="ResolutionType"
                                                                                options={ResolutionType}
                                                                                onChange={handleDropdown}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-lg-6 col-xl-6">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">Select File</label>
                                                                            <input
                                                                                type="file"
                                                                                name="resolutionTypeFile"
                                                                                className={`form-control ${isResolutionTypeFile !== undefined
                                                                                    ? isResolutionTypeFile
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Resolution Type File"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                            Resolution Type File is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Resolution Date </label>
                                                                        <input
                                                                            type="date"
                                                                            name="Resolution-Date"
                                                                            className={`form-control ${isResolutionDate !== undefined
                                                                                ? isResolutionDate
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Resolution Date "
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Resolution Date  is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Description</label>
                                                                        <textarea
                                                                            type="file"
                                                                            name="Description"
                                                                            className={`form-control ${isResolutionDescription !== undefined
                                                                                ? isResolutionDescription
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Description"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <div className="invalid-feedback">
                                                                            Description is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Shareholding Details
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">Shareholder Name </label>
                                                                        <input
                                                                            type="text"
                                                                            name="shareholderName"
                                                                            className={`form-control ${isShareHolderName !== undefined
                                                                                ? isShareHolderName
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Shareholder Name "
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Shareholder Name  is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Number of Shares</label>
                                                                        <input
                                                                            type="number"
                                                                            name="Number-of-Shares"
                                                                            className={`form-control ${isNumberOfShare !== undefined
                                                                                ? isNumberOfShare
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Number of Shares"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Number of Shares are required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Shareholder Percentage</label>
                                                                        <input
                                                                            type="number"
                                                                            name="ShareholderPercentage"
                                                                            className={`form-control ${isShareHolderPercentage !== undefined
                                                                                ? isShareHolderPercentage
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Shareholder Percentage"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Shareholder Percentage required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Directorship Details
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                    //     }`}
                                                                    >
                                                                        <label className="label-style">Director Name </label>
                                                                        <input
                                                                            type="text"
                                                                            name="Director-Name"
                                                                            className={`form-control ${isDirectorName !== undefined
                                                                                ? isDirectorName
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Director Name "
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Director Name  is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Director Start Date</label>
                                                                        <input
                                                                            type="date"
                                                                            name="Director-Start-Date"
                                                                            className={`form-control ${isStartdDate !== undefined
                                                                                ? isStartdDate
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Director Start Date"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Director Start Date
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Director End Date</label>
                                                                        <input
                                                                            type="date"
                                                                            name="Director-End-Date"
                                                                            className={`form-control ${isEndDate !== undefined
                                                                                ? isEndDate
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Director End Date"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Director End Date
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Auditor's report
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >

                                                                        <input
                                                                            type="file"
                                                                            name="AuditorReport"
                                                                            className={`form-control ${isAuditorReport !== undefined
                                                                                ? isAuditorReport
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Auditor's report is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Compliance Certificates
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    // className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                                                    //     }`}
                                                                    >
                                                                        <input
                                                                            type="file"
                                                                            name="compliancecertificates"
                                                                            className={`form-control ${isComplianceCertificate !== undefined
                                                                                ? isComplianceCertificate
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="compliance certificates"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            compliance certificates is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                     {isBalanceSheet !== undefined  ||
                     isProfitLossStatement !== undefined ||
                     isAnnualReport !== undefined ||
                     isAuditorReport !== undefined ||
                     isComplianceCertificate !== undefined ||
                     isShareHolderName !== undefined ||
                     isTaxReturnFile !== undefined ||
                     isResolutionDate !== undefined ||
                     isResolutionDescription !== undefined ||
                     isStartdDate !== undefined ||
                     isEndDate !== undefined ||
                     isNumberOfShare !== undefined ||
                     isShareHolderPercentage !== undefined ||
                     isResolutionTypeFile !== undefined ||
                     isResolutionType !== undefined ? (
                          !isBalanceSheet ||
                          !isProfitLossStatement ||
                          !isAnnualReport ||
                          !isAuditorReport ||
                          !isComplianceCertificate ||
                          !isShareHolderName ||
                          !isTaxReturnFile ||
                          !isResolutionDate ||
                          !isResolutionDescription ||
                          !isStartdDate ||
                          !isEndDate ||
                          !isNumberOfShare ||
                          !isShareHolderPercentage ||
                          !isResolutionTypeFile ||
                          !isResolutionType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Please fill all epmty fileds...!
                            </p>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )} 
                                                <div className="step-nav-box">
                                                    <Button
                                                        id="saveInformation"
                                                        variant="contained"
                                                        style={{
                                                            borderRadius: "25px",
                                                            background: "#AE0721",
                                                        }}
                                                        // onClick={() => checkValidationStep6()}
                                                        endIcon={<SendIcon />}
                                                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                    >
                                                        Save & Make Payment
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </section>

                            {/* Footer part */}
                            <section className="grayad">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                                            <span>
                                                <b>Don’t have Information or documents?</b>
                                            </span>
                                            <span className="clearfix d-block">
                                                No worries! You can edit/submit the info and document
                                                later as well
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                                            <div className="pl-0 pl-lg-1 pl-xl-4">
                                                <span className="blue-text">
                                                    {/* <b>{stepName?.data?.planName}</b> */}
                                                </span>
                                                <span className="clearfix d-block smalltext">
                                                    Made for Immovable property purchasers
                                                </span>
                                            </div>
                                        </div>
                                        {/* {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                                            <a
                                                className="btn btn-dark regbtn"
                                                onClick={() => checkValidationStep6()}
                                            >
                                                Make Payment
                                            </a>
                                        </div> : ''} */}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LlpReturnForm;
