import React, { useState } from "react";
import "../../common/Common.css";
import "../incometax/Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const TdsOnPropertyPurchaseForm = () => {
    const [planFee, setPlanFee] = useState();
    const [data, setData] = useState({});
    const [userId, setUserId] = useState();
    const [steps, setSteps] = useState();
    const [properties, setProperties] = useState();
    const [number, setNumber] = useState(1);
    const [count, setCount] = useState();
    const [IsEnable, setIsEnable] = useState(false);
    const [isLastName, setIsLastName] = useState();
    const [isFirstName, setIsFirstName] = useState();
    const [isMiddleName, setIsMiddleName] = useState();
    const [isEmail, setIsEmail] = useState();
    const [isMobile, setIsMobile] = useState();
    const [isPassport, setIsPassport] = useState();
    const [passportValue, setIsPassportValue] = useState("");
    const [isPan, setIsPan] = useState();
    const [panValue, setIsPanValue] = useState("");
    const [isPanDocument, setPanIsDocument] = useState();
    const [isAdhar, setIsAdhar] = useState();
    const [isAdharDocument, setIsAdharDocument] = useState();
    const [ToastCount, setToastCount] = useState(0);
    const [isRadio1Selected, setIsRadio1Selected] = useState(true);
    const [documentId, setDocumentId] = useState();
    const [token, setToken] = useState();
    const [isForm16File, setIsForm16File] = useState();
    const [isAnotherDiv, setIsAnotherDiv] = useState(false);

    const [bankAccountData, setBankAccountData] = useState({});

    const [isPanOfBuyer, setIsPanOfBuyer] = useState();
    const [isPanOfSeller, setIsPanOfSeller] = useState();
    const [isAadharOfBuyer, setIsAadharOfBuyer] = useState();
    const [isAadharOfSeller, setIsAadharOfSeller] = useState();
    const [isPropertyValue, setIsPropertyValue] = useState();
    const [isPropertyAddress, setIsPropertyAddress] = useState();
    const [isPropertySelected, setIsPropertySelected] = useState();
    const [isPropertySize, setIsPropertySize] = useState();
    const [isTdsAmount, setIsTdsAmount] = useState();
    const [isTransactionDate, setIsTransactionDate] = useState();
    const [isChallanReceipt, setIsChallanReceipt] = useState();

    const [showMakePaymentBTN, setShowMakePaymentBTN] = useState(false)

    const navigate = useNavigate();
    const Location = useLocation();
    const stepName = Location.state;
    console.log(stepName);
    useEffect(() => {
        window.scrollTo(0, 0);
        setPlanFee(sessionStorage.getItem("planFee"));
        setDocumentId(localStorage.getItem("documentId"));
        setToken(sessionStorage.getItem("token"));
    }, []);

    const PropertyType = [
        { value: "residential", label: "residential" },
        { value: "commercial", label: " commercial," },
        { value: "agricultural", label: "agricultural" },
    ]

    const inputHandle = (e) => {
        const { name, value, type, files } = e.target;

        console.log("radio : ", name, value.toUpperCase(), type);
        if (type == "file") {
            console.log(files);
            console.log(files[0].type);
            const fileType = files[0].type.split("/")[1];
            const method = `document/upload/${fileType}`;
            const formData = new FormData();
            formData.append("file", files[0]);
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            setData({ ...data, [name]: res.data.data.key });

                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            if (name === "panNumber") {
                setIsPanValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            if (name === "passportNumber") {
                setIsPassportValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            setData({ ...data, [name]: value });
        }
    };

    const inputValidation = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passportPattern =
            /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
        const { name, value, type, files } = e.target;
        const isValidEmail = emailPattern.test(value);
        const isValidPassportNumber = passportPattern.test(value);

        if (name === "pan-of-buyer") {
            if (files.length > 0) {
                setIsPanOfBuyer(true);
            } else {
                setIsPanOfBuyer(false)
            }
        }
        if (name === "pan-of-seller") {
            if (files.length > 0) {
                setIsPanOfSeller(true);
            } else {
                setIsPanOfSeller(false)
            }
        }
        if (name === "AadharCard-of-buyer") {
            if (files.length > 0) {
                setIsAadharOfBuyer(true);
            } else {
                setIsAadharOfBuyer(false)
            }
        }
        if (name === "AadharCard-of-seller") {
            if (files.length > 0) {
                setIsAadharOfSeller(true);
            } else {
                setIsAadharOfSeller(false)
            }
        }
        if (name === "property-value") {
            if (value.length > 1) {
                setIsPropertyValue(true);
            } else {
                setIsPropertyValue(false);
            }
        }
        if (name === "Property-Address") {
            if (value.length > 1) {
                setIsPropertyAddress(true);
            } else {
                setIsPropertyAddress(false);
            }
        }
        if (name === "Property-Size") {
            if (value.length > 1) {
                setIsPropertySize(true);
            } else {
                setIsPropertySize(false);
            }
        }
        if (name === "TDS-amount") {
            if (value.length > 1) {
                setIsTdsAmount(true);
            } else {
                setIsTdsAmount(false);
            }
        }
        if (name === "Transaction-Date") {
            const inputDate = new Date(value);
        
            if (isNaN(inputDate.getTime())) {
                setIsTransactionDate(false);
            } else {
                setIsTransactionDate(true);
            }
        }
        if (name === "challan-receipt") {
            if (files.length > 0) {
                setIsChallanReceipt(true);
            } else {
                setIsChallanReceipt(false)
            }
        }
    };

    const checkValidationStep6 = () => {
        console.log("data : ", bankAccountData);
        const method = "document/add/";
        const body = {
            docs: [
                Object.entries(bankAccountData).map(([propertyName, value]) => ({
                    propertyName: propertyName,
                    value: value,
                })),
            ],
        };

        const bodyData = {
            docs: body.docs[0],
        };

        console.log("bodyData : ", bodyData);

        axios
            .post(
                REACT_APP_BASE_URL + method + stepName.docId + "/" + "6",
                bodyData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(
                (res) => {
                    console.log("response : ", res);
                    if (res) {
                        toast.success("Your Information saved successfully..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        window.scrollTo(0, 0);
                        displayRazorpay();
                    }
                },
                (error) => {
                    toast.error("Something went wrong..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            );
    };

    const propertyTypeSelect = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
            setIsPropertySelected(false);
        } else {
            setIsPropertySelected(true);
        }
        setData({ ...data, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        newFormData.append("namePanCard", data.namePanCard);
        newFormData.append("passportNumber", data.passportNumber);
        newFormData.append("email", data.email);
        newFormData.append("mobileNumber", data.mobileNumber);
        newFormData.append("panCardDocument", data.panCardDocument);
        newFormData.append("aadharCardDocument", data.aadharCardDocument);
        newFormData.append("form16File", data.form16File);
        newFormData.append(
            "anyOtherExemptionToBeClaimed",
            data.anyOtherExemptionToBeClaimed
        );
        newFormData.append(
            "exemptionRelatedDocuments",
            data.exemptionRelatedDocuments
        );
        newFormData.append("remarks", data.remarks);
        newFormData.append("annualSalary", data.annualSalary);
        newFormData.append("nameOfEmployer", data.nameOfEmployer);
        newFormData.append("panOfEmployer", data.panOfEmployer);
        newFormData.append("otherAttachments", data.otherAttachments);
        newFormData.append("remarks2", data.remarks2);
        newFormData.append("savingBankInterest", data.savingBankInterest);
        newFormData.append("interestOnFD", data.interestOnFD);
        newFormData.append("interestOnFDFile", data.interestOnFDFile);
        newFormData.append("interestOnRD", data.interestOnRD);
        newFormData.append("interestOnRDFile", data.interestOnRDFile);
        newFormData.append("remarks3", data.remarks3);
        newFormData.append(
            "expenseIfAnyInRespectOfIncome",
            data.expenseIfAnyInRespectOfIncome
        );
        newFormData.append(
            "expenseIfAnyInRespectOfAboveIncome",
            data.expenseIfAnyInRespectOfAboveIncome
        );
        newFormData.append("addOtherIncome", data.addOtherIncome);
        newFormData.append("address", data.address);
        newFormData.append("coOwned", data.coOwned);
        newFormData.append("typeOfHouseProperty", data.typeOfHouseProperty);
        newFormData.append("nameOfTenant", data.nameOfTenant);
        newFormData.append("panOfTenant", data.panOfTenant);
        newFormData.append("annualRent", data.annualRent);
        newFormData.append("municipalTax", data.municipalTax);
        newFormData.append("municipalTaxPaidFile", data.municipalTaxPaidFile);
        newFormData.append("interestOnHousingLoan", data.interestOnHousingLoan);
        newFormData.append(
            "interestOnHousingLoanFile",
            data.interestOnHousingLoanFile
        );
        newFormData.append("princiaplRepaid", data.princiaplRepaid);
        newFormData.append("principalRepaidFile", data.principalRepaidFile);
        newFormData.append("remarks4", data.remarks4);
        newFormData.append("otherDeductions", data.otherDeductions);
        newFormData.append("excelFile", data.excelFile);
        newFormData.append("bank", data.bank);
        newFormData.append("bankAccountNumber", data.bankAccountNumber);
        newFormData.append("ifscode", data.ifscode);
        newFormData.append(
            "governedByPortugueseCivil",
            data.governedByPortugueseCivil
        );
        newFormData.append("directorAtAnyCompany", data.directorAtAnyCompany);
        newFormData.append("claimBenefits", data.claimBenefits);
        newFormData.append("heldUnlisted", data.heldUnlisted);

        const url = "https://apibase.taxespro.in/";
        const method = `user/generate/income-tax/document/${userId}`;
        try {
            const response = await axios.post(url + method, newFormData);
            console.log(response.data); // Output: Response data from the server
            toast.success("Information saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };


    //Razor Pay Integration Loader(Pre-requisition)
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resolve(true);
            };

            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    //Razor Pay Integration (main)

    const displayRazorpay = async (amt) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("you r offline... Failed to load");
            return;
        }

        const options = {
            key: "rzp_test_snn65WkJ8E1lJr",
            currency: "INR",
            amount: parseInt(planFee) * 100,
            name: "Techgigs",
            description: "Thanks For Purchasing",

            handler: function (response) {
                const body = {
                    razorpayOrderId: response.razorpay_payment_id,
                };
                console.log(body);
                const methods = `document/payment/${stepName.docId}`;
                axios
                    .post(REACT_APP_BASE_URL + methods, body, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        console.log("RazerPay Response", res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                toast.success("Your Payment is successfull..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/incometaxreturn");
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <>
            <DemoHeader />
            <div className="bannertopstep">
                <div className="container text-left">
                    <div className="banner-vhmian1 itr-breadcrumb">

                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <Breadcrumbs aria-label="breadcrumb">

                        <Link
                            underline="hover"
                            color="inherit"
                            // to="/incometaxreturn"
                            onClick={() => window.history.go(-1)}
                        >
                            TDS on Property Purchase
                        </Link>
                        <Typography color="text.primary">TDS on Property Purchase Form</Typography>
                    </Breadcrumbs>
                    <div className="">
                        <div className="top-space-section step-gap">
                            <section className="step-box clearfix">
                                <div className="container">
                                    <div

                                    ></div>
                                    {/* steps name */}
                                    <ul className="nav nav-fill step-number row gutter-0 d-flex" style={{ justifyContent: 'flex-start' }}>
                                        {/* step 1 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm1 active"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="General Information"
                                            >
                                                <span>
                                                    1<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    TDS on Property Purchase
                                                </p>
                                            </a>
                                        </li>
                                    </ul>

                                    <form onSubmit={handleSubmit}>
                                        <div className="step-details step-form">
                                            {/*Step-1  */}
                                            <div className={`step-${number}`}>
                                                <div className="row">

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    PAN Card of the Buyer and Seller
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-sm-6 col-lg-4 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">PAN of the Buyer</label>
                                                                            <input
                                                                                type="file"
                                                                                name="pan-of-buyer"
                                                                                className={`form-control ${isPanOfBuyer !== undefined
                                                                                    ? isPanOfBuyer
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="PAN of the Buyer"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                PAN of the Buyer is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-sm-6 col-lg-4 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">PAN of the Seller</label>
                                                                            <input
                                                                                type="file"
                                                                                name="pan-of-seller"
                                                                                className={`form-control ${isPanOfSeller !== undefined
                                                                                    ? isPanOfSeller
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="PAN of the Seller"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                PAN of the Seller is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Aadhar Card of the Buyer and Seller
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-sm-6 col-lg-4 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">Aadhar Card of the Buyer</label>
                                                                            <input
                                                                                type="file"
                                                                                name="AadharCard-of-buyer"
                                                                                className={`form-control ${isAadharOfBuyer !== undefined
                                                                                    ? isAadharOfBuyer
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Aadhar Card of the Buyer"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Aadhar Card of the Buyer is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-sm-6 col-lg-4 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">Aadhar Card of the Seller</label>
                                                                            <input
                                                                                type="file"
                                                                                name="AadharCard-of-seller"
                                                                                className={`form-control ${isAadharOfSeller !== undefined
                                                                                    ? isAadharOfSeller
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Aadhar Card of the Seller"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Aadhar Card of the Seller is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Property Transaction Details
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Property Value</label>
                                                                        <input
                                                                            type="text"
                                                                            name="property-value"
                                                                            className={`form-control ${isPropertyValue !== undefined
                                                                                ? isPropertyValue
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="property value"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            property value is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Property Address</label>
                                                                        <input
                                                                            type="text"
                                                                            name="Property-Address"
                                                                            className={`form-control ${isPropertyAddress !== undefined
                                                                                ? isPropertyAddress
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Property Address"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Property Address is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">
                                                                            Select Property Type
                                                                        </label>
                                                                        <Select
                                                                            name="title"
                                                                            options={PropertyType}
                                                                            onChange={propertyTypeSelect}
                                                                            // onBlur={inputValidation}
                                                                            styles={{ width: "100%", height: "max-content" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Property Size</label>
                                                                        <input
                                                                            type="text"
                                                                            name="Property-Size"
                                                                            className={`form-control ${isPropertySize !== undefined
                                                                                ? isPropertySize
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Property Size"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Property Size is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">TDS amount</label>
                                                                        <input
                                                                            type="text"
                                                                            name="TDS-amount"
                                                                            className={`form-control ${isTdsAmount !== undefined
                                                                                ? isTdsAmount
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter TDS amount"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            TDS amount is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Transaction Date</label>
                                                                        <input
                                                                            type="date"
                                                                            name="Transaction-Date"
                                                                            className={`form-control ${isTransactionDate !== undefined
                                                                                ? isTransactionDate
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Transaction Date"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Transaction Date is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Challan receipt for TDS payment
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Challan receipt for TDS payment</label>
                                                                        <input
                                                                            type="file"
                                                                            name="challan-receipt"
                                                                            className={`form-control ${isChallanReceipt !== undefined
                                                                                ? isChallanReceipt
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Challan receipt for TDS payment"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Challan receipt for TDS payment is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                                                {isPanOfBuyer !== undefined ||
                                                isPanOfSeller !== undefined ||
                                                isAadharOfBuyer !== undefined ||
                                                isAadharOfSeller !== undefined ||
                                                isPropertyValue !== undefined ||
                                                isPropertyAddress !== undefined ||
                                                isPropertySelected !== undefined ||
                                                isPropertySize !== undefined ||
                                                isTdsAmount !== undefined ||
                                                isTransactionDate !== undefined ||
                                                isChallanReceipt !== undefined ? (
                                                    !isPanOfBuyer ||
                                                    !isPanOfSeller ||
                                                    !isAadharOfBuyer ||
                                                    !isAadharOfSeller ||
                                                    !isPropertyValue ||
                                                    !isPropertyAddress ||
                                                    !isPropertySelected ||
                                                    !isPropertySize ||
                                                    !isTdsAmount ||
                                                    !isTransactionDate ||
                                                    !isChallanReceipt ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Please fill all epmty fileds...!
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                <div className="step-nav-box">
                                                    <Button
                                                        id="saveInformation"
                                                        variant="contained"
                                                        style={{
                                                            borderRadius: "25px",
                                                            background: "#AE0721",
                                                        }}
                                                        onClick={() => checkValidationStep6()}
                                                        endIcon={<SendIcon />}
                                                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                    >
                                                        Save & Make Payment
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </section>

                            {/* Footer part */}
                            <section className="grayad">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                                            <span>
                                                <b>Don’t have Information or documents?</b>
                                            </span>
                                            <span className="clearfix d-block">
                                                No worries! You can edit/submit the info and document
                                                later as well
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                                            <div className="pl-0 pl-lg-1 pl-xl-4">
                                                <span className="blue-text">
                                                    <b>{stepName?.data?.planName}</b>
                                                </span>
                                                <span className="clearfix d-block smalltext">
                                                    Made for Immovable property purchasers
                                                </span>
                                            </div>
                                        </div>
                                        {/* {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                                            <a
                                                className="btn btn-dark regbtn"
                                                onClick={() => checkValidationStep6()}
                                            >
                                                Make Payment
                                            </a>
                                        </div> : ''} */}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TdsOnPropertyPurchaseForm;
