import React, { useState, useEffect } from "react";
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Carousel from './components/carousel/Carousel';
import FaqSection from './components/FAQ-section/Faq-section';
import AskExpert from './components/askexpert/AskExpert';
import ChoosebestPlan from './components/choosebestplan/ChoosebestPlan';
import DoYouKnow from './components/doyouknow/DoYouKnow';
import GetRsOff from './components/getrsoff/GetRsOff';
import GstFilling from './components/gstfilling/GstFilling';
import IncomeTaxFilling from './components/incometaxfilling/IncomeTaxFilling';
import WhyTaxSavvy from './components/whytaxsavvy/WhyTaxSavvy';
import StayTuned from './components/staytuned/StayTuned';
import Powerfull from './components/powerfull/Powerfull';
import ContactUs from './components/coontactus/ContactUs';
import OurServices from './components/ourservices/OurServices';
import Testimonial from './components/testimonial/Testimonial';
import IncometaxReturn from './components/services/incometax/IncometaxReturn';
import HomePage from './components/homepage/HomePage';
import ApplicationForPan from './components/services/incometax/ApplicationForPan';
import AdvisoryTaxPlanning from './components/services/incometax/AdvisoryTaxPlanning';
import AdvancedTaxCalculation from './components/services/incometax/AdvancedTaxCalculation';
import TdsOnProperty from './components/services/incometax/TdsOnProperty';
import TdsReturn from './components/services/incometax/TdsReturn';
import TanRegistration from './components/services/incometax/TanRegistration';
import IncomeTaxNotice from './components/services/incometax/IncomeTaxNotice';
import FillingOfForm15CA from './components/services/incometax/FillingOfForm15CA';
import MoreFaq from './components/morefaq/MoreFaq';
import PlanA from './components/services/incometax/PlanA';
import PlanB from './components/services/incometax/PlanB';
import PlanC from './components/services/incometax/PlanC';
import PlanD from './components/services/incometax/PlanD';
import PlanE from './components/services/incometax/PlanE';
import PlanF from './components/services/incometax/PlanF';
import IncomeTaxReadMore from './components/services/incometax/IncomeTaxReadMore';
import SignUplogin from './components/signuplogin/SignUplogin';
import NewPanCard from './components/services/incometax/NewPanCard';
import PancardCorrection from './components/services/incometax/PancardCorrection';
import PanCardNonResidential from './components/services/incometax/PanCardNonResidential';
import Step from './components/services/incometax/Step';
import TdsSalaryPayment from './components/services/incometax/TdsSalaryPayment';
import TdsOtherThanSalary from './components/services/incometax/TdsOtherThanSalary';
import TdsSalaryAndOtherPayment from './components/services/incometax/TdsSalaryAndOtherPayment';
import TdsOutsideIndia from './components/services/incometax/TdsOutsideIndia';
import TdsRentPayment from './components/services/incometax/TdsRentPayment';
import GetOtp from './components/getOtp/GetOtp';
import Verify from './components/getOtp/Verify';
import { Navigate } from 'react-router-dom';
import SignUp from "./components/signuplogin/SignUp";
import Profile from "./components/profile/Profile";
import Dashboard from "./components/pages/Dashboard";
import About from "./components/pages/About";
import ProductList from "./components/pages/ProductList";
import Sidebar from "./components/Sidebar";
import ViewPlanDetails from "./components/profile/ViewPlanDetails";
import BenefitsOfFillingItr from "./components/services/incometax/BenefitsOfFillingItr";
import ErrorPage from "./components/errorPage/ErrorPage";
import StepForPlanB from "./components/services/incometax/StepForPlanB";
import StepForPlanA from "./components/services/incometax/StepForPlanA";
import PanApplicationForm from "./components/services/incometax/PanApplicationForm";
import ShowDetailedHistory from "./components/profile/ShowDetailedHistory";
import NewPanCardForm from "./components/services/incometax/New-Pancard-Form";
import CorrectionPanCardForm from "./components/services/incometax/Correction-Pancard-Form";
import NriPanCardForm from "./components/services/incometax/Nri-Pancard-Form";
import CompanyCarporationForm from "./components/services/registrations/Company-Carporation-Form";
import LLPForm from "./components/services/registrations/LLP-form";
import CompanyIncarporation from "./components/services/registrations/CompanyIncarporation";
import LLP from "./components/services/registrations/LLP";
import PartenershipFirmForm from "./components/services/registrations/PartenershipFirmForm";
import StartUpRegistrationForm from "./components/services/registrations/StartUpRegistrationForm";
import ShopEstablishmentForm from "./components/services/registrations/ShopEstablishmentForm";
import ESICForm from "./components/services/registrations/ESICForm";
import FSSAForm from "./components/services/registrations/FSSAForm";
import ImportExportCodeForm from "./components/services/registrations/ImportExportCodeForm";
import ProfessionalTaxForm from "./components/services/registrations/ProfessionalTaxForm";
import ProvidentFundRegistrationForm from "./components/services/registrations/ProvidentFundRegistrationForm";
import PartenershipFirm from "./components/services/registrations/PartenershipFirm";
import StartUpRegistration from "./components/services/registrations/StartUpRegistration";
import ShopEstablishment from "./components/services/registrations/ShopEstablishment";
import ProvidentFundRegistration from "./components/services/registrations/ProvidentFundRegistration";
import ESIC from "./components/services/registrations/ESIC";
import ProfessionalTax from "./components/services/registrations/ProfessionalTax";
import ImportExportCode from "./components/services/registrations/ImportExportCode";
import FSSA from "./components/services/registrations/FSSA";
import GstRegistration from "./components/services/gst/GstRegistration";
import GstRegistrationForm from "./components/services/gst/GstRegistrationForm";
import GstReturnFilling from "./components/services/gst/GstReturnFilling";
import GstReturnFillingForm from "./components/services/gst/GstReturnFillingForm";
import GstEWayBill from "./components/services/gst/GstEWayBill";
import GstEWayBillForm from "./components/services/gst/GstEWayBillForm";
import GstCostOptimizationService from "./components/services/gst/GstCostOptimizationService";
import GstCostOptimizationServiceForm from "./components/services/gst/GstCostOptimizationServiceForm";
import GstConsultancyService from "./components/services/gst/GstConsultancyService";
import GstConsultancyServiceForm from "./components/services/gst/GstConsultancyServiceForm";
import LetterOfUndertaking from "./components/services/gst/LetterOfUndertaking";
import LetterOfUndertakingForm from "./components/services/gst/LetterOfUndertakingForm";
import GstAnnualReturn from "./components/services/gst/GstAnnualReturn";
import GstAnnualReturnForm from "./components/services/gst/GstAnnualReturnForm";
import GstCancellation from "./components/services/gst/GstCancellation";
import GstCancellationForm from "./components/services/gst/GstCancellationForm";
import TdsTcsReturn from "./components/services/gst/TdsTcsReturn";
import TdsTcsReturnForm from "./components/services/gst/TdsTcsReturnForm";
import ReconciliationOfGSTR from "./components/services/gst/ReconciliationOfGSTR";
import ReconciliationOfGSTRForm from "./components/services/gst/ReconciliationOfGSTRForm";
import Itc04 from "./components/services/gst/Itc04";
import Itc04Form from "./components/services/gst/Itc04Form";
import TdsOnPropertyPurchaseForm from "./components/services/incometax/TdsOnPropertyPurchaseForm";
import AdvancedTaxCalculationForm from "./components/services/incometax/AdvancedTaxCalculationForm";
import NewTanApplicationForm from "./components/services/incometax/NewTanApplicationForm";
import ChangeInTanForm from "./components/services/incometax/ChangeInTanForm";
import FillingOfForm15CAForm from "./components/services/incometax/FillingOfForm15CA-Form";
import TdsReturnForm from "./components/services/incometax/TdsReturnForm";
import LlpReturn from "./components/services/roc_compliances/llp_return/LlpReturn";
import LlpReturnForm from "./components/services/roc_compliances/llp_return/LlpReturnForm";
import RocFilling from "./components/services/roc_compliances/roc-filling/RocFilling";
import RocFillingForm from "./components/services/roc_compliances/roc-filling/RocFillingForm";
import ChangeCompaanyName from "./components/services/roc_compliances/change_company_name.js/ChangeCompaanyName";
import ChangeCompaanyNameForm from "./components/services/roc_compliances/change_company_name.js/ChangeCompaanyNameForm";
import AppointmentOfDirector from "./components/services/roc_compliances/appointment_of_director/AppointmentOfDirector";
import AppointmentOfDirectorForm from "./components/services/roc_compliances/appointment_of_director/AppointmentOfDirectorForm";
import WindingUpOfCompany from "./components/services/roc_compliances/windingup_of_company/WindingUpOfCompany";
import WindingUpOfCompanyForm from "./components/services/roc_compliances/windingup_of_company/WindingUpOfCompanyForm";
import ChangeInShareCapital from "./components/services/roc_compliances/change_in_sharecapital/ChangeInShareCapital";
import ChangeInShareCapitalForm from "./components/services/roc_compliances/change_in_sharecapital/ChangeInShareCapitalForm";
import ChangesInMemorandum from "./components/services/roc_compliances/changes_in_memorandom/ChangesInMemorandum";
import ChangesInMemorandumForm from "./components/services/roc_compliances/changes_in_memorandom/ChangesInMemorandumForm";
import DirectorKyc from "./components/services/roc_compliances/director_kyc/DirectorKyc";
import DirectorKycForm from "./components/services/roc_compliances/director_kyc/DirectorKycForm";

function App() {
  const [userId, setUserId] = useState()

  
  const getUserId = () => {
    const data = sessionStorage.getItem('userId');
    console.log("data : ", data)
    if(data)
    {
      setUserId(data)
    }
  }

  useEffect(()=>{
    getUserId()
  },[])

  return (
    <div className="App">
      {/* <Sidebar> */}
      <Routes>


        {/* <Carousel/>
      <Header/>
      <ContactUs/>
      <OurServices/>
      <IncomeTaxFilling/>
      <WhyTaxSavvy/>
      <AskExpert/>
      <ChoosebestPlan/>
      <GstFilling/>
      <DoYouKnow/>
      <GetRsOff/>
      <StayTuned/>
    <Testimonial/>
      <Powerfull/>
      <FaqSection />*/}
        <Route path='/' element={<HomePage />} />
        <Route path='/carousel' element={<Carousel />} />
        <Route path='/header' element={<Header />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/ourservices' element={<OurServices />} />
        <Route path='/incometaxfillling' element={<IncomeTaxFilling />} />
        <Route path='/whytaxsavvy' element={<WhyTaxSavvy />} />
        <Route path='/askexpert' element={<AskExpert />} />
        <Route path='/choosebestplan' element={<ChoosebestPlan />} />
        <Route path='/gstfilling' element={<GstFilling />} />
        <Route path='/doyouknow' element={<DoYouKnow />} />
        <Route path='/getrsoff' element={<GetRsOff />} />
        <Route path='/staytuned' element={<StayTuned />} />
        <Route path='/testimonial' element={<Testimonial />} />
        <Route path='/powerful' element={<Powerfull />} />
        <Route path='/faqsection' element={<FaqSection />} />
        <Route path='/footer' element={<Footer />} />

      
        <Route path='/incometaxreturn' element={<IncometaxReturn />} />
        <Route path='/applicationforpan' element={<ApplicationForPan />} />
        <Route path='/advisorytaxplanning' element={<AdvisoryTaxPlanning />} />
        <Route path='/advancedtaxcalculation' element={<AdvancedTaxCalculation />} />
        <Route path='/advancedtaxcalculation-form' element={<AdvancedTaxCalculationForm />} />
        <Route path='/tdsonproperty' element={<TdsOnProperty />} />
        <Route path='/tdsonproperty-form' element={<TdsOnPropertyPurchaseForm />} />
        <Route path='/tdsreturn' element={<TdsReturn />} />
        <Route path='/tdsreturn-form' element={<TdsReturnForm />} />
        <Route path='/tanregistration' element={<TanRegistration />} />
        <Route path='/new-tan-application-form' element={<NewTanApplicationForm />} />
        <Route path='/change-in-tan-form' element={<ChangeInTanForm />} />
        <Route path='/incometaxnotice' element={<IncomeTaxNotice />} />
        <Route path='/fillingformof15CA' element={<FillingOfForm15CA />} />
        <Route path='/fillingformof15CA-form' element={<FillingOfForm15CAForm />} />
        <Route path='/morefaq' element={<MoreFaq />} />
        <Route path='/plana' element={<PlanA />} />
        <Route path='/plana/:id' element={<PlanA />} />

        <Route path='/planb' element={<PlanB />} />
        <Route path='/planc' element={<PlanC />} />
        <Route path='/pland' element={<PlanD />} />
        <Route path='/plane' element={<PlanE />} />
        <Route path='/planf' element={<PlanF />} />
        <Route path='/incometaxreadmore' element={<IncomeTaxReadMore />} />
        <Route path='/signuplogin' element={<SignUplogin getUserId={getUserId}/>} />
        <Route path='/newpancard' element={<NewPanCard />} />
        <Route path='/pancardcorrection' element={<PancardCorrection />} />
        <Route path="/pancardnonresidential" element={<PanCardNonResidential />} />
        <Route path="/new-pancard-form" element={<NewPanCardForm />} />
        <Route path="/correction-pancard-form" element={<CorrectionPanCardForm />} />
        <Route path="/nri-pancard-form" element={<NriPanCardForm />} />

        <Route path="/company-incarporation" element={<CompanyIncarporation />} />
        <Route path="/company-carporation-form" element={<CompanyCarporationForm />} />
        <Route path='/llp' element={<LLP />} />
        <Route path='/llpform' element={<LLPForm />} />
        <Route path='/partenershipfirm' element={<PartenershipFirm />} />
        <Route path='/partenershipfirmform' element={<PartenershipFirmForm />} />
        <Route path='/startupregistration' element={<StartUpRegistration />} />
        <Route path='/startupregistrationform' element={<StartUpRegistrationForm />} />
        <Route path='/shopestablishment' element={<ShopEstablishment />} />
        <Route path='/shopestablishmentform' element={<ShopEstablishmentForm />} />
        <Route path='/professionaltax' element={<ProfessionalTax />} />
        <Route path='/professionaltaxform' element={<ProfessionalTaxForm />} />
        <Route path='/providentfundregistration' element={<ProvidentFundRegistration />} />
        <Route path='/providentfundregistrationform' element={<ProvidentFundRegistrationForm />} />
        <Route path='/esic' element={<ESIC />} />
        <Route path='/esicform' element={<ESICForm />} />
        <Route path='/importexportcode' element={<ImportExportCode />} />
        <Route path='/importexportcodeform' element={<ImportExportCodeForm />} />
        <Route path='/fssa' element={<FSSA />} />
        <Route path='/fssaform' element={<FSSAForm />} />

        <Route path='/gstregistration' element={<GstRegistration />} />
        <Route path='/gstregistrationform' element={<GstRegistrationForm />} />
        <Route path='/gstreturnfilling' element={<GstReturnFilling />} />
        <Route path='/gstreturnfillingform' element={<GstReturnFillingForm />} />
        <Route path='/gstewaybill' element={< GstEWayBill/>} />
        <Route path='/gstewaybillform' element={< GstEWayBillForm/>} />
        <Route path='/gstcostoptimizationservice' element={< GstCostOptimizationService/>} />
        <Route path='/gstcostoptimizationserviceform' element={< GstCostOptimizationServiceForm/>} />
        <Route path="/gstconsultancyservice" element={<GstConsultancyService/>} />
        <Route path="/gstconsultancyserviceform" element={<GstConsultancyServiceForm/>} />
        <Route path="/letterofundertaking" element={<LetterOfUndertaking/>} />
        <Route path="letterofundartakingform" element={<LetterOfUndertakingForm/>} />
        <Route path="/gstAnnualReturn" element={<GstAnnualReturn/>} />
        <Route path="/gstannualreturnform" element={<GstAnnualReturnForm/>} />
        <Route path="/gstcancellation" element={<GstCancellation/>}/>
        <Route path="/gstcancellationform" element={<GstCancellationForm/>}/>
        <Route path="/tdstcsreturn" element={<TdsTcsReturn/>}/>
        <Route path="/tdstcsreturnform" element={<TdsTcsReturnForm/>}/>
        <Route path="/recounciliationofgstr" element={<ReconciliationOfGSTR/>} />
        <Route path="/recounciliationofgstrform" element={<ReconciliationOfGSTRForm/>} />
        <Route path="/itc-job-work-return" element={<Itc04/>}/>
        <Route path="/itc-job-work-return-form" element={<Itc04Form/>} />

        <Route path="/roc-filling" element={<RocFilling/>}/>
        <Route path="/roc-filling-form" element={<RocFillingForm/>}/>
        <Route path="/llp-return" element={<LlpReturn/>}/>
        <Route path="/llp-return-form" element={<LlpReturnForm/>}/>
        <Route path="/change-company-name" element={<ChangeCompaanyName/>}/>
        <Route path="/change-company-name-form" element={<ChangeCompaanyNameForm/>}/>
        <Route path="/appointment-of-director" element={<AppointmentOfDirector/>}/>
        <Route path="/appointment-of-director-form" element={<AppointmentOfDirectorForm/>}/>
        <Route path="/winding-up-of-company" element={<WindingUpOfCompany/>}/>
        <Route path="/winding-up-of-company-form" element={<WindingUpOfCompanyForm/>}/>
        <Route path="/change-in-sharecapital" element={<ChangeInShareCapital/>}/>
        <Route path="/change-in-sharecapital-form" element={<ChangeInShareCapitalForm/>}/>
        <Route path="/changes-in-memorandum" element={<ChangesInMemorandum/>}/>
        <Route path="/changes-in-memorandum-form" element={<ChangesInMemorandumForm/>}/>
        <Route path="/director-kyc" element={<DirectorKyc/>}/>
        <Route path="/director-kyc-form" element={<DirectorKycForm/>}/>


        <Route path="/step"element={
    userId ? (
      <Step />  
    ) : (
       <Step />
    )
  }/>
        <Route path="/tdssalarypayment" element={<TdsSalaryPayment />} />
        <Route path="/tdsotherthansalary" element={<TdsOtherThanSalary />} />
        <Route path='tdssalaryandotherpayment' element={<TdsSalaryAndOtherPayment />} />
        <Route path='/tdsoutsideindia' element={<TdsOutsideIndia />} />
        <Route path='/tdsrentpayment' element={<TdsRentPayment />} />
        <Route path='/getotp' element={<GetOtp />} />
        <Route path='/verify' element={<Verify />} />
        <Route path='/login' element={<SignUplogin />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/profilee' element={<Dashboard />} />
        <Route path='/about' element={<About/>} />
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/product-list' element={<ProductList/>} />
        <Route path='/viewplandetails' element={<ViewPlanDetails/>} />
        <Route path='/stepforplana' element={<StepForPlanA/>} />
        <Route path='/stepforplanb' element={<StepForPlanB/>} />
        <Route path='/panapplicationform' element={<PanApplicationForm/>} />
        <Route path='/detailedhistory' element={<ShowDetailedHistory/>} />


        <Route path='/benefitsOfFillingItr' element={<BenefitsOfFillingItr/>} />
<Route path="*" element={<ErrorPage/>}/>


        {/* Sidebar routes */}
{/*        
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/about' element={<About />} />
        <Route path='/comment' element={<Comment />} />
        <Route path='/analytics' element={<Analytics />} />
        <Route path='/product' element={<Product />} />
        <Route path='/productlist' element={<ProductList />} /> */}
        


      </Routes>
      {/* </Sidebar> */}
    </div>
  );
}

export default App;