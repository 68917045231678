import axios from "axios";
import React, { useEffect, useState } from "react";
import { REACT_APP_BASE_URL } from "../../service/Constant";

const IncomeTaxFilling = () => {
  const [bannerData, setBannerData] = useState([]);
  const [img, setImg] = useState(""); // Add a new state for the image

  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = () => {
    const method = "banner/images?subServiceId=Home&position=1";
    axios.get(REACT_APP_BASE_URL + method).then((res) => {
      console.log("Incom tAx Filling Banner ", res.data.data);
      setBannerData(res.data.data);
      const imgData = res?.data?.data?.image;
      //   console.log(`res.data.data:img/jpeg;base64,${imgData}`);
      setImg(imgData); // Update the image state
    }).catch((err)=>{
      console.log(err)
    })
  };

  return (
    <>
      <section
        className="redimg-bo"
        style={{
          backgroundImage: `url(data:image/jpeg;base64,${bannerData[0]?.image})`,height:"200px",backgroundSize:'cover'
        }}
      >
        <div className="container" style={{paddingTop:"3rem"}}>
          <h4 className="white-title">
            {/* Income Tax Filling */}
            {bannerData[0]?.title}
          </h4>
          <div className="row mtp15  align-items-center" style={{marginTop:'0px'}}>
            <div className="col-lg-9">
              <p className="white-smalltext mbp0">
                {/* Tax filling was never that easy. Complete your online tax filling now. It’s absolutely  */}
                {bannerData[0]?.text}
                <span className="yellow-text">FREE!!!</span>
              </p>
            </div>
            <div className="col-lg-3 text-left text-lg-right it-filling-resp">
              <button className="btn btn-light regbtn" type="button">
                {/* START TAX FILLING */}
                {bannerData[0]?.buttonText}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IncomeTaxFilling;
