import React, { useEffect, useState } from "react";
import './PaymentSuccessfull.css'
import { Button, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { REACT_APP_BASE_URL } from "../../service/Constant";
import axios from "axios";

const PaymentSuccessfull = () => {
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});

  useEffect(() => {

   
    getAllData();
    
  }, []);
  const getAllData = () => {
    const token = sessionStorage.getItem("token");

    const method = "user/profile";
    axios
      .get(REACT_APP_BASE_URL + method, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("User Profile :", res.data.data);
        setGetData(res.data.data);
      })
      .catch((err) => {
        console.log("User Profile :", err);
      });
  };
  const handlenavigateHome = () => {
    navigate('/incometaxreturn')
  }
  return (
    <div className='Parent-Container'>
      <Container className="Container" >
        <Row className='Container-Row1'>
          <img src="/images/6iroM6gAT.png" className='Container-Row1-img' />
        </Row>
        <Row className='Container-Row2'>
          <p>
              Hi {getData.firstName} {getData.lastName}, you have successfully purchased the plan.<br />
          </p>
          <p className='Container-Row2-p' onClick={handlenavigateHome}>
            <Button className="Container-Row3-Button">
              Continue
            </Button>
          </p>
        </Row>
        <Row >
        
        </Row>
      </Container>
    </div>
  )
}

export default PaymentSuccessfull