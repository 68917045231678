import "./Demohed.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineWallet } from "react-icons/ai";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { FaBars } from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsFillTelephoneFill, IconName } from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  colors,
} from "@material-ui/core";
import { BiSolidUserCircle } from "react-icons/bi";
//   import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaUserCircle } from "react-icons/fa";
const DemoHeader = () => {
  const [navSize, setnavSize] = useState("6.1rem");
  const [navColor, setnavColor] = useState("transparent");
  const [navTextColor, setnavTextColor] = useState("#FFFFFF");
  const [loginbtn, setLoginBTN] = useState("#FFFFFF");
  const [scroll, setScroll] = useState(false);
  const [show, setshow] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const [applyToken, setApplyToken] = useState();
  const [getData, setGetData] = useState({});
  const defaultImage = "/images/user1.png"
  const defaultImage2 = "/images/user11.png"
  const [userDefaultImage, setUserDefaultImage] = useState(defaultImage)
  const [contactus, setContactUs] = useState(false)
  const [subService, setSubService] = useState([]);
  const [incom, setIncom] = useState("");


  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#FFFFFF") : setnavColor("transparent");
    //   window.scrollX > 10 ? setnavColor("#FFFFFF") : setnavColor("transparent");
    window.scrollY > 10
      ? setnavTextColor("#002480")
      : setnavTextColor("#FFFFFF");
    window.scrollY > 10 ? setLoginBTN("#002480") : setLoginBTN("#FFFFFF");
    window.scrollY > 10 ? setnavSize("6.1rem") : setnavSize("6.1rem");
    window.scrollY > 10 ? setUserDefaultImage(defaultImage2) : setUserDefaultImage(defaultImage);
  };

  //   const listenScrollEvent = () => {
  //     scrollY > 10 ? setnavColor("#FFFFFF") : setnavColor("transparent");
  //     scrollY > 10 ? setnavTextColor("#002480") : setnavTextColor("#FFFFFF");
  //     scrollY > 10 ? setLoginBTN("#002480") : setLoginBTN("#FFFFFF");
  //     scrollY > 10 ? setnavSize("5.3rem") : setnavSize("5.3rem");
  //   };
  const Navigate = useNavigate()
  useEffect(() => {

    getToken();

    getWalletBalance();
    getAllData();
    getSubServices();
    const userId = sessionStorage.getItem("userId");
    if (userId) {
      setshow(true);
    } else {
      setshow(false);
    }
    window.scrollTo(0, 0);
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [scroll]);

  const handleScroll = () => {
    setScroll(!scroll);
  };

  // React.useEffect(()=>{

  // },[])

  const getAllData = () => {
    const token = sessionStorage.getItem("token");

    const method = "user/profile";
    axios
      .get(REACT_APP_BASE_URL + method, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("User Profile :", res.data);
        setGetData(res.data.data);
      })
      .catch((err) => {
        console.log("User Profile :", err);
      });
  };
  const handleLogOut = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("token");
    window.location.href = "/";
  };

  const getToken = () => {
    const token = sessionStorage.getItem("token");
    setApplyToken(token);
    // console.log(token);
  };
  const getWalletBalance = () => {
    console.log(applyToken);
    const method = "user/wallet/balance";

    const tokan = applyToken;
    if (tokan || sessionStorage.getItem("token")) {
      axios
        .get(REACT_APP_BASE_URL + method, {
          headers: {
            Authorization: tokan ? tokan : sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setWalletBalance(res.data.data.balance);
        })
        .catch((error) => {
          console.log(error);
        });
    }

  };
  const showContactUs = () => {
    setContactUs(true)
  }
  const cancleContactUs = () => {
    setContactUs(false)
  }

  const handleNavigateSignUp = () => {
    Navigate("/signup")
  }

  const [showw, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getSubServices = () => {
    const method = "sub-services/list/";
    axios.get(REACT_APP_BASE_URL + method + '64eee8dd9e22723d1fa81cf3').then((res) => {
      setSubService(res.data.data);
      console.log("Sub Services", res.data.data);
    }).catch((error)=>{
      console.log(error)
    })
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg show-on-desk "
        style={{
          backgroundColor: `${navColor}`,
          height: navSize,
          transition: "all 0s",
        }}
      >
        <div className="container-fluid demoHead-resp">
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <Link to="/">
            <img src="../images/logo.jpg" onClick={handleScroll} />
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" id="phone"><path fill="none" d="M0 0h48v48H0z"></path><path d="M40 31c-2.49 0-4.9-.4-7.14-1.14-.69-.22-1.48-.06-2.03.49l-4.4 4.41a30.171 30.171 0 0 1-13.18-13.17l4.4-4.42c.55-.55.71-1.34.49-2.03C17.4 12.9 17 10.49 17 8c0-1.11-.89-2-2-2H8a2 2 0 0 0-2 2c0 18.78 15.22 34 34 34 1.11 0 2-.89 2-2v-7c0-1.11-.89-2-2-2zM24 6v20l6-6h12V6H24z"></path></svg> */}
          </Link>
          {/* <div className="call-fabar-outer-div">
            <div>
              <img className="call-png" src="./images/call-icon.png" onClick={showContactUs} />
            </div>

            <div>
              <button
                className="navbar-toggler demo-hed-toggle-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContents"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon demo-hed-toggle-btn">
                  <FaBars />
                </span>
              </button>
            </div>
          </div> */}

          <div className="collapse navbar-collapse drop-new" id="navbarSupportedContent"
          // style={{ marginLeft: "30rem" }}
          >
            <ul className="navbar-nav main-menu">
              {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Link</a>
        </li> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: navTextColor }}
                >
                  SALARIED
                </a>
                <ul
                  className="dropdown-menu demohed-drop-menu"
                  aria-labelledby="navbarDropdown"
                >
                  {/* <Link to="/applicationforpan">
                    <li>
                      <a className="dropdown-item li-txt">
                        Application for PAN
                      </a>
                    </li>
                  </Link> */}
                  {/* <Link to="/incometaxreturn">
                    <li>
                      <a className="dropdown-item" href="#">
                        Income Tax Returns
                      </a>
                    </li>
                  </Link> */}
                  {/* <Link to="/tdsonproperty">
                    <li>
                      <a className="dropdown-item" href="#">
                        TDS on property purchase (Form 26QB)
                      </a>
                    </li>
                  </Link>
                  <Link to="/tdsreturn">
                    <li>
                      <a className="dropdown-item" href="#">
                        Other TDS Returns
                      </a>
                    </li>
                  </Link>
                  <Link to={"/advancedtaxcalculation"}>
                    <li>
                      <a className="dropdown-item" href="#">
                        Advance Tax Calculation
                      </a>
                    </li>
                  </Link>
                  <Link to="/advisorytaxplanning">
                    <li>
                      <a className="dropdown-item" href="#">
                        Adviosry on Tax Planning
                      </a>
                    </li>
                  </Link>
                  <Link to="/incometaxnotice">
                    <li className="demp-hed-lc">
                      <a className="dropdown-item" href="#">
                        Income Tax Notice (Litigations and Scrutiny)
                      </a>
                    </li>
                  </Link> */}
                    {incom &&
              incom.map((data, i) => {
                return (
                  <a
                  className="service-box"
                    // className={`service-box ${activeId === 1 ? "active" : ""}`}
                    id="myHeader1"
                    onClick={(e) => {
                      // showonlyone("newboxes1");
                      // handleClick(1);
                      getSubServices(data.serviceId);
                      // handleShowServices(false)
                    }}
                  >
                    <div className="row-1">
                      <img
                        src="images/row-2.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/income-tax-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}
                <div className="newboxes" id="newboxes1"
                  style={{ display: "block",height:'400px',overflowY:'auto' ,backgroundColor: 'rgba(0, 0, 0, 0.47)',border:'none' }}
                >
                  <div className="sublinkbox" style={{padding:"0px"}}>
                  <ul>
                    {subService.map((data, index) => {
                      return (
                        <Link
                          key={index + 1}
                          to={data?.link}
                          state={data}
                        >
                          <li className="listOnHover">
                            <a style={{padding:'5px'}}>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    })}
                
                  </ul>
                  </div>
                </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: navTextColor }}
                >
                  BUSINESS
                </a>
                <ul
                  className="dropdown-menu demohed-drop-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Company Incorporation
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Limited Liability Partnership (LLP)
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Partnership Firm
                    </a>
                  </li>
                  <li className="demp-hed-lc">
                    <a className="dropdown-item" href="#">
                      Proprieter
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item two-drop">
                {!show ? (
                  <Link to="/signup">
                    <li className="nav-item dropdown ">
                      <a
                        className="btn btn-light btn-sm loginbtn"
                        id="signin"
                        type="submit"
                        style={{
                          color: navTextColor,
                          background: "transparent",
                          boxShadow: '0px 3px 6px #0000006e'
                        }}
                      >
                        SIGN IN
                      </a>
                    </li>
                  </Link>
                ) : (
                  <>
                    <li className="nav-item dropdown ms-5">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            getData.avatar ? `data:image/jpeg;base64,${getData.avatar}` :
                              userDefaultImage
                          }
                          className="demoHed-img"
                          style={{ color: navTextColor }}
                        />
                      </a>
                      <ul
                        className="dropdown-menu demohed-drop-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/profilee">
                          <li>
                            <a className="dropdown-item" href="#">
                              Profile
                            </a>
                          </li>
                        </Link>
                        <li onClick={handleLogOut} className="demp-hed-lc">
                          <a className="dropdown-item ">Log Out</a>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
              </li>
            </ul>
          </div>
        



          {/* <div id="navbarSupportedContents" className="demo-hed-res-dropdow">
          <div>
            <Accordion style={{ marginTop: "0.5rem" }} className="resp-head-dropdown">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>SALARIED</Typography>
              </AccordionSummary>
              <AccordionDetails className="demohed-drop-menu">
                <List>
                  <ListItem>
                    <Link to='/applicationforpan'>
                      <ListItemText primary="Application for PAN" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/incometaxreturn'>
                      <ListItemText primary="Income Tax Returns" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/tdsonproperty'>
                      <ListItemText primary="TDS on property purchase (Form 26QB)" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/tdsreturn'>
                      <ListItemText primary=" Other TDS Returns" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/advancedtaxcalculation'>
                      <ListItemText primary="Advance Tax Calculation" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/advisorytaxplanning'>
                      <ListItemText primary="Adviosry on Tax Planning" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/incometaxnotice'>
                      <ListItemText primary="Income Tax Notice (Litigations and Scrutiny)" />
                    </Link>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "0.5rem" }} className="resp-head-dropdown">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>BUSINESS</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: "100%" }}>
                <List>
                  <ListItem>
                    <ListItemText primary="Business Incorporation" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Item 2" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Item 3" />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {show ? <>
              <Accordion style={{ marginTop: "0.5rem" }} className="resp-head-dropdown">
                <AccordionSummary>
                  <Link to="/profilee">
                    <Typography >My Profile</Typography>
                  </Link>
                </AccordionSummary>
              </Accordion>
              <Accordion style={{ marginTop: "0.5rem" }} className="resp-head-dropdown">
                <AccordionSummary onClick={handleLogOut}>
                  <Link onClick={handleLogOut}>
                    <Typography >Sign Out</Typography>
                  </Link>
                </AccordionSummary>
              </Accordion>
            </> :
              <Accordion style={{ marginTop: "0.5rem" }} className="resp-head-dropdown">
                <AccordionSummary>
                  <Link to="/signup">
                    <Typography >Sign In</Typography>
                  </Link>
                </AccordionSummary>
              </Accordion>}
          </div>
        </div> */}
        </div>

        {contactus && <div className="contact-resp" style={{ marginTop: '5rem' }}>
          <div className="bgblack">
            <div className="contact-us-inner">
              <div style={{ display: 'flex', justifyContent: 'end' }} onClick={cancleContactUs}>
                <img src="images/cross.png" alt="" />{" "}
              </div>
              <div className="calltext">Call us at</div>
              <div className="number1"><a href='tel:+91 9800 1010 1010' >+91 9800 1010 1010</a></div>
              <div className="number1"><a href='tel:+91  1800 000 000' > 1800 000 000</a></div>
            </div>
            {/* <div className="find">Find Local numbers <a href="#">here</a></div> */}
          </div>
        </div>}


        {/* className Removed line474 = collapse navbar-collapse drop-new */}
        <div className="collapse demo-hed-res-dropdow drop-new" id="navbarSupportedContents"
        // style={{ marginLeft: "30rem" }}
        >
          <ul className="navbar-nav main-menu">
            {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Link</a>
        </li> */}
            <li className="nav-item dropdown new-dropdown-css">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              // style={{ color: navTextColor }}
              >
                SALARIED
              </a>
              <ul
                className="dropdown-menu demohed-drop-menu"
                aria-labelledby="navbarDropdown"
              >
                <Link to="/applicationforpan">
                  <li>
                    <a className="dropdown-item li-txt">
                      Application for PAN
                    </a>
                  </li>
                </Link>
                {/* <Link to="/incometaxreturn">
                    <li>
                      <a className="dropdown-item" href="#">
                        Income Tax Returns
                      </a>
                    </li>
                  </Link> */}
                <Link to="/tdsonproperty">
                  <li>
                    <a className="dropdown-item" href="#">
                      TDS on property purchase (Form 26QB)
                    </a>
                  </li>
                </Link>
                <Link to="/tdsreturn">
                  <li>
                    <a className="dropdown-item" href="#">
                      Other TDS Returns
                    </a>
                  </li>
                </Link>
                <Link to={"/advancedtaxcalculation"}>
                  <li>
                    <a className="dropdown-item" href="#">
                      Advance Tax Calculation
                    </a>
                  </li>
                </Link>
                <Link to="/advisorytaxplanning">
                  <li>
                    <a className="dropdown-item" href="#">
                      Adviosry on Tax Planning
                    </a>
                  </li>
                </Link>
                <Link to="/incometaxnotice">
                  <li className="demp-hed-lc">
                    <a className="dropdown-item" href="#">
                      Income Tax Notice (Litigations and Scrutiny)
                    </a>
                  </li>
                </Link>
              </ul>
            </li>
            <li className="nav-item dropdown new-dropdown-css">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              // style={{ color: navTextColor }}
              >
                BUSINESS
              </a>
              <ul
                className="dropdown-menu demohed-drop-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Company Incorporation
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Limited Liability Partnership (LLP)
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Partnership Firm
                  </a>
                </li>
                <li className="demp-hed-lc">
                  <a className="dropdown-item" href="#">
                    Proprieter
                  </a>
                </li>
              </ul>
            </li>
            {show ?
              <>
                <Link to="/profilee">
                  <li className="nav-item dropdown new-dropdown-css">
                    <a
                      className="nav-link dropdown-toggle"
                    // href="#"
                    // id="navbarDropdown"
                    // role="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    // style={{ color: navTextColor }}
                    >
                      MY PROFILE
                    </a>
                  </li>
                </Link>
                <li className="nav-item dropdown new-dropdown-css" onClick={handleLogOut}>
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  // style={{ color: navTextColor }}
                  >
                    SIGN OUT
                  </a>
                </li>
              </> :
              <li className="nav-item dropdown new-dropdown-css" onClick={handleNavigateSignUp}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                // style={{ color: navTextColor }}
                >
                  Sign In
                </a>
              </li>}
          </ul>
        </div>

      </nav>



      {/* mobile view */}

      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary show-on-mob">
        <Container style={{ paddingTop: '0rem' }}>
          <Navbar.Brand style={{ width: '199px' }}>
            <Link to="/">
              <img src="../images/logo.jpg" onClick={handleScroll} />
            </Link>
          </Navbar.Brand>
          <Navbar.Brand className="custom-navbar-brand2" style={{ width: '40px' }}>
          <BsFillTelephoneFill onClick={handleShow} />
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Navbar.Brand>
          {/* <div>
          <BsFillTelephoneFill onClick={handleShow} />
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div> */}
          <Navbar.Collapse id="responsive-navbar-nav" className="headerCustomDropdown">
            <Nav className="me-auto">
              <NavDropdown title="SALARIED" id="collapsible-nav-dropdown">
              {/* <NavDropdown.Item >
                  <Link to="/advancedtaxcalculation">
                    Advance Tax Calculation
                  </Link>
                </NavDropdown.Item> */}
                     {incom &&
              incom.map((data, i) => {
                return (
                  <a
                  className="service-box"
                    // className={`service-box ${activeId === 1 ? "active" : ""}`}
                    id="myHeader1"
                    onClick={(e) => {
                      // showonlyone("newboxes1");
                      // handleClick(1);
                      getSubServices(data.serviceId);
                      // handleShowServices(false)
                    }}
                  >
                    <div className="row-1">
                      <img
                        src="images/row-2.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/income-tax-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}
                <div className="" id="newboxes1"
                  style={{ display: "block",height:'400px',overflowY:'auto' }}
                >
                  <div className="sublinkbox">
                  <ul>
                    {subService.map((data, index) => {
                      return (
                        <Link
                          key={index + 1}
                          to={data?.link}
                          state={data}
                        >
                          <li style={{color:"#002581",textDecoration:"underline"}}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    })}
                
                  </ul>
                  </div>
                </div>
                {/* <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
            <Nav className="me-auto">
      <NavDropdown title="BUSINESS" id="collapsible-nav-dropdown">
        {/* <NavDropdown.Item>Business Incorporation</NavDropdown.Item>
        <NavDropdown.Item>Government Registrations</NavDropdown.Item>
        <NavDropdown.Item>Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item>Separated link</NavDropdown.Item> */}
        {/* Nested Dropdown */}
        <NavDropdown title="Business Incorporation" id="nested-dropdown">
          <NavDropdown.Item>Company Incorporation</NavDropdown.Item>
          <NavDropdown.Item>Limited Liability Partnership (LLP)</NavDropdown.Item>
          <NavDropdown.Item>Partnership Firm</NavDropdown.Item>
          <NavDropdown.Item>Proprieter</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Government Registrations" id="nested-dropdown">
          <NavDropdown.Item>Shop Act License</NavDropdown.Item>
          <NavDropdown.Item>Provident Fund </NavDropdown.Item>
          <NavDropdown.Item>ESIC</NavDropdown.Item>
          <NavDropdown.Item>PT Registration</NavDropdown.Item>
          <NavDropdown.Item>Import Export Code</NavDropdown.Item>
          <NavDropdown.Item>FSSAI/Food License</NavDropdown.Item>
          <NavDropdown.Item>Udyog Adhar</NavDropdown.Item>
          <NavDropdown.Item>PAN Registration</NavDropdown.Item>
          <NavDropdown.Item>TAN Registration</NavDropdown.Item>

        </NavDropdown>
        <NavDropdown title="Startup Services" id="nested-dropdown">
          <NavDropdown.Item>StartupIndia Recognition (DIPP)</NavDropdown.Item>
          <NavDropdown.Item>MSME Registartion</NavDropdown.Item>
          <NavDropdown.Item>Accounts Outsourcing</NavDropdown.Item>
          <NavDropdown.Item>Payroll Management</NavDropdown.Item>
          <NavDropdown.Item>Virtual CFO</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Income Tax" id="nested-dropdown">
          <NavDropdown.Item>Income Tax Returns (ITR)</NavDropdown.Item>
          <NavDropdown.Item>TDS Returns</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Goods and Services Tax (GST)" id="nested-dropdown">
          <NavDropdown.Item>Nested Item 1</NavDropdown.Item>
          <NavDropdown.Item>Nested Item 2</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Legal Compliance" id="nested-dropdown">
          <NavDropdown.Item>Nested Item 1</NavDropdown.Item>
          <NavDropdown.Item>Nested Item 2</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown.Item>Trademark Registartion</NavDropdown.Item>
        <NavDropdown.Item>Drafting and Reviewing Agreements</NavDropdown.Item>
        <NavDropdown.Item>Digital Signature Certificate</NavDropdown.Item>
      </NavDropdown>
    </Nav>
            {show ? <Nav>
              <Link to="/profilee">
                <Nav.Link href="#deets" className="dropdown-Option">
                  My Profile
                </Nav.Link>
              </Link>
              <Nav.Link eventKey={2} href="#memes" onClick={handleLogOut} className="dropdown-Option">
                Sign Out
              </Nav.Link>
            </Nav> :
              <Nav>
                <Link to="/signup">
                  <Nav.Link href="#deets" className="dropdown-Option">Sign In</Nav.Link>
                </Link>
              </Nav>}
          </Navbar.Collapse>
        </Container>

      </Navbar>

      <Modal show={showw} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <a className="floting-btn d-none d-sm-block onTablet" >
              <img src="images/call-icon-w.png" alt="" className="d-block m-auto" />
              <span>Contact Us</span>
            </a>

            <div className="" >
              <div className="row" style={{ backgroundColor: '#28292a', color: '#ffffff' }}>
                <div className="col-sm-12 align-content-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '8%', paddingLeft: '1px', position: 'absolute', paddingTop: '6rem', color: '#fff', cursor: 'pointer' }} viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fill-rule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clip-rule="evenodd" />
                  </svg> */}
                  <div className="customCssContactUs" style={{ padding: '26px 30px 5px 30px' }}>

                    <div className="">Call us at</div>
                    <div className=""><a href='tel:+91 9800 1010 1010' >+91 9800 1010 1010</a></div>
                    <div className=""><a href='tel:+91  1800 000 000' > 1800 000 000</a></div>
                    <div className="">Find Local numbers <a href="#">here</a></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="call-gary">
                    <div className="left"><img src="images/chat-icon.png" alt="" /></div>
                    <div className="right">
                      <h4>Chat with Us</h4>
                      <p>Chat with us and get expert advice at anytime</p>
                    </div>
                  </div>
                  <div className="call-gary">
                    <div className="left"><img src="images/email-icon.png" alt="" /></div>
                    <div className="right">
                      <h4>Chat with Us</h4>
                      <p>Email us with comments, ueries or feedbacks</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default DemoHeader;
