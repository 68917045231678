import React, { useState } from "react";
import "../../common/Common.css";
import "../incometax/Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const PartenershipFirmForm = () => {
  const [planFee, setPlanFee] = useState();
  const [data, setData] = useState({});
  const [userId, setUserId] = useState();
  const [steps, setSteps] = useState();
  const [number, setNumber] = useState(1);
  const [count, setCount] = useState();
  const [IsEnable, setIsEnable] = useState(false);
  const [passportValue, setIsPassportValue] = useState("");
  const [panValue, setIsPanValue] = useState("");
  const [documentId, setDocumentId] = useState();
  const [token, setToken] = useState();
  const [isRadio1Step4Selected, setIsRadio1Step4Selected] = useState(true);
  const [partenerMapCount, setPartenerMapCount] = useState();
  const [isHouseProperty, setIsHouseProperty] = useState(false);
  const [bankAccountData, setBankAccountData] = useState({});

  const [isTradeLicense, setIsTradeLicense] = useState();
  const [isCurrentTradeLicense, setCurrentTradeLicense] = useState();
  const [isPartenershipDeed, setIsPartenershipDeed] = useState();
  const [isSpecialCertificate, setIsSpecialCertificate] = useState();
  const [isPanForFirm, setIsPanFirm] = useState();
  const [isProfessionalFund, setIsProfessionalFund] = useState();
  const [isAadharOfPartener, setIsAadharOfPartener] = useState();
  const [isEmailOfPartener, setIsEmailOfPartener] = useState();

  const [showMakePaymentBTN, setShowMakePaymentBTN] = useState(false)


  const navigate = useNavigate();

  const Location = useLocation();
  const stepName = Location.state;
  console.log(stepName);
  useEffect(() => {
    window.scrollTo(0, 0);
    setPlanFee(sessionStorage.getItem("planFee"));
    getStepsData();
    setDocumentId(localStorage.getItem("documentId"));
    setToken(sessionStorage.getItem("token"));
  }, [number, IsEnable, isHouseProperty]);

  // GET 6 STEPS
  const getStepsData = () => {
    const planId = stepName?.data.planId
      ? stepName?.data.planId
      : sessionStorage.getItem("planId");
    const subServiceId = stepName?.subServiceIds
      ? stepName?.subServiceIds
      : sessionStorage.getItem("subServiceIds");

    // console.log(planId, subServiceId)

    const data = localStorage.getItem("userId");
    setUserId(data);
    const token = localStorage.getItem("token");
    // console.log("token : ", token)
    const method = "steps/";
    axios
      .get(`${REACT_APP_BASE_URL}steps/${subServiceId}/${planId}`)
      .then((response) => {
        console.log("steps : ", response.data.data);
        setSteps(response.data.data.steps);
        setCount(response.data.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const partenerCount = (e) => {
    const count = e.target.value >= 1 && e.target.value;
    setPartenerMapCount(count);
  }

  const handlePartenerCountPlus = () => {
    if (
      partenerMapCount === undefined ||
      partenerMapCount === null ||
      partenerMapCount === NaN
    ) {
      let count = 1;
      setPartenerMapCount(parseInt(count));
    } else {
      setPartenerMapCount(partenerMapCount + 1);
    }
  }

  const handlePartenerCountMinus = () => {
    if (partenerMapCount >= 1)
      setPartenerMapCount(partenerMapCount - 1)
  }

  const inputHandle = (e) => {
    const { name, value, type, files } = e.target;

    console.log("radio : ", name, value.toUpperCase(), type);
    if (type == "file") {
      console.log(files);
      console.log(files[0].type);
      const fileType = files[0].type.split("/")[1];
      const method = `document/upload/${fileType}`;
      const formData = new FormData();
      formData.append("file", files[0]);
      try {
        //Get link request to store image/file into the S3 bucket
        axios
          .get(REACT_APP_BASE_URL + method, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(
            (res) => {
              console.log("res", res);
              setData({ ...data, [name]: res.data.data.key });

              if (res.data.status === 1) {
                //After got link from S3 bucket we hit put request to that S3 bucket link
                axios
                  .put(res.data.data.url, files[0], {
                    headers: {
                      "Content-Type": files[0].type,
                    },
                  })
                  .then(
                    (response) => {
                      console.log("S3 bucket response : ", response);
                      console.log("S3 bucket url : ", response.config.url);
                    },
                    (err) => {
                      console.log("S3 bucket error : ", err);
                    }
                  );
              }
            },
            (error) => {
              console.log("error : ", error);
            }
          );
      } catch (error) {
        console.log("error : ", error);
      }
    } else {
      if (name === "panNumber") {
        setIsPanValue(value.toUpperCase());
        setData({ ...data, [name]: value.toUpperCase() });
      }
      if (name === "passportNumber") {
        setIsPassportValue(value.toUpperCase());
        setData({ ...data, [name]: value.toUpperCase() });
      }
      setData({ ...data, [name]: value });
    }
  };


  const inputValidation = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passportPattern =
      /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
    const { name, value, type, files } = e.target;
    const isValidEmail = emailPattern.test(value);
    const isValidPassportNumber = passportPattern.test(value);

    if (name === "initialtradelicense") {
      if (files.length > 0) {
        setIsTradeLicense(true);
      } else {
        setIsTradeLicense(false)
      }
    }
    if (name === "currentTradeLic") {
      if (files.length > 0) {
        setCurrentTradeLicense(true);
      } else {
        setCurrentTradeLicense(false)
      }
    }
    if (name === "partnershipDeed") {
      if (files.length > 0) {
        setIsPartenershipDeed(true);
      } else {
        setIsPartenershipDeed(false)
      }
    }
    if (name === "specialCertificate") {
      if (files.length > 0) {
        setIsSpecialCertificate(true);
      } else {
        setIsSpecialCertificate(false)
      }
    }
    if (name === "panForFirm") {
      if (files.length > 0) {
        setIsPanFirm(true);
      } else {
        setIsPanFirm(false)
      }
    }
    if (name === "professionalTax") {
      if (value.length > 1) {
        setIsProfessionalFund(true);
      } else {
        setIsProfessionalFund(false);
      }
    }
    if (name === "AadharOrPanOfPartener") {
      if (files.length > 0) {
        setIsAadharOfPartener(true);
      } else {
        setIsAadharOfPartener(false)
      }
    }
    if (name === "emailOrMobileNoOfPartener") {
      if (value.length > 1) {
        setIsEmailOfPartener(true);
      } else {
        setIsEmailOfPartener(false);
      }
    }
  };

  const checkValidationStep6 = () => {
    console.log("data : ", bankAccountData);
    const method = "document/add/";
    const body = {
      docs: [
        Object.entries(bankAccountData).map(([propertyName, value]) => ({
          propertyName: propertyName,
          value: value,
        })),
      ],
    };

    const bodyData = {
      docs: body.docs[0],
    };

    console.log("bodyData : ", bodyData);

    axios
      .post(
        REACT_APP_BASE_URL + method + stepName.docId + "/" + "6",
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(
        (res) => {
          console.log("response : ", res);
          if (res) {
            toast.success("Your Information saved successfully..!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.scrollTo(0, 0);
            displayRazorpay();
          }
        },
        (error) => {
          toast.error("Something went wrong..!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFormData = new FormData();
    newFormData.append("namePanCard", data.namePanCard);
    newFormData.append("passportNumber", data.passportNumber);
    newFormData.append("email", data.email);
    newFormData.append("mobileNumber", data.mobileNumber);
    newFormData.append("panCardDocument", data.panCardDocument);
    newFormData.append("aadharCardDocument", data.aadharCardDocument);
    newFormData.append("form16File", data.form16File);

    const url = "https://apibase.taxespro.in/";
    const method = `user/generate/income-tax/document/${userId}`;
    try {
      const response = await axios.post(url + method, newFormData);
      console.log(response.data); // Output: Response data from the server
      toast.success("Information saved successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };


  //Razor Pay Integration Loader(Pre-requisition)
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  //Razor Pay Integration (main)

  const displayRazorpay = async (amt) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("you r offline... Failed to load");
      return;
    }

    const options = {
      key: "rzp_test_snn65WkJ8E1lJr",
      currency: "INR",
      amount: parseInt(planFee) * 100,
      name: "Techgigs",
      description: "Thanks For Purchasing",

      handler: function (response) {
        const body = {
          razorpayOrderId: response.razorpay_payment_id,
        };
        console.log(body);
        const methods = `document/payment/${stepName.docId}`;
        axios
          .post(REACT_APP_BASE_URL + methods, body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log("RazerPay Response", res);
          })
          .catch((err) => {
            console.log(err);
          });
        toast.success("Your Payment is successfull..!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/incometaxreturn");
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      <DemoHeader />
      <div className="bannertopstep">
        <div className="container text-left">
          <div className="banner-vhmian1 itr-breadcrumb">

          </div>
        </div>
      </div>

      <section>
        <div className="container">

          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              // to="/incometaxreturn"
              onClick={() => window.history.go(-1)}
            >
              Partnership Firm
            </Link>
            <Typography color="text.primary">Partnership Firm Form</Typography>
          </Breadcrumbs>
          <div className="">
            <div className="top-space-section step-gap">
              <section className="step-box clearfix">
                <div className="container">
                  <div
                  ></div>
                  {/* steps name */}
                  <ul className="nav nav-fill step-number row gutter-0 d-flex" style={{ justifyContent: 'flex-start' }}>
                    {/* step 1 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm1 active"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="General Information"
                      >
                        <span>
                          1<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          Partnership Firm Registration
                        </p>
                      </a>
                    </li>

                  </ul>

                  <form onSubmit={handleSubmit}>
                    <div className="step-details step-form">
                      {/*Step-1  */}
                      <div className={`step-${number}`}>
                        <div className="row">

                          <div className="col-6">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Initial Trade License
                                </label>
                                <br />
                              </div>
                              <br />
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xl-12">
                              <div className="row">
                                <div className="col-xl-9">
                                  <div
                                    className="form-group bottom-space"
                                  >
                                    <label className="label-style">Initial Trade License</label>
                                    <input
                                      type="file"
                                      name="initialtradelicense"
                                      className={`form-control ${isTradeLicense !== undefined
                                        ? isTradeLicense
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="Initial Trade License"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      Trade License is required
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Current Trade Lic
                                </label>
                                <br />
                              </div>
                              <br />
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xl-12">
                              <div className="row">
                                <div className="col-xl-9">
                                  <div
                                    className="form-group bottom-space"
                                  >
                                    <label className="label-style">Current Trade Lic</label>
                                    <input
                                      type="file"
                                      name="currentTradeLic"
                                      className={`form-control ${isCurrentTradeLicense !== undefined
                                        ? isCurrentTradeLicense
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="From 16"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      Current Trade Lic is required
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Partnership Deed
                                </label>
                                <br />
                              </div>
                              <br />
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xl-12">
                              <div className="row">
                                <div className="col-xl-9">
                                  <div
                                    className="form-group bottom-space"
                                  >
                                    <label className="label-style">Partnership Deed</label>
                                    <input
                                      type="file"
                                      name="partnershipDeed"
                                      className={`form-control ${isPartenershipDeed !== undefined
                                        ? isPartenershipDeed
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="Initial Trade License"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      Partnership Deed is required
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Special Certificate
                                </label>
                                <br />
                              </div>
                              <br />
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-12">
                              <div className="row">
                                <div className="col-xl-9">
                                  <div
                                    className="form-group bottom-space"
                                  >
                                    <label className="label-style">Special Certificate</label>
                                    <input
                                      type="file"
                                      name="specialCertificate"
                                      className={`form-control ${isSpecialCertificate !== undefined
                                        ? isSpecialCertificate
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="Special Certificate"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      Certificate is required
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  PAN for Firm
                                </label>
                                <br />
                              </div>
                              <br />
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-12">
                              <div className="row">
                                <div className="col-xl-9">
                                  <div
                                    className="form-group bottom-space"
                                  >
                                    <label className="label-style">PAN for Firm</label>
                                    <input
                                      type="file"
                                      name="panForFirm"
                                      className={`form-control ${isPanForFirm !== undefined
                                        ? isPanForFirm
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="Initial Trade License"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      PAN for Firm is required
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Professional Tax
                                </label>
                                <br />
                              </div>
                              <br />
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xl-12">
                              <div className="row">
                                <div className="col-xl-9">
                                  <div
                                    className="form-group bottom-space"
                                  >
                                    <label className="label-style">Professional Tax</label>
                                    <input
                                      type="text"
                                      name="professionalTax"
                                      className={`form-control ${isProfessionalFund !== undefined
                                        ? isProfessionalFund
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="Professional Tax"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      Professional Tax Amount is required
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="row">
                            <div className="col">
                              <div className={`form-group bottom-space`}>
                                <label className="label-style" style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}>
                                  Enter Number of Parteners
                                </label>
                                <br />
                                <br />
                                <div>
                                  <span
                                    style={{
                                      border: "1px solid gray",
                                      padding: "10px",
                                    }}
                                  >
                                    <RemoveIcon
                                      onClick={handlePartenerCountMinus}
                                    />
                                  </span>
                                  <input
                                    type="number"
                                    value={partenerMapCount}
                                    onChange={partenerCount}
                                    style={{
                                      height: "38px",
                                      width: "50px",
                                      border: "1px solid gray",
                                      textAlign: "center",
                                    }}
                                  />
                                  <span
                                    style={{
                                      border: "1px solid gray",
                                      padding: "10px",
                                    }}
                                  >
                                    <AddIcon
                                      onClick={handlePartenerCountPlus}
                                    />
                                  </span>
                                </div>
                                {/* <div className="input-group mb-2 mr-sm-2" style={{width:"40%"}}>
                                  <input
                                    type="number"
                                    name="HousePropertyInput"
                                    className={`form-control`}
                                    id=""
                                    placeholder="House property count"
                                    onChange={handleHouseProperty}
                                    autoComplete="off"
                                    value={housePropertyMapCount}
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                          {isRadio1Step4Selected &&
                            Array.from({ length: partenerMapCount }).map(
                              (_, index) => (
                                <>
                                  <div className="col-sm-12 col-lg-6 col-xl-5">

                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="col-sm-12 col-lg-12 col-xl-12">
                                        <div className="form-group bottom-space">
                                          <label
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            <span style={{ color: "#AE0721" }}>
                                              {" "}
                                              {index + 1}{" )   "}
                                            </span>
                                            Aadhaar / Pan Card of Parteners
                                          </label>
                                          <br />
                                        </div>
                                        <br />
                                      </div>
                                      <div className="col-sm-6 col-lg-4 col-xl-12">
                                        <div className="row">
                                          <div className="col-xl-9">
                                            <div
                                              className="form-group bottom-space"
                                            >
                                              <label className="label-style">Aadhaar / Pan Card of Parteners</label>
                                              <input
                                                type="file"
                                                name="AadharOrPanOfPartener"
                                                className={`form-control ${isAadharOfPartener !== undefined
                                                  ? isAadharOfPartener
                                                    ? "is-valid"
                                                    : "is-invalid"
                                                  : ""
                                                  }`}
                                                placeholder="Choose Aadhar or Pan"
                                                onChange={inputHandle}
                                                onBlur={inputValidation}
                                                autoComplete="off"
                                              />
                                              <div className="invalid-feedback">
                                                Identity Proof is required
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="col-sm-12 col-lg-12 col-xl-12">
                                        <div className="form-group bottom-space">
                                          <label
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Email ID / Mobile No All Partners
                                          </label>
                                          <br />
                                        </div>
                                        <br />
                                      </div>
                                      <div className="col-sm-6 col-lg-4 col-xl-12">
                                        <div className="row">
                                          <div className="col-xl-9">
                                            <div
                                              className="form-group bottom-space"
                                            >
                                              <label className="label-style">Email ID / Mobile No All Partners</label>
                                              <input
                                                type="text"
                                                name="emailOrMobileNoOfPartener"
                                                className={`form-control ${isEmailOfPartener !== undefined
                                                  ? isEmailOfPartener
                                                    ? "is-valid"
                                                    : "is-invalid"
                                                  : ""
                                                  }`}
                                                placeholder="Enter  Email ID Or Mobile No"
                                                onChange={inputHandle}
                                                onBlur={inputValidation}
                                                autoComplete="off"
                                              />
                                              <div className="invalid-feedback">
                                                Email ID Or Mobile No is required
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </>
                              )
                            )}


                        </div>
                        {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                        {isTradeLicense !== undefined ||
                          isCurrentTradeLicense !== undefined ||
                          isPartenershipDeed !== undefined ||
                          isSpecialCertificate !== undefined ||
                          isPanForFirm !== undefined ||
                          isProfessionalFund !== undefined  ? (
                          !isTradeLicense ||
                            !isCurrentTradeLicense ||
                            !isPartenershipDeed ||
                            !isSpecialCertificate ||
                            !isPanForFirm ||
                            !isProfessionalFund  ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Please fill all epmty fileds...!
                            </p>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <div className="step-nav-box">
                          <Button
                            id="saveInformation"
                            variant="contained"
                            style={{
                              borderRadius: "25px",
                              background: "#AE0721",
                            }}
                            onClick={() => checkValidationStep6()}
                            endIcon={<SendIcon />}
                            disabled={IsEnable} // Disable the button when isSendingOTP is true
                          >
                            Save & Make Payment
                          </Button>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </section>

              {/* Footer part */}
              <section className="grayad">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                      <span>
                        <b>Don’t have Information or documents?</b>
                      </span>
                      <span className="clearfix d-block">
                        No worries! You can edit/submit the info and document
                        later as well
                      </span>
                    </div>
                    <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                      <div className="pl-0 pl-lg-1 pl-xl-4">
                        <span className="blue-text">
                          <b>{stepName?.data?.planName}</b>
                        </span>
                        <span className="clearfix d-block smalltext">
                          Made for Immovable property purchasers
                        </span>
                      </div>
                    </div>
                    {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                      <a
                        className="btn btn-dark regbtn"
                        onClick={() => checkValidationStep6()}
                      >
                        Make Payment
                      </a>
                    </div> : ''}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartenershipFirmForm;
