import React from 'react'
import '../../common/Common.css'
import './FileItr.css'

const FileItr = () => {
  return (

    <>
     <section className="tb-space graybg">
            <div className="container">
                <div className="row fileItr-row">
                    <div className="col-sm-12 text-center">
                        <h5 className="main-title red-row mbp15 d-inline-block">Who can file ITR with Tax Savvy?</h5>
                    </div>
                    <div className="col-sm-12 mtp10">
                        <div className="row">
                            <div className="col-sm-6 mtp20">
                                <div className="media itrlist">
                                    <img src="images/itr-01.png" className="align-self-start" alt=""/>
                                    <div className="media-body">
                                       
                                            <h5 className="mt-0">Multiple Income source</h5>
                                        
                                        <p>Individual having income from Capital Gains, Mutual Funds Stocks, House Property, Fix Deposit Bank Interest etc</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mtp20">
                                <div className="media itrlist">
                                    <img src="images/itr-02.png" className="align-self-start" alt=""/>
                                    <div className="media-body">
                                       
                                            <h5 className="mt-0">Only Salaried</h5>
                                       
                                        <p>Individual who only have Salary Income can file with or without Form -16</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 mtp20">
                                <div className="media itrlist">
                                    <img src="images/itr-03.png" className="align-self-start" alt=""/>
                                    <div className="media-body">
                                        
                                            <h5 className="mt-0">Job Switched</h5>
                                      
                                        <p>Individual who have switched Jobs during the financial year (Multiple Form-16)</p>

                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 mtp20">
                                <div className="media itrlist">
                                    <img src="images/itr-04.png" className="align-self-start" alt=""/>
                                    <div className="media-body">
                                        
                                            <h5 className="mt-0">Have Foreign Income</h5>
                                     
                                        <p>Individual having foreign Income (Onsite Deputation) Foreign Assets, NRI</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default FileItr