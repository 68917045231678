import React from 'react'
import Header from '../../header/Header'
import AskExpert from '../../askexpert/AskExpert'
import WhyTaxSavvy from '../../whytaxsavvy/WhyTaxSavvy'
import GetRsOff from '../../getrsoff/GetRsOff'
import IncomeTaxFilling from '../../incometaxfilling/IncomeTaxFilling'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'
import DemoHeader from '../../demoHeader/DemoHeader'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'

const IncomeTaxReadMore = () => {
    return (
        <>
            <DemoHeader />
            <div className="bannertop">
                <div className="container text-left">
                    <div className="banner-vhmian itr-breadcrumb">
                        <div>
                            <Breadcrumbs aria-label="breadcrumb" style={{color:'#FFF'}}>
                                <Link underline="hover" style={{color:'#FFFFFF'}} to="/">
                                    HOME
                                </Link>
                                <Typography color="text.primary" className="breadcrumb-color">More Services</Typography>

                            </Breadcrumbs>
                        </div>
                        <div className="vhin">
                            <h1>Income Tax</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="whitebox readmore-whitebox">
                        <div className="row">
                            <div className="col-sm-12 text-center mbp25">
                                <h5 className="main-title red-row mbp15 d-inline-block">Services under Income Tax</h5>
                            </div>
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Income Tax Notice <br />(Litigations and Assessments)</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Individual Return</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Advisory on Tax Planning</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>TDS Returns</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>TDS on Property purchase (Challan 26QB)</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Advance Tax Calculation</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>SFT Filling (Statement of Financial Transaction)</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Trust Registration under Income Tax Act (Section 12AA)</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Section 80 G Registration for Trust</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Application for PAN</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Application for TAN</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-3 mb-xl-4">
                                        <div className="lightbg-box">
                                            <h6>Filling of Form 15CA (Foreign Remittance)</h6>
                                            <p>Two liner description about the product brief, short and crisp</p>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <AskExpert />
            <WhyTaxSavvy />
            <GetRsOff />

            <section className="tb-space aboutPlan-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">About Tax Savvy</h5>
                        </div>
                        <div className="col-lg-10 text-center offset-lg-1">
                            {/* <p className="smalltext-black mtp20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                        </div>

                    </div>
                    <div className="row mtp30 mbp20">
                        <div className="col-sm-4">
                            <div className="about-box">
                                <img src="images/misson-icon.png" alt="" className="m-auto" />
                                <h6>Our Misson</h6>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="about-box">
                                <img src="images/vision-icon.png" alt="" className="m-auto" />
                                <h6>Our Vision</h6>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="about-box">
                                <img src="images/goal-icon.png" alt="" className="m-auto" />
                                <h6>Our Goal</h6>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <IncomeTaxFilling />
            <FaqSection />
            <Footer />
        </>
    )
}

export default IncomeTaxReadMore