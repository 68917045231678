import React, { useEffect, useState } from "react";
import "../../components/common/Common.css";
import "./OurServices.css"
import $, { data } from "jquery";
import { Link } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";

const OurServices = (props) => {
  const [activeId, setActiveId] = useState(1);
  const [serviceData, setServiceData] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [incom, setIncom] = useState("");
  const [gst, setGst] = useState("");
  const [registration, setRegistration] = useState("");
  const [support, setSupport] = useState("");
  const [roc, setRoc] = useState("");
  const [defaultId, setDefaultId] = useState("");
  const [subService, setSubService] = useState([]);
  const [isShow, setIsShow] = useState(true)

  function showonlyone(thechosenone) {
    $(".newboxes").each(function (index) {
      if ($(this).attr("id") == thechosenone) {
        $(this).fadeIn(0);
      } else {
        $(this).fadeOut(0);
      }
    });
  }

  const handleClick = (e) => {
    setActiveId(e);
  };

  useEffect(() => {
    if (incom.length <= 0) {
      getServices();
    }

    //   const userAgent = navigator.userAgent;
    // let deviceType = 'Unknown';

    // if (userAgent.match(/Mobile/i)) {
    //   deviceType = 'Mobile Device';
    // } else if (userAgent.match(/Tablet/i)) {
    //   deviceType = 'Tablet Device';
    // } else if (userAgent.match(/Desktop/i)) {
    //   deviceType = 'Desktop Device';
    // }
  }, [activeId]);

  // useEffect(()=>{
  //     getSubServices(defaultId)
  // },[])

  const getServices = () => {
    const method = "services/list";

    axios.get(REACT_APP_BASE_URL + method).then((res) => {
      setServiceData(res.data.data);
      console.log("Get Services", res.data.data);
      const incomeTaxServices = res.data.data.filter(
        (data) => data.serviceName === "Income Tax"
      );
      setDefaultId(incomeTaxServices[0].serviceId);
      getSubServices(incomeTaxServices[0].serviceId);
      const gstServices = res.data.data.filter(
        (data) => data.serviceName === "Goods and Services Tax (GST)"
      );
      const registrations = res.data.data.filter(
        (data) => data.serviceName === "Registrations"
      );

      const businessSupport = res.data.data.filter(
        (data) => data.serviceName === "Business Support"
      );

      const rocsupport = res.data.data.filter(
        (data) => data.serviceName === "ROC Compliances"
      );

      console.log("IncomeTax Service : ", incomeTaxServices)
      setIncom(incomeTaxServices);
      setGst(gstServices);
      setRegistration(registrations);
      setSupport(businessSupport);
      setRoc(rocsupport);
    }).catch((error) => {
      console.log(error);
    })
  };

  const getSubServices = (id) => {
    const method = "sub-services/list/";
    axios.get(REACT_APP_BASE_URL + method + id).then((res) => {
      setSubService(res.data.data);
      console.log("Sub Services", res.data.data);
    }).catch((error) => {
      console.log(error)
    })
  };

  const handleShowServices = (flag) => {
    if (flag) {
      setIsShow(true)
    }
    else {
      setIsShow(false)
    }
  }

  return (
    <section className="tb-space" id="section2">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center custom-position ">
            <h5 className="main-title red-row mbp15 d-inline-block">
              Our Services
            </h5>
          </div>
        </div>
        <div className="row mtp30 our-services-outer mobileview-our-services">
          {isShow && <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive">

            {incom &&
              incom.map((data, i) => {
                return (
                  <a
                    className={`service-box ${activeId === 1 ? "active" : ""}`}
                    id="myHeader1"
                    onClick={(e) => {
                      showonlyone("newboxes1");
                      handleClick(1);
                      getSubServices(data.serviceId);
                      handleShowServices(false)
                    }}
                  >
                    <div className="row-1">
                      <img
                        src="images/row-2.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/income-tax-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {gst &&
              gst.map((data, i) => {
                return (
                  <a
                    className={`service-box position-set ${activeId === 2 ? "active" : ""
                      }`}
                    id="myHeader2"
                    onClick={(e) => {
                      showonlyone("newboxes2");
                      handleClick(2);
                      getSubServices(data.serviceId);
                      handleShowServices(false)
                    }}
                    title="Coming Soon...."
                  >
                    <div className="row-4">
                      <img
                        src="images/row-4.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/services-text-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {registration &&
              registration.map((data, i) => {
                return (
                  <a
                    className={`service-box ${activeId === 5 ? "active" : ""}`}
                    id="myHeader5"
                    onClick={(e) => {
                      showonlyone("newboxes5");
                      handleClick(5);
                      getSubServices(data.serviceId);
                      handleShowServices(false)
                    }}
                    title="Coming Soon...."
                  >
                    <div className="row-2">
                      <img
                        src="images/row-2.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/registar-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {support &&
              support.map((data, i) => {
                return (
                  <a
                    className={`service-box position-set ${activeId === 3 ? "active" : ""
                      }`}
                    id="myHeader3"
                    onClick={(e) => {
                      showonlyone("newboxes3");
                      handleClick(3);
                      handleShowServices(false)
                      getSubServices(data.serviceId);
                    }}
                    title="Coming Soon...."
                  >
                    <div className="row-5">
                      <img src="images/row-4.png" className="img-fluid" alt="" />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/business-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}


            {roc &&
              roc.map((data, i) => {
                return (
                  <a
                    className={`service-box ${activeId === 4 ? "active" : ""}`}
                    id="myHeader4"
                    onClick={(e) => {
                      showonlyone("newboxes4");
                      handleClick(4);
                      handleShowServices(false)
                      getSubServices(data.serviceId);
                    }}
                    title="Coming Soon...."
                  >
                    <div className="row-3">
                      <img src="images/row-2.png" className="img-fluid" alt="" />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img src="images/roc-icon.png" alt="" className="img-fluid" />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}
          </div>}

          {<div className={`col-lg-4 col-xl-5 ${isShow ? 'd-none' : ''}`}>
            <div className="rightboxmain">
              <div className="newboxes" id="newboxes1"
                style={{ display: "block" }}
              >
                <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Income Tax</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.map((data, index) => {
                      return (
                        <Link
                          key={index + 1}
                          to={data?.link}
                          state={data}
                        >
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    })}

                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <Link to="/incometaxreadmore">
                    <button
                      type="button"
                      className="btn btn-dark regbtn md"
                      onclick="location.href='income-tax.html';"
                    >
                      READ MORE
                    </button>
                  </Link>
                </div>
              </div>
              <div className="newboxes" id="newboxes2">
                <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Goods and Service Tax (GST)</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to="/incometaxreturn">
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}

                    {/* <li><a href="#">GST Registration</a></li>
                                        <li><a href="#">GST Return Filling</a></li>
                                        <li><a href="#">GST Annual Return (GSTR 9)</a></li>
                                        <li><a href="#">GST E-way bill service</a></li>
                                        <li><a href="#">Reconciliation of GSTR 2A and GSTR 3B</a></li>
                                        <li><a href="#">GST TDS and GST TCS Return</a></li>
                                        <li><a href="#">ITC 04 - Job Work return</a></li>
                                        <li><a href="#">Letter of Undertaking (LUT)</a></li>
                                        <li><a href="#">GST Cancellation or Surrender</a></li>
                                        <li><a href="#">GST Consultancy Services</a></li>
                                        <li><a href="#">GST Cost Optimization Service</a></li> */}
                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
              <div className="newboxes" id="newboxes3">
                <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Business Support</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}
                    {/* <li><a href="#">Payroll Processing</a></li>
                                        <li><a href="#">Accounting Services</a></li>
                                        <li><a href="#">Virtual CFO</a></li>
                                        <li><a href="#">Business Valuation Services</a></li>
                                        <li><a href="#">Drafting and Reviewing Agreements</a></li>
                                        <li><a href="#">Startup Packages</a></li>
                                        <li><a href="#">Trainings</a></li>
                                        <li><a href="#">Ask an expert</a></li> */}
                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
              <div className="newboxes" id="newboxes4">
                <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>ROC Compliances</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}
                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
              <div className="newboxes" id="newboxes5">
                <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Registrations</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}
                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
            </div>
          </div>}
        </div>

        {/* //webview */}
        <div className="row mtp30 our-services-outer webview-our-services">
          <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive">

            {incom &&
              incom.map((data, i) => {
                return (
                  <a
                    className={`service-box ${activeId === 1 ? "active" : ""}`}
                    id="myHeader1"
                    onClick={(e) => {
                      showonlyone("newboxes1");
                      handleClick(1);
                      getSubServices(data.serviceId);
                    }}
                  >
                    <div className="row-1">
                      <img
                        src="images/row-2.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/income-tax-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {gst &&
              gst.map((data, i) => {
                return (
                  <a
                    className={`service-box position-set ${activeId === 2 ? "active" : ""
                      }`}
                    id="myHeader2"
                    onClick={(e) => {
                      showonlyone("newboxes2");
                      handleClick(2);
                      getSubServices(data.serviceId);
                    }}
                    
                  >
                    <div className="row-4">
                      <img
                        src="images/row-4.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/services-text-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {registration &&
              registration.map((data, i) => {
                return (
                  <a
                    className={`service-box ${activeId === 5 ? "active" : ""}`}
                    id="myHeader5"
                    onClick={(e) => {
                      showonlyone("newboxes5");
                      handleClick(5);
                      getSubServices(data.serviceId);
                    }}
                   
                  >
                    <div className="row-2">
                      <img
                        src="images/row-2.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/registar-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {support &&
              support.map((data, i) => {
                return (
                  <a
                    className={`service-box position-set ${activeId === 3 ? "active" : ""
                      }`}
                    id="myHeader3"
                    onClick={(e) => {
                      showonlyone("newboxes3");
                      handleClick(3);
                      getSubServices(data.serviceId);
                    }}
                    title="Coming Soon...."
                  >
                    <div className="row-5">
                      <img src="images/row-4.png" className="img-fluid" alt="" />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img
                          src="images/business-icon.png"
                          alt=""
                          className="img-fluid"
                        />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}

            {roc &&
              roc.map((data, i) => {
                return (
                  <a
                    className={`service-box ${activeId === 4 ? "active" : ""}`}
                    id="myHeader4"
                    onClick={(e) => {
                      showonlyone("newboxes4");
                      handleClick(4);
                      getSubServices(data.serviceId);
                    }}
                    title="Coming Soon...."
                  >
                    <div className="row-3">
                      <img src="images/row-2.png" className="img-fluid" alt="" />{" "}
                    </div>
                    <div className="ser-img-text">
                      <div className="left-part">
                        <img src="images/roc-icon.png" alt="" className="img-fluid" />{" "}
                      </div>
                      <div className="right-part">{data.serviceName}</div>
                    </div>
                    <div className="for-mobile d-none d-md-block"></div>
                  </a>
                );
              })}
          </div>

          <div className="col-lg-4 col-xl-5">
            <div className="rightboxmain">
              <div className="newboxes" id="newboxes1"
                style={{ display: "block" }}
              >
                <a className="close-icon d-block d-lg-none">
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Income Tax</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {/* {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data?.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>} */}



                    {subService.map((data, index) => {
                      return (
                        <Link
                          key={index + 1}
                          to={data?.link}
                          state={data}
                        >
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    })}




                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <Link to="/incometaxreadmore">
                    <button
                      type="button"
                      className="btn btn-dark regbtn md"
                      onclick="location.href='income-tax.html';"
                    >
                      READ MORE
                    </button>
                  </Link>
                </div>
              </div>
              <div className="newboxes" id="newboxes2">
                <a className="close-icon d-block d-lg-none">
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Goods and Service Tax (GST)</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data?.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}


                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
              <div className="newboxes" id="newboxes3">
                <a className="close-icon d-block d-lg-none">
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Business Support</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data?.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}

                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
              <div className="newboxes" id="newboxes4">
                <a className="close-icon d-block d-lg-none">
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>ROC Compliances</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data?.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}

                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
              <div className="newboxes" id="newboxes5">
                <a className="close-icon d-block d-lg-none">
                  <img src="images/close-icon.png" alt="" />{" "}
                </a>
                <div className="topbox">
                  <h4>Registrations</h4>
                </div>
                <div className="sublinkbox">
                  <ul>
                    {subService.length > 0 ? subService.map((data, index) => {
                      return (
                        <Link key={index + 1} to={data.link}>
                          <li style={{ color: "#002581", textDecoration: "underline" }}>
                            <a>{data?.subServiceName}</a>
                          </li>
                        </Link>
                      );
                    }) : <h3>Comming Soon...</h3>}
                  </ul>
                </div>
                <div className="clearfix text-right btnbottom">
                  <button
                    type="button"
                    className="btn btn-dark btn-dark regbtn md"
                  >
                    READ MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;

// import React, { useEffect, useState } from "react";
// import "../../components/common/Common.css";
// import $ from "jquery";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { REACT_APP_BASE_URL } from "../../service/Constant";

// const OurServices = () => {
//   const [activeId, setActiveId] = useState(1);
//   const [serviceData, setServiceData] = useState([]);
//   const [subService, setSubService] = useState([]);

//   function showOnlyOne(theChosenOne) {
//     $(".newboxes").each(function (index) {
//       if ($(this).attr("id") === theChosenOne) {
//         $(this).fadeIn(0);
//       } else {
//         $(this).fadeOut(0);
//       }
//     });
//   }

//   const handleClick = (serviceId) => {
//     setActiveId(serviceId);
//   };

//   useEffect(() => {
//     if (serviceData.length <= 0) {
//       getServices();
//     }
//   }, []);

//   const getServices = () => {
//     const method = "services/list";

//     axios.get(REACT_APP_BASE_URL + method).then((res) => {
//       setServiceData(res.data.data);
//       console.log("Get Services", res.data.data);
//       const incomeTaxServices = res.data.data.filter(
//         (data) => data.serviceName === "Income Tax"
//       );
//       setActiveId(incomeTaxServices[0].serviceId);
//       getSubServices(incomeTaxServices[0].serviceId);
//     });
//   };

//   const getSubServices = (id) => {
//     const method = "sub-services/list/";
//     axios.get(REACT_APP_BASE_URL + method + id).then((res) => {
//       setSubService(res.data.data);
//       console.log("Sub Services", res.data.data);
//     });
//   };

//   return (
//     <section className="tb-space" id="section2">
//       <div className="container">
//         <div className="row">
//           <div className="col-sm-12 text-center">
//             <h5 className="main-title red-row mbp15 d-inline-block">
//               Our Services
//             </h5>
//           </div>
//         </div>
//         <div className="row mtp30">
//           <div className="col-lg-8 col-xl-7 activelink position-relative">
//             {serviceData.map((data) => (
//               <a
//                 className={`service-box ${
//                   activeId === data.serviceId ? "active" : ""
//                 } ${data.serviceId === 1 ? "income-tax" : ""} ${
//                   data.serviceId === 2 ? "gst" : ""
//                 }`}
//                 onClick={() => {
//                   showOnlyOne(`newboxes${data.serviceId}`);
//                   handleClick(data.serviceId);
//                   getSubServices(data.serviceId);
//                 }}
//               >
//                 <div className="row-1">
//                   <img
//                     src="images/row-2.png"
//                     className="img-fluid"
//                     alt=""
//                   />
//                 </div>
//                 <div className="ser-img-text">
//                   <div className="left-part">
//                     <img
//                       src="images/income-tax-icon.png"
//                       alt=""
//                       className="img-fluid"
//                     />
//                   </div>
//                   <div className="right-part">{data.serviceName}</div>
//                 </div>
//                 <div className="for-mobile d-none d-md-block"></div>
//               </a>
//             ))}
//           </div>

//           <div className="col-lg-4 col-xl-5">
//             <div className="rightboxmain">
//               {serviceData.map((data) => (
//                 <div
//                   className={`newboxes ${
//                     activeId === data.serviceId ? "active" : ""
//                   } ${data.serviceId === 1 ? "income-tax" : ""} ${
//                     data.serviceId === 2 ? "gst" : ""
//                   }`}
//                   id={`newboxes${data.serviceId}`}
//                   style={{
//                     display: activeId === data.serviceId ? "block" : "none",
//                   }}
//                 >
//                   <a className="close-icon d-block d-lg-none">
//                     <img src="images/close-icon.png" alt="" />
//                   </a>
//                   <div className="topbox">
//                     <h4>{data.serviceName}</h4>
//                   </div>
//                   <div className="sublinkbox">
//                     <ul>
//                       {subService.map((subData, index) => (
//                         <Link
//                           key={index + 1}
//                           to="/incometaxreturn"
//                           state={subData}
//                         >
//                           <li>
//                             <a>{subData?.subServiceName}</a>
//                           </li>
//                         </Link>
//                       ))}
//                     </ul>
//                   </div>
//                   <div className="clearfix text-right btnbottom">
//                     <Link to="/incometaxreadmore">
//                       <button
//                         type="button"
//                         className="btn btn-dark regbtn md"
//                       >
//                         READ MORE
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default OurServices;
