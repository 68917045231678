// import * as React from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import UserProfile from "../profile/UserProfile"
// import MyDoc from './MyDoc';

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// export default function Profile() {
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Box>
//         <h3 style={{padding: '3rem 5rem'}}>Profile</h3>

//      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '0.5rem' }}>
//      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//        <Tabs
//          value={value}
//          onChange={handleChange}
//          aria-label="basic tabs example"
//          centered
//        >
//          <Tab label="User Profile" {...a11yProps(0)} />
//          <Tab label="My Doc" {...a11yProps(1)} />
//          <Tab label="My Services" {...a11yProps(2)} />
//          <Tab label="My Plan" {...a11yProps(3)} />
//        </Tabs>
//      </Box>
//      <CustomTabPanel value={value} index={0}>
//        <UserProfile/>
//      </CustomTabPanel>
//      <CustomTabPanel value={value} index={1}>
// <MyDoc/>
//      </CustomTabPanel>
//      <CustomTabPanel value={value} index={2}>
//        My Services
//      </CustomTabPanel>
//      <CustomTabPanel value={value} index={3}>
//        My Plans
//      </CustomTabPanel>
//    </Box>
//    </Box>
//   );
// }


import React from 'react'
import Sidebar from './Sidebar'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import DemoHeader from '../demoHeader/DemoHeader'

const Profile = () => {
  return (
    <div>
      <Header/>

    {/* <Sidebar/> */}
    <DemoHeader/>
    <Footer/>
    </div>
  )
}

export default Profile
