import React from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div>
      <div className="container-error">
        <div className="gif-error">
          <img src="https://i.postimg.cc/2yrFyxKv/giphy.gif" alt="gif_ing" />
        </div>
        <div className="content-error">
          <h1 className="main-heading-error">This page is gone.</h1>
          <p className="p-error">
            ...maybe the page you're looking for is not found or never existed.
          </p>
          <Link to="/">
            <a>
              <button className="button-error">
                Back to home <i className="far fa-hand-point-right"></i>
              </button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
