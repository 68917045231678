import React from 'react'
import '../incometax/IncomeTaxCss.css'
import Header from '../../header/Header'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'

const PancardCorrection = () => {
    return (
        <>
            {/* <div className="position-relative">
            <div className="owl-carousel owl-theme banner">
                <div className="item purpal-nav-bg">
                    <div className="d-none d-md-block" style="background: url(images/plan-img.png) no-repeat bottom right; opacity: 0.8; position:absolute;  z-index:0; right:0; bottom:0; height:100%; width: 50%; background-size: 100% 100%;"></div>
              
                    <div className="container-fluid">
                        <div className="row align-items-center vh-100">
                            <div className="col-sm-12 col-md-10  col-lg-8">
                                <div className="banner-text banner-text-xl">

                                    <h2>Change or Correction or Reissue of Pan Card</h2>
                                    <div className="banner-text-bottom">
                                        <span>Key Highlights of Plan</span>
                                        <ul>
                                            <li>Especially for womens after marriage for their name change</li>
                                            <li>For Individual, HUF, Partnership, LLP, Company or any other body corporate</li>
                                            <li>Least Documentation</li>
                                            <li>Dedicated expert assigned on your case</li>
                                            <li>Continuous assistance till you receive your Updated PAN Card</li>
                                        </ul>
                                        <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     

                </div>
            </div>
        </div> */}
            <Header />

            <div className="container-fluid" style={{ backgroundColor: '#5136e6' }}>
                <div className="row align-items-center vh-100 headerbackground">
                    <div className="col-sm-12 col-md-10  col-lg-8">
                        <div className="banner-text banner-text-xl">

                            <h2>Change or Correction or Reissue of Pan Card</h2>
                            <div className="banner-text-bottom">
                                <span>Key Highlights of Plan</span>
                                <ul>
                                    <li>Especially for womens after marriage for their name change</li>
                                    <li>For Individual, HUF, Partnership, LLP, Company or any other body corporate</li>
                                    <li>Least Documentation</li>
                                    <li>Dedicated expert assigned on your case</li>
                                    <li>Continuous assistance till you receive your Updated PAN Card</li>
                                </ul>
                                <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="planbox" id="Header2">
                <div className="planbox-title">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-9 col-md-8">
                                <h4>Change or Correction or Reissue of Pan Card</h4>
                            </div>
                            <div className="col-3 col-md-4 text-right" style={{ display: 'flex' }}>
                                <div className="dropdown d-inline-block arrowbtn d-sm-none">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="images/down-arrow.svg" />
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#aboutplan">About the Plan</a>
                                        <a className="dropdown-item" href="#howitdone">How it’s done</a>
                                        <a className="dropdown-item" href="#docreq">Documents required</a>
                                        <a className="dropdown-item" href="#faqs">FAQs</a>
                                    </div>
                                </div>
                                <div className="priceboxin">
                                    <span>Just for</span>
                                    <h6>₹1499</h6>
                                </div>
                                <a className="btn btn-dark btnblack float-right" href="step.html">BUY NOW</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="plannav d-none d-sm-block mtp5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 text-left fixnav">
                                <ul className="nav nav-pills nav-fill" id="top-menu">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#aboutplan">About the Plan</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#howitdone">How it’s done</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#docreq">Documents required</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#faqs">FAQs</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="tb-space" id="aboutplan">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">About the Plan</h5>
                        </div>
                    </div>
                    <div className="row mtp15">
                        <div className="col-sm-6 col-xl-7">
                            <h5 className="sub-title-xs mbp10">Plan details</h5>
                            <p className="black-smalltext">This product is applicable for applicants who are Indian citizen and want to make changes in their PAN card like name, father's name, signature, date of birth etc. Especially in the case womens, PAN details needs to be change after marriage. Also, in case if you have lost/damage your PAN card and you have the PAN number with you, you can reapply for new PAN card.
                                This plan also covers PAN change application for Individual, HUF, Partnership, LLP, Company or any other body corporate like trust, Association of persons (AOP).
                                A dedicated expert will help you in getting you PAN changes done. Our expert will provide time to time assistance till the time you received your Updated PAN card. Therefore, an individual shall be guided to best of his/her interest.
                            </p>

                            <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
                            <p className="black-smalltext">1. Individual who are Indian citizen and want to make changes in their PAN card</p>
                            <p className="black-smalltext">2. Married womens for their change in name after marriage</p>
                            <p className="black-smalltext">3. Individual who are Indian citizen and lost their PAN card and have the PAN number with them</p>
                            <p className="black-smalltext">4. HUF, Partnership, LLP, Company or any other body corporate for change in PAN Details</p>
                            <p className="text-xs"> <i>Note:<br /> In case of Non Resident Indians (NRI), please refer our succeeding plan “Pan Card for Non Resident” for changes or correction in PAN details.</i></p>

                        </div>
                        <div className="col-sm-6  col-xl-5">
                            <img src="images/about-img.png" alt="" className="img-fluid m-auto float-md-right" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space graybg" id="howitdone">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">How it’s Done</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="hid-list-main">
                                <ul className="hid-list">
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/cart-icon.png" /></div>
                                            <div className="hid-text">Purchase of Plan</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/upload-icon.png" /></div>
                                            <div className="hid-text">Upload Documents</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Filing of Form 49A</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/file-search-icon.png" /></div>
                                            <div className="hid-text">Review & Submission of form</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/generation-form-icon.png" /></div>
                                            <div className="hid-text">Paste photo, sign on Pre filled form and post to our address (Not required in case mobile is linked with Aadhar)</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/file-search-icon.png" /></div>
                                            <div className="hid-text">Tracking of PAN Card Status</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Feedback</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="estimated-row">
                                <span>5 Days Estimated</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space blackbg" id="docreq">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title white-row mbp15 d-inline-block white-color">Documents required</h5>
                        </div>
                    </div>
                    <div className="row mtp25 gutter-20">
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">01</div>
                                <div className="doc-details">Proof of Identity</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">02</div>
                                <div className="doc-details">Proof of Address</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">03</div>
                                <div className="doc-details">Proof of Date of Birth</div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <FaqSection/>
            <Footer/>
        </>
    )
}

export default PancardCorrection