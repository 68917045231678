import React, { useState } from "react";
import "../../common/Common.css";
import "../incometax/Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const LLPForm = () => {
    const [planFee, setPlanFee] = useState();
    const [data, setData] = useState({});
    const [userId, setUserId] = useState();
    const [steps, setSteps] = useState();
    const [properties, setProperties] = useState();
    const [number, setNumber] = useState(1);
    const [count, setCount] = useState();
    const [IsEnable, setIsEnable] = useState(false);
    const [isLastName, setIsLastName] = useState();
    const [isFirstName, setIsFirstName] = useState();
    const [isMiddleName, setIsMiddleName] = useState();
    const [isEmail, setIsEmail] = useState();
    const [isMobile, setIsMobile] = useState();
    const [passportValue, setIsPassportValue] = useState("");
    const [isPan, setIsPan] = useState();
    const [panValue, setIsPanValue] = useState("");
    const [isPanDocument, setPanIsDocument] = useState();
    const [isAdhar, setIsAdhar] = useState();
    const [isAdharDocument, setIsAdharDocument] = useState();
    const [ToastCount, setToastCount] = useState(0);
    const [isRadio1Selected, setIsRadio1Selected] = useState(true);
    const [documentId, setDocumentId] = useState();
    const [token, setToken] = useState();
    const [isForm16File, setIsForm16File] = useState();
    const [isanyOtherExemptionToBeClaimed, setIsanyOtherExemptionToBeClaimed] =
        useState();

    const [isexemptionRelatedDocuments, setIsexemptionRelatedDocuments] =
        useState();
    const [isRemarks, setIsRemarks] = useState();
    const [isAnnualSalary, setIsAnnualSalary] = useState();
    const [isNameOfEmployer, setIsNameOfEmployer] = useState();
    const [isPanOfEmployer, setIsPanOfEmployer] = useState();
    const [isRemarks2, setIsRemarks2] = useState();

    const [isSavingBankInterest, setIsSavingBankInterest] = useState();
    const [isInterestOnFD, setIsInterestOnFD] = useState();
    const [isInterestOnFDFile, setIsInterestOnFDFile] = useState();
    const [isInterestOnRD, setIsInterestOnRD] = useState();
    const [isInterestOnRDFile, setIsInterestOnRDFile] = useState();
    const [isExpenseIfAnyInRespectOfIncome, setIsExpenseIfAnyInRespectOfIncome] =
        useState();
    const [
        isExpenseIfAnyInRespectOfAboveIncome,
        setIsExpenseIfAnyInRespectOfAboveIncome,
    ] = useState();
    const [isAnotherIncomeDocument, setIsAnotherIncomeDocument] = useState();
    const [isRemarks3, setIsRemarks3] = useState();

    const [housePropertyData, setHousePropertyData] = useState({});
    const [isRadio1Step4Selected, setIsRadio1Step4Selected] = useState(true);
    const [isBtn, setBtn] = useState();
    const [housePropertyMapCount, setHousePropertyMapCount] = useState();
    const [isHouseProperty, setIsHouseProperty] = useState(false);

    const [bankAccountData, setBankAccountData] = useState({});


    const [isPanCard, setIsPanCard] = useState();
    const [isPassport, setIsPassport] = useState();
    const [isAddressProofSelected, setIsAddressProofSelected] = useState();
    const [isaddressProofFile, setIsAddressProofFile] = useState();
    const [isresidenceProofSelected, setIsResidenceProofSelected] = useState();
    const [isResidenseProofFile, setIsResidenseProofFile] = useState();
    const [isNriAddressSelected, setIsNriAddressSelected] = useState();
    const [isNriAddressProofFile, setIsNriAddressProofFile] = useState();
    const [ispostiledCopy, setIsPostiledCopy] = useState();
    const [isOfficeAddress, setIsOfficeAddress] = useState();
    const [isNoc, setIsNoc] = useState();
    const [isUtilityBillSelected, setIsUtilityBillSelected] = useState();
    const [isUtilityBilFile, setIsUtilityBilFile] = useState();
    const [isSignature, setIsSignature] = useState();

    const [isExcelFile, setIsExcelFile] = useState();


    const [showMakePaymentBTN, setShowMakePaymentBTN] = useState(false)


    const navigate = useNavigate();

    const Location = useLocation();
    const stepName = Location.state;
    console.log(stepName);
    useEffect(() => {
        window.scrollTo(0, 0);
        setPlanFee(sessionStorage.getItem("planFee"));
        getStepsData();
        setDocumentId(localStorage.getItem("documentId"));
        setToken(sessionStorage.getItem("token"));
    }, [number, IsEnable, isHouseProperty]);

    // GET 6 STEPS
    const getStepsData = () => {
        const planId = stepName?.data.planId
            ? stepName?.data.planId
            : sessionStorage.getItem("planId");
        const subServiceId = stepName?.subServiceIds
            ? stepName?.subServiceIds
            : sessionStorage.getItem("subServiceIds");

        // console.log(planId, subServiceId)

        const data = localStorage.getItem("userId");
        setUserId(data);
        const token = localStorage.getItem("token");
        // console.log("token : ", token)
        const method = "steps/";
        axios
            .get(`${REACT_APP_BASE_URL}steps/${subServiceId}/${planId}`)
            .then((response) => {
                console.log("steps : ", response.data.data);
                setSteps(response.data.data.steps);
                setCount(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const AddressProofLlp = [
        { value: "Voter’s ID", label: "Voter’s ID" },
        { value: "Passport", label: "Passport" },
        { value: "Driver’s licence", label: "Driver’s licence" },
        { value: "Aadhar Card", label: "Aadhar Card" },
    ]
    const ResidentialProof = [
        { value: "bank statement", label: "bank statement" },
        { value: "telephone bill", label: "telephone bill" },
        { value: "mobile bill", label: "mobile bill" },
        { value: "electricity bill", label: "electricity bill" },
        { value: "gas bill", label: "gas bill" },
    ]
    const NRIAddress = [
        { value: "Driver’s licence", label: "Driver’s licence" },
        { value: "bank statement", label: "bank statement" },
        { value: "residence card", label: "residence card" },
    ]
    const UtilityFile = [
        { value: "electricity bill", label: "electricity bill" },
        { value: "telephone bill,", label: "telephone bill," },
    ]

    // $(document).ready(function () {
    $(".nm1").on("click", function () {
        setBtn(false);
        $(".nm1").addClass("active");
        $(".nm1,.nm2,.nm3,.nm4,.nm5,.nm6").removeClass("done");
        $(".nm2,.nm3,.nm4,.nm5,.nm6").removeClass("active");
        $(".step-1").removeClass("d-none");
        $(".step-2, .step-3, .step-4, .step-5, .step-6").addClass("d-none");
    });

    $(".nm2").on("click", function () {
        setIsEnable(false);
        setBtn(false);

        if (
            isPanCard &&
            isPassport &&
            isAddressProofSelected &&
            isaddressProofFile &&
            isresidenceProofSelected &&
            isResidenseProofFile &&
            isNriAddressSelected &&
            isNriAddressProofFile &&
            ispostiledCopy
        ) {
        $(".nm2").addClass("active");
        $(".nm1").addClass("done");
        $(".nm2,.nm3,.nm4,.nm5,.nm6").removeClass("done");
        $(".nm1,.nm3,.nm4,.nm5,.nm6").removeClass("active");

        $(".step-2").removeClass("d-none");
        $(".step-1, .step-3, .step-4, .step-5, .step-6").addClass("d-none");
        }
        else{
          toast.warn("Please fill all empty field..!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
    }
    );

    // $(".nm3").on("click", function () {
    //   setBtn(false)
    //   console.log("isRadioSelected : ", isRadio1Selected);
    //   console.log(
    //     "isanyOtherExemptionToBeClaimed : ",
    //     isanyOtherExemptionToBeClaimed
    //   );
    //   console.log("isAnnualSalary : ", isAnnualSalary);
    //   if (isRadio1Selected) {
    //     if (
    //       isanyOtherExemptionToBeClaimed &&
    //       isanyOtherExemptionToBeClaimed !== undefined
    //     ) {
    //       if (data.anyOtherExemptionToBeClaimed > 0) {
    //         console.log("In 1 claimed");
    //         if (
    //           isForm16File &&
    //           isanyOtherExemptionToBeClaimed &&
    //           isexemptionRelatedDocuments &&
    //           isRemarks
    //         ) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       } else {
    //         if (isForm16File && isanyOtherExemptionToBeClaimed && isRemarks) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       }
    //     }
    //   } else {
    //     if (isAnnualSalary && isAnnualSalary !== undefined) {
    //       if (data.annualSalary > 0) {
    //         console.log("in greater than 0 condition")
    //         if (
    //           isAnnualSalary &&
    //           isNameOfEmployer &&
    //           isPanOfEmployer &&
    //           isRemarks2
    //         ) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       } else {
    //         console.log("in equal to 0 condition")

    //         if (isAnnualSalary === 0) {
    //           $(".nm3").addClass("active");
    //           $(".nm1, .nm2").addClass("done");
    //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
    //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

    //           $(".step-3").removeClass("d-none");
    //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
    //         }
    //       }
    //     }
    //   }
    // });
    $(".nm3").on("click", function () {

        $(".nm3").addClass("active");
        $(".nm1, .nm2").addClass("done");
        $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
        $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

        $(".step-3").removeClass("d-none");
        $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
            "d-none"
        );
    });



    $(".step-1n, .step-3p").on("click", function () {
        $(".step-2").removeClass("d-none");
        $(".step-1, .step-3, .step-4, .step-5, .step-6").addClass("d-none");

        $(".nm1").removeClass("active");
        $(".nm1").addClass("done");

        $(".nm2").addClass("active");

        $(".nm3").removeClass("active");
        $(".nm2").removeClass("done");
        $(".nm2").addClass("active");
    });
    $(".step-2n, .step-4p").on("click", function () {
        $(".step-3").removeClass("d-none");
        $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");

        $(".nm2").removeClass("active");
        $(".nm2").addClass("done");

        $(".nm3").addClass("active");

        $(".nm4").removeClass("active");
        $(".nm3").removeClass("done");
        $(".nm3").addClass("active");
    });


    $(".step-2p").on("click", function () {
        $(".step-1").removeClass("d-none");
        $(".step-2, .step-3 .step-4, .step-5, .step-6").addClass("d-none");

        $(".nm2").removeClass("active");
        $(".nm1").removeClass("done");
        $(".nm1").addClass("active");
    });
    $("a.form-title").on("click", function () {
        $(this).next(".form-details").slideToggle();
        $(this).toggleClass("imgtoggle");
        return false;
    });
    // });

    const inputHandle = (e) => {
        const { name, value, type, files } = e.target;

        console.log("radio : ", name, value.toUpperCase(), type);
        if (type == "file") {
            console.log(files);
            console.log(files[0].type);
            const fileType = files[0].type.split("/")[1];
            const method = `document/upload/${fileType}`;
            const formData = new FormData();
            formData.append("file", files[0]);
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            setData({ ...data, [name]: res.data.data.key });

                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            if (name === "panNumber") {
                setIsPanValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            if (name === "passportNumber") {
                setIsPassportValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            setData({ ...data, [name]: value });
        }
    };

    const inputValidation = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passportPattern =
            /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
        const { name, value, type, files } = e.target;
        const isValidEmail = emailPattern.test(value);
        const isValidPassportNumber = passportPattern.test(value);

        if (name === "panCard") {
            if (files.length > 0) {
                setIsPanCard(true);
            } else {
                setIsPanCard(false)
            }
        }
        if (name === "passport") {
            if (files.length > 0) {
                setIsPassport(true);
            } else {
                setIsPassport(false)
            }
        }
        if (name === "address-proof-file") {
            if (files.length > 0) {
                setIsAddressProofFile(true);
            } else {
                setIsAddressProofFile(false)
            }
        }
        if (name === "residense-proof-file") {
            if (files.length > 0) {
                setIsResidenseProofFile(true);
            } else {
                setIsResidenseProofFile(false)
            }
        }
        if (name === "nri-address-proof") {
            if (files.length > 0) {
                setIsNriAddressProofFile(true);
            } else {
                setIsNriAddressProofFile(false)
            }
        }
        if (name === "apostiledCopy") {
            if (files.length > 0) {
                setIsPostiledCopy(true);
            } else {
                setIsPostiledCopy(false)
            }
        }
        if (name === "officeAddress") {
            if (files.length > 0) {
                setIsOfficeAddress(true);
            } else {
                setIsOfficeAddress(false)
            }
        }
        if (name === "noc") {
            if (files.length > 0) {
                setIsNoc(true);
            } else {
                setIsNoc(false)
            }
        }
        if (name === "utility-Bill-File") {
            if (files.length > 0) {
                setIsUtilityBilFile(true);
            } else {
                setIsUtilityBilFile(false)
            }
        }
        if (name === "signature") {
            if (files.length > 0) {
                setIsSignature(true);
            } else {
                setIsSignature(false)
            }
        }
    };
    const utilityBillType = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
          setIsUtilityBillSelected(false);
        } else {
          setIsUtilityBillSelected(true);
        }
        setData({ ...data, [name]: value });
      };

    const checkValidationStep1 = () => {
        if (
            isPanCard &&
            isPassport &&
            isAddressProofSelected &&
            isaddressProofFile &&
            isresidenceProofSelected &&
            isResidenseProofFile &&
            isNriAddressSelected &&
            isNriAddressProofFile &&
            ispostiledCopy
        ) {
            //Post API for sending step's data
            const method = "document/add/";
            const body = {
                docs: [
                    {
                        propertyName: "Name (as per PAN card)",
                        value: data.namePanCard,
                    },
                    {
                        propertyName: "Email Address",
                        value: data.email,
                    },
                    {
                        propertyName: "Mobile Number",
                        value: data.mobileNumber,
                    },
                    {
                        propertyName: "Passport Number",
                        value: data.passportNumber,
                    },
                    {
                        propertyName: "Pan Card",
                        value: data.panCardDocument,
                    },
                    {
                        propertyName: "Adhar Card",
                        value: data.adharCardDocument,
                    },
                ],
            };

            console.log("adhar card : ", data);

            // axios
            //     .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "1", body, {
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //         },
            //     })
            //     .then(
            //         (res) => {
            //             console.log("response : ", res);
            //             if (res) {
            //                 toast.success("Your Information saved successfully..!", {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                 });
            //                 window.scrollTo(0, 0);
            //             }
            //         },
            //         (error) => {
            //             toast.error("Something went wrong..!", {
            //                 position: toast.POSITION.TOP_RIGHT,
            //             });
            //         }
            //     );
        } else {
            toast.error("Please fill all empty field..!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const checkValidationStep2 = () => {
        console.log("radio is checked or not : ", isRadio1Selected);
        if (isRadio1Selected) {
            //First radio button is selected logic
            if (data.anyOtherExemptionToBeClaimed > 0) {
                console.log("IN greater than zero condition");
                if (
                    isForm16File &&
                    isanyOtherExemptionToBeClaimed &&
                    isexemptionRelatedDocuments &&
                    isRemarks
                ) {
                    //Post API for sending step's data
                    const method = "document/add/";
                    const body = {
                        docs: [
                            {
                                propertyName: "Form 16",
                                value: data.form16File,
                            },
                            {
                                propertyName: "Any other Exemption to be claimed",
                                value: data.anyOtherExemptionToBeClaimed,
                            },
                            {
                                propertyName: "Exemption related documentary",
                                value: data.exemptionRelatedDocuments
                                    ? data.exemptionRelatedDocuments
                                    : "null",
                            },
                            {
                                propertyName: "Remarks if any",
                                value: data.passportNumber,
                            },
                        ],
                    };

                    axios
                        .post(
                            REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                            body,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then(
                            (res) => {
                                console.log("response : ", res);
                                if (res) {
                                    toast.success("Your Information saved successfully..!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    window.scrollTo(0, 0);
                                    $(".nm3").addClass("active");
                                    $(".nm1, .nm2").addClass("done");
                                    $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                    $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                    $(".step-3").removeClass("d-none");
                                    $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                        "d-none"
                                    );
                                }
                            },
                            (error) => {
                                toast.error("Something went wrong..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        );
                } else {
                    toast.error("Please fill all empty field..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                console.log("In value is zero condition");
                //Post API for sending step's data
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Form 16",
                            value: data.form16File,
                        },
                        {
                            propertyName: "Any other Exemption to be claimed",
                            value: data.anyOtherExemptionToBeClaimed,
                        },
                        {
                            propertyName: "Exemption related documentary",
                            value: "null",
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks,
                        },
                    ],
                };

                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                                $(".nm3").addClass("active");
                                $(".nm1, .nm2").addClass("done");
                                $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                $(".step-3").removeClass("d-none");
                                $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                    "d-none"
                                );
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            }
        } else {
            //Second radio button is selected logic
            if (data.annualSalary > 0) {
                if (
                    isAnnualSalary &&
                    isNameOfEmployer &&
                    isPanOfEmployer &&
                    isRemarks2
                ) {
                    //Post API for sending step's data
                    const method = "document/add/";
                    const body = {
                        docs: [
                            {
                                propertyName: "Annual Salary/Pension",
                                value: data.annualSalary,
                            },
                            {
                                propertyName: "Name of the Employer",
                                value: data.nameOfEmployer,
                            },
                            {
                                propertyName: "Pan of the Employer",
                                value: data.panOfEmployer,
                            },
                            {
                                propertyName: "Remarks if any",
                                value: data.remarks2,
                            },
                        ],
                    };

                    axios
                        .post(
                            REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                            body,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then(
                            (res) => {
                                console.log("response : ", res);
                                if (res) {
                                    toast.success("Your Information saved successfully..!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    $(".nm3").addClass("active");
                                    $(".nm1, .nm2").addClass("done");
                                    $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                    $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                    $(".step-3").removeClass("d-none");
                                    $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                        "d-none"
                                    );
                                }
                            },
                            (error) => {
                                toast.error("Something went wrong..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        );
                } else {
                    toast.error("Please fill all empty field..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Annual Salary/Pension",
                            value: data.annualSalary,
                        },
                        {
                            propertyName: "Name of the Employer",
                            value: data.nameOfEmployer ? data.nameOfEmployer : "null",
                        },
                        {
                            propertyName: "Pan of the Employer",
                            value: data.panOfEmployer ? data.panOfEmployer : "null",
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks2 ? data.remarks2 : "null",
                        },
                    ],
                };

                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "2",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                                $(".nm3").addClass("active");
                                $(".nm1, .nm2").addClass("done");
                                $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                                $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                                $(".step-3").removeClass("d-none");
                                $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                                    "d-none"
                                );
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            }
        }
    };

    const checkValidationStep3 = () => {
        if (data.addOtherIncome !== "None") {
            if (
                isSavingBankInterest &&
                isInterestOnFD &&
                isInterestOnFDFile &&
                isInterestOnRD &&
                isInterestOnRDFile &&
                isExpenseIfAnyInRespectOfIncome &&
                isExpenseIfAnyInRespectOfAboveIncome &&
                data.addOtherIncome !== null &&
                isAnotherIncomeDocument &&
                isRemarks3
            ) {
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Saving Bank Interest",
                            value: data.savingBankInterest,
                        },
                        {
                            propertyName: "Interest on fixed deposit",
                            value: data.interestOnFD,
                        },
                        {
                            propertyName: "Interest on fixed deposit file",
                            value: data.interestOnFDFile,
                        },
                        {
                            propertyName: "Interest on recurring deposit",
                            value: data.interestOnRD,
                        },
                        {
                            propertyName: "Interest on recurring deposit file",
                            value: data.interestOnRDFile,
                        },
                        {
                            propertyName: "Expense if any in respect of income",
                            value: data.expenseIfAnyInRespectOfIncome,
                        },
                        {
                            propertyName: "Expense if any in respect of above income",
                            value: data.expenseIfAnyInRespectOfAboveIncome,
                        },
                        {
                            propertyName: "Add Other Income",
                            value: data.addOtherIncome,
                        },
                        {
                            propertyName: "Add Other Income file",
                            value: data.anotherIncomeDocument,
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks3,
                        },
                    ],
                };
                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "3",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            } else {
                toast.error("Please fill empty fields..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            if (
                isSavingBankInterest &&
                isInterestOnFD &&
                isInterestOnFDFile &&
                isInterestOnRD &&
                isInterestOnRDFile &&
                isExpenseIfAnyInRespectOfIncome &&
                isExpenseIfAnyInRespectOfAboveIncome &&
                isRemarks3
            ) {
                const method = "document/add/";
                const body = {
                    docs: [
                        {
                            propertyName: "Saving Bank Interest",
                            value: data.savingBankInterest,
                        },
                        {
                            propertyName: "Interest on fixed deposit",
                            value: data.interestOnFD,
                        },
                        {
                            propertyName: "Interest on fixed deposit file",
                            value: data.interestOnFDFile,
                        },
                        {
                            propertyName: "Interest on recurring deposit",
                            value: data.interestOnRD,
                        },
                        {
                            propertyName: "Interest on recurring deposit file",
                            value: data.interestOnRDFile,
                        },
                        {
                            propertyName: "Expense if any in respect of income",
                            value: data.expenseIfAnyInRespectOfIncome,
                        },
                        {
                            propertyName: "Expense if any in respect of above income",
                            value: data.expenseIfAnyInRespectOfAboveIncome,
                        },
                        {
                            propertyName: "Add Other Income",
                            value: "None",
                        },
                        {
                            propertyName: "Remarks if any",
                            value: data.remarks3,
                        },
                    ],
                };
                axios
                    .post(
                        REACT_APP_BASE_URL + method + stepName.docId + "/" + "3",
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(
                        (res) => {
                            console.log("response : ", res);
                            if (res) {
                                toast.success("Your Information saved successfully..!", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.scrollTo(0, 0);
                            }
                        },
                        (error) => {
                            toast.error("Something went wrong..!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    );
            } else {
                toast.error("Please fill empty fields..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const checkValidationStep6 = () => {
        console.log("data : ", bankAccountData);
        const method = "document/add/";
        const body = {
            docs: [
                Object.entries(bankAccountData).map(([propertyName, value]) => ({
                    propertyName: propertyName,
                    value: value,
                })),
            ],
        };

        const bodyData = {
            docs: body.docs[0],
        };

        console.log("bodyData : ", bodyData);

        axios
            .post(
                REACT_APP_BASE_URL + method + stepName.docId + "/" + "6",
                bodyData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(
                (res) => {
                    console.log("response : ", res);
                    if (res) {
                        toast.success("Your Information saved successfully..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        window.scrollTo(0, 0);
                        displayRazorpay();
                    }
                },
                (error) => {
                    toast.error("Something went wrong..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            );
    };

    const addressProofSelected = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
            setIsAddressProofSelected(false);
        } else {
            setIsAddressProofSelected(true);
        }
        setData({ ...data, [name]: value });
    };

    const ResidenceProofSelected = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
            setIsResidenceProofSelected(false);
        } else {
            setIsResidenceProofSelected(true);
        }
        setData({ ...data, [name]: value });
    };

    const nriAddressProofSelected = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
            setIsNriAddressSelected(false);
        } else {
            setIsNriAddressSelected(true);
        }
        setData({ ...data, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        newFormData.append("namePanCard", data.namePanCard);
        newFormData.append("passportNumber", data.passportNumber);
        newFormData.append("email", data.email);
        newFormData.append("mobileNumber", data.mobileNumber);
        newFormData.append("panCardDocument", data.panCardDocument);
        newFormData.append("aadharCardDocument", data.aadharCardDocument);
        newFormData.append("form16File", data.form16File);
        newFormData.append(
            "anyOtherExemptionToBeClaimed",
            data.anyOtherExemptionToBeClaimed
        );
        newFormData.append(
            "exemptionRelatedDocuments",
            data.exemptionRelatedDocuments
        );
        newFormData.append("remarks", data.remarks);
        newFormData.append("annualSalary", data.annualSalary);
        newFormData.append("nameOfEmployer", data.nameOfEmployer);
        newFormData.append("panOfEmployer", data.panOfEmployer);
        newFormData.append("otherAttachments", data.otherAttachments);
        newFormData.append("remarks2", data.remarks2);
        newFormData.append("savingBankInterest", data.savingBankInterest);
        newFormData.append("interestOnFD", data.interestOnFD);
        newFormData.append("interestOnFDFile", data.interestOnFDFile);
        newFormData.append("interestOnRD", data.interestOnRD);
        newFormData.append("interestOnRDFile", data.interestOnRDFile);
        newFormData.append("remarks3", data.remarks3);
        newFormData.append(
            "expenseIfAnyInRespectOfIncome",
            data.expenseIfAnyInRespectOfIncome
        );
        newFormData.append(
            "expenseIfAnyInRespectOfAboveIncome",
            data.expenseIfAnyInRespectOfAboveIncome
        );
        newFormData.append("addOtherIncome", data.addOtherIncome);
        newFormData.append("address", data.address);
        newFormData.append("coOwned", data.coOwned);
        newFormData.append("typeOfHouseProperty", data.typeOfHouseProperty);
        newFormData.append("nameOfTenant", data.nameOfTenant);
        newFormData.append("panOfTenant", data.panOfTenant);
        newFormData.append("annualRent", data.annualRent);
        newFormData.append("municipalTax", data.municipalTax);
        newFormData.append("municipalTaxPaidFile", data.municipalTaxPaidFile);
        newFormData.append("interestOnHousingLoan", data.interestOnHousingLoan);
        newFormData.append(
            "interestOnHousingLoanFile",
            data.interestOnHousingLoanFile
        );
        newFormData.append("princiaplRepaid", data.princiaplRepaid);
        newFormData.append("principalRepaidFile", data.principalRepaidFile);
        newFormData.append("remarks4", data.remarks4);
        newFormData.append("otherDeductions", data.otherDeductions);
        newFormData.append("excelFile", data.excelFile);
        newFormData.append("bank", data.bank);
        newFormData.append("bankAccountNumber", data.bankAccountNumber);
        newFormData.append("ifscode", data.ifscode);
        newFormData.append(
            "governedByPortugueseCivil",
            data.governedByPortugueseCivil
        );
        newFormData.append("directorAtAnyCompany", data.directorAtAnyCompany);
        newFormData.append("claimBenefits", data.claimBenefits);
        newFormData.append("heldUnlisted", data.heldUnlisted);

        const url = "https://apibase.taxespro.in/";
        const method = `user/generate/income-tax/document/${userId}`;
        try {
            const response = await axios.post(url + method, newFormData);
            console.log(response.data); // Output: Response data from the server
            toast.success("Information saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };


    //Razor Pay Integration Loader(Pre-requisition)
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resolve(true);
            };

            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    //Razor Pay Integration (main)

    const displayRazorpay = async (amt) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("you r offline... Failed to load");
            return;
        }

        const options = {
            key: "rzp_test_snn65WkJ8E1lJr",
            currency: "INR",
            amount: parseInt(planFee) * 100,
            name: "Techgigs",
            description: "Thanks For Purchasing",

            handler: function (response) {
                const body = {
                    razorpayOrderId: response.razorpay_payment_id,
                };
                console.log(body);
                const methods = `document/payment/${stepName.docId}`;
                axios
                    .post(REACT_APP_BASE_URL + methods, body, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        console.log("RazerPay Response", res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                toast.success("Your Payment is successfull..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/incometaxreturn");
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <>
            <DemoHeader />
            <div className="bannertopstep">
                <div className="container text-left">
                    <div className="banner-vhmian1 itr-breadcrumb">



                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            HOME
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            // to="/incometaxreturn"
                            onClick={() => window.history.go(-1)}
                        >
                            Pan Services
                        </Link>
                        <Typography color="text.primary">Form</Typography>
                    </Breadcrumbs>
                    <div className="">
                        <div className="top-space-section step-gap">
                            <section className="step-box clearfix">
                                <div className="container">
                                    <div className="step-number-row1"></div>
                                    {/* steps name */}
                                    <ul className="nav nav-fill step-number row gutter-0 d-flex">
                                        {/* step 1 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm1 active"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="General Information"
                                            >
                                                <span>
                                                    1<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    Documents of Partners
                                                </p>
                                            </a>
                                        </li>
                                        {/* step 2 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm2"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Income from salary"
                                            >
                                                <span>
                                                    2<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    Documents of LLP
                                                </p>
                                            </a>
                                        </li>
                                    </ul>

                                    <form onSubmit={handleSubmit}>
                                        <div className="step-details step-form">
                                            {/*Step-1  */}
                                            <div className={`step-${number}`}>
                                                <div className="row">

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    PAN Card
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-5">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Choose PAN Card File</label>
                                                                        <input
                                                                            type="file"
                                                                            name="panCard"
                                                                            className={`form-control ${isPanCard !== undefined
                                                                                ? isPanCard
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Pan Card is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Passport
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-5">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Choose Passport File</label>
                                                                        <input
                                                                            type="file"
                                                                            name="passport"
                                                                            className={`form-control ${isPassport !== undefined
                                                                                ? isPassport
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Passport is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Address Proof of Partners
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-4">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">
                                                                            Select Address Proof
                                                                        </label>
                                                                        <Select
                                                                            name="title"
                                                                            options={AddressProofLlp}
                                                                            onChange={addressProofSelected}
                                                                            styles={{ width: "100%", height: "max-content" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-5">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Address Proof</label>
                                                                        <input
                                                                            type="file"
                                                                            name="address-proof-file"
                                                                            className={`form-control ${isaddressProofFile !== undefined
                                                                                ? isaddressProofFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Select Address Proof"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Address Proof is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Residence Proof of Partners
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-4">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">
                                                                            Select Residence Proof
                                                                        </label>
                                                                        <Select
                                                                            name="title"
                                                                            options={ResidentialProof}
                                                                            onChange={ResidenceProofSelected}
                                                                            styles={{ width: "100%", height: "max-content" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-5">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Residence Proof</label>
                                                                        <input
                                                                            type="file"
                                                                            name="residense-proof-file"
                                                                            className={`form-control ${isResidenseProofFile !== undefined
                                                                                ? isResidenseProofFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Address Proof is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    NRIs Proof of Address
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-4">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">
                                                                            Select NRI Address Proof
                                                                        </label>
                                                                        <Select
                                                                            name="title"
                                                                            options={NRIAddress}
                                                                            onChange={nriAddressProofSelected}
                                                                            styles={{ width: "100%", height: "max-content" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-5">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">NRI Address Proof</label>
                                                                        <input
                                                                            type="file"
                                                                            name="nri-address-proof"
                                                                            className={`form-control ${isNriAddressProofFile !== undefined
                                                                                ? isNriAddressProofFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Identity Proof is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    apostilled translation copy
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-5">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">If the documents are in other than the English language, a notarized or apostilled translation copy</label>
                                                                        <input
                                                                            type="file"
                                                                            name="apostiledCopy"
                                                                            className={`form-control ${ispostiledCopy !== undefined
                                                                                ? ispostiledCopy
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                        apostilled translation copy is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                                                {isPanCard !== undefined ||
                                                    isPassport !== undefined ||
                                                    isAddressProofSelected !== undefined ||
                                                    isaddressProofFile !== undefined ||
                                                    isresidenceProofSelected !== undefined ||
                                                    isResidenseProofFile !== undefined ||
                                                    isNriAddressSelected !== undefined ||
                                                    isNriAddressProofFile !== undefined ||
                                                    ispostiledCopy != undefined ? (
                                                    !isPanCard ||
                                                        !isPassport ||
                                                        !isAddressProofSelected ||
                                                        !isaddressProofFile ||
                                                        !isresidenceProofSelected ||
                                                        !isResidenseProofFile ||
                                                        !isNriAddressSelected ||
                                                        !isNriAddressProofFile ||
                                                        !ispostiledCopy ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Please fill all epmty fileds...!
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                <Button
                                                    className="nm2"
                                                    id="saveInformation"
                                                    variant="contained"
                                                    style={{
                                                        borderRadius: "25px",
                                                        background: "#AE0721",
                                                    }}
                                                      onClick={() => checkValidationStep1()}
                                                    endIcon={<SendIcon />}
                                                    disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                >
                                                    Save & Next
                                                </Button>
                                            </div>
                                        </div>

                                        {/*Step-2 */}
                                        <div className="step-2 d-none  step-form">
                                            <div className="row">

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Proof of Registered Office Address
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                >
                                                                    <label className="label-style">Choose File</label>
                                                                    <input
                                                                        type="file"
                                                                        name="officeAddress"
                                                                        className={`form-control ${isOfficeAddress !== undefined
                                                                            ? isOfficeAddress
                                                                                ? "is-valid"
                                                                                : "is-invalid"
                                                                            : ""
                                                                            }`}
                                                                        placeholder=" Proof of Registered Office Address"
                                                                        onChange={inputHandle}
                                                                        onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Identity Proof is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                No-Objection certificate
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                >
                                                                    <label className="label-style">If the registered office is taken on rent, a rent agreement and a no-objection certificate from the landlord</label>
                                                                    <input
                                                                        type="file"
                                                                        name="noc"
                                                                        className={`form-control ${isNoc !== undefined
                                                                            ? isNoc
                                                                                ? "is-valid"
                                                                                : "is-invalid"
                                                                            : ""
                                                                            }`}
                                                                        placeholder="NOC"
                                                                        onChange={inputHandle}
                                                                        onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        No Objection Certificate is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Utility bills
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div className="form-group bottom-space">
                                                                    <label className="label-style">
                                                                        Select Utility Proof
                                                                    </label>
                                                                    <Select
                                                                        name="title"
                                                                        options={UtilityFile}
                                                                        onChange={utilityBillType}
                                                                        styles={{ width: "100%", height: "max-content" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                >
                                                                    <label className="label-style">Select File</label>
                                                                    <input
                                                                        type="file"
                                                                        name="utility-Bill-File"
                                                                        className={`form-control ${isUtilityBilFile !== undefined
                                                                            ? isUtilityBilFile
                                                                                ? "is-valid"
                                                                                : "is-invalid"
                                                                            : ""
                                                                            }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Utility Bill is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                                        <div className="form-group bottom-space">
                                                            <label
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Digital Signature Certificate
                                                            </label>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-sm-6 col-lg-4 col-xl-5">
                                                        <div className="row">
                                                            <div className="col-xl-9">
                                                                <div
                                                                    className="form-group bottom-space"
                                                                >
                                                                    <label className="label-style">One of the designated partners needs to opt for a digital signature certificate also since all documents and applications will be digitally signed by the authorized signatory.</label>
                                                                    <input
                                                                        type="file"
                                                                        name="signature"
                                                                        className={`form-control ${isSignature !== undefined
                                                                            ? isSignature
                                                                                ? "is-valid"
                                                                                : "is-invalid"
                                                                            : ""
                                                                            }`}
                                                                        placeholder="From 16"
                                                                        onChange={inputHandle}
                                                                        onBlur={inputValidation}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Digital Signature is required
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* for display none use this class -> d-none */}
                                            {isOfficeAddress !== undefined ||
                                            isNoc !== undefined ||
                                            isUtilityBillSelected !== undefined ||
                                            isUtilityBilFile !== undefined ||
                                            isSignature !== undefined? (
                                                   !isOfficeAddress ||
                                                   !isNoc ||
                                                   !isUtilityBillSelected ||
                                                   !isUtilityBilFile ||
                                                   !isSignature ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Please fill all epmty fileds...!
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            {<div className="step-nav-box">
                                                <Button
                                                    id="saveInformation"
                                                    variant="contained"
                                                    style={{
                                                        borderRadius: "25px",
                                                        background: "#AE0721",
                                                    }}
                                                    onClick={() => checkValidationStep6()}
                                                    endIcon={<SendIcon />}
                                                    disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                >
                                                    Save & Make Payment
                                                </Button>
                                            </div>}
                                        </div>
                                    </form>
                                </div>
                            </section>

                            {/* Footer part */}
                            <section className="grayad">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                                            <span>
                                                <b>Don’t have Information or documents?</b>
                                            </span>
                                            <span className="clearfix d-block">
                                                No worries! You can edit/submit the info and document
                                                later as well
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                                            <div className="pl-0 pl-lg-1 pl-xl-4">
                                                <span className="blue-text">
                                                    <b>{stepName?.data?.planName}</b>
                                                </span>
                                                <span className="clearfix d-block smalltext">
                                                    Made for Immovable property purchasers
                                                </span>
                                            </div>
                                        </div>
                                        {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                                            <a
                                                className="btn btn-dark regbtn"
                                                onClick={() => checkValidationStep6()}
                                            >
                                                Make Payment
                                            </a>
                                        </div> : ''}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LLPForm;
