import "../common/Common.css"
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { REACT_APP_BASE_URL } from '../../service/Constant';
import './GstFilling.css'

const GstFilling = () => {

    const [bannerData, setBannerData] = useState([]);
  const [img, setImg] = useState(''); // Add a new state for the image

  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = () => {
    const method = 'banner/images?subServiceId=Home&position=3';
    axios.get(REACT_APP_BASE_URL + method).then((res) => {
      console.log('Incom tAx Filling Banner ', res.data.data);
      setBannerData(res.data.data);
      const imgData = res?.data?.data[0]?.image;
    //   console.log(`res.data.data:img/jpeg;base64,${imgData}`);
      setImg(imgData); // Update the image state
    }).catch((err)=>{
      console.log(err)
    })
  };
  return (
    <>
    <section className="blueimg-bo" style={{
          backgroundImage: `url(data:image/jpeg;base64,${bannerData[0]?.image})`,height:"12rem",backgroundSize:'cover'
        }}>
             <div className="container">
                 <h4 className="white-title">
                    {/* GST Filling */} {bannerData[0]?.title}
                    </h4>
                 <div className="row align-items-center">
                     <div className="col-lg-9">
                         <p className="white-smalltext mbp0">
                            {/* Experience seamless Billing and GST Returns Filing along with easy reconciliation of mismatched invoices and automatic data validation. */}
                            {bannerData[0]?.text}
                            </p>
                     </div>
                     <div className="col-lg-3 text-left text-lg-right gst-filling-btn">
                         <button className="btn btn-light regbtn" type="button">
                            {/* START GST FILLING */}
                            {bannerData[0]?.buttonText}
                            </button>
                     </div>
                 </div>
             </div>
         </section>
    </>
  )
}

export default GstFilling