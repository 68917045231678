import React, { useState } from 'react'
import "../../components/common/Common.css"
import "../coontactus/ContactUs.css"

const ContactUs = () => {
    
    const [buttonsVisible, setButtonsVisible] = useState(false);
    const toggleContactVisibility = () => {
        console.log("buttonsVisible :", buttonsVisible)
        setButtonsVisible(!buttonsVisible);
    };
    const cancleContactModel = () => {
        setButtonsVisible(buttonsVisible);
    }
    return (
        <>
        <div>
            <a className="floting-btn d-none d-sm-block onTablet" onClick={toggleContactVisibility}>
                <img src="images/call-icon-w.png" alt="" className="d-block m-auto" />
                <span>Contact Us</span>
            </a>
            {buttonsVisible && 
            <div className="contact-box1 onTablet" style={{display:`${buttonsVisible?"block":"none"}`}} onClick={toggleContactVisibility}>
                <div className="row gutter-0">
                    <div className="col-sm-6 align-content-center">
                    {buttonsVisible && <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '8%', paddingLeft: '1px', position: 'absolute', paddingTop: '6rem', color: '#fff', cursor: 'pointer' }} onClick={cancleContactModel} viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fill-rule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clip-rule="evenodd" />
                            </svg>}
                        <div className="bgblack">

                            <div className="calltext">Call us at</div>
                            <div className="number1"><a href='tel:+91 9800 1010 1010' >+91 9800 1010 1010</a></div>
                            <div className="number1"><a href='tel:+91  1800 000 000' > 1800 000 000</a></div>
                            <div className="find">Find Local numbers <a href="#">here</a></div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="call-gary">
                            <div className="left"><img src="images/chat-icon.png" alt="" /></div>
                            <div className="right">
                                <h4>Chat with Us</h4>
                                <p>Chat with us and get expert advice at anytime</p>
                            </div>
                        </div>
                        <div className="call-gary">
                            <div className="left"><img src="images/email-icon.png" alt="" /></div>
                            <div className="right">
                                <h4>Chat with Us</h4>
                                <p>Email us with comments, ueries or feedbacks</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        </>
    )
}

export default ContactUs