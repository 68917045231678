import React, { useState } from "react";
import "../../common/Common.css";
import "../incometax/Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const GstAnnualReturnForm = () => {
    const [planFee, setPlanFee] = useState();
    const [data, setData] = useState({});
    const [userId, setUserId] = useState();
    const [steps, setSteps] = useState();
    const [number, setNumber] = useState(1);
    const [count, setCount] = useState();
    const [IsEnable, setIsEnable] = useState(false);
    const [passportValue, setIsPassportValue] = useState("");
    const [panValue, setIsPanValue] = useState("");
    const [documentId, setDocumentId] = useState();
    const [token, setToken] = useState();
    const [isHouseProperty, setIsHouseProperty] = useState(false);

    const [bankAccountData, setBankAccountData] = useState({});

    const [isInwardSupplyValue, setIsInwardSupplyValue] = useState();
    const [isOutwardSupplyValue, setIsOutwardSupplyValue] = useState();
    const [isgstNumber, setIsGSTNumber] = useState();
    const [isReconcilationStatement, setIsReconcilationStatement] = useState();
    const [isIncomeTaxLedger, setIsIncomeTaxLedger] = useState();
    const [isAmountOfCredit, setIsAmountOfCredit] = useState();
    const [isCapitalGooddescription, setIsCapitalGooddescription] = useState();
    const [isCostOfCapitalGood, setIsCostOfCapitalGood] = useState();
    const [isPurchaseDate, setIsPurchaseDate] = useState();

    const [showMakePaymentBTN, setShowMakePaymentBTN] = useState(false)


    const navigate = useNavigate();

    const Location = useLocation();
    const stepName = Location.state;
    useEffect(() => {
        window.scrollTo(0, 0);
        setPlanFee(sessionStorage.getItem("planFee"));
        getStepsData();
        setDocumentId(localStorage.getItem("documentId"));
        setToken(sessionStorage.getItem("token"));
    }, [number, IsEnable, isHouseProperty]);

    // GET 6 STEPS
    const getStepsData = () => {
        const planId = stepName?.data.planId
            ? stepName?.data.planId
            : sessionStorage.getItem("planId");
        const subServiceId = stepName?.subServiceIds
            ? stepName?.subServiceIds
            : sessionStorage.getItem("subServiceIds");

        // console.log(planId, subServiceId)

        const data = localStorage.getItem("userId");
        setUserId(data);
        const token = localStorage.getItem("token");
        // console.log("token : ", token)
        const method = "steps/";
        axios
            .get(`${REACT_APP_BASE_URL}steps/${subServiceId}/${planId}`)
            .then((response) => {
                console.log("steps : ", response.data.data);
                setSteps(response.data.data.steps);
                setCount(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const inputHandle = (e) => {
        const { name, value, type, files } = e.target;

        console.log("radio : ", name, value.toUpperCase(), type);
        if (type == "file") {
            console.log(files);
            console.log(files[0].type);
            const fileType = files[0].type.split("/")[1];
            const method = `document/upload/${fileType}`;
            const formData = new FormData();
            formData.append("file", files[0]);
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            setData({ ...data, [name]: res.data.data.key });

                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            if (name === "panNumber") {
                setIsPanValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            if (name === "passportNumber") {
                setIsPassportValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            setData({ ...data, [name]: value });
        }
    };

    const inputValidation = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passportPattern =
            /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
        const { name, value, type, files } = e.target;
        const isValidEmail = emailPattern.test(value);
        const isValidPassportNumber = passportPattern.test(value);

        if (name === "InwardSupplies") {
            if (value > 0) {
                setIsInwardSupplyValue(true);
            } else {
                setIsInwardSupplyValue(false)
            }
        }
        if(name === "OutwardSupplies"){
            if(value > 0){
                setIsOutwardSupplyValue(false);
            }else{
                setIsOutwardSupplyValue(false)
            }
        }
        if (name === "gstNumber") {
            if (value.length > 1) {
                setIsGSTNumber(true);
            } else {
                setIsGSTNumber(false);
            }
        }
        if (name === "GSTReconciliationStatement") {
            if (files.length > 0) {
                setIsReconcilationStatement(true);
            } else {
                setIsReconcilationStatement(false)
            }
        }
        if (name === "IncomeTaxCreditLedger") {
            if (files.length > 0) {
                setIsIncomeTaxLedger(true);
            } else {
                setIsIncomeTaxLedger(false)
            }
        }
        if (name === "amountofcreadit") {
            if (value > 0) {
                setIsAmountOfCredit(true);
            } else {
                setIsAmountOfCredit(false)
            }
        }
        if (name === "descriptionOfCapitalGood") {
            if (value.length > 1) {
                setIsCapitalGooddescription(true);
            } else {
                setIsCapitalGooddescription(false);
            }
        }
        if (name === "costOfCapitalGood") {
            if (value > 0) {
                setIsCostOfCapitalGood(true);
            } else {
                setIsCostOfCapitalGood(false)
            }
        }
        if (name === "PurchaseDate") {
            const inputDate = new Date(value);
        
            if (isNaN(inputDate.getTime())) {
                setIsPurchaseDate(false);
            } else {
                setIsPurchaseDate(true);
            }
        }
    };

    const checkValidationStep6 = () => {
        console.log("data : ", bankAccountData);
        const method = "document/add/";
        const body = {
            docs: [
                Object.entries(bankAccountData).map(([propertyName, value]) => ({
                    propertyName: propertyName,
                    value: value,
                })),
            ],
        };

        const bodyData = {
            docs: body.docs[0],
        };

        console.log("bodyData : ", bodyData);

        axios
            .post(
                REACT_APP_BASE_URL + method + stepName.docId + "/" + "6",
                bodyData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(
                (res) => {
                    console.log("response : ", res);
                    if (res) {
                        toast.success("Your Information saved successfully..!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        window.scrollTo(0, 0);
                        displayRazorpay();
                    }
                },
                (error) => {
                    toast.error("Something went wrong..!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        newFormData.append("namePanCard", data.namePanCard);
        newFormData.append("passportNumber", data.passportNumber);
        newFormData.append("email", data.email);
        newFormData.append("mobileNumber", data.mobileNumber);
        newFormData.append("panCardDocument", data.panCardDocument);
        newFormData.append("aadharCardDocument", data.aadharCardDocument);
        newFormData.append("form16File", data.form16File);
        newFormData.append(
            "anyOtherExemptionToBeClaimed",
            data.anyOtherExemptionToBeClaimed
        );
        newFormData.append(
            "exemptionRelatedDocuments",
            data.exemptionRelatedDocuments
        );
        newFormData.append("remarks", data.remarks);
        newFormData.append("annualSalary", data.annualSalary);
        newFormData.append("nameOfEmployer", data.nameOfEmployer);
        newFormData.append("panOfEmployer", data.panOfEmployer);
        newFormData.append("otherAttachments", data.otherAttachments);
        newFormData.append("remarks2", data.remarks2);
        newFormData.append("savingBankInterest", data.savingBankInterest);
        newFormData.append("interestOnFD", data.interestOnFD);
        newFormData.append("interestOnFDFile", data.interestOnFDFile);
        newFormData.append("interestOnRD", data.interestOnRD);
        newFormData.append("interestOnRDFile", data.interestOnRDFile);
        newFormData.append("remarks3", data.remarks3);
        newFormData.append(
            "expenseIfAnyInRespectOfIncome",
            data.expenseIfAnyInRespectOfIncome
        );
        newFormData.append(
            "expenseIfAnyInRespectOfAboveIncome",
            data.expenseIfAnyInRespectOfAboveIncome
        );
        newFormData.append("addOtherIncome", data.addOtherIncome);
        newFormData.append("address", data.address);
        newFormData.append("coOwned", data.coOwned);
        newFormData.append("typeOfHouseProperty", data.typeOfHouseProperty);
        newFormData.append("nameOfTenant", data.nameOfTenant);
        newFormData.append("panOfTenant", data.panOfTenant);
        newFormData.append("annualRent", data.annualRent);
        newFormData.append("municipalTax", data.municipalTax);
        newFormData.append("municipalTaxPaidFile", data.municipalTaxPaidFile);
        newFormData.append("interestOnHousingLoan", data.interestOnHousingLoan);
        newFormData.append(
            "interestOnHousingLoanFile",
            data.interestOnHousingLoanFile
        );
        newFormData.append("princiaplRepaid", data.princiaplRepaid);
        newFormData.append("principalRepaidFile", data.principalRepaidFile);
        newFormData.append("remarks4", data.remarks4);
        newFormData.append("otherDeductions", data.otherDeductions);
        newFormData.append("excelFile", data.excelFile);
        newFormData.append("bank", data.bank);
        newFormData.append("bankAccountNumber", data.bankAccountNumber);
        newFormData.append("ifscode", data.ifscode);
        newFormData.append(
            "governedByPortugueseCivil",
            data.governedByPortugueseCivil
        );
        newFormData.append("directorAtAnyCompany", data.directorAtAnyCompany);
        newFormData.append("claimBenefits", data.claimBenefits);
        newFormData.append("heldUnlisted", data.heldUnlisted);

        const url = "https://apibase.taxespro.in/";
        const method = `user/generate/income-tax/document/${userId}`;
        try {
            const response = await axios.post(url + method, newFormData);
            console.log(response.data); // Output: Response data from the server
            toast.success("Information saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    //Razor Pay Integration Loader(Pre-requisition)
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resolve(true);
            };

            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    //Razor Pay Integration (main)

    const displayRazorpay = async (amt) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("you r offline... Failed to load");
            return;
        }

        const options = {
            key: "rzp_test_snn65WkJ8E1lJr",
            currency: "INR",
            amount: parseInt(planFee) * 100,
            name: "Techgigs",
            description: "Thanks For Purchasing",

            handler: function (response) {
                const body = {
                    razorpayOrderId: response.razorpay_payment_id,
                };
                console.log(body);
                const methods = `document/payment/${stepName.docId}`;
                axios
                    .post(REACT_APP_BASE_URL + methods, body, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        console.log("RazerPay Response", res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                toast.success("Your Payment is successfull..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/incometaxreturn");
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <>
            <DemoHeader />
            <div className="bannertopstep">
                <div className="container text-left">
                    <div className="banner-vhmian1 itr-breadcrumb">

                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            // to="/incometaxreturn"
                            onClick={() => window.history.go(-1)}
                        >
                            GST Annual Return
                        </Link>
                        <Typography color="text.primary"> GST Annual Return Form</Typography>
                    </Breadcrumbs>
                    <div className="">
                        <div className="top-space-section step-gap">
                            <section className="step-box clearfix">
                                <div className="container">
                                    <div
                                    ></div>
                                    {/* steps name */}
                                    <ul className="nav nav-fill step-number row gutter-0 d-flex" style={{ justifyContent: 'flex-start' }}>
                                        {/* step 1 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm1 active"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="General Information"
                                            >
                                                <span>
                                                    1<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    GST Annual Return
                                                </p>
                                            </a>
                                        </li>

                                    </ul>

                                    <form onSubmit={handleSubmit}>
                                        <div className="step-details step-form">
                                            {/*Step-1  */}
                                            <div className={`step-${number}`}>
                                                <div className="row">

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Details of total Inward and Outward Supplies in the scheduled Financial Year
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Total Value of Inward Supplies</label>
                                                                        <input
                                                                            type="number"
                                                                            name="InwardSupplies"
                                                                            className={`form-control ${isInwardSupplyValue !== undefined
                                                                                ? isInwardSupplyValue
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Inward Supplies Value"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Inward Supplies Value is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Total Value of Outward Supplies</label>
                                                                        <input
                                                                            type="number"
                                                                            name="OutwardSupplies"
                                                                            className={`form-control ${isOutwardSupplyValue !== undefined
                                                                                ? isOutwardSupplyValue
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Outward Supplies Value"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Outward Supplies value is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    GST Related Information
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">GSTIN of Business Entity/Seller</label>
                                                                        <input
                                                                            type="text"
                                                                            name="gstNumber"
                                                                            className={`form-control ${isgstNumber !== undefined
                                                                                ? isgstNumber
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Goods and Services Tax Identification Number"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                        GSTIN of Business Entity is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">GST Reconciliation Statement</label>
                                                                        <input
                                                                            type="file"
                                                                            name="GSTReconciliationStatement"
                                                                            className={`form-control ${isReconcilationStatement !== undefined
                                                                                ? isReconcilationStatement
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Choose GST Reconciliation Statement"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            GST Reconciliation Statement is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Income Tax Credit Ledger for the scheduled Financial Year
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <input
                                                                            type="file"
                                                                            name="IncomeTaxCreditLedger"
                                                                            className={`form-control ${isIncomeTaxLedger !== undefined
                                                                                ? isIncomeTaxLedger
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="Enter Income Tax Credit Ledger"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Income Tax Credit Ledger is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Details of the Block Credit, Capital Goods, and related documents
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                                                <div className="form-group bottom-space">
                                                                    <label
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        Block Credit
                                                                    </label>
                                                                    <br />
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div className="col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">Amount of Credit</label>
                                                                            <input
                                                                                type="number"
                                                                                name="amountofcreadit"
                                                                                className={`form-control ${isAmountOfCredit !== undefined
                                                                                    ? isAmountOfCredit
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Enter Amount of Credit"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Amount of Credit is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                                                <div className="form-group bottom-space">
                                                                    <label
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        Capital Goods
                                                                    </label>
                                                                    <br />
                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div className="col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">Description Of Capital Good</label>
                                                                            <textarea
                                                                                type="text"
                                                                                name="descriptionOfCapitalGood"
                                                                                className={`form-control ${isCapitalGooddescription !== undefined
                                                                                    ? isCapitalGooddescription
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Enter Description Of Capital Good"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            ></textarea>
                                                                            <div className="invalid-feedback">
                                                                                Amount of Capital Good is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style">Cost Of Capital Good</label>
                                                                            <input
                                                                                type="number"
                                                                                name="costOfCapitalGood"
                                                                                className={`form-control ${isCostOfCapitalGood !== undefined
                                                                                    ? isCostOfCapitalGood
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Enter Cost Of Capital Good"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Cost Of Capital Good is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="row">
                                                                    <div className="col-xl-9">
                                                                        <div
                                                                            className="form-group bottom-space"
                                                                        >
                                                                            <label className="label-style"> Purchase Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="PurchaseDate"
                                                                                className={`form-control ${isPurchaseDate !== undefined
                                                                                    ? isPurchaseDate
                                                                                        ? "is-valid"
                                                                                        : "is-invalid"
                                                                                    : ""
                                                                                    }`}
                                                                                placeholder="Enter Amount of Credit"
                                                                                onChange={inputHandle}
                                                                                onBlur={inputValidation}
                                                                                autoComplete="off"
                                                                            />
                                                                            <div className="invalid-feedback">
                                                                                Purchase Date is required
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>


                                                </div>
                                                {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                                                {isInwardSupplyValue !== undefined ||
                                                isOutwardSupplyValue !== undefined ||
                                                isgstNumber !== undefined ||
                                                isReconcilationStatement !== undefined ||
                                                isIncomeTaxLedger !== undefined ||
                                                isAmountOfCredit !== undefined ||
                                                isCapitalGooddescription !== undefined ||
                                                isCostOfCapitalGood !== undefined ||
                                                isPurchaseDate !== undefined ? (
                                                     !isInwardSupplyValue ||
                                                     !isOutwardSupplyValue ||
                                                     !isgstNumber ||
                                                     !isReconcilationStatement ||
                                                     !isIncomeTaxLedger ||
                                                     !isAmountOfCredit ||
                                                     !isCapitalGooddescription ||
                                                     !isCostOfCapitalGood ||
                                                     !isPurchaseDate ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Please fill all epmty fileds...!
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                <div className="step-nav-box">
                                                    <Button
                                                        id="saveInformation"
                                                        variant="contained"
                                                        style={{
                                                            borderRadius: "25px",
                                                            background: "#AE0721",
                                                        }}
                                                        onClick={() => checkValidationStep6()}
                                                        endIcon={<SendIcon />}
                                                        disabled={IsEnable}
                                                    >
                                                        Save & Make Payment
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </section>

                            {/* Footer part */}
                            <section className="grayad">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                                            <span>
                                                <b>Don’t have Information or documents?</b>
                                            </span>
                                            <span className="clearfix d-block">
                                                No worries! You can edit/submit the info and document
                                                later as well
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                                            <div className="pl-0 pl-lg-1 pl-xl-4">
                                                <span className="blue-text">
                                                    <b>{stepName?.data?.planName}</b>
                                                </span>
                                                <span className="clearfix d-block smalltext">
                                                    Made for Immovable property purchasers
                                                </span>
                                            </div>
                                        </div>
                                        {/* {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                                            <a
                                                className="btn btn-dark regbtn"
                                                onClick={() => checkValidationStep6()}
                                            >
                                                Make Payment
                                            </a>
                                        </div> : ''} */}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GstAnnualReturnForm;
