import React from 'react'

const Verify = () => {
    const handleChnge=(e)=>{
console.log(e.target.value);
    }
  return (
    <div>
        <form>
            <div>
                <input type='text' name='otpveri' onChange={handleChnge}/>
            </div>
        </form>
    </div>
  )
}

export default Verify