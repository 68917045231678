// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import { Container } from "@mui/material";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import SendIcon from "@mui/icons-material/Send";
// import axios from "axios";
// import { REACT_APP_BASE_URL } from "../../service/Constant";
// import { app, auth } from "../../service/FirebaseConfig";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import CircularProgress from "@mui/material/CircularProgress";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const SignUplogin = () => {
//   const Navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [isEmail, setIsEmail] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [emailError, setEmailError] = useState("");
//   const [error, setError] = useState("");
//   const [counter, setCounter] = useState(300);
//   const [signInFlag, setSignInFlag] = useState(true);
//   const [otpFlag, setOtpFlag] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [confirmationObject, setConfirmationObject] = useState("");
//   const [otp, setOtp] = useState("");
//   const [userId, setUserId] = useState("");
//   const [isSendingOTP, setIsSendingOTP] = useState(false);

//   const handleEmailChange = (e) => {
//     // console.log(e.target.value)
//     setEmail(e.target.value);
//     setIsEmail(true);
//     setIsMobile(false);
//   };
//   const handlMobileNumber = (e) => {
//     setPhoneNumber(e.target.value);
//     setIsEmail(false);
//     setIsMobile(true);
//   };
//   const handleLogIn = () => {
//     console.log(email);
//     const mail = {
//       email: email,
//     };
//     const method = "user/login/email/otp-verification";
//     // setLoader(true);
//     setIsSendingOTP(true);
//     axios
//       .post(REACT_APP_BASE_URL + method, mail)
//       .then((res) => {
//         console.log("email res", res);
//         setUserId(res.data.data.userId);
//         setOtpFlag(true);
//         setSignInFlag(false);
//         setCounter(300);
//         setIsSendingOTP(false);
//         toast.success('OTP Send to Your Email Id!', { position: toast.POSITION.TOP_RIGHT });
//       })
//       .catch((error) => {
//         console.error("Login failed", error);
//         toast.warn('ERROR!', { position: toast.POSITION.TOP_RIGHT });
//         setIsSendingOTP(false);
//       });

//     // Navigate("/otpverification")
//   };

//   const validateEmail = () => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailRegex.test(email)) {
//       setEmailError("Email format is not valid");
//     } else {
//       setEmailError("");
//     }
//   };

//   const sendMobileOTP = async () => {
//     console.log("mobile : ", phoneNumber);
//     if (phoneNumber === "" || phoneNumber === undefined)
//       return setError("Please enter the valid phone number");
//       setIsSendingOTP(true);
//     try {
//       const response = await SetUpRecaptch(phoneNumber);
//       console.log("response : ", response.verificationId);
//       setConfirmationObject(response);
//       setOtpFlag(true);
//       setSignInFlag(false);
//       setCounter(300);
//       setIsSendingOTP(false);
//     } catch (error) {
//       setError(error.message);
//       setIsSendingOTP(false);
//     }
//     console.log("phoneNumber : ", phoneNumber);
//   };

//   const callMobileorEmail = () => {
//     if (isEmail) {
//       handleLogIn();
//     } else if (isMobile) {
//       sendMobileOTP();
//     }
//   };

//   const SetUpRecaptch = (number) => {
//     const recaptchaVerifier = new RecaptchaVerifier(
//       auth,
//       "recaptcha-container",
//       { size: "normal" }
//     );
//     recaptchaVerifier.render();
//     return signInWithPhoneNumber(auth, "+91" + number, recaptchaVerifier);
//   };

//   const sendMobileData = () => {
//     const method = "user/login/phone-number/otp-verification";
//     const body = {
//       phoneNumber: phoneNumber,
//       otpVerification: "true",
//       fcmToken: confirmationObject.verificationId,
//     };
//     setLoader(true);
//     axios
//       .post(REACT_APP_BASE_URL + method, body)
//       .then((res) => {
//         console.log("res", res);
//         sessionStorage.setItem("userId", res.data.data[0].userId);
//         toast.success('Login successfully!', { position: toast.POSITION.TOP_RIGHT });

//         Navigate("/");
//       })
//       .catch((error) => {
//         console.error("Login failed", error);
//       });
//   };

//   const VerifyOTP = async () => {
//     if (isMobile) {
//       try {
//         await confirmationObject.confirm(otp);
//         setOtpFlag(false);
//         setLoader(true);
//         sendMobileData();
//       } catch (error) {
//         console.log("error : ", error);
//         toast.warn('Please Enter Valid OTP!', { position: toast.POSITION.TOP_RIGHT });

//       }
//     } else
//     {
//       const method = "user/register/verify-email-otp/";
//       const data = {
//         OTP:otp
//       }
//       axios
//         .post(REACT_APP_BASE_URL + method + userId, data)
//         .then((res) => {
//           console.log("email res", res);
//           sessionStorage.setItem("userId", res.data.data.token);
//           toast.success('Login successfully!', { position: toast.POSITION.TOP_RIGHT });
//           if(res.data.status === 1)
//           {
//             Navigate("/");
//           }
//         })
//         .catch((error) => {
//           console.error("Login failed", error);
//         });
//     }
//     setCounter(300);
//   };

//   const VerifyEmailOTP=()=>{
//     setCounter(300);
//     const method = "user/register/regenrate-otp/email/"
//      axios.post(REACT_APP_BASE_URL+method+ userId).then((res)=>{
//        console.log("Resend Email OTP Res",res);
//        toast.success('OTP Send to Your Email Adress!', { position: toast.POSITION.TOP_RIGHT });
//   })
// }

//   // useEffect(() => {
//   //   const timer =
//   //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
//   //   return () => clearInterval(timer);
//   // }, [otpFlag, counter]);
//   let minutes = Math.floor(counter / 60); // Calculate minutes
//   let seconds = counter % 60; // Calculate seconds

//   useEffect(() => {
//     const timer =
//       counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
//     return () => clearInterval(timer);
//   }, [otpFlag, counter]);

//   return (
//     <>
//       {signInFlag && (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100vh",
//           }}
//         >
//           <Container maxWidth="sm">
//             <Card
//               sx={{
//                 minWidth: 75,
//                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
//               }}
//             >
//               <Box
//                 component="form"
//                 sx={{
//                   "& .MuiTextField-root": { m: 1, width: "25ch" },
//                 }}
//                 noValidate
//                 autoComplete="off"
//               >
//                 <CardContent style={{ textAlign: "center" }}>
//                   <Typography
//                     sx={{ fontSize: 45 }}
//                     color="text.secondary"
//                     gutterBottom
//                   >
//                     Log In
//                   </Typography>
//                   <div style={{ paddingBottom: "1rem" }}>
//                     <Typography sx={{ fontSize: 14 }} component="div">
//                       Login to your TaxSavvy account.
//                       <br />
//                       Don't have an account?{" "}
//                       <Link
//                         to="/signup"
//                         style={{ color: "darkorange", textDecoration: "none" }}
//                       >
//                         Sign up
//                       </Link>
//                     </Typography>
//                   </div>
//                   <TextField
//                     id="standard-password-input"
//                     label="Mobile number"
//                     // type="password"
//                     autoComplete="current-password"
//                     variant="standard"
//                     style={{ width: "70%" }}
//                     onChange={handlMobileNumber}
//                   />
//                   <Typography style={{ marginTop: 30 }}>OR</Typography>
//                   <Typography variant="body2">
//                     <TextField
//                       id="standard-password-input"
//                       label="Email"
//                       type="email"
//                       autoComplete="current-password"
//                       variant="standard"
//                       style={{ width: "70%" }}
//                       onChange={handleEmailChange}
//                       onBlur={validateEmail}
//                       error={emailError.length > 0}
//                       helperText={emailError}
//                     />
//                   </Typography>

//                   <div
//                     style={{ display: "flex", justifyContent: "center" }}
//                     id="recaptcha-container"
//                   ></div>

//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       paddingTop: "1rem",
//                     }}
//                   >
//                     <CardActions>

//                       <Button
//   id="sign-in-button"
//   variant="contained"
//   style={{ borderRadius: "25px", background: "#e66d62" }}
//   onClick={() => callMobileorEmail()}
//   endIcon={<SendIcon />}
//   disabled={isSendingOTP} // Disable the button when isSendingOTP is true
// >
//   Send OTP
// </Button>
//                     </CardActions>
//                   </div>
//                 </CardContent>
//               </Box>
//             </Card>
//           </Container>
//         </div>
//       )}
//       {loader && (
//         <Container maxWidth="sm" sx={{ marginTop: 35 }}>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <CircularProgress sx={{ color: "darkorange" }} />
//             <Typography sx={{ fontSize: 45 }}>Loading...</Typography>
//           </Box>
//         </Container>
//       )}
//       {otpFlag && (
//         <Container
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100vh",
//           }}
//         >
//           <Card style={{ width: "50%" }}>
//             <Box
//               component="form"
//               sx={{
//                 "& .MuiTextField-root": { m: 1, width: "25ch" },
//               }}
//               noValidate
//               autoComplete="off"
//             >
//               <CardContent>
//                 <Typography
//                   sx={{ fontSize: 25 }}
//                   color="text.secondary"
//                   gutterBottom
//                   style={{ textAlign: "center" }}
//                 >
//                   Otp Verification
//                 </Typography>

//                 <Typography
//                   style={{ margin: "1rem 1rem", textAlign: "center" }}
//                 >
//                   <TextField
//                     id="standard-password-input"
//                     label="Enter OTP"
//                     // type="password"
//                     autoComplete="current-password"
//                     variant="standard"
//                     style={{ width: "70%" }}
//                     onChange={(e) => {
//                       setOtp(e.target.value);
//                     }}
//                   />
//                 </Typography>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     paddingTop: "1rem",
//                   }}
//                 >
//                   <CardActions>
//                     {counter === 0 ? <Button
//                       onClick={() => VerifyEmailOTP()}
//                       variant="contained"
//                       style={{ borderRadius: "25px", background: "#e66d62" }}
//                     >
//                       {/* {counter === 0 ? "Resend Otp" : "Verify OTP"} */}
//                       Resend OTP
//                     </Button>

//                   :
//                   <Button
//                       onClick={() => VerifyOTP()}
//                       variant="contained"
//                       style={{ borderRadius: "25px", background: "#e66d62" }}
//                     >
//                       verify OTP
//                     </Button>
//                   }
//                     {/* <Button
//                       onClick={() => VerifyOTP()}
//                       variant="contained"
//                       style={{ borderRadius: "25px", background: "#e66d62" }}
//                     >
//                       {counter === 0 ? "Resend Otp" : "Verify OTP"}
//                     </Button>
//                     <Button
//                       onClick={() => VerifyOTP()}
//                       variant="contained"
//                       style={{ borderRadius: "25px", background: "#e66d62" }}
//                     >
//                       Resend Otp
//                     </Button> */}
//                   </CardActions>
//                 </div>
//                 {/* <Typography style={{ textAlign: "center", padding: "2rem" }}>
//                   <span>Re-send OTP in {counter} sec</span>
//                 </Typography> */}
//                 <Typography style={{ textAlign: "center", padding: "2rem" }}>
//   <span>Re-send OTP in {minutes} min {seconds} sec</span>
// </Typography>

//               </CardContent>
//             </Box>
//           </Card>
//         </Container>
//       )}
//     </>
//   );
// };

// export default SignUplogin;

import React, { useState } from "react";
import "./SignUplogin.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";


const SignUplogin = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const handleCountryCodeChange = (selectedOption) => {
    if (selectedOption === null) {
      setSelectedCountryCode({ value: "+91", label: "+91 (India)" });
    } else {
      setSelectedCountryCode(selectedOption);
    }
    console.log(selectedOption);
  };
  const [phone, setPhone] = useState("+91");
  const [phoneLastTenDigits, setPhoneLastTenDigits] = useState('');
const [phoneRemainingDigits, setPhoneRemainingDigits] = useState('');


  // const handlePhoneChange = (value) => {
  //   console.log(value)
  //   setPhone(value || "+91");
  // };
  const handlePhoneChange = (value) => {
    // Remove any non-digit characters from the phone number
    const digitsOnly = value.replace(/\D/g, '');
  
    // Extract the last 10 digits
    const lastTenDigits = digitsOnly.slice(-10);
  
    // Extract the remaining digits (if any)
    const remainingDigits = digitsOnly.slice(0, -10);
  
    setPhoneLastTenDigits(lastTenDigits);
    setPhoneRemainingDigits(remainingDigits.length > 0 ? `+${remainingDigits}` : '');
    console.log(lastTenDigits)
    console.log(remainingDigits)
  };

  return (
    <>
      <div className="body1">
        <div className="container1" style={{ width: "75%" }}>
          <input type="checkbox" id="flip" />
          <div className="cover">
            <div className="front">
              <img src="images/frontImg.jpg" alt="" />
              <div className="text">
                <span className="text-1">
                  Every new friend is a <br /> new adventure
                </span>
                <span className="text-2">Let's get connected</span>
              </div>
            </div>
            <div className="back">
              <img className="backImg" src="images/backImg.jpg" alt="" />
              <div className="text">
                <span className="text-1">
                  Complete miles of journey <br /> with one step
                </span>
                <span className="text-2">Let's get started</span>
              </div>
            </div>
          </div>
          <div className="forms">
            <div className="form-content">
              <div className="login-form">
                <div className="title">Login</div>
                <form action="#">
                  <div className="input-boxes">
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Email Or Mobile No"
                        variant="outlined"
                      />
                    </Box>
                    {/* <div className="input-box">
                      <i className="fas fa-envelope"></i>
                      <input
                        type="text"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-lock"></i>
                      <input
                        type="password"
                        placeholder="Enter your password"
                        required
                      />
                    </div>
                    <div className="text">
                      <a href="#">Forgot password?</a>
                    </div> */}
                    <div className="button input-box">
                      <input type="submit" value="Sumbit" />
                    </div>
                    <div className="text sign-up-text">
                      Don't have an account? <label for="flip">Sigup now</label>
                    </div>
                    <div className="text sign-up-text">
                      {" "}
                      <label for="flip">
                        <Link to="/">Skip For Now</Link>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div className="signup-form">
                <div className="title">Signup</div>
                <form action="#">
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-basic"
                      label="Email Address"
                      variant="outlined"
                    />
                    {/* <PhoneInput
      country="us"
      value={phone}
      onChange={handlePhoneChange}
    /> */}
                    <div>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {/* <i className="fas fa-mobile-alt"></i> */}
                        <PhoneInput
                          country={"in"}
                          value={phone}
                          onChange={handlePhoneChange}
                          inputComponent={TextField}
                          inputProps={{
                            id: "outlined-basic",
                            label: "Phone Number",
                            variant: "outlined",
                          }}
                        />
                      </span>
                    </div>
                    <div className="button input-box">
                      <input type="submit" value="Sumbit" />
                    </div>
                    <div
                      className="text sign-up-text"
                      style={{ width: "42ch" }}
                    >
                      Already have an account?{" "}
                      <label for="flip">Login now</label>
                    </div>
                    <div className="text sign-up-text">
                      {" "}
                      <label for="flip">
                        <Link to="/">Skip For Now</Link>
                      </label>
                    </div>
                  </Box>
                  {/* <div className="input-boxes">
                    <div className="input-box">
                      <i className="fas fa-user"></i>
                      <input
                        type="text"
                        placeholder="Enter your FirstName"
                        required
                      />
                      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-user"></i>
                      <input
                        type="text"
                        placeholder="Enter your LastName"
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-envelope"></i>
                      <input
                        type="text"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div  style={{
    marginTop: "28px"}}>
      <span style={{display:"flex"}}>
                      <i className="fas fa-mobile-alt"></i>

                      <PhoneInput
      country="us"
      value={phone}
      onChange={handlePhoneChange}
    />
    </span>
                    </div>
                    
                    <div className="input-box">
                      <i className="fas fa-mobile-alt"></i>
                      <input
                        type="munber"
                        placeholder="Enter your mobile number"
                        required
                      />
                    </div>
                    <div className="button input-box">
                      <input type="submit" value="Sumbit" />
                    </div>
                    <div className="text sign-up-text">
                      Already have an account?{" "}
                      <label for="flip">Login now</label>
                    </div>
                    <div className="text sign-up-text">
                      {" "}
                      <label for="flip">
                        <Link to="/">Skip For Now</Link>
                      </label>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUplogin;
