import React, { useEffect, useState } from "react";
import "../../common/Common.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import AskExpert from "../../askexpert/AskExpert";
import FileItr from "./FileItr";
import GetRsOff from "../../getrsoff/GetRsOff";
import BenefitsOfFillingItr from "./BenefitsOfFillingItr";
import IncomeTaxFilling from "../../incometaxfilling/IncomeTaxFilling";
import FaqSection from "../../FAQ-section/Faq-section";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import { error } from "jquery";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const ApplicationForPan = () => {
  const [planData, setPlanData] = useState();
  const [subServiceId, setSubServiceId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const datas = location.state;
  // console.log(datas);
  useEffect(() => {
    if (datas && datas.subServiceId) {
      setSubServiceId(datas.subServiceId);
    }
  }, [datas]);

  useEffect(() => {
    if (subServiceId) {
      setIsLoading(true);
      getpanCardPlan();
    }
  }, [subServiceId]);

  const getpanCardPlan = () => {
    const subserviceId = subServiceId;
    const method = "plans/";
    axios
      .get(REACT_APP_BASE_URL + method + subserviceId)
      .then((res) => {
        // console.log(res.data.data);
        setPlanData(res.data.data);
        setIsLoading(false);  
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>

      {/* <Header /> */}
      <DemoHeader/>
      {isLoading && <Loader/>}
       <div className="bannertop">
        <div className="container text-left">
          <div className="banner-vhmian itr-breadcrumb">
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" style={{color:'#FFFFFF'}} to="/">
                  Home
                </Link>
                <Typography style={{color:'#FFFFFF'}}>PAN Services</Typography>
              </Breadcrumbs>
            </div>
            <div className="vhin">
              <h1>PAN Services</h1>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="whitebox sal-bus-outer">
            <div className="row">
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row mtp30">
                          {planData &&
                            planData.map((data, i) => {
                              return (
                                <div className="col-sm-6 col-lg-4">
                                  <div
                                    className={`${
                                      ((i + 1) % 2 == 0 && "blue-bg") ||
                                      ((i + 1) % 3 == 0 && "orange-bg") ||
                                      "green-bg"
                                    } radius-shadow probox-main`}
                                  >
                                    <div className="proimg-box">
                                      <img
                                        src={`data:image/jpeg;base64,${data.image}`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="prodetails">
                                      <h4>{data.planName}</h4>
                                      <div className="prolist-scroll">
                                      <ul dangerouslySetInnerHTML={{ __html: data?.description }}>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="price-box">
                                          <span>Just for</span>
                                          <div className="rupee">
                                            <img
                                              src="images/rupee-icon.png"
                                              alt=""
                                            />
                                            {data.fee}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <Link to="/newpancard" state={data}>
                                          <a
                                            href="pancard-new.html"
                                            className="probtn border-white"
                                          >
                                            GET PLAN
                                          </a>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {/* <div className="col-sm-6 col-lg-4">
                            <div className="blue-bg radius-shadow probox-main">
                              <div className="proimg-box">
                                <img
                                  src="images/plan-01.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="prodetails">
                                <h4>New PAN Card</h4>
                                <div className="prolist-scroll">
                                  <ul>
                                    <li>
                                      Person applying PAN for the first time
                                    </li>
                                    <li>
                                      Applicable for Individual, HUF,
                                      Partnership, LLP, Company or any other
                                      body corporate
                                    </li>
                                    <li>
                                      Minor(individual below the age of 18
                                      years) can also apply for PAN Card
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="price-box">
                                    <span>Just for</span>
                                    <div className="rupee">
                                      <img src="images/rupee-icon.png" alt="" />
                                      999{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <Link to="/newpancard">
                                    <a
                                      href="pancard-new.html"
                                      className="probtn border-white"
                                    >
                                      GET PLAN
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="orange-bg radius-shadow probox-main">
                              <div className="proimg-box">
                                <img
                                  src="images/plan-02.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="prodetails">
                                <h4>
                                  Change or Correction or Reissue of PAN Card
                                </h4>
                                <div className="prolist-scroll">
                                  <ul>
                                    <li>
                                      Applicable in case of change in any
                                      details like name, signature, date of
                                      birth etc
                                    </li>
                                    <li>
                                      Especially for women’s after marriage for
                                      name change
                                    </li>
                                    <li>Reapply in case of lost Pan card</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="price-box">
                                    <span>Just for</span>
                                    <div className="rupee">
                                      <img src="images/rupee-icon.png" alt="" />
                                      999{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <Link to="/pancardcorrection">
                                    <a className="probtn border-white">
                                      GET PLAN
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="green-bg radius-shadow probox-main">
                              <div className="proimg-box">
                                <img
                                  src="images/plan-01.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="prodetails">
                                <h4>
                                  PAN Card for Non Resident (Fresh Application
                                  or Change/Correction)
                                </h4>
                                <div className="prolist-scroll">
                                  <ul>
                                    <li>
                                      Applies to NRI (Non - Resident Indians ),
                                      OCI(Overseas Citizen of India), PIO
                                      (Person of Indian Origin)
                                    </li>
                                    <li>
                                      Change or correction in PAN details for
                                      NRI like name, signature, date of birth
                                      etc
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="price-box">
                                    <span>Just for</span>
                                    <div className="rupee">
                                      <img src="images/rupee-icon.png" alt="" />
                                      4999{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <Link to="/pancardnonresidential">
                                    <a
                                      href="pancard-nonresidence.html"
                                      className="probtn border-white"
                                    >
                                      GET PLAN
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AskExpert />
      <FileItr />
      <GetRsOff />
      <BenefitsOfFillingItr />
      <IncomeTaxFilling />
      <FaqSection />
      <Footer />
    </>
  );
};

export default ApplicationForPan;
