import React from "react";
import Sidebar from "../Sidebar";
import MyDoc from "../profile/MyDoc";
import DemoHeader from "../demoHeader/DemoHeader";
import ProfileHeder from "../profileHed/ProfileHeder";
import Footer from "../footer/Footer";

const About = () => {
  return (
    <div>
      <ProfileHeder />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-9">
            <MyDoc />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
