import React from 'react'
import Header from '../../header/Header'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'

const TdsSalaryAndOtherPayment = () => {
    return (
        <>
            {/* <div className="position-relative">
            <div className="owl-carousel owl-theme banner">
                <div className="item purpal-nav-bg">
                    <div className="d-none d-md-block" style="background: url(images/plan-img.png) no-repeat bottom right; opacity: 0.8; position:absolute;  z-index:0; right:0; bottom:0; height:100%; width: 50%; background-size: 100% 100%;"></div>
              
                    <div className="container-fluid">
                        <div className="row align-items-center vh-100">
                            <div className="col-sm-12 col-md-10  col-lg-8">
                                <div className="banner-text banner-text-xl">

                                    <h2>TDS Returns on Salary and other payments (Form 24Q and Form 26Q) </h2>
                                    <div className="banner-text-bottom">
                                        <span>Key Highlights of Plan</span>
                                        <ul>
                                            <li>No limit on number of transactions (For Salary as well as Others)</li>
                                            <li> Online PAN & Challan verification</li>
                                            <li>Dedicated CA assigned on your case</li>
                                            <li>Generation of Form 16 & Form 16A</li>
                                            <li>Advisory on queries pertaining to TDS</li>
                                        </ul>
                                        <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     

                </div>
            </div>
        </div> */}

        <Header/>

            <div className="container-fluid" style={{ backgroundColor: '#5136e6' }}>
                <div className="row align-items-center vh-100 headerbackground">
                    <div className="col-sm-12 col-md-10  col-lg-8">
                        <div className="banner-text banner-text-xl">

                            <h2>TDS Returns on Salary and other payments (Form 24Q and Form 26Q) </h2>
                            <div className="banner-text-bottom">
                                <span>Key Highlights of Plan</span>
                                <ul>
                                    <li>No limit on number of transactions (For Salary as well as Others)</li>
                                    <li> Online PAN & Challan verification</li>
                                    <li>Dedicated CA assigned on your case</li>
                                    <li>Generation of Form 16 & Form 16A</li>
                                    <li>Advisory on queries pertaining to TDS</li>
                                </ul>
                                <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="planbox" id="Header2">
                <div className="planbox-title">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-9 col-md-8">
                                <h4>TDS Returns on Salary and other payments</h4>
                            </div>
                            <div className="col-3 col-md-4 text-right" style={{display:'flex'}}>
                                <div className="dropdown d-inline-block arrowbtn d-sm-none">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="images/down-arrow.svg" />
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#aboutplan">About the Plan</a>
                                        <a className="dropdown-item" href="#howitdone">How it’s done</a>
                                        <a className="dropdown-item" href="#docreq">Documents required</a>
                                        <a className="dropdown-item" href="#faqs">FAQs</a>
                                    </div>
                                </div>
                                <div className="priceboxin">
                                    <span>Just for</span>
                                    <h6>₹2499</h6>
                                </div>
                                <a className="btn btn-dark btnblack float-right" href="step.html">BUY NOW</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="plannav d-none d-sm-block mtp5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 text-left fixnav">
                                <ul className="nav nav-pills nav-fill" id="top-menu">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#aboutplan">About the Plan</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#howitdone">How it’s done</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#docreq">Documents required</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#faqs">FAQs</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="tb-space" id="aboutplan">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">About the Plan</h5>
                        </div>
                    </div>
                    <div className="row mtp15">
                        <div className="col-sm-6 col-xl-7">
                            <h5 className="sub-title-xs mbp10">Plan details</h5>
                            <p className="black-smalltext">This tax product is designed to comply with tax deducted at source (TDS) implications for individual, partnership firm or any other corporates. As per the provision of Income tax Act, while making certain payments like salary, rent, commission, professional fees etc. above prescribe limits TDS needs to be deducted. Details of the same along with the details of deductee need to be reported in Form 24Q/26Q on a quarterly basis. Any delay while filing TDS return may invite future litigations and excess payment in terms of penalty and interest.
                                A dedicated expert shall ensure that your TDS return is filed after considering all the provisions of Income tax law updated time to time. Therefore, an individual shall be guided to best of his/her interest and be made aware of tax implications applicable to him/her.

                            </p>
                            <p className="text-xs"> <i>Note: This plan covers TDS return filing for one quarter
                            </i></p>

                            <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
                            <p className="black-smalltext">1. Any individual who has deducted TDS at the time of making certain payment</p>
                            <p className="black-smalltext">2. Any organization such as corporate, partnership firm etc. who has deducted TDS at the time of making certain payment</p>
                        </div>
                        <div className="col-sm-6  col-xl-5">
                            <img src="images/about-img.png" alt="" className="img-fluid m-auto float-md-right" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space graybg" id="howitdone">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">How it’s Done</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="hid-list-main">
                                <ul className="hid-list six">
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/cart-icon.png" /></div>
                                            <div className="hid-text">Purchase of Plan</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/upload-icon.png" /></div>
                                            <div className="hid-text">Fill in the details in the template provided </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Case overview with expert</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/file-search-icon.png" /></div>
                                            <div className="hid-text">Preparation and Filing of TDS return</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/generation-form-icon.png" /></div>
                                            <div className="hid-text">Generation of Form 16 and Form 16A</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Feedback</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="estimated-row">
                                <span>7 Days Estimated</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space blackbg" id="docreq">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title white-row mbp15 d-inline-block white-color">Documents required</h5>
                        </div>
                    </div>
                    <div className="row mtp25 gutter-20">
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">01</div>
                                <div className="doc-details">Details of Deductor</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">02</div>
                                <div className="doc-details">Details of Responsible person</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">03</div>
                                <div className="doc-details">Details of Deductee</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">04</div>
                                <div className="doc-details">Details of TDS paid Challans</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">05</div>
                                <div className="doc-details">Deduction Details</div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <FaqSection/>
            <Footer/>
        </>
    )
}

export default TdsSalaryAndOtherPayment