import React from 'react'
import '../../common/Common.css'
import Header from '../../header/Header'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'
import { Link } from 'react-router-dom'
import DemoHeader from '../../demoHeader/DemoHeader'

const PlanE = () => {
    return (
        <>
            {/* <div className="position-relative">
            <div className="owl-carousel owl-theme banner">
                <div className="item purpal-nav-bg">
                    <div className="d-none d-md-block" style="background: url(images/plan-img.png) no-repeat bottom right; opacity: 0.8; position:absolute;  z-index:0; right:0; bottom:0; height:100%; width: 50%; background-size: 100% 100%;"></div>
              
                    <div className="container-fluid">
                        <div className="row align-items-center vh-100">
                            <div className="col-sm-12 col-md-10  col-lg-8">
                                <div className="banner-text banner-text-xl">
                                    <h2>Plan E – Business or Professionals (Presumptive)</h2>
                                    <div className="banner-text-bottom">
                                        <span>Key Highlights of Plan</span>
                                        <ul>
                                            <li>Especially designed for Freelancers and Professionals</li>
                                            <li>Designed to safeguard an individual from maintaining books of account</li>
                                            <li>Dedicated CA assigned on your case</li>
                                            <li>Suggestions on New Tax Saving avenues for next year</li>
                                            <li>Tracking and updating the status of refund, if any</li>
                                            <li>Advisory on queries pertaining to the Income tax return filing</li>
                                        </ul>
                                        <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     

                </div>
            </div>
        </div> */}
            <DemoHeader />

            <div className="container-fluid" style={{ backgroundColor: '#5136e6' }}>
                <div className="row align-items-center vh-100 headerbackground">
                    <div className="col-sm-12 col-md-10  col-lg-8">
                        <div className="banner-text banner-text-xl">
                            <h2>Plan E – Business or Professionals (Presumptive)</h2>
                            <div className="banner-text-bottom">
                                <span>Key Highlights of Plan</span>
                                <ul>
                                    <li>Especially designed for Freelancers and Professionals</li>
                                    <li>Designed to safeguard an individual from maintaining books of account</li>
                                    <li>Dedicated CA assigned on your case</li>
                                    <li>Suggestions on New Tax Saving avenues for next year</li>
                                    <li>Tracking and updating the status of refund, if any</li>
                                    <li>Advisory on queries pertaining to the Income tax return filing</li>
                                </ul>
                                <Link to="/step">

                                <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="planbox" id="Header2">
            <div className="planbox-title">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-9 col-md-8">
                            <h4>Plan E - Business Return</h4>
                        </div>
                        <div className="col-3 col-md-4 text-right" style={{display:'flex'}}>
                            <div className="dropdown d-inline-block arrowbtn d-sm-none">
                                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="images/down-arrow.svg"/>
                                </a>

                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <a className="dropdown-item" href="#aboutplan">About the Plan</a>
                                    <a className="dropdown-item" href="#howitdone">How it’s done</a>
                                    <a className="dropdown-item" href="#docreq">Documents required</a>
                                    <a className="dropdown-item" href="#faqs">FAQs</a>
                                </div>
                            </div>
                            <div className="priceboxin">
                                <span>Just for</span>
                                <h6>₹1499</h6>
                            </div>
                            <Link to="/step">

                            <a className="btn btn-dark btnblack float-right" href="step.html">BUY NOW</a>
                            </Link>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="plannav d-none d-sm-block mtp5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 text-left fixnav">
                            <ul className="nav nav-pills nav-fill" id="top-menu">
                                <li className="nav-item">
                                    <a className="nav-link" href="#aboutplan">About the Plan</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#howitdone">How it’s done</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#docreq">Documents required</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#faqs">FAQs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


            <section className="tb-space" id="aboutplan">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">About the Plan</h5>
                        </div>
                    </div>
                    <div className="row mtp15">
                        <div className="col-sm-6 col-xl-7">
                            <h5 className="sub-title-xs mbp10">Plan details</h5>
                            <p className="black-smalltext">This tax product is designed for small scale business enterprises whose turnover is less than Rs 2 crores in case of business or Rs 50 lakhs in case of profession.
                                Individual dealing in business or profession but who do not maintain books of accounts can file their income tax return with this product by declaring their profit at or above 8% (business) or 50% (profession) of the turnover as the case may be.
                                A dedicated expert shall ensure that your income tax return is filed after considering all the provisions of Income tax law updated time to time.
                                Therefore, an individual shall be guided to best of his/her interest and be made aware of tax implications applicable to him/her.
                            </p>
                            <p className="text-xs"> <i>This plan covers the Income tax return filing for an individual who has source of income from any one, two or all of the following heads of income. </i></p>
                            <ul className="list01 mbp15">
                                <li>Income from business where turnover is less than Rs 2 crore and the profit declared is 8% or above</li>
                                <li>Income from profession where turnover is less than Rs 50 lakhs and the profit declared is 50% or above</li>
                                <li>Income from Salary, House Rent or any other source</li>
                            </ul>
                            <p className="text-xs"> <i>Note: 1)In case of any business/profession income where accounts are maintained or individual claims to have income below 8%/50% of turnover. Please refer succeeding product.<br />
                                2)This package provides Computation of Income and ITR Form.
                                Financial Statements (Balance Sheet and Profit and Loss A/c) can be provided upon request on separate terms.<br />
                                3)The package does not include professional charges for DSC and Audit Fees. The same can be provided upon request on separate terms.</i></p>

                            <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
                            <p className="black-smalltext">1. A business person whose turnover is less than Rs 2 crore and wants to declare profit at 8% or above</p>
                            <p className="black-smalltext">2. Professionals like freelancers, doctor, architects etc. whose turnover is less than Rs 50 lakhs and wants to declare profit at 50% or above</p>
                            <p className="black-smalltext">3. Partnership Firm whose turnover is less than Rs 2 crore in case of business or Rs 50 lakhs in case of profession and wants to declare profit at 8%/50% or above respectively</p>
                            <p className="text-xs"> <i>Note: 1)An individual earning income from agency or commissions and brokerage CANNOT opt under this plan. In that case kindly refer our ‘Product F’.<br />
                                2)An individual earning income outside India is not covered under this plan. In that case kindly refer our ‘Product C’.<br />
                                3)This plan doesn’t apply to Limited Liability Partnership (LLP).</i></p>


                        </div>
                        <div className="col-sm-6  col-xl-5">
                            <img src="images/about-img.png" alt="" className="img-fluid m-auto float-md-right" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space graybg" id="howitdone">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">How it’s Done</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="hid-list-main">
                                <ul className="hid-list six">
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/cart-icon.png" /></div>
                                            <div className="hid-text">Purchase of Plan</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/upload-icon.png" /></div>
                                            <div className="hid-text">Upload Documents</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/file-search-icon.png" /></div>
                                            <div className="hid-text">Case overview with expert</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Review & Approval of Computation of Income</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/generation-form-icon.png" /></div>
                                            <div className="hid-text">Filling of Income Tax Return with Acknowledgment</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Feedback</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="estimated-row">
                                <span>5 Days Estimated</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space blackbg" id="docreq">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title white-row mbp15 d-inline-block white-color">Documents required</h5>
                        </div>
                    </div>
                    <div className="row mtp25 gutter-20">
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">01</div>
                                <div className="doc-details">PAN & Aadhar Card</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">02</div>
                                <div className="doc-details">Bank Details</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">03</div>
                                <div className="doc-details">Investment Details</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">04</div>
                                <div className="doc-details">Other Documents</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqSection />
            <Footer />
        </>
    )
}

export default PlanE