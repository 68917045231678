import { Typography } from '@material-ui/core'
import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Loader from '../loader/Loader'
import "./Sidebar.css"

const ShowDetailedHistory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const Location = useLocation()
    const [prevData, setPrevdata] = useState();
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        console.log(Location.state)
        console.log("process.env.REACT_APP_S3URL : ",process.env.REACT_APP_S3URL)
        if (sessionStorage.getItem("count")) {
            window.location.reload();
            sessionStorage.removeItem("count")
        }
        setPrevdata(Location.state)
        setIsLoading(false)
    },[]);

  return (
    <>
    {isLoading && <Loader/>}
    <Card className="mb-5 card-print print-me" style={{ background: '#ffffff' }}>
        <Card.Body>
            <Row className="d-flex flex-row align-items-center">
                <Col className="col-auto mb-3 mb-sm-0 me-auto">
                    <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/product-list">
                    <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/" style={{color:'#000'}}>
                  Home
                </Link>
                <Link underline="hover" color="inherit" to="/" onClick={() => window.history.go(-1)} style={{color:'#000'}}> 
                  Purchase History
                </Link>
              </Breadcrumbs>
                    </NavLink>
                    <h1 className="mb-0 pb-0 display-4" id="title">
                    {prevData?.planInformation.planName} 
                    </h1>
                </Col>
                {/* <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                    <Button
                        variant="outline-primary"
                        className="btn-icon btn-icon-start w-100 w-md-auto"
                        onClick={() => {
                            window.print();
                            return false;
                        }}
                    >
                        <CsLineIcons icon="print" /> <span>Print</span>
                    </Button>
                </Col> */}
            </Row>
            <div className="separator separator-light mt-5 mb-5" />
            <div>
                <Row className="mb-4 d-none d-sm-flex   ">
                    <Col xs="6">
                        <p className="mb-0 text-small text-muted">Title</p>
                    </Col>
                    <Col xs="3">
                        <p className="mb-0 text-small text-muted">INFORMATION</p>
                    </Col>
                </Row>

                <Row className="mb-4 mb-sm-2 p-3 custom-css-detailedHistory">
                    <Col sm="6">
                        <h6 className="mb-0">Plan Name</h6>
                    </Col>
                    <Col sm="3">
                        <p className="mb-0 text-alternate">{prevData?.planInformation?.planName}</p>
                    </Col>
                </Row>
                <Row className="mb-4 mb-sm-2 p-3 custom-css-detailedHistory">
                    <Col sm="6">
                        <h6 className="mb-0">Plan Fee</h6>
                    </Col>
                    <Col sm="3">
                        <p className="mb-0 text-alternate">{prevData?.planInformation?.planFee}</p>
                    </Col>
                </Row>
                <Row className="mb-4 mb-sm-2 p-3 custom-css-detailedHistory">
                    <Col sm="6">
                        <h6 className="mb-0">Tax Year</h6>
                    </Col>
                    <Col sm="3">
                        <p className="mb-0 text-alternate">{prevData?.taxYear}</p>
                    </Col>
                </Row>

                {prevData && prevData.documents.map((data, index) => {
                    return (
                        <Row className="mb-4 mb-sm-2 my-3 p-3" key={index + 1} style={{ border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#f7f7f7' }}>
                            <Col sm="6">
                                <h6 className="mb-0">{data.propertyName}</h6>
                            </Col>
                            <Col sm="3">
                                {
                                    data.value.search('.jpeg') !== -1 || data.value.search('.png') !== -1 || data.value.search('.WebP') !== -1 ? (
                                        <img height='100px' width='200px' src={`${process.env.REACT_APP_S3URL}${data.value}`} alt="S3Image" />
                                      ) : data.value.toLowerCase().endsWith('.pdf') ? (
                                        <object data={`${process.env.REACT_APP_S3URL}${data.value}`} type="application/pdf" width="100%" height="500px">
                                          <embed src={`${process.env.REACT_APP_S3URL}${data.value}`} type="application/pdf" />
                                        </object>
                                      ) : (
                                        <p className="mb-0 text-alternate">{data.value}</p>
                                      )
                                }
                               
                            </Col>
                        </Row>
                    )
                })
                }
               
            </div>
            {/* const imageURL = ; */}
            <div className="separator separator-light mt-5 mb-5" />
            <div className="separator separator-light mt-5 mb-5" />
        </Card.Body>
    </Card>
    {/* Standard End */}
</>
  )
}

export default ShowDetailedHistory