import React, { useState, useEffect } from "react";
import "./MostPopularPlan.css";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../common/Common.css";

const MostPopularPlan = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay:true,
    autoplaySpeed:2000,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
  };

  return (
    <>
    <div className="most-popular-outer">
      <section className="tb-space graybg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h5 className="main-title red-row mbp15 d-inline-block">Most Popular Plans</h5>
            </div>
            <div className="col-sm-12 ">
              <div className="budget-list owl-carousel owl-theme">
                <Slider
                  {...settings}
                  style={{ width: "100%" }}
                >
                  <div className="item">
                    <div className="budget-box">
                      <div className="imagebox"><img src="images/plan-03.png" alt="" className="img-fluid" /></div>
                      <div className="detailsbody">
                        <div className="detailsbox">
                          <h5>Company Registration</h5>
                          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </div>
                        <div className="clearfix mtp10">
                          <div className="row">
                            <div className="col-6">
                              <button className="btn btn-outline-dark regbtn md" type="button">READ MORE</button>
                            </div>
                            <div className="col-6 plan-price text-right">
                              Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="budget-box">
                      <div className="imagebox"><img src="images/plan-04.png" alt="" className="img-fluid" /></div>
                      <div className="detailsbody">
                        <div className="detailsbox">
                          <h5>GST Registration</h5>
                          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </div>
                        <div className="clearfix mtp10">
                          <div className="row">
                            <div className="col-6">
                              <button className="btn btn-outline-dark regbtn md" type="button">READ MORE</button>
                            </div>
                            <div className="col-6 plan-price text-right">
                              Just for <span><img src="images/rupee-icon-b.png" alt="" />988</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="budget-box">
                      <div className="imagebox"><img src="images/plan-05.png" alt="" className="img-fluid" /></div>
                      <div className="detailsbody">
                        <div className="detailsbox">
                          <h5>GST Return filling</h5>
                          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </div>
                        <div className="clearfix mtp10">
                          <div className="row">
                            <div className="col-6">
                              <button className="btn btn-outline-dark regbtn md" type="button">READ MORE</button>
                            </div>
                            <div className="col-6 plan-price text-right">
                              Just for <span><img src="images/rupee-icon-b.png" alt="" />8899</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="budget-box">
                      <div className="imagebox"><img src="images/plan-04.png" alt="" className="img-fluid" /></div>
                      <div className="detailsbody">
                        <div className="detailsbox">
                          <h5>Company Registration</h5>
                          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </div>
                        <div className="clearfix mtp10">
                          <div className="row">
                            <div className="col-6">
                              <button className="btn btn-outline-dark regbtn md" type="button">READ MORE</button>
                            </div>
                            <div className="col-6 plan-price text-right">
                              Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="budget-box">
                      <div className="imagebox"><img src="images/plan-04.png" alt="" className="img-fluid" /></div>
                      <div className="detailsbody">
                        <div className="detailsbox">
                          <h5>GST Registration</h5>
                          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </div>
                        <div className="clearfix mtp10">
                          <div className="row">
                            <div className="col-6">
                              <button className="btn btn-outline-dark regbtn md" type="button">READ MORE</button>
                            </div>
                            <div className="col-6 plan-price text-right">
                              Just for <span><img src="images/rupee-icon-b.png" alt="" />988</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="budget-box">
                      <div className="imagebox"><img src="images/plan-05.png" alt="" className="img-fluid" /></div>
                      <div className="detailsbody">
                        <div className="detailsbox">
                          <h5>GST Return filling</h5>
                          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </div>
                        <div className="clearfix mtp10">
                          <div className="row">
                            <div className="col-6">
                              <button className="btn btn-outline-dark regbtn md" type="button">READ MORE</button>
                            </div>
                            <div className="col-6 plan-price text-right">
                              Just for <span><img src="images/rupee-icon-b.png" alt="" />8899</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>

              </div>
            </div>

          </div>
        </div>
      </section>


      <Slider className="slider-div"
        {...settings}
        style={{ width: "80%", marginLeft: "10rem"
       }}
      >
        <div>
          <div className="item">
            <div className="budget-box">
              <div className="imagebox"><img src="../images/plan-04.png" alt="" className="img-fluid" /></div>
              <div className="detailsbody">
                <div className="detailsbox">
                  <h5>Company Registration</h5>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                </div>
                <div className="clearfix mtp10">
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-outline-dark regbtn md" type="button" style={{fontSize:"12px"}}>READ MORE</button>
                    </div>
                    <div className="col-6 plan-price text-right">
                      Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="item">
            <div className="budget-box">
              <div className="imagebox"><img src="../images/plan-05.png" alt="" className="img-fluid" /></div>
              <div className="detailsbody">
                <div className="detailsbox">
                  <h5>GST Registration</h5>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                </div>
                <div className="clearfix mtp10">
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-outline-dark regbtn md" type="button" style={{fontSize:"12px"}}>READ MORE</button>
                    </div>
                    <div className="col-6 plan-price text-right">
                      Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="item">
            <div className="budget-box">
              <div className="imagebox"><img src="../images/plan-05.png" alt="" className="img-fluid" /></div>
              <div className="detailsbody">
                <div className="detailsbox">
                  <h5>GST Return filling</h5>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                </div>
                <div className="clearfix mtp10">
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-outline-dark regbtn md" type="button" style={{fontSize:"12px"}}>READ MORE</button>
                    </div>
                    <div className="col-6 plan-price text-right">
                      Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="item">
            <div className="budget-box">
              <div className="imagebox"><img src="../images/plan-05.png" alt="" className="img-fluid" /></div>
              <div className="detailsbody">
                <div className="detailsbox">
                  <h5>Company Registration</h5>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                </div>
                <div className="clearfix mtp10">
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-outline-dark regbtn md" type="button" style={{fontSize:"12px"}}>READ MORE</button>
                    </div>
                    <div className="col-6 plan-price text-right">
                      Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="item">
            <div className="budget-box">
              <div className="imagebox"><img src="../images/plan-03.png" alt="" className="img-fluid" /></div>
              <div className="detailsbody">
                <div className="detailsbox">
                  <h5>Company Registration</h5>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                </div>
                <div className="clearfix mtp10">
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-outline-dark regbtn md" type="button" style={{fontSize:"12px"}}>READ MORE</button>
                    </div>
                    <div className="col-6 plan-price text-right">
                      Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          <div className="item">
            <div className="budget-box">
              <div className="imagebox"><img src="../images/plan-01.png" alt="" className="img-fluid" /></div>
              <div className="detailsbody">
                <div className="detailsbox">
                  <h5>Company Registration</h5>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                </div>
                <div className="clearfix mtp10">
                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-outline-dark regbtn md" type="button" style={{fontSize:"12px"}}>READ MORE</button>
                    </div>
                    <div className="col-6 plan-price text-right">
                      Just for <span><img src="images/rupee-icon-b.png" alt="" />6899</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>


  </>
  );
};

export default MostPopularPlan;
