import React, { useEffect, useState } from "react";
import "../common/Common.css";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { error } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const AskExpert = () => {
  const [bannerData, setBannerData] = useState([]);
  const [img, setImg] = useState(""); // Add a new state for the image
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [age, setAge] = React.useState('');
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [services, setServices] = useState('');
  const [subServices, setSubServices] = useState('');
  const [description, setDescription] = useState('');
  const [emailError, setEmailError] = useState('');
  const [servicesError, setServicesError] = useState('');
  const [subServicesError, setSubServicesError] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');

  useEffect(() => {
    getBanner();
  }, []);

  const handleChange = (event) => {
    // Update state variables based on the input field name
    switch (event.target.name) {
      case 'name':
        setName(event.target.value);
        break;
      case 'mobileNumber':
        setMobileNumber(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'services':
        setServices(event.target.value);
        
        break;
      case 'subServices':
        setSubServices(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    // Reset previous error messages
    setNameError('');
    setMobileNumberError('');
    setEmailError('');
    setServicesError('');
    setSubServicesError('');

    // Validation checks
    let valid = true;

    if (name.trim() === '') {
      setNameError('Name is required');
      valid = false;
    }

    if (!/^\d{10}$/.test(mobileNumber)) {
      setMobileNumberError('Invalid mobile number');
      valid = false;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Invalid email format');
      valid = false;
    }

    if (services.trim() === '') {
      setServicesError('Service is required');
      valid = false;
    }

    if (subServices.trim() === '') {
      setSubServicesError('Sub-Service is required');
      valid = false;
    }

    if (valid) {
      // Proceed with form submission
      console.log('Name:', name);
      console.log('Mobile Number:', mobileNumber);
      console.log('Email:', email);
      console.log('Services:', services);
      console.log('Sub-Services:', subServices);
      console.log('Description:', description);

      // Reset form fields (if needed)
      setName('');
      setMobileNumber('');
      setEmail('');
      setServices('');
      setSubServices('');
      setDescription('');
      handleClose();
      toast.success('Form submitted successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // Display a Toast notification for missing required fields
      toast.error('All fields are required or contain errors', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  const getBanner = () => {
    const method = "banner/images?subServiceId=Home&position=2";
    axios.get(REACT_APP_BASE_URL + method).then((res) => {
      console.log("Ask Expert Banner ", res.data.data);
      setBannerData(res.data.data);
      const imgData = res?.data?.data[0]?.image;
      //   console.log(`res.data.data:img/jpeg;base64,${imgData}`);
      setImg(imgData); // Update the image state
    }).catch((error)=>{
      console.log(error)
    })
  };

  return (
    <>
      {/* <img src={`res.data.data:img/jpeg;base64,${img}`} alt="Banner" /> */}
      <section
        className="brownimg-bo"
        style={{
          backgroundImage: `url(data:image/jpeg;base64,${bannerData[0]?.image})`,height:"14rem"
        }}
      >
        <div className="container" style={{height:"10rem"}}>
          <h4 className="white-title askexpert-responsive">
            {/* Ask Tax Savvy Expert @ just ₹ 1499/- */}
            {bannerData[0]?.title}
          </h4>
          <div className="row mtp15 align-items-center">
            <div className="col-lg-9">
              <p className="white-smalltext mbp0">
                {/* - Schedule 45 min Phone call with Clear tax Expert for Salaried individuals, Freelancers, Financial Traders
                <br />
                - Includes advisory on Salary & other income, Capital Gains, Foreign assets & income tax notices */}
                {bannerData[0]?.text}
              </p>
            </div>
            <div className="col-lg-3 text-left text-lg-right askexpert-btn-div"  style={{alignSelf:'stretch'}}>
              <button
                className="btn btn-light regbtn"
                type="button"
                onClick={handleOpen}
              >
                {bannerData[0]?.buttonText}
              </button>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{border:"none"}} className="modal-outer">
          <Typography style={{ textAlign: "center", fontSize: "28px", background:"ghostwhite;" }}>
            ASK Expert's
          </Typography>
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            
            <Grid xs={6}>
              <Typography fullWidth id="modal-modal-description" sx={{}} style={{width:"95%"}}>
                <TextField
                  fullWidth
                  id="standard-password-input"
                  label="Name"
                  type="text"
                  // autoComplete="current-password"
                  variant="standard"
                  name="name"
                value={name}
                onChange={handleChange}
                />
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                fullWidth
                id="modal-modal-description"
                sx={{}}
                // style={{ marginLeft: "1rem" }}
              >
                <TextField
                  fullWidth
                  id="standard-password-input"
                  label="Mobile Number"
                  type="number"
                  // autoComplete="current-password"
                  variant="standard"
                  name="mobileNumber"
                value={mobileNumber}
                onChange={handleChange}
                />
              </Typography>
            </Grid>

            <Grid xs={12} style={{marginTop:"2rem"}}>
              <Typography fullWidth id="modal-modal-description" sx={{}}>
                <TextField
                  fullWidth
                  id="standard-password-input"
                  label="Email"
                  type="email"
                  // autoComplete="current-password"
                  variant="standard"
                  name="email"
                value={email}
                onChange={handleChange}
                />
              </Typography>
            </Grid>

            <Grid xs={6} style={{marginTop:"2rem"}}>
            <FormControl fullWidth  style={{width:"95%"}}>
        <InputLabel id="demo-simple-select-label">Services</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={services}
          label="Services"
          name="services"
          onChange={handleChange}
        >
          <MenuItem value='Incom Tax'>Incom Tax</MenuItem>
          <MenuItem value='Goods and Service Tax'>Goods and Service Tax</MenuItem>
          <MenuItem value='Registrations'>Registrations</MenuItem>
          <MenuItem value='Business Support'>Business Support</MenuItem>
          <MenuItem value='ROC Compilance'>ROC Compilance</MenuItem>
        </Select>
      </FormControl>
            </Grid>


            <Grid xs={6} style={{marginTop:"2rem"}}>
            <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Sub-Services</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subServices}
          label="Sub-Services"
          name="subServices"
          onChange={handleChange}
        >
          <MenuItem value='Incom Tax Returns ITR'>Incom Tax Returns ITR</MenuItem>
          <MenuItem value='Application for Plan'>Application for Plan</MenuItem>
          <MenuItem value='Advisory on tax planning'>Advisory on tax planning</MenuItem>
          <MenuItem value='Advance tax calculation'>Advance tax calculation</MenuItem>
          <MenuItem value='TDS on property purchase'>TDS on property purchase</MenuItem>
          <MenuItem value='TDS returns'>TDS returns</MenuItem>
          <MenuItem value='TAN registration'>TAN registration</MenuItem>
          <MenuItem value='Incom tax notice'>Incom tax notice</MenuItem>
          <MenuItem value='Filling of Form 15CA'>Filling of Form 15CA</MenuItem>
          {/* <MenuItem value={30}></MenuItem> */}
        </Select>
      </FormControl>
            </Grid>

      

    <Grid xs={12} fullWidth style={{marginTop:"2rem"}}>
    <TextField
    fullWidth
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          name="description"
              value={description}
              onChange={handleChange}
          // defaultValue="Default Value"
        />

    </Grid>

    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <Stack spacing={2} direction="row" >
      
      <Button variant="contained" onClick={handleSubmit}>Submit</Button>
    </Stack>
    </Grid>

          </Grid>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
          id="standard-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="standard"
        />
        <TextField
          id="standard-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="standard"
        />
          </Typography> */}
        </Box>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default AskExpert;
