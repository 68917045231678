import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import FaqSection from "../../FAQ-section/Faq-section";
import Footer from "../../footer/Footer";
import "../incometax/IncomeTaxCss.css";
import { Link, useLocation } from "react-router-dom";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Typography } from "@mui/material";
import "../../common/Common.css"

const NewPanCard = () => {
  const [planData, setPlanData] = useState();
  const [idData, setIdData] = useState();
  const refreshData = 'refreshData';

  const location = useLocation();
  const datas = location.state;

  useEffect(() => {
    console.log(location.state);
    setPlanData(datas);
    // console.log(datas)
    getId();
  }, []);

  const getId = () => {
    const dataid = sessionStorage.getItem("userId");
    setIdData(dataid);
    // console.log(dataid);
  };

  useEffect(() => {
    const smoothScroll = (targetId) => {
      const target = document.getElementById(targetId);
      if (target) {
        window.scrollTo({
          top: target.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    };

    const handleHashChange = () => {
      if (window.location.hash) {
        smoothScroll(window.location.hash.substring(1));
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <>
      {/* <Header /> */}
      <DemoHeader />
      {/* <div className="position-relative">
            <div className="owl-carousel owl-theme banner">
                <div className="item purpal-nav-bg">
                    <div className="d-none d-md-block" style="background: url(images/plan-img.png) no-repeat bottom right; opacity: 0.8; position:absolute;  z-index:0; right:0; bottom:0; height:100%; width: 50%; background-size: 100% 100%;"></div>
              
                    <div className="container-fluid">
                        <div className="row align-items-center vh-100">
                            <div className="col-sm-12 col-md-10  col-lg-8">
                                <div className="banner-text banner-text-xl">

                                    <h2>New PAN Card</h2>
                                    <div className="banner-text-bottom">
                                        <span>Key Highlights of Plan</span>
                                        <ul>
                                            <li>For Individual, HUF, Partnership, LLP, Company or any other body corporate</li>
                                            <li>Least Documentation</li>
                                            <li>Dedicated expert assigned on your case</li>
                                            <li>Continuous assistance till you receive your PAN Card</li>
                                        </ul>
                                        <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     

                </div>
            </div>
        </div> */}
      {/* <div className="container-fluid planA-outer" style={{ backgroundColor: "#5136e6" }}>
        <div className="row align-items-center vh-100 headerbackground">
          <div className="col-sm-12 col-md-10  col-lg-8">
            <div className="banner-text banner-text-xl">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="light" to="/" className="breadcrumb-color">
                  HOME
                </Link>
                <Link
                  className="breadcrumb-color"
                  underline="hover"
                  color="inherit"
                  onClick={() => window.history.go(-1)}
                >
                  Pan Services
                </Link>

                <Link
                  className="breadcrumb-color"
                  underline="hover"
                  color="inherit"
                  onClick={() => window.history.go(-1)}
                >
                  {planData?.planName}
                </Link>
              </Breadcrumbs>
              <h2>{planData?.planName}</h2>
              <div className="banner-text-bottom">
                <span>Key Highlights of Plan</span>
                <ul>
                  <li>
                    For Individual, HUF, Partnership, LLP, Company or any other
                    body corporate
                  </li>
                  <li>Least Documentation</li>
                  <li>Dedicated expert assigned on your case</li>
                  <li>Continuous assistance till you receive your PAN Card</li>
                </ul>
                {idData === null ? (
                  <button
                    className="btn btn-light regbtn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    BUY NOW
                  </button>
                ) : (
                  <Link to={planData?.pageLink}>
                    <button className="btn btn-light regbtn" type="button">
                      BUY NOW
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-main">
        <div className="container-main-inner">
          <div className="container-div-left">
            <div>
              <div>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="light" to="/" className="breadcrumb-color">
                    HOME
                  </Link>
                  <Link
                    className="breadcrumb-color"
                    underline="hover"
                    color="inherit"
                    onClick={() => window.history.go(-1)}
                  >
                    Pan Services
                  </Link>

                  <Link
                    className="breadcrumb-color"
                    underline="hover"
                    color="inherit"
                    onClick={() => window.history.go(-1)}
                  >
                    {planData?.planName}
                  </Link>
                </Breadcrumbs>
              </div>
            </div>
            <div>
              <div>
                <h2 className="planName"> {planData?.planName}</h2>
              </div>
            </div>
            <div className="container-innerdiv-3">
              <div className="div3-div1"><p className="div3-div1-p">Made for Immovable property purchasers</p></div>
              <div className="banner-text-bottom">
                <span className="container-innerdiv-3-span">Key Highlights of Plan</span>
                <ul className="container-innerdiv-3-ul">
                  <li>Dedicated CA assigned on your case</li>
                  <li>Prior Consultation on maximum tax benefits</li>
                  <li>Suggestions on New Tax Saving avenues for next year</li>
                  <li>Tracking and updating the status of refund, if any</li>
                  <li>Advisory on queries pertaining to the Income tax return filing</li>
                </ul>
                {idData === null ? (
                  <Link>
                    <button
                      type="button"
                      className="btn btn-light regbtn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      BUY NOW
                    </button>
                  </Link>
                ) : (
                  <Link to={planData?.pageLink}>
                    <button
                      className="btn btn-light regbtn"
                      type="button"
                    // onClick={() => navigateStep()}
                    >
                      BUY NOW
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="container-div-right">
            <img src="images/plan-img.png" style={{ maxWidth: '100%', height: '100%' }} />
          </div>
        </div>
      </div>

      <div
        className="modal fade sign-resp"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ marginTop: '15rem', width: '93%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                TaxSavvy
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="C lose"
              ></button>
            </div>
            <div className="modal-body" style={{ padding: '2rem' }}>
              <div style={{ fontSize: "23px", paddingBottom: '2rem' }}>
                Please{" "} Sign In {" "}

                to continue...
              </div>
              <div className="modal-SignIn-btn">
                <Link to="/signup" state={{ refreshData }}>
                  <button className="no-login-buy"
                    type="button"
                  >
                    Sign In
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="planbox planbox-outer" id="Header2">
        <div className="planbox-title">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-5 col-md-6 col-lg-9 col-xl-9">
                {/* <h4>New PAN Card</h4> */}
                <h4>{planData?.planName}</h4>
              </div>
              <div className="col-7 col-md-6 col-lg-3 col-xl-3 text-right justify-content-end"
                style={{ display: "flex" }}
              >
                <div className="dropdown d-inline-block arrowbtn d-sm-none">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="images/down-arrow.svg" />
                  </a>

                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a className="dropdown-item" href="#aboutplan">
                      About the Plan
                    </a>
                    <a className="dropdown-item" href="#howitdone">
                      How it’s done
                    </a>
                    <a className="dropdown-item" href="#docreq">
                      Documents required
                    </a>
                    <a className="dropdown-item" href="#faqs">
                      FAQs
                    </a>
                  </div>
                </div>
                <div className="priceboxin">
                  <span>Just for</span>
                  {/* <h6>₹1499</h6> */}
                  <h6>{planData?.fee}</h6>
                </div>
                {/* <a className="btn btn-dark btnblack float-right" href="step.html">BUY NOW</a> */}
                {idData === null ? (
                  <button
                    className="btn btn-dark btnblack"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    BUY NOW
                  </button>
                ) : (
                  <Link to={planData?.pageLink}>
                    <button className="btn btn-dark btnblack" type="button">
                      BUY NOW
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="plannav d-none d-sm-block mtp5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-left fixnav">
                <ul className="nav nav-pills nav-fill" id="top-menu">
                  <li className="nav-item">
                    <a className="nav-link" href="#aboutplan">
                      About the Plan
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#howitdone">
                      How it’s done
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#docreq">
                      Documents required
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#faqs">
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="tb-space aboutPlan-outer" id="aboutplan">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h5 className="main-title red-row mbp15 d-inline-block">
                About the Plan
              </h5>
            </div>
          </div>
          <div className="row mtp15">
            <div className="col-sm-6 col-xl-7">
              <h5 className="sub-title-xs mbp10">Plan details</h5>
              <p className="black-smalltext">
                This product is applicable for applicants who are Indian Citizen
                and has never applied for a PAN or does not have PAN allotted to
                him/her. Also, this plan covers PAN application for Individual,
                HUF, Partnership, LLP, Company or any other body corporate. Even
                minor can apply for PAN card through Representative Assessee/
                Parent / Guardian. A dedicated expert will help you in getting
                you PAN card. Our expert will provide time to time assistance
                till the time you received your PAN card. Therefore, an
                individual shall be guided to best of his/her interest.
              </p>
              <p className="text-xs">
                {" "}
                <i>
                  Note:
                  <br /> 1. For Change or correction or reissue of Pan Card,
                  please refer our succeeding plan.
                  <br />
                  2. In case of Non Resident Indians (NRI), please refer our
                  succeeding plan “Pan Card for Non Resident” for new or changes
                  in PAN card.
                </i>
              </p>

              <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
              <p className="black-smalltext">
                1. Individual who have never applied for a PAN or does not have
                PAN allotted to him/her
              </p>
              <p className="black-smalltext">
                2. Minor (Individual below the age of 18 years)
              </p>
              <p className="black-smalltext">
                3. HUF, Partnership, LLP, Company or any other body corporate
                like trust, Association of persons (AOP)
              </p>
            </div>
            <div className="col-sm-6  col-xl-5">
              <img
                src="images/about-img.png"
                alt=""
                className="img-fluid m-auto float-md-right"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="tb-space graybg howitdone-outer" id="howitdone">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h5 className="main-title red-row mbp15 d-inline-block">
                How it’s Done
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center  ">
              <div className="hid-list-main howItDone-bg-img howItDone-bg-img1">
                <ul className="hid-list six ">

                  <li className="hid-list-li-resp">
                    <div className="hid-list-li-div1-resp">
                      <div className="hid-img">
                        <img src="images/cart-icon.png" />
                      </div>
                      <div className="hid-text">Purchase of Plan</div>
                    </div>
                  </li>

                  <li className="hid-list-li-resp">
                    <div className="hid-list-li-div2-resp">
                      <div className="hid-img">
                        <img src="images/upload-icon.png" />
                      </div>
                      <div className="hid-text">Upload Documents</div>
                    </div>
                  </li>

                  <li className="hid-list-li-resp">
                    <div className="hid-list-li-div1-resp">
                      <div className="hid-img">
                        <img src="images/file-search-icon.png" />
                      </div>
                      <div className="hid-text">Case overview with expert</div>
                    </div>
                  </li>

                  <li className="hid-list-li-resp">
                    <div className="hid-list-li-div2-resp">
                      <div className="hid-img">
                        <img src="images/filling-form-icon.png" />
                      </div>
                      <div className="hid-text">
                        Review & Approval of Computation of Income
                      </div>
                    </div>
                  </li>

                  <li className="hid-list-li-resp">
                    <div className="hid-list-li-div1-resp">
                      <div className="hid-img">
                        <img src="images/generation-form-icon.png" />
                      </div>
                      <div className="hid-text">
                        Filling of Income Tax Return with Acknowledgment
                      </div>
                    </div>
                  </li>

                  <li className="hid-list-li-resp">
                    <div className="hid-list-li-div2-resp">
                      <div className="hid-img">
                        <img src="images/filling-form-icon.png" />
                      </div>
                      <div className="hid-text">Feedback</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="estimated-row">
                <span>3 Days Estimated</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tb-space blackbg document-outer" id="docreq">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h5 className="main-title white-row mbp15 d-inline-block white-color">
                Documents required
              </h5>
            </div>
          </div>
          <div className="row mtp25 gutter-20 documentsrequired">
            <div className="col-sm-6 col-md-3">
              <div className="doc-box">
                <div className="number-box">01</div>
                <div className="doc-details">Proof of Identity</div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="doc-box">
                <div className="number-box">02</div>
                <div className="doc-details">Proof of Address</div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="doc-box">
                <div className="number-box">03</div>
                <div className="doc-details">Proof of Date of Birth</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tb-space" id="faqs">
        <FaqSection />
      </section>
      
      <Footer />
    </>
  );
};

export default NewPanCard;
