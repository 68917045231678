import React, { useState } from 'react'
import DemoHeader from '../../demoHeader/DemoHeader'
import Button from "@mui/material/Button";
// import "../../common/Common.css";
import "./Step.css";
import "./PanApplicationForm.css"

const PanApplicationForm = () => {
  const [number, setNumber] = useState(1);
  const [isEmail, setIsEmail] = useState();
  const [isMobile, setIsMobile] = useState();
  const [isPassport, setIsPassport] = useState();
  const [isName, setIsName] = useState();
  const [isPanDocument, setPanIsDocument] = useState();
  const [isAdharDocument, setIsAdharDocument] = useState();
  const [IsEnable, setIsEnable] = useState(false);



  return (
    <>
      <DemoHeader />
      <div className="bannertopstep">
        <div className="container text-left">
          <div className="banner-vhmian1">
            <div className="vhin">{/* <h1>Steps</h1> */}</div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="">
            <div className="Custom-Action">
              <div className="vhin"><h1>Application Form For Pan Card</h1></div>
              <section className="step-box clearfix">
                <div className="container">
                  <form
                  // onSubmit={handleSubmit}
                  >
                    <div className="step-details step-form">
                      {/*Step-1  */}
                      <div className={`step-${number}`}>
                        <div className="row">
                          <div className="col-sm-6 col-lg-4 col-xl-3">
                            {/* name field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Name
                              </label>
                              <input
                                type="text"
                                name="namePanCard"
                                className={`form-control ${isName !== undefined
                                  ? isName
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                placeholder="Enter your name"
                                // onChange={inputHandle}
                                // onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div className="invalid-feedback">
                                Name should not be null
                              </div>
                            </div>
                            {/* email field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Email address
                              </label>
                              <input
                                type="email"
                                name="email"
                                className={`form-control ${isEmail !== undefined
                                  ? isEmail
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                required
                                // onChange={inputHandle}
                                // onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div className="invalid-feedback">
                                Incorrect email format
                              </div>
                            </div>
                            {/* Voter Id field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">Voter ID</label>
                              <div>
                                <input
                                  type="text"
                                  name="voterIdNumber"
                                  className={`form-control ${isMobile !== undefined
                                    ? isMobile
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  required
                                // onChange={inputHandle}
                                // onBlur={inputValidation}
                                />
                              </div>
                              <div>
                                <input
                                  type="file"
                                  name="voterIdDocument"
                                  className={`form-control ${isAdharDocument !== undefined
                                    ? isAdharDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add pan card"
                                  // onChange={inputHandle}
                                  // onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invalid-feedback">
                                Adhar Card is required
                              </div>
                            </div>
                            {/* Ration Id field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">Ration Card</label>
                              <div>
                                <input
                                  type="file"
                                  name="rationCardDocument"
                                  className={`form-control ${isAdharDocument !== undefined
                                    ? isAdharDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add ration card"
                                  // onChange={inputHandle}
                                  // onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invalid-feedback">
                                Adhar Card is required
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                            {/* passport number field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Passport Number
                              </label>
                              <input
                                type="text"
                                name="passportNumber"
                                className={`form-control ${isPassport !== undefined
                                  ? isPassport
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                placeholder="Enter passport number"
                                // onChange={inputHandle}
                                // onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div className="invalid-feedback">
                                Incorrect Passport number format
                              </div>
                            </div>
                            {/* mobile number field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Mobile Number
                              </label>
                              <input
                                type="number"
                                name="mobileNumber"
                                className={`form-control ${isMobile !== undefined
                                  ? isMobile
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                required
                              // onChange={inputHandle}
                              // onBlur={inputValidation}
                              />
                              <div className="invalid-feedback">
                                Incorrect Mobile number
                              </div>
                            </div>
                            {/* adhard card field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">Adhar Card</label>
                              <div>
                                <input
                                  type="text"
                                  name="aadharNumber"
                                  className={`form-control ${isMobile !== undefined
                                    ? isMobile
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  required
                                // onChange={inputHandle}
                                // onBlur={inputValidation}
                                />
                              </div>
                              <div>
                                <input
                                  type="file"
                                  name="adharCardDocument"
                                  className={`form-control ${isAdharDocument !== undefined
                                    ? isAdharDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add pan card"
                                  // onChange={inputHandle}
                                  // onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invalid-feedback">
                                Adhar Card is required
                              </div>
                            </div>
                            {/* Driving Licence field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">Driving Licence</label>
                              <div>
                                <input
                                  type="text"
                                  name="aadharNumber"
                                  className={`form-control ${isMobile !== undefined
                                    ? isMobile
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  required
                                // onChange={inputHandle}
                                // onBlur={inputValidation}
                                />
                              </div>
                              <div className="invalid-feedback">
                                Adhar Card is required
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                            {/* Photo field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Photo ID card
                              </label>
                              <div>
                                <input
                                  type="file"
                                  name="photo_id"
                                  className={`form-control ${isAdharDocument !== undefined
                                    ? isAdharDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add Scanned Photo"
                                  // onChange={inputHandle}
                                  // onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invalid-feedback">
                                Incorrect Passport number format
                              </div>
                            </div>
                            {/* Pensioner card copy field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Pensioner card copy
                              </label>
                              <div>
                                <input
                                  type="file"
                                  name="photo_id"
                                  className={`form-control ${isAdharDocument !== undefined
                                    ? isAdharDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add Scanned Photo"
                                  // onChange={inputHandle}
                                  // onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invalid-feedback">
                                Incorrect Passport number format
                              </div>
                            </div>
                          
                          </div>
                        </div>
                        {isName !== undefined ||
                          isEmail !== undefined ||
                          isMobile !== undefined ||
                          isPassport !== undefined ||
                          isPanDocument !== undefined ||
                          isAdharDocument !== undefined ? (
                          !isName ||
                            !isEmail ||
                            !isMobile ||
                            !isPassport ||
                            !isPanDocument ||
                            !isAdharDocument ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Please fill all epmty fileds...!
                            </p>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <Button
                          className="nm2"
                          id="saveInformation"
                          variant="contained"
                          style={{
                            borderRadius: "25px",
                            background: "#AE0721",
                          }}
                          // onClick={() => checkValidationStep1()}
                          // endIcon={<SendIcon />}
                          disabled={IsEnable}
                        // Disable the button when isSendingOTP is true
                        >
                          Save & Next
                        </Button>
                      </div>
                    </div>

                    {/* {!isRadio1Step4Selected &&
                      housePropertyMapCount >= 1 &&
                      !isBtn && (
                        <Button
                          className="nm5"
                          id="openDialogueBtn"
                          variant="contained"
                          style={{
                            borderRadius: "25px",
                            background: "#AE0721",
                          }}
                          onClick={() => checkValidationStep4()}
                          endIcon={<SendIcon />}
                          disabled={IsEnable} // Disable the button when isSendingOTP is true
                        >
                          Save & Next
                        </Button>
                      )}
                    {isBtn && (
                      <Button
                        className="nm5"
                        id="openDialogueBtn0"
                        variant="contained"
                        style={{ borderRadius: "25px", background: "#AE0721" }}
                        onClick={() => checkValidationStep4(true)}
                        endIcon={<SendIcon />}
                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                      >
                        Save & Next
                      </Button>
                    )} */}
                    <br />
                    <br />
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default PanApplicationForm