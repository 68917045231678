import React from 'react'

const WhyTaxSavvy = () => {
  return (
    <>
 
    <section className="tb-space why-tax-outer">
               <div className="container">
                   <div className="row">
                       <div className="col-sm-12 text-center">
                           <h5 className="main-title red-row mbp15 d-inline-block">Why Tax Savvy?</h5>
                       </div>
                      
                       
                           <div className="col-sm-12 col-lg-10 offset-lg-1 mtp25">
                                   <div className="tilebox view-tenth">
                                       <div className="topdiv">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <img src="images/ts-icon1.png" alt="" className="m-auto img-fluid"/>
                                                   <span>Do not pay unless you<br/> are fully ready</span>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="mask">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <div className="imgbox">
                                                       <img src="images/ts-icon1-h.png" alt="" className="m-auto img-fluid"/>
                                                       <span>Do not pay unless you are fully ready</span>
                                                   </div>
                                                   <p>Earning the trust is most important. We allow you to test our entire process without any payment.</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="tilebox view-tenth">
                                       <div className="topdiv light-color">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <img src="images/ts-icon2.png" alt="" className="m-auto img-fluid"/>
                                                   <span>Free investment<br/> advisory for next year</span>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="mask">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <div className="imgbox">
                                                       <img src="images/ts-icon2-h.png" alt="" className="m-auto img-fluid"/>
                                                       <span>Free investment advisory for next year</span>
                                                   </div>
                                                   <p>Once filing of your IT return is done, we provide you with written advisory regarding tax saving options you can explore for running Financial year.</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="tilebox view-tenth">
                                       <div className="topdiv">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <img src="images/ts-icon3.png" alt="" className="m-auto img-fluid"/>
                                                   <span>Expert advises</span>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="mask">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <div className="imgbox">
                                                       <img src="images/ts-icon3-h.png" alt="" className="m-auto img-fluid"/>
                                                       <span>Expert advises</span>
                                                   </div>
                                                   <p>With ever changing taxation laws, we make sure you interact with various latest taxation provisions beneficial for you so that you do not miss on any perk.</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="tilebox view-tenth">
                                       <div className="topdiv light-color">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <img src="images/ts-icon4.png" alt="" className="m-auto img-fluid"/>
                                                   <span>Expert advises</span>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="mask">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <div className="imgbox">
                                                       <img src="images/ts-icon4-h.png" alt="" className="m-auto img-fluid"/>
                                                       <span>Expert advises</span>
                                                   </div>
                                                   <p>We make sure we do not waste the treasure on unwanted questions and in turn save your time to fullest.</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="tilebox view-tenth">
                                       <div className="topdiv">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <img src="images/ts-icon5.png" alt="" className="m-auto img-fluid"/>
                                                   <span>User friendly portal</span>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="mask">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <div className="imgbox">
                                                       <img src="images/ts-icon5-h.png" alt="" className="m-auto img-fluid"/>
                                                       <span>User friendly portal</span>
                                                   </div>
                                                   <p>We made sure that our portal does not lure you to unwanted windows. You get direct and easy access to the product you desire to avail.</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="tilebox view-tenth">
                                       <div className="topdiv light-color">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <img src="images/ts-icon6.png" alt="" className="m-auto img-fluid"/>
                                                   <span>Minimum price</span>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="mask">
                                           <div className="h-100 row  align-items-center">
                                               <div className="col">
                                                   <div className="imgbox">
                                                       <img src="images/ts-icon6-h.png" alt="" className="m-auto img-fluid"/>
                                                       <span>Minimum price</span>
                                                   </div>
                                                   <p>Our minimum price policy won't let you feel any hit on your pockets.</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                     
                           <div className="col-lg-10 offset-lg-1 mtp30 d-none">
                               <div className="row position-relative">
                                   <div className="boxmain">
                                       <div className="boxwidth">
                                           <div className="iconbox d-inline-block text-center">
                                               <img src="images/ts-icon1.png" alt="" className="blackimg"/>
                                               <img src="images/ts-icon1-h.png" alt="" className="whiteimg"/>
                                               <span>Do not pay unless you<br/> are fully ready</span>
                                           </div>
                                           <div className="box-details">
                                               <p>Earning the trust is most important. We allow you to test our entire process without any payment.</p>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="boxmain">
                                       <div className="boxwidth light-color">
                                           <div className="iconbox d-inline-block text-center">
                                               <img src="images/ts-icon2.png" alt="" className="blackimg"/>
                                               <img src="images/ts-icon2-h.png" alt="" className="whiteimg"/>
                                               <span>Free investment<br/> advisory for next year</span>
                                           </div>
                                           <div className="box-details">
                                               <p>Once filing of your IT return is done, we provide you with written advisory regarding tax saving options you can explore for running Financial year.</p>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="boxmain">
                                       <div className="boxwidth">
                                           <div className="iconbox d-inline-block text-center">
                                               <img src="images/ts-icon3.png" alt="" className="blackimg"/>
                                               <img src="images/ts-icon3-h.png" alt="" className="whiteimg"/>
                                               <span>Expert advises</span>
                                           </div>
                                           <div className="box-details">
                                               <p>With ever changing taxation laws, we make sure you interact with various latest taxation provisions beneficial for you so that you do not miss on any perk.</p>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="boxmain">
                                       <div className="boxwidth light-color">
                                           <div className="iconbox d-inline-block text-center">
                                               <img src="images/ts-icon4.png" alt="" className="blackimg"/>
                                               <img src="images/ts-icon4-h.png" alt="" className="whiteimg"/>
                                               <span>Your time is our treasure</span>
                                           </div>
                                           <div className="box-details">
                                               <p>We make sure we do not waste the treasure on unwanted questions and in turn save your time to fullest.</p>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="boxmain">
                                       <div className="boxwidth">
                                           <div className="iconbox d-inline-block text-center">
                                               <img src="images/ts-icon5.png" alt="" className="blackimg"/>
                                               <img src="images/ts-icon5-h.png" alt="" className="whiteimg"/>
                                               <span>User friendly portal</span>
                                           </div>
                                           <div className="box-details">
                                               <p>We made sure that our portal does not lure you to unwanted windows. You get direct and easy access to the product you desire to avail.</p>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="boxmain">
                                       <div className="boxwidth light-color">
                                           <div className="iconbox d-inline-block text-center">
                                               <img src="images/ts-icon6.png" alt="" className="blackimg"/>
                                               <img src="images/ts-icon6-h.png" alt="" className="whiteimg"/>
                                               <span>Minimum price</span>
                                           </div>
                                           <div className="box-details">
                                               <p>Our minimum price policy won't let you feel any hit on your pockets.</p>
                                           </div>
                                       </div>
                                   </div>
   
                               </div>
                           </div>
   
                      
                   </div>
               </div>
           </section>
    </>
  )
}

export default WhyTaxSavvy