import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import "./Sidebar.css";
import DemoHeader from "../demoHeader/DemoHeader";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
// import "./Profiles.css";

const MyDoc = () => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const token = sessionStorage.getItem("token");

    const method = "document/data/";
    const docId = "64cb469b65401854d5cfeb89";
    const stepNo = "1";
    axios
      .get(REACT_APP_BASE_URL + method + docId + "/" + stepNo, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data.data.docs);
        setDocuments(res.data.data.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Container maxWidth="xl" style={{ marginTop: "7rem" }}>
        <Card sx={{ paddingBottom: 3 }}>
          <CardContent>
            <div className="row">
              <div className="col-6">
                <Typography sx={{ fontSize: 45 }} color="text.secondary">
                  Documents
                </Typography>
              </div>
              <div className="col-6 userProfileEdit">
                <button
                  type="button"
                  className="btn btn-dark regbtn md "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Edit
                </button>
              </div>
            </div>

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Modal title
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <h1>Modal Works</h1>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <Grid>
              <div className="row docs">
                <div className="col-md-6 py-3">
                  <span>Pan card</span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>aadahr Card</span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Form 16 or Salary slip </span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Excemption related Document </span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Intrest on fixed deposite (FD) </span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Intrest on Recurring deposite (RD) </span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Muncipal Tax Paid</span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Housing Loan </span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Principal Repaid</span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-6 py-3">
                  <span>Other Attachments </span>
                </div>
                <div className="col-md-6">
                  <span>
                    :<span className="dwnld">Download</span>
                  </span>
                </div>

                <div className="col-md-12 py-3">
                  <span>Other Attachments : </span>
                </div>
              </div>
            </Grid> */}

            <Grid>
              <div className="row docs">
                {documents.map((document) => (
                  <React.Fragment key={document._id}>
                    <div className="col-md-6 py-3">
                      <span>{document.propertyName}</span>
                    </div>
                    <div className="col-md-6">
                      <span>
                        :
                        <a className="dwnld" href={document.value} download>
                          Download
                        </a>
                      </span>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default MyDoc;
