import React, { useState } from "react";
import "../../common/Common.css";
import "../incometax/Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const GstRegistrationForm = () => {
    const [planFee, setPlanFee] = useState();
    const [data, setData] = useState({});
    const [userId, setUserId] = useState();
    const [steps, setSteps] = useState();
    const [properties, setProperties] = useState();
    const [number, setNumber] = useState(1);
    const [count, setCount] = useState();
    const [IsEnable, setIsEnable] = useState(false);
    const [passportValue, setIsPassportValue] = useState("");
    const [isPan, setIsPan] = useState();
    const [panValue, setIsPanValue] = useState("");
    const [documentId, setDocumentId] = useState();
    const [token, setToken] = useState();
    const [isAnotherDiv, setIsAnotherDiv] = useState(false);
    const [isBtn, setBtn] = useState();
    const [isHouseProperty, setIsHouseProperty] = useState(false);
    const [isIFSC, setIsIFSC] = useState();
    const [isExcelFile, setIsExcelFile] = useState();

    const [isPanCard, setIsPanCard] = useState();
    const [isAadharCard, setInAadharCard] = useState();
    const [isIdSelected, setIsIdSelected] = useState();
    const [isIdProofFile, setIsIdProofFile] = useState();
    const [isAddressProofSelected, setIsAddressProofSelected] = useState();
    const [isAddressProofFile, setIsAddressProofFile] = useState();
    const [isPhotographs, setIsPhotographs] = useState();
    const [isBusinessRegistrationFile, setIsBusinessRegistrationFile] = useState();
    const [isBankStatementFile, setIsBankStatementFile] = useState();
    const [isElectricityBillFile, setIsElectricityBillFile] = useState();


    const [showMakePaymentBTN, setShowMakePaymentBTN] = useState(false)


    const navigate = useNavigate();

    const Location = useLocation();
    const stepName = Location.state;
    useEffect(() => {
        window.scrollTo(0, 0);
        setPlanFee(sessionStorage.getItem("planFee"));
        getStepsData();
        setDocumentId(localStorage.getItem("documentId"));
        setToken(sessionStorage.getItem("token"));
    }, [number, IsEnable, isHouseProperty]);

    // GET 6 STEPS
    const getStepsData = () => {
        const planId = stepName?.data.planId
            ? stepName?.data.planId
            : sessionStorage.getItem("planId");
        const subServiceId = stepName?.subServiceIds
            ? stepName?.subServiceIds
            : sessionStorage.getItem("subServiceIds");

        // console.log(planId, subServiceId)

        const data = localStorage.getItem("userId");
        setUserId(data);
        const token = localStorage.getItem("token");
        // console.log("token : ", token)
        const method = "steps/";
        axios
            .get(`${REACT_APP_BASE_URL}steps/${subServiceId}/${planId}`)
            .then((response) => {
                console.log("steps : ", response.data.data);
                setSteps(response.data.data.steps);
                setCount(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const IdentityProof = [
        { value: "Aadhar Card", label: "Aadhar Card" },
        { value: "PAN Card", label: "PAN Card" },
        { value: "Passport", label: "Passport" },
        { value: "Driving License", label: "Driving License" },
        { value: "Voter ID", label: "Voter ID" },
    ]

    const handleIdProofDropdown = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
          setIsIdSelected(false);
        } else {
          setIsIdSelected(true);
        }
        setData({ ...data, [name]: value });
      };

      const handleAddressProofDropdown = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
          setIsAddressProofSelected(false);
        } else {
          setIsAddressProofSelected(true);
        }
        setData({ ...data, [name]: value });
      };

    const inputHandle = (e) => {
        const { name, value, type, files } = e.target;

        console.log("radio : ", name, value.toUpperCase(), type);
        if (type == "file") {
            console.log(files);
            console.log(files[0].type);
            const fileType = files[0].type.split("/")[1];
            const method = `document/upload/${fileType}`;
            const formData = new FormData();
            formData.append("file", files[0]);
            try {
                //Get link request to store image/file into the S3 bucket
                axios
                    .get(REACT_APP_BASE_URL + method, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(
                        (res) => {
                            console.log("res", res);
                            setData({ ...data, [name]: res.data.data.key });

                            if (res.data.status === 1) {
                                //After got link from S3 bucket we hit put request to that S3 bucket link
                                axios
                                    .put(res.data.data.url, files[0], {
                                        headers: {
                                            "Content-Type": files[0].type,
                                        },
                                    })
                                    .then(
                                        (response) => {
                                            console.log("S3 bucket response : ", response);
                                            console.log("S3 bucket url : ", response.config.url);
                                        },
                                        (err) => {
                                            console.log("S3 bucket error : ", err);
                                        }
                                    );
                            }
                        },
                        (error) => {
                            console.log("error : ", error);
                        }
                    );
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            if (name === "panNumber") {
                setIsPanValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            if (name === "passportNumber") {
                setIsPassportValue(value.toUpperCase());
                setData({ ...data, [name]: value.toUpperCase() });
            }
            setData({ ...data, [name]: value });
        }
    };

    const inputValidation = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passportPattern =
            /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
        const { name, value, type, files } = e.target;
        const isValidEmail = emailPattern.test(value);
        const isValidPassportNumber = passportPattern.test(value);

        if (name === "panCard") {
            if (files.length > 0) {
                setIsPanCard(true);
            } else {
                setIsPanCard(false)
            }
        }
        if (name === "aadharCard") {
            if (files.length > 0) {
                setInAadharCard(true);
            } else {
                setInAadharCard(false)
            }
        }
        if (name === "identity-proof") {
            if (files.length > 0) {
                setIsIdProofFile(true);
            } else {
                setIsIdProofFile(false)
            }
        }
        if (name === "address-proof") {
            if (files.length > 0) {
                setIsAddressProofFile(true);
            } else {
                setIsAddressProofFile(false)
            }
        }
        if (name === "photographs") {
            if (files.length > 0) {
                setIsPhotographs(true);
            } else {
                setIsPhotographs(false)
            }
        }
        if (name === "proofofbusinessregistration") {
            if (files.length > 0) {
                setIsBusinessRegistrationFile(true);
            } else {
                setIsBusinessRegistrationFile(false)
            }
        }
        if (name === "bankaccountstatements") {
            if (files.length > 0) {
                setIsBankStatementFile(true);
            } else {
                setIsBankStatementFile(false)
            }
        }
        if (name === "electricitybill") {
            if (files.length > 0) {
                setIsElectricityBillFile(true);
            } else {
                setIsElectricityBillFile(false)
            }
        }
    };

    const handleDropdown = (e, { name }) => {
        const { value } = e;
        if (value === "None") {
            setIsAnotherDiv(false);
        } else {
            setIsAnotherDiv(true);
        }
        setData({ ...data, [name]: value });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        newFormData.append("namePanCard", data.namePanCard);
        newFormData.append("passportNumber", data.passportNumber);
        newFormData.append("email", data.email);
        newFormData.append("mobileNumber", data.mobileNumber);
        newFormData.append("panCardDocument", data.panCardDocument);
        newFormData.append("aadharCardDocument", data.aadharCardDocument);
        newFormData.append("form16File", data.form16File);
        newFormData.append(
            "anyOtherExemptionToBeClaimed",
            data.anyOtherExemptionToBeClaimed
        );
        newFormData.append(
            "exemptionRelatedDocuments",
            data.exemptionRelatedDocuments
        );
        newFormData.append("remarks", data.remarks);
        newFormData.append("annualSalary", data.annualSalary);
        newFormData.append("nameOfEmployer", data.nameOfEmployer);
        newFormData.append("panOfEmployer", data.panOfEmployer);
        newFormData.append("otherAttachments", data.otherAttachments);
        newFormData.append("remarks2", data.remarks2);
        newFormData.append("savingBankInterest", data.savingBankInterest);
        newFormData.append("interestOnFD", data.interestOnFD);
        newFormData.append("interestOnFDFile", data.interestOnFDFile);
        newFormData.append("interestOnRD", data.interestOnRD);
        newFormData.append("interestOnRDFile", data.interestOnRDFile);
        newFormData.append("remarks3", data.remarks3);
        newFormData.append(
            "expenseIfAnyInRespectOfIncome",
            data.expenseIfAnyInRespectOfIncome
        );
        newFormData.append(
            "expenseIfAnyInRespectOfAboveIncome",
            data.expenseIfAnyInRespectOfAboveIncome
        );
        newFormData.append("addOtherIncome", data.addOtherIncome);
        newFormData.append("address", data.address);
        newFormData.append("coOwned", data.coOwned);
        newFormData.append("typeOfHouseProperty", data.typeOfHouseProperty);
        newFormData.append("nameOfTenant", data.nameOfTenant);
        newFormData.append("panOfTenant", data.panOfTenant);
        newFormData.append("annualRent", data.annualRent);
        newFormData.append("municipalTax", data.municipalTax);
        newFormData.append("municipalTaxPaidFile", data.municipalTaxPaidFile);
        newFormData.append("interestOnHousingLoan", data.interestOnHousingLoan);
        newFormData.append(
            "interestOnHousingLoanFile",
            data.interestOnHousingLoanFile
        );
        newFormData.append("princiaplRepaid", data.princiaplRepaid);
        newFormData.append("principalRepaidFile", data.principalRepaidFile);
        newFormData.append("remarks4", data.remarks4);
        newFormData.append("otherDeductions", data.otherDeductions);
        newFormData.append("excelFile", data.excelFile);
        newFormData.append("bank", data.bank);
        newFormData.append("bankAccountNumber", data.bankAccountNumber);
        newFormData.append("ifscode", data.ifscode);
        newFormData.append(
            "governedByPortugueseCivil",
            data.governedByPortugueseCivil
        );
        newFormData.append("directorAtAnyCompany", data.directorAtAnyCompany);
        newFormData.append("claimBenefits", data.claimBenefits);
        newFormData.append("heldUnlisted", data.heldUnlisted);

        const url = "https://apibase.taxespro.in/";
        const method = `user/generate/income-tax/document/${userId}`;
        try {
            const response = await axios.post(url + method, newFormData);
            console.log(response.data); // Output: Response data from the server
            toast.success("Information saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };


    //Razor Pay Integration Loader(Pre-requisition)
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resolve(true);
            };

            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    //Razor Pay Integration (main)

    const displayRazorpay = async (amt) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("you r offline... Failed to load");
            return;
        }

        const options = {
            key: "rzp_test_snn65WkJ8E1lJr",
            currency: "INR",
            amount: parseInt(planFee) * 100,
            name: "Techgigs",
            description: "Thanks For Purchasing",

            handler: function (response) {
                const body = {
                    razorpayOrderId: response.razorpay_payment_id,
                };
                console.log(body);
                const methods = `document/payment/${stepName.docId}`;
                axios
                    .post(REACT_APP_BASE_URL + methods, body, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        console.log("RazerPay Response", res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                toast.success("Your Payment is successfull..!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/incometaxreturn");
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <>
            <DemoHeader />
            <div className="bannertopstep">
                <div className="container text-left">
                    <div className="banner-vhmian1 itr-breadcrumb">

                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            // to="/incometaxreturn"
                            onClick={() => window.history.go(-1)}
                        >
                            GST Registration
                        </Link>
                        <Typography color="text.primary">GST Registration Form</Typography>
                    </Breadcrumbs>
                    <div className="">
                        <div className="top-space-section step-gap">
                            <section className="step-box clearfix">
                                <div className="container">
                                    <div
                                    ></div>
                                    {/* steps name */}
                                    <ul className="nav nav-fill step-number row gutter-0 d-flex" style={{ justifyContent: 'flex-start' }}>

                                        {/* step 1 title */}
                                        <li className="nav-item col-2">
                                            <a
                                                className="nav-link nm1 active"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="General Information"
                                            >
                                                <span>
                                                    1<img src="images/check-w.png" alt="" />
                                                </span>
                                                <p className="d-none d-sm-block">
                                                    GST Registration
                                                </p>
                                            </a>
                                        </li>

                                    </ul>

                                    <form onSubmit={handleSubmit}>
                                        <div className="step-details step-form">
                                            {/*Step-1  */}
                                            <div className={`step-${number}`}>
                                                <div className="row">

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Pan Card
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Choose Pan Card</label>
                                                                        <input
                                                                            type="file"
                                                                            name="panCard"
                                                                            className={`form-control ${isPanCard !== undefined
                                                                                ? isPanCard
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Pan Card is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Aadhar Card
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Signature</label>
                                                                        <input
                                                                            type="file"
                                                                            name="aadharCard"
                                                                            className={`form-control ${isAadharCard !== undefined
                                                                                ? isAadharCard
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Aadhar Card is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Proof of identity
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">
                                                                            Select identity Proof
                                                                        </label>
                                                                        <Select
                                                                            name="title"
                                                                            options={IdentityProof}
                                                                            onChange={handleIdProofDropdown}
                                                                            styles={{ width: "100%", height: "max-content" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">identity Proof</label>
                                                                        <input
                                                                            type="file"
                                                                            name="identity-proof"
                                                                            className={`form-control ${isIdProofFile !== undefined
                                                                                ? isIdProofFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Identity Proof is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Address proof of persons in charge
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div className="form-group bottom-space">
                                                                        <label className="label-style">
                                                                            Select Address Proof
                                                                        </label>
                                                                        <Select
                                                                            name="title"
                                                                            options={IdentityProof}
                                                                            onChange={handleAddressProofDropdown}
                                                                            styles={{ width: "100%", height: "max-content" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-6">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Address Proof</label>
                                                                        <input
                                                                            type="file"
                                                                            name="address-proof"
                                                                            className={`form-control ${isAddressProofFile !== undefined
                                                                                ? isAddressProofFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Address Proof is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Photographs
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Choose Photo ID</label>
                                                                        <input
                                                                            type="file"
                                                                            name="photographs"
                                                                            className={`form-control ${isPhotographs !== undefined
                                                                                ? isPhotographs
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Photographs is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Proof of business registration
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Proof of business registration</label>
                                                                        <input
                                                                            type="file"
                                                                            name="proofofbusinessregistration"
                                                                            className={`form-control ${isBusinessRegistrationFile !== undefined
                                                                                ? isBusinessRegistrationFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Proof of business registration is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Bank account statements
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Bank account statements</label>
                                                                        <input
                                                                            type="file"
                                                                            name="bankaccountstatements"
                                                                            className={`form-control ${isBankStatementFile !== undefined
                                                                                ? isBankStatementFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Identity Proof is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="col-sm-12 col-lg-12 col-xl-12">
                                                            <div className="form-group bottom-space">
                                                                <label
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Electricity Bill
                                                                </label>
                                                                <br />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-4 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-xl-9">
                                                                    <div
                                                                        className="form-group bottom-space"
                                                                    >
                                                                        <label className="label-style">Choose Electricity Bill File</label>
                                                                        <input
                                                                            type="file"
                                                                            name="electricitybill"
                                                                            className={`form-control ${isElectricityBillFile !== undefined
                                                                                ? isElectricityBillFile
                                                                                    ? "is-valid"
                                                                                    : "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                            placeholder="From 16"
                                                                            onChange={inputHandle}
                                                                            onBlur={inputValidation}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            Electricity Bill is required
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                                                {isPanCard !== undefined ||
                                                isAadharCard !== undefined ||
                                                isIdSelected !== undefined ||
                                                isIdProofFile !== undefined ||
                                                isAddressProofSelected !== undefined ||
                                                isAddressProofFile !== undefined ||
                                                isPhotographs !== undefined ||
                                                isBusinessRegistrationFile !== undefined ||
                                                isBankStatementFile !== undefined ||
                                                isElectricityBillFile !== undefined? (
                                                    !isPanCard ||
                                                    !isAadharCard ||
                                                    !isIdSelected ||
                                                    !isIdProofFile ||
                                                    !isAddressProofSelected ||
                                                    !isAddressProofFile ||
                                                    !isPhotographs ||
                                                    !isBusinessRegistrationFile ||
                                                    !isBankStatementFile ||
                                                    !isElectricityBillFile? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Please fill all epmty fileds...!
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                <div className="step-nav-box">
                                                    <Button
                                                        id="saveInformation"
                                                        variant="contained"
                                                        style={{
                                                            borderRadius: "25px",
                                                            background: "#AE0721",
                                                        }}
                                                        // onClick={() => checkValidationStep6()}
                                                        endIcon={<SendIcon />}
                                                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                                                    >
                                                        Save & Make Payment
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </section>

                            {/* Footer part */}
                            <section className="grayad">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                                            <span>
                                                <b>Don’t have Information or documents?</b>
                                            </span>
                                            <span className="clearfix d-block">
                                                No worries! You can edit/submit the info and document
                                                later as well
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                                            <div className="pl-0 pl-lg-1 pl-xl-4">
                                                <span className="blue-text">
                                                    <b>{stepName?.data?.planName}</b>
                                                </span>
                                                <span className="clearfix d-block smalltext">
                                                    Made for Immovable property purchasers
                                                </span>
                                            </div>
                                        </div>
                                        {showMakePaymentBTN ? <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                                            <a
                                                className="btn btn-dark regbtn"
                                            // onClick={() => checkValidationStep6()}
                                            >
                                                Make Payment
                                            </a>
                                        </div> : ''}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GstRegistrationForm;
