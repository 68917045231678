import React, { useState } from "react";
import "../../common/Common.css";
import "./Step.css";
import Header from "../../header/Header";
import $ from "jquery";
import forge from "node-forge";
import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { REACT_APP_BASE_URL ,RAZORPAY_TEST_ID_KEY,} from "../../../service/Constant";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Box, Breadcrumbs, Modal, Typography } from "@material-ui/core";
import { Container, Row } from "react-bootstrap";
import PaymentSuccessfull from "../../payment_successfull/PaymentSuccessfull";
import ServiceStore from "../../../utils/ServiceStore";

const StepForPlanB = () => {
  const [planFee, setPlanFee] = useState();
  const [data, setData] = useState({});
  const [userId, setUserId] = useState();
  const [steps, setSteps] = useState();
  const [properties, setProperties] = useState();
  const [number, setNumber] = useState(1);
  const [count, setCount] = useState();
  const [IsEnable, setIsEnable] = useState(false);
  const [isName, setIsName] = useState();
  const [isEmail, setIsEmail] = useState();
  const [isMobile, setIsMobile] = useState();
  const [isPassport, setIsPassport] = useState();
  const [isPanDocument, setPanIsDocument] = useState();
  const [isAdharDocument, setIsAdharDocument] = useState();
  const [ToastCount, setToastCount] = useState(0);
  const [isRadio1Selected, setIsRadio1Selected] = useState(true);
  const [documentId, setDocumentId] = useState();
  const [token, setToken] = useState();
  const [isForm16File, setIsForm16File] = useState();
  const [isanyOtherExemptionToBeClaimed, setIsanyOtherExemptionToBeClaimed] =
    useState();

  const [isexemptionRelatedDocuments, setIsexemptionRelatedDocuments] =
    useState();
  const [isRemarks, setIsRemarks] = useState();
  const [isAnnualSalary, setIsAnnualSalary] = useState();
  const [isNameOfEmployer, setIsNameOfEmployer] = useState();
  const [isPanOfEmployer, setIsPanOfEmployer] = useState();
  const [isRemarks2, setIsRemarks2] = useState();

  const [isSavingBankInterest, setIsSavingBankInterest] = useState();
  const [isInterestOnFD, setIsInterestOnFD] = useState();
  const [isInterestOnFDFile, setIsInterestOnFDFile] = useState();
  const [isInterestOnRD, setIsInterestOnRD] = useState();
  const [isInterestOnRDFile, setIsInterestOnRDFile] = useState();
  const [isExpenseIfAnyInRespectOfIncome, setIsExpenseIfAnyInRespectOfIncome] =
    useState();
  const [
    isExpenseIfAnyInRespectOfAboveIncome,
    setIsExpenseIfAnyInRespectOfAboveIncome,
  ] = useState();
  const [isAnotherDiv, setIsAnotherDiv] = useState(false);
  const [isAnotherIncomeDocument, setIsAnotherIncomeDocument] = useState();
  const [isRemarks3, setIsRemarks3] = useState();

  const [housePropertyData, setHousePropertyData] = useState({});
  const [isRadio1Step4Selected, setIsRadio1Step4Selected] = useState(true);
  const [isBtn, setBtn] = useState();
  const [housePropertyMapCount, setHousePropertyMapCount] = useState();
  const [inputHouseProperty, setInputHouseProperty] = useState(0);
  const [housePropertyCount, setHousePropertyCount] = useState(1);
  const [isHouseProperty, setIsHouseProperty] = useState(false);
  const [isOpenDialogue, setIsOpenDialogue] = useState(false);

  const [bankAccountData, setBankAccountData] = useState({});
  const [bankAccountMapCount, setBankAccountMapCount] = useState(0);
  const [isIFSC, setIsIFSC] = useState();

  const [isExcelFile, setIsExcelFile] = useState();
  const [opensuccess, setOpenSuccess] = useState(false);


  const Location = useLocation();
  const stepName = Location.state;
  console.log(stepName)
  useEffect(() => {
    window.scrollTo(0, 0);
    setPlanFee(sessionStorage.getItem("planFee"));
    getStepsData();
    setDocumentId(localStorage.getItem("documentId"));
    setToken(sessionStorage.getItem("token"));
  }, [number, IsEnable, isHouseProperty]);

  // GET 6 STEPS
  const getStepsData = () => {
    const planId = stepName?.data.planId
      ? stepName?.data.planId
      : sessionStorage.getItem("planId");
    const subServiceId = stepName?.data.SubServiceId
      ? stepName?.data.SubServiceId
      : sessionStorage.getItem("subServiceIds");

     console.log("subServiceId",planId, subServiceId)

    const data = localStorage.getItem("userId");
    setUserId(data);
    const token = localStorage.getItem("token");
    // console.log("token : ", token)
    const method = "steps/";
    axios
      .get(`${REACT_APP_BASE_URL}steps/${subServiceId}/${planId}`)
      .then((response) => {
        console.log("steps : ", response.data.data);
        setSteps(response.data.data.steps);
        setCount(response.data.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Banks = [
    { value: "Punjab National Bank", label: "Punjab National Bank" },
    { value: "Bank of Baroda", label: "Bank of Baroda" },
    { value: "Indian Bank", label: "Indian Bank" },
    { value: "UCO Bank", label: "UCO Bank" },
    { value: "Axis Bank", label: "Axis Bank" },
    { value: "Kotak Mahindra Bank", label: "Kotak Mahindra Bank" },
    { value: "Union Bank", label: "Union Bank" },
    { value: "Indian Overseas Bank", label: "Indian Overseas Bank" },
    { value: "Bank of Maharashtra", label: "Bank of Maharashtra" },
    { value: "HDFC Bank", label: "HDFC Bank" },
    { value: "Yes Bank", label: "Yes Bank" },
    { value: "Bank Of India", label: "Bank Of India" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "Central Bank of India", label: "Central Bank of India" },
    { value: "ICICI Bank", label: "ICICI Bank" },
    { value: "IndusInd Bank", label: "IndusInd Bank" },
    { value: "Punjab & Sind Bank", label: "Punjab & Sind Bank" },
    { value: "Syndicate Bank", label: "Syndicate Bank" },
    { value: "Federal Bank", label: "Federal Bank" },
    { value: "Allahabad Bank", label: "Allahabad Bank" },
    { value: "Andhra Bank", label: "Andhra Bank" },
    { value: "IDBI Bank", label: "IDBI Bank" },
    { value: "Corporation Bank", label: "Corporation Bank" },
    { value: "Canara Bank", label: "Canara Bank" },
  ];

  const OtherIncome = [
    { value: "None", label: "None" },
    { value: "Dividend", label: "Dividend" },
    {
      value: "Interest on Post Office Deposit",
      label: "Interest on Post Office Deposit",
    },
    {
      value: "Interest on Co-Operative Deposit",
      label: "Interest on Co-Operative Deposit",
    },
    {
      value: "Interest on Public Provident Fund",
      label: "Interest on Public Provident Fund",
    },
    {
      value: "Interest on Income Tax Refund",
      label: "Interest on Income Tax Refund",
    },
    { value: "Gift Income", label: "Gift Income" },
    {
      value: "Income from Family Pension",
      label: "Income from Family Pension",
    },
    { value: "Maturity Proceeds of LIC", label: "Maturity Proceeds of LIC" },
    {
      value: "Withdrawal of Recognised Provident Fund",
      label: "Withdrawal of Recognised Provident Fund",
    },
    {
      value: "Withdrawal of Public Provident Fund",
      label: "Withdrawal of Public Provident Fund",
    },
    { value: "Agriculture Income", label: "Agriculture Income" },
  ];

  const handleRadioChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === "radio1") {
      setIsRadio1Selected(true);
      setIsAnnualSalary();
      setIsNameOfEmployer();
      setIsPanOfEmployer();
      setIsRemarks2();
      data.annualSalary = "";
      data.nameOfEmployer = "";
      data.panOfEmployer = "";
      data.remarks2 = "";
    } else {
      setIsRadio1Selected(false);
      setIsForm16File();
      setIsanyOtherExemptionToBeClaimed();
      setIsexemptionRelatedDocuments();
      setIsRemarks();
      data.form16File = "";
      data.anyOtherExemptionToBeClaimed = "";
      data.exemptionRelatedDocuments = "";
      data.remarks = "";
    }
  };

  const handleRadioStep4Change = (event) => {
    if (event.target.value === "radio1Step4") {
      setBtn(true);
      setIsRadio1Step4Selected(true);
    } else {
      setBtn(false);
      setIsRadio1Step4Selected(false);
    }
  };

  // $(document).ready(function () {
  $(".nm1").on("click", function () {
    setBtn(false);
    $(".nm1").addClass("active");
    $(".nm1,.nm2,.nm3,.nm4,.nm5,.nm6").removeClass("done");
    $(".nm2,.nm3,.nm4,.nm5,.nm6").removeClass("active");
    $(".step-1").removeClass("d-none");
    $(".step-2, .step-3, .step-4, .step-5, .step-6").addClass("d-none");
  });

  $(".nm2").on("click", function () {
    setIsEnable(false);
    setBtn(false);

    if (
      isEmail &&
      isName &&
      isMobile &&
      isPassport &&
      isPanDocument &&
      isAdharDocument
    ) {
      $(".nm2").addClass("active");
      $(".nm1").addClass("done");
      $(".nm2,.nm3,.nm4,.nm5,.nm6").removeClass("done");
      $(".nm1,.nm3,.nm4,.nm5,.nm6").removeClass("active");

      $(".step-2").removeClass("d-none");
      $(".step-1, .step-3, .step-4, .step-5, .step-6").addClass("d-none");
    }
    // else{
    //   toast.warn("Please fill all empty field..!", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  });

  // $(".nm3").on("click", function () {
  //   setBtn(false)
  //   console.log("isRadioSelected : ", isRadio1Selected);
  //   console.log(
  //     "isanyOtherExemptionToBeClaimed : ",
  //     isanyOtherExemptionToBeClaimed
  //   );
  //   console.log("isAnnualSalary : ", isAnnualSalary);
  //   if (isRadio1Selected) {
  //     if (
  //       isanyOtherExemptionToBeClaimed &&
  //       isanyOtherExemptionToBeClaimed !== undefined
  //     ) {
  //       if (data.anyOtherExemptionToBeClaimed > 0) {
  //         console.log("In 1 claimed");
  //         if (
  //           isForm16File &&
  //           isanyOtherExemptionToBeClaimed &&
  //           isexemptionRelatedDocuments &&
  //           isRemarks
  //         ) {
  //           $(".nm3").addClass("active");
  //           $(".nm1, .nm2").addClass("done");
  //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
  //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

  //           $(".step-3").removeClass("d-none");
  //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
  //         }
  //       } else {
  //         if (isForm16File && isanyOtherExemptionToBeClaimed && isRemarks) {
  //           $(".nm3").addClass("active");
  //           $(".nm1, .nm2").addClass("done");
  //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
  //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

  //           $(".step-3").removeClass("d-none");
  //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
  //         }
  //       }
  //     }
  //   } else {
  //     if (isAnnualSalary && isAnnualSalary !== undefined) {
  //       if (data.annualSalary > 0) {
  //         console.log("in greater than 0 condition")
  //         if (
  //           isAnnualSalary &&
  //           isNameOfEmployer &&
  //           isPanOfEmployer &&
  //           isRemarks2
  //         ) {
  //           $(".nm3").addClass("active");
  //           $(".nm1, .nm2").addClass("done");
  //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
  //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

  //           $(".step-3").removeClass("d-none");
  //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
  //         }
  //       } else {
  //         console.log("in equal to 0 condition")

  //         if (isAnnualSalary === 0) {
  //           $(".nm3").addClass("active");
  //           $(".nm1, .nm2").addClass("done");
  //           $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
  //           $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

  //           $(".step-3").removeClass("d-none");
  //           $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");
  //         }
  //       }
  //     }
  //   }
  // });

  $(".nm4").on("click", function () {
    if (
      isSavingBankInterest &&
      isInterestOnFD &&
      isInterestOnFDFile &&
      isInterestOnRD &&
      isInterestOnRDFile &&
      isExpenseIfAnyInRespectOfIncome &&
      isExpenseIfAnyInRespectOfAboveIncome &&
      data.addOtherIncome &&
      isAnotherIncomeDocument &&
      isRemarks3
    ) {
      setBtn(true);
      $(".nm4").addClass("active");
      $(".nm1, .nm2, .nm3").addClass("done");
      $(".nm4,.nm5,.nm6").removeClass("done");
      $(".nm1,.nm2,.nm3,.nm5,.nm6").removeClass("active");

      $(".step-4").removeClass("d-none");
      $(".step-1, .step-2, .step-3, .step-5, .step-6").addClass("d-none");
    } else {
      if (
        data.addOtherIncome === undefined ||
        data.addOtherIncome === null ||
        data.addOtherIncome === "None"
      ) {
        if (
          isSavingBankInterest &&
          isInterestOnFD &&
          isInterestOnFDFile &&
          isInterestOnRD &&
          isInterestOnRDFile &&
          isExpenseIfAnyInRespectOfIncome &&
          isExpenseIfAnyInRespectOfAboveIncome &&
          isRemarks3
        ) {
          setBtn(true);
          $(".nm4").addClass("active");
          $(".nm1, .nm2, .nm3").addClass("done");
          $(".nm4,.nm5,.nm6").removeClass("done");
          $(".nm1,.nm2,.nm3,.nm5,.nm6").removeClass("active");

          $(".step-4").removeClass("d-none");
          $(".step-1, .step-2, .step-3, .step-5, .step-6").addClass("d-none");
        }
      }
    }
  });

  $(".nm5").on("click", function () {
    setBtn(false);
    setIsRadio1Step4Selected(true);
    console.log("housePropertyData : ", housePropertyMapCount);
    if (
      isRadio1Step4Selected ||
      housePropertyData === undefined ||
      housePropertyData === null
    ) {
      $(".nm5").addClass("active");
      $(".nm1, .nm2, .nm3, .nm4").addClass("done");
      $(".nm5,.nm6").removeClass("done");
      $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

      $(".step-5").removeClass("d-none");
      $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
    } else if (!isRadio1Step4Selected || housePropertyMapCount > 0) {
      $(".nm5").addClass("active");
      $(".nm1, .nm2, .nm3, .nm4").addClass("done");
      $(".nm5,.nm6").removeClass("done");
      $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

      $(".step-5").removeClass("d-none");
      $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
    }
  });

  $(".nm6").on("click", function () {
    setBtn(false);

    console.log("isExcel : ", isExcelFile);
    if (
      isExcelFile &&
      isExcelFile !== undefined &&
      data?.otherDeductions === "Yes"
    ) {
      $(".nm6").addClass("active");
      $(".nm1, .nm2, .nm3, .nm4, .nm5").addClass("done");
      $(".nm6").removeClass("done");
      $(".nm1,.nm2,.nm3,.nm4,.nm5").removeClass("active");

      $(".step-6").removeClass("d-none");
      $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass("d-none");
    } else if (data?.otherDeductions === "No") {
      $(".nm6").addClass("active");
      $(".nm1, .nm2, .nm3, .nm4, .nm5").addClass("done");
      $(".nm6").removeClass("done");
      $(".nm1,.nm2,.nm3,.nm4,.nm5").removeClass("active");

      $(".step-6").removeClass("d-none");
      $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass("d-none");
    }
  });

  $(".step-1n, .step-3p").on("click", function () {
    $(".step-2").removeClass("d-none");
    $(".step-1, .step-3, .step-4, .step-5, .step-6").addClass("d-none");

    $(".nm1").removeClass("active");
    $(".nm1").addClass("done");

    $(".nm2").addClass("active");

    $(".nm3").removeClass("active");
    $(".nm2").removeClass("done");
    $(".nm2").addClass("active");
  });
  $(".step-2n, .step-4p").on("click", function () {
    $(".step-3").removeClass("d-none");
    $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass("d-none");

    $(".nm2").removeClass("active");
    $(".nm2").addClass("done");

    $(".nm3").addClass("active");

    $(".nm4").removeClass("active");
    $(".nm3").removeClass("done");
    $(".nm3").addClass("active");
  });
  $(".step-3n, .step-5p").on("click", function () {
    $(".step-4").removeClass("d-none");
    $(".step-1, .step-2, .step-3, .step-5, .step-6").addClass("d-none");

    $(".nm3").removeClass("active");
    $(".nm3").addClass("done");

    $(".nm4").addClass("active");

    $(".nm5").removeClass("active");
    $(".nm4").removeClass("done");
    $(".nm4").addClass("active");
  });
  $(".step-4n").on("click", function () {
    $(".step-5").removeClass("d-none");
    $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");

    $(".nm4").removeClass("active");
    $(".nm4").addClass("done");

    $(".nm5").addClass("active");
  });
  $(".step-5n").on("click", function () {
    $(".step-6").removeClass("d-none");
    $(".step-1, .step-2, .step-3, .step-4, .step-5").addClass("d-none");

    $(".nm5").removeClass("active");
    $(".nm5").addClass("done");

    $(".nm6").addClass("active");
  });
  $(".step-6p").on("click", function () {
    if (data.otherDeductions === "Yes") {
      if (data.excelFile) {
        $(".step-5").removeClass("d-none");
        $(".step-1, .step-2, .step-3 .step-4, .step-6").addClass("d-none");

        $(".nm6").removeClass("active");
        $(".nm5").removeClass("done");
        $(".nm5").addClass("active");
      }
    } else {
      $(".step-5").removeClass("d-none");
      $(".step-1, .step-2, .step-3 .step-4, .step-6").addClass("d-none");

      $(".nm6").removeClass("active");
      $(".nm5").removeClass("done");
      $(".nm5").addClass("active");
    }
  });

  $(".step-2p").on("click", function () {
    $(".step-1").removeClass("d-none");
    $(".step-2, .step-3 .step-4, .step-5, .step-6").addClass("d-none");

    $(".nm2").removeClass("active");
    $(".nm1").removeClass("done");
    $(".nm1").addClass("active");
  });
  $("a.form-title").on("click", function () {
    $(this).next(".form-details").slideToggle();
    $(this).toggleClass("imgtoggle");
    return false;
  });
  // });

  const inputHandle = (e) => {
    const { name, value, type, files } = e.target;

    console.log("radio : ", name, value, type);
    if (type == "file") {
      console.log(files);
      console.log(files[0].type);
      const fileType = files[0].type.split("/")[1];
      const method = `document/upload/${fileType}`;
      const formData = new FormData();
      formData.append("file", files[0]);
      new ServiceStore().UploadImage('step/uploadDocuments', formData).then((res) => {
        console.log("res:::::",res.data.data.imagename)
        if(res.data){
          setData({ ...data, [name]: res.data.data.imagename });
         
          
        }

        })
      // try {
      //   //Get link request to store image/file into the S3 bucket
      //   axios
      //     .get(REACT_APP_BASE_URL + method, {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     })
      //     .then(
      //       (res) => {
      //         console.log("res", res);
      //         if (res.data.status === 1) {
      //           //After got link from S3 bucket we hit put request to that S3 bucket link
      //           axios
      //             .put(res.data.data.url, files[0], {
      //               headers: {
      //                 "Content-Type": files[0].type,
      //               },
      //             })
      //             .then(
      //               (response) => {
      //                 console.log("S3 bucket response : ", response);
      //                 console.log("S3 bucket url : ", response.config.url);
      //                 setData({ ...data, [name]: response.config.url });
      //               },
      //               (err) => {
      //                 console.log("S3 bucket error : ", err);
      //               }
      //             );
      //         }
      //       },
      //       (error) => {
      //         console.log("error : ", error);
      //       }
      //     );
      // } catch (error) {
      //   console.log("error : ", error);
      // }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const inputHandleHouseProperty = (e) => {
    const { name, value, type, files } = e.target;

    console.log("radio : ", name, value, type);
    if (type == "file") {
      console.log(files[0]);
      const method = "document/upload";
      const formData = new FormData();
      formData.append("file", files[0]);
      new ServiceStore().UploadImage('step/uploadDocuments', formData).then((res) => {
        console.log("res:::::",res.data.data.imagename)
        if(res.data){
         
          setHousePropertyData({
            ...housePropertyData,
            [name]: res.data.data.imagename,
          });
        }

        })
      // setData({ ...data, [name]: files[0] });
      // try {
      //   //Get link request to store image/file into the S3 bucket
      //   axios
      //     .get(REACT_APP_BASE_URL + method, {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     })
      //     .then(
      //       (res) => {
      //         console.log("res", res);
      //         if (res.data.status === 1) {
      //           //After got link from S3 bucket we hit put request to that S3 bucket link
      //           axios
      //             .put(res.data.data.url, files[0], {
      //               headers: {
      //                 "Content-Type": files[0].type,
      //               },
      //             })
      //             .then(
      //               (response) => {
      //                 console.log("S3 bucket response : ", response);
      //                 console.log("S3 bucket url : ", response.config.url);
      //                 setHousePropertyData({
      //                   ...housePropertyData,
      //                   [name]: response.config.url,
      //                 });
      //               },
      //               (err) => {
      //                 console.log("S3 bucket error : ", err);
      //               }
      //             );
      //         }
      //       },
      //       (error) => {
      //         console.log("error : ", error);
      //       }
      //     );
      // } catch (error) {
      //   console.log("error : ", error);
      // }
    } else {
      setHousePropertyData({ ...housePropertyData, [name]: value });
    }
  };

  const inputHandleBankAccount = (e) => {
    const { name, value, type, files } = e.target;
    const alphanumericRegex = /^[A-Za-z0-9]+$/;
    console.log("radio : ", name, value, type);
    if (type == "file") {
      console.log(files[0]);
      const method = "document/upload";
      const formData = new FormData();
      formData.append("file", files[0]);
      new ServiceStore().UploadImage('step/uploadDocuments', formData).then((res) => {
        console.log("res:::::",res.data.data.imagename)
        if(res.data){
          setBankAccountData({
            ...bankAccountData,
            [name]: res.data.data.imagename,
          });
        }

        })
      // setData({ ...data, [name]: files[0] });
      // try {
      //   //Get link request to store image/file into the S3 bucket
      //   axios
      //     .get(REACT_APP_BASE_URL + method, {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     })
      //     .then(
      //       (res) => {
      //         console.log("res", res);
      //         if (res.data.status === 1) {
      //           //After got link from S3 bucket we hit put request to that S3 bucket link
      //           axios
      //             .put(res.data.data.url, files[0], {
      //               headers: {
      //                 "Content-Type": files[0].type,
      //               },
      //             })
      //             .then(
      //               (response) => {
      //                 console.log("S3 bucket response : ", response);
      //                 console.log("S3 bucket url : ", response.config.url);
      //                 setBankAccountData({
      //                   ...bankAccountData,
      //                   [name]: response.config.url,
      //                 });
      //               },
      //               (err) => {
      //                 console.log("S3 bucket error : ", err);
      //               }
      //             );
      //         }
      //       },
      //       (error) => {
      //         console.log("error : ", error);
      //       }
      //     );
      // } catch (error) {
      //   console.log("error : ", error);
      // }
    } else if (name.includes("ifsc")) {
      if (alphanumericRegex.test(value)) {
        setIsIFSC(true);
        setBankAccountData({ ...bankAccountData, [name]: value });
      } else {
        setIsIFSC(false);
      }
    } else {
      setBankAccountData({ ...bankAccountData, [name]: value });
    }
  };

  const inputValidation = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passportPattern =
      /^(?:[A-Z]{1}[0-9]{6}|[A-Z]{2}[0-9]{6}|[A-Z]{1}[0-9]{7})$/;
    const { name, value, type, files } = e.target;
    const isValidEmail = emailPattern.test(value);
    const isValidPassportNumber = passportPattern.test(value);
    if (name === "namePanCard") {
      if (value.length > 1) {
        setIsName(true);
      } else {
        setIsName(false);
      }
    }
    if (type === "email") {
      if (isValidEmail) {
        setIsEmail(true);
      } else {
        setIsEmail(false);
      }
    }
    if (name === "mobileNumber") {
      if (value.length === 10) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (name === "passportNumber") {
      if (isValidPassportNumber) {
        setIsPassport(true);
      } else {
        setIsPassport(false);
      }
    }
    if (name === "panCardDocument") {
      if (files.length > 0) {
        setPanIsDocument(true);
      } else {
        setPanIsDocument(false);
      }
    }
    if (name === "adharCardDocument") {
      if (files.length > 0) {
        setIsAdharDocument(true);
      } else {
        setIsAdharDocument(false);
      }
    }
    if (name === "form16File") {
      if (files.length > 0) {
        setIsForm16File(true);
      } else {
        setIsForm16File(false);
      }
    }
    if (name === "anyOtherExemptionToBeClaimed") {
      if (value.length > 0) {
        setIsanyOtherExemptionToBeClaimed(true);
      } else {
        setIsanyOtherExemptionToBeClaimed(false);
      }
    }
    if (name === "exemptionRelatedDocuments") {
      if (files.length > 0 && data.anyOtherExemptionToBeClaimed > 0) {
        setIsexemptionRelatedDocuments(true);
      } else {
        setIsexemptionRelatedDocuments(false);
      }
    }
    if (name === "remarks") {
      if (value.length > 0) {
        setIsRemarks(true);
      } else {
        setIsRemarks(false);
      }
    }
    if (name === "annualSalary") {
      if (value.length > 0) {
        setIsAnnualSalary(true);
      } else {
        setIsAnnualSalary(false);
      }
    }
    if (name === "nameOfEmployer") {
      if (value.length > 1) {
        setIsNameOfEmployer(true);
      } else {
        setIsNameOfEmployer(false);
      }
    }
    if (name === "panOfEmployer") {
      if (files.length > 0 && data.annualSalary > 0) {
        setIsPanOfEmployer(true);
      } else {
        setIsPanOfEmployer(false);
      }
    }
    if (name === "remarks2") {
      if (value.length > 0) {
        setIsRemarks2(true);
      } else {
        setIsRemarks2(false);
      }
    }
    if (name === "savingBankInterest") {
      if (value.length > 0) {
        setIsSavingBankInterest(true);
      } else {
        setIsSavingBankInterest(false);
      }
    }
    if (name === "interestOnFD") {
      if (value.length > 0) {
        setIsInterestOnFD(true);
      } else {
        setIsInterestOnFD(false);
      }
    }
    if (name === "interestOnFDFile") {
      if (files.length > 0) {
        setIsInterestOnFDFile(true);
      } else {
        setIsInterestOnFDFile(false);
      }
    }
    if (name === "interestOnRD") {
      if (value.length > 0) {
        setIsInterestOnRD(true);
      } else {
        setIsInterestOnRD(false);
      }
    }
    if (name === "interestOnRDFile") {
      if (files.length > 0) {
        setIsInterestOnRDFile(true);
      } else {
        setIsInterestOnRDFile(false);
      }
    }
    if (name === "expenseIfAnyInRespectOfIncome") {
      if (value.length > 0) {
        setIsExpenseIfAnyInRespectOfIncome(true);
      } else {
        setIsExpenseIfAnyInRespectOfIncome(false);
      }
    }
    if (name === "expenseIfAnyInRespectOfAboveIncome") {
      if (value.length > 0) {
        setIsExpenseIfAnyInRespectOfAboveIncome(true);
      } else {
        setIsExpenseIfAnyInRespectOfAboveIncome(false);
      }
    }
    if (name === "anotherIncomeDocument") {
      if (files.length > 0) {
        setIsAnotherIncomeDocument(true);
      } else {
        setIsAnotherIncomeDocument(false);
      }
    }
    if (name === "remarks3") {
      if (value.length > 0) {
        setIsRemarks3(true);
      } else {
        setIsRemarks3(false);
      }
    }
    if (name === "excelFile") {
      if (files.length > 0) {
        setIsExcelFile(true);
      } else {
        setIsExcelFile(false);
      }
    }
  };

  const checkValidationStep1 = () => {
    if (
      isEmail &&
      isName &&
      isMobile &&
      isPassport &&
      isPanDocument &&
      isAdharDocument
    ) {
      //Post API for sending step's data
      const method = "document/add/";
      const body = {
        docs: [
          {
            propertyName: "Name (as per PAN card)",
            value: data.namePanCard,
          },
          {
            propertyName: "Email Address",
            value: data.email,
          },
          {
            propertyName: "Mobile Number",
            value: data.mobileNumber,
          },
          {
            propertyName: "Passport Number",
            value: data.passportNumber,
          },
          {
            propertyName: "Pan Card",
            value: data.panCardDocument,
          },
          {
            propertyName: "Adhar Card",
            value: data.adharCardDocument,
          },
        ],
      };

      console.log("adhar card : ", data,body);

      axios
        .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "1", body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (res) => {
            console.log("response : ", res);
            if (res) {
              toast.success("Your Information saved successfully..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              window.scrollTo(0, 0);
            }
          },
          (error) => {
            toast.error("Something went wrong..!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
    } else {
      toast.error("Please fill all empty field..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const checkValidationStep2 = () => {
    console.log("radio is checked or not : ", isRadio1Selected);
    if (isRadio1Selected) {
      //First radio button is selected logic
      if (data.anyOtherExemptionToBeClaimed > 0) {
        console.log("IN greater than zero condition")
        if (
          isForm16File &&
          isanyOtherExemptionToBeClaimed &&
          isexemptionRelatedDocuments &&
          isRemarks
        ) {
          //Post API for sending step's data
          const method = "document/add/";
          const body = {
            docs: [
              {
                propertyName: "Form 16",
                value: data.form16File,
              },
              {
                propertyName: "Any other Exemption to be claimed",
                value: data.anyOtherExemptionToBeClaimed,
              },
              {
                propertyName: "Exemption related documentary",
                value: data.exemptionRelatedDocuments
                  ? data.exemptionRelatedDocuments
                  : "null",
              },
              {
                propertyName: "Remarks if any",
                value: data.passportNumber,
              },
            ],
          };

          axios
            .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "2", body, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(
              (res) => {
                console.log("response : ", res);
                if (res) {
                  toast.success("Your Information saved successfully..!", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  window.scrollTo(0, 0);
                  $(".nm3").addClass("active");
                  $(".nm1, .nm2").addClass("done");
                  $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                  $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                  $(".step-3").removeClass("d-none");
                  $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                    "d-none"
                  );
                }
              },
              (error) => {
                toast.error("Something went wrong..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            );
        } else {
          toast.error("Please fill all empty field..!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        console.log("In value is zero condition")
        //Post API for sending step's data
        const method = "document/add/";
        const body = {
          docs: [
            {
              propertyName: "Form 16",
              value: data.form16File,
            },
            {
              propertyName: "Any other Exemption to be claimed",
              value: data.anyOtherExemptionToBeClaimed,
            },
            {
              propertyName: "Exemption related documentary",
              value: "null",
            },
            {
              propertyName: "Remarks if any",
              value: data.remarks,
            },
          ],
        };

        axios
          .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "2", body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(
            (res) => {
              console.log("response : ", res);
              if (res) {
                toast.success("Your Information saved successfully..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                window.scrollTo(0, 0);
                $(".nm3").addClass("active");
                $(".nm1, .nm2").addClass("done");
                $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                $(".step-3").removeClass("d-none");
                $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                  "d-none"
                );
              }
            },
            (error) => {
              toast.error("Something went wrong..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
      }
    } else {
      //Second radio button is selected logic
      if (data.annualSalary > 0) {
        if (
          isAnnualSalary &&
          isNameOfEmployer &&
          isPanOfEmployer &&
          isRemarks2
        ) {
          //Post API for sending step's data
          const method = "document/add/";
          const body = {
            docs: [
              {
                propertyName: "Annual Salary/Pension",
                value: data.annualSalary,
              },
              {
                propertyName: "Name of the Employer",
                value: data.nameOfEmployer,
              },
              {
                propertyName: "Pan of the Employer",
                value: data.panOfEmployer,
              },
              {
                propertyName: "Remarks if any",
                value: data.remarks2,
              },
            ],
          };

          axios
            .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "2", body, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(
              (res) => {
                console.log("response : ", res);
                if (res) {
                  toast.success("Your Information saved successfully..!", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  $(".nm3").addClass("active");
                  $(".nm1, .nm2").addClass("done");
                  $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                  $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                  $(".step-3").removeClass("d-none");
                  $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                    "d-none"
                  );
                }
              },
              (error) => {
                toast.error("Something went wrong..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            );
        } else {
          toast.error("Please fill all empty field..!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        const method = "document/add/";
        const body = {
          docs: [
            {
              propertyName: "Annual Salary/Pension",
              value: data.annualSalary,
            },
            {
              propertyName: "Name of the Employer",
              value: data.nameOfEmployer ? data.nameOfEmployer : "null",
            },
            {
              propertyName: "Pan of the Employer",
              value: data.panOfEmployer ? data.panOfEmployer : "null",
            },
            {
              propertyName: "Remarks if any",
              value: data.remarks2 ? data.remarks2 : "null",
            },
          ],
        };

        axios
          .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "2", body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(
            (res) => {
              console.log("response : ", res);
              if (res) {
                toast.success("Your Information saved successfully..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                window.scrollTo(0, 0);
                $(".nm3").addClass("active");
                $(".nm1, .nm2").addClass("done");
                $(".nm3,.nm4,.nm5,.nm6").removeClass("done");
                $(".nm1,.nm2,.nm4,.nm5,.nm6").removeClass("active");

                $(".step-3").removeClass("d-none");
                $(".step-1, .step-2, .step-4, .step-5, .step-6").addClass(
                  "d-none"
                );
              }
            },
            (error) => {
              toast.error("Something went wrong..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
      }
    }
  };

  const checkValidationStep3 = () => {
    if (data.addOtherIncome !== "None") {
      if (
        isSavingBankInterest &&
        isInterestOnFD &&
        isInterestOnFDFile &&
        isInterestOnRD &&
        isInterestOnRDFile &&
        isExpenseIfAnyInRespectOfIncome &&
        isExpenseIfAnyInRespectOfAboveIncome &&
        data.addOtherIncome !== null &&
        isAnotherIncomeDocument &&
        isRemarks3
      ) {
        const method = "document/add/";
        const body = {
          docs: [
            {
              propertyName: "Saving Bank Interest",
              value: data.savingBankInterest,
            },
            {
              propertyName: "Interest on fixed deposit",
              value: data.interestOnFD,
            },
            {
              propertyName: "Interest on fixed deposit file",
              value: data.interestOnFDFile,
            },
            {
              propertyName: "Interest on recurring deposit",
              value: data.interestOnRD,
            },
            {
              propertyName: "Interest on recurring deposit file",
              value: data.interestOnRDFile,
            },
            {
              propertyName: "Expense if any in respect of income",
              value: data.expenseIfAnyInRespectOfIncome,
            },
            {
              propertyName: "Expense if any in respect of above income",
              value: data.expenseIfAnyInRespectOfAboveIncome,
            },
            {
              propertyName: "Add Other Income",
              value: data.addOtherIncome,
            },
            {
              propertyName: "Add Other Income file",
              value: data.anotherIncomeDocument,
            },
            {
              propertyName: "Remarks if any",
              value: data.remarks3,
            },
          ],
        };
        axios
          .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "3", body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(
            (res) => {
              console.log("response : ", res);
              if (res) {
                toast.success("Your Information saved successfully..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                window.scrollTo(0, 0);
              }
            },
            (error) => {
              toast.error("Something went wrong..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
      } else {
        toast.error("Please fill empty fields..!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (
        isSavingBankInterest &&
        isInterestOnFD &&
        isInterestOnFDFile &&
        isInterestOnRD &&
        isInterestOnRDFile &&
        isExpenseIfAnyInRespectOfIncome &&
        isExpenseIfAnyInRespectOfAboveIncome &&
        isRemarks3
      ) {
        const method = "document/add/";
        const body = {
          docs: [
            {
              propertyName: "Saving Bank Interest",
              value: data.savingBankInterest,
            },
            {
              propertyName: "Interest on fixed deposit",
              value: data.interestOnFD,
            },
            {
              propertyName: "Interest on fixed deposit file",
              value: data.interestOnFDFile,
            },
            {
              propertyName: "Interest on recurring deposit",
              value: data.interestOnRD,
            },
            {
              propertyName: "Interest on recurring deposit file",
              value: data.interestOnRDFile,
            },
            {
              propertyName: "Expense if any in respect of income",
              value: data.expenseIfAnyInRespectOfIncome,
            },
            {
              propertyName: "Expense if any in respect of above income",
              value: data.expenseIfAnyInRespectOfAboveIncome,
            },
            {
              propertyName: "Add Other Income",
              value: "None",
            },
            {
              propertyName: "Remarks if any",
              value: data.remarks3,
            },
          ],
        };
        axios
          .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "3", body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(
            (res) => {
              console.log("response : ", res);
              if (res) {
                toast.success("Your Information saved successfully..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                window.scrollTo(0, 0);
              }
            },
            (error) => {
              toast.error("Something went wrong..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
      } else {
        toast.error("Please fill empty fields..!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const checkValidationStep4 = (id) => {
    console.log("data : ", housePropertyData);
    const method = "document/add/";
    const body = {
      docs: [
        Object.entries(housePropertyData).map(([propertyName, value]) => ({
          propertyName: propertyName,
          value: value,
        })),
      ],
    };

    // const filterData = body.docs[0].map((data)=>{
    //   return data;
    // })

    const bodyData = {
      docs: body.docs[0],
    };
    axios
      .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "4", bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (res) => {
          console.log("response : ", res);
          if (res) {
            toast.success("Your Information saved successfully..!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setBtn(false);
            setIsRadio1Step4Selected(true);
            window.scrollTo(0, 0);
            $(".nm5").addClass("active");
            $(".nm1, .nm2, .nm3, .nm4").addClass("done");
            $(".nm5,.nm6").removeClass("done");
            $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

            $(".step-5").removeClass("d-none");
            $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
          }
        },
        (error) => {
          toast.error("Something went wrong..!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );

    if (id) {
      window.scrollTo(0, 0);
      $(".nm5").addClass("active");
      $(".nm1, .nm2, .nm3, .nm4").addClass("done");
      $(".nm5,.nm6").removeClass("done");
      $(".nm1,.nm2,.nm3,.nm4,.nm6").removeClass("active");

      $(".step-5").removeClass("d-none");
      $(".step-1, .step-2, .step-3, .step-4, .step-6").addClass("d-none");
    }
  };

  const checkValidationStep5 = () => {
    const method = "document/add/";
    const body = {
      docs: [
        {
          propertyName: "otherDeductions",
          value: data?.otherDeductions,
        },
        {
          propertyName: "excelFile",
          value: data?.excelFile ? data?.excelFile : "Null",
        },
      ],
    };

    if (data.otherDeductions === "Yes") {
      if (data.excelFile) {
        axios
          .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "5", body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(
            (res) => {
              console.log("response : ", res);
              if (res) {
                toast.success("Your Information saved successfully..!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                window.scrollTo(0, 0);
              }
            },
            (error) => {
              toast.error("Something went wrong..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
      } else {
        toast.error("Please upload file..!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      axios
        .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "5", body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (res) => {
            console.log("response : ", res);
            if (res) {
              toast.success("Your Information saved successfully..!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              window.scrollTo(0, 0);
            }
          },
          (error) => {
            toast.error("Something went wrong..!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
    }
  };

  const checkValidationStep6 = () => {
    console.log("data : ", bankAccountData);
    const method = "document/add/";
    const body = {
      docs: [
        Object.entries(bankAccountData).map(([propertyName, value]) => ({
          propertyName: propertyName,
          value: value,
        })),
      ],
    };

    const bodyData = {
      docs: body.docs[0],
    };

    console.log("bodyData : ", bodyData);

    axios
      .post(REACT_APP_BASE_URL + method + stepName.docId + "/" + "6", bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (res) => {
          console.log("response : ", res);
          if (res) {
            toast.success("Your Information saved successfully..!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.scrollTo(0, 0);
            displayRazorpay();
          }
        },
        (error) => {
          toast.error("Something went wrong..!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
  };

  const handleDropdown = (e, { name }) => {
    const { value } = e;
    if (value === "None") {
      setIsAnotherDiv(false);
    } else {
      setIsAnotherDiv(true);
    }
    setData({ ...data, [name]: value });
  };

  const handleBankAccountDropdown = (e, { name }) => {
    setBankAccountData({ ...bankAccountData, [name]: e.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFormData = new FormData();
    newFormData.append("namePanCard", data.namePanCard);
    newFormData.append("passportNumber", data.passportNumber);
    newFormData.append("email", data.email);
    newFormData.append("mobileNumber", data.mobileNumber);
    newFormData.append("panCardDocument", data.panCardDocument);
    newFormData.append("aadharCardDocument", data.aadharCardDocument);
    newFormData.append("form16File", data.form16File);
    newFormData.append(
      "anyOtherExemptionToBeClaimed",
      data.anyOtherExemptionToBeClaimed
    );
    newFormData.append(
      "exemptionRelatedDocuments",
      data.exemptionRelatedDocuments
    );
    newFormData.append("remarks", data.remarks);
    newFormData.append("annualSalary", data.annualSalary);
    newFormData.append("nameOfEmployer", data.nameOfEmployer);
    newFormData.append("panOfEmployer", data.panOfEmployer);
    newFormData.append("otherAttachments", data.otherAttachments);
    newFormData.append("remarks2", data.remarks2);
    newFormData.append("savingBankInterest", data.savingBankInterest);
    newFormData.append("interestOnFD", data.interestOnFD);
    newFormData.append("interestOnFDFile", data.interestOnFDFile);
    newFormData.append("interestOnRD", data.interestOnRD);
    newFormData.append("interestOnRDFile", data.interestOnRDFile);
    newFormData.append("remarks3", data.remarks3);
    newFormData.append(
      "expenseIfAnyInRespectOfIncome",
      data.expenseIfAnyInRespectOfIncome
    );
    newFormData.append(
      "expenseIfAnyInRespectOfAboveIncome",
      data.expenseIfAnyInRespectOfAboveIncome
    );
    newFormData.append("addOtherIncome", data.addOtherIncome);
    newFormData.append("address", data.address);
    newFormData.append("coOwned", data.coOwned);
    newFormData.append("typeOfHouseProperty", data.typeOfHouseProperty);
    newFormData.append("nameOfTenant", data.nameOfTenant);
    newFormData.append("panOfTenant", data.panOfTenant);
    newFormData.append("annualRent", data.annualRent);
    newFormData.append("municipalTax", data.municipalTax);
    newFormData.append("municipalTaxPaidFile", data.municipalTaxPaidFile);
    newFormData.append("interestOnHousingLoan", data.interestOnHousingLoan);
    newFormData.append(
      "interestOnHousingLoanFile",
      data.interestOnHousingLoanFile
    );
    newFormData.append("princiaplRepaid", data.princiaplRepaid);
    newFormData.append("principalRepaidFile", data.principalRepaidFile);
    newFormData.append("remarks4", data.remarks4);
    newFormData.append("otherDeductions", data.otherDeductions);
    newFormData.append("excelFile", data.excelFile);
    newFormData.append("bank", data.bank);
    newFormData.append("bankAccountNumber", data.bankAccountNumber);
    newFormData.append("ifscode", data.ifscode);
    newFormData.append(
      "governedByPortugueseCivil",
      data.governedByPortugueseCivil
    );
    newFormData.append("directorAtAnyCompany", data.directorAtAnyCompany);
    newFormData.append("claimBenefits", data.claimBenefits);
    newFormData.append("heldUnlisted", data.heldUnlisted);

    const url = "https://apibase.taxespro.in/";
    const method = `user/generate/income-tax/document/${userId}`;
    try {
      const response = await axios.post(url + method, newFormData);
      console.log(response.data); // Output: Response data from the server
      toast.success("Information saved successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNumber = (i) => {
    setNumber(i);
  };

  const checkHouseProperty = (value) => {
    setInputHouseProperty(value);
  };

  const openDialogue = () => {
    setIsOpenDialogue(true);
  };

  const closeDialogue = () => {
    setIsOpenDialogue(false);
  };

  const addHouseProperty = () => {
    setIsHouseProperty(true);
  };

  const handleHouseProperty = (e) => {
    const count = e.target.value >= 1 && e.target.value;
    setHousePropertyMapCount(count);
    addHouseProperty();
  };

  const handleHousePropertyPlus = () => {
    console.log("houseCount : ", housePropertyMapCount);
    if (
      housePropertyMapCount === undefined ||
      housePropertyMapCount === null ||
      housePropertyMapCount === NaN
    ) {
      let count = 1;
      setHousePropertyMapCount(parseInt(count));
    } else {
      setHousePropertyMapCount(housePropertyMapCount + 1);
    }
  };

  const handleHousePropertyMinus = () => {
    if (housePropertyMapCount >= 1)
      setHousePropertyMapCount(housePropertyMapCount - 1);
  };

  const AddBankAccount = () => {
    setBankAccountMapCount(bankAccountMapCount + 1);
  };

  const RemoveBankAccount = () => {
    if (bankAccountMapCount >= 0) {
      setBankAccountMapCount(bankAccountMapCount - 1);
    }
  };

  //Razor Pay Integration Loader(Pre-requisition)
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  //Razor Pay Integration (main)

  const displayRazorpay = async (amt) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("you r offline... Failed to load");
      return;
    }

    const options = {
      key: RAZORPAY_TEST_ID_KEY,
      currency: "INR",
      amount: parseInt(planFee) * 100,
      name: "Techgigs",
      description: "Thanks For Purchasing",

      handler: function (response) {
        const body = {
          razorpayOrderId: response.razorpay_payment_id
        }
        console.log(body)
        const methods = `document/payment/${stepName.docId}`
        axios.post(REACT_APP_BASE_URL + methods, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          console.log("RazerPay Response", res)
          // const data  = {'paymentStatus':"completed","id":stepName.docId}
          //   new ServiceStore().UpdateData('plans/update-document',data ).then((res) => {
          //     console.log("res:::::",res)
              
      
          //     })
        }).catch((err) => { console.log(err) });
        // alert(response.razorpay_payment_id);
        // alert("payment success");
        setOpenSuccess(true)
      },
    };

   

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

  };

  

  return (
    <>
      {/* <Header /> */}
      <DemoHeader />
      {opensuccess &&<PaymentSuccessfull/>}
      <div className="bannertopstep">
        <div className="container text-left">
          <div className="banner-vhmian1">
            <div className="vhin">{/* <h1>Steps</h1> */}</div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                HOME
              </Link>
              <Link
                underline="hover"
                color="inherit"
                // to="/incometaxreturn"
                onClick={() => window.history.go(-1)}

              >
                Plan B
              </Link>
              <Typography color="text.primary" >Steps For Plan B</Typography>

            </Breadcrumbs>
          </div>
          <div className="">
            <div className="top-space-section">
              <section className="step-box clearfix">
                <div className="container">
                  <div className="step-number-row" style={{ marginRight: '8%' }}></div>
                  {/* steps name */}
                  <ul className="nav nav-fill step-number row gutter-0 d-flex justify-content-space-between">
                    {/* {steps &&
                      steps.map((data, i) => {
                        return (
                          <li className="nav-item col-2" key={i}>
                            <a
                              className={`nav-link nm${data?.number} ${i === 0 ? 'active' : '' }`}
                              
                              data-toggle="tooltip"
                              data-placement="top"
                              // title="General Information"
                              onClick={()=>{handleNumber(i+1)}}
                            >
                              <span>
                                {data?.number}
                                <img src="images/check-w.png" alt="" />
                              </span>
                              <p className="d-none d-sm-block">{data?.title}</p>
                            </a>
                          </li>
                        );
                      })} */}

                    {/* nav-link nm1 active */}
                    {/* step 1 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm1 active"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="General Information"
                      >
                        <span>
                          1<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          General
                          <br />
                          Information
                        </p>
                      </a>
                    </li>
                    {/* step 2 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Income from salary"
                      >
                        <span>
                          2<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          Income from salary
                          <br />
                          (Including Pension)
                        </p>
                      </a>
                    </li>
                    {/* step 3 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm3"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Income from other sources"
                      >
                        <span>
                          3<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          Income from
                          <br />
                          other sources
                        </p>
                      </a>
                    </li>
                    {/* step 4 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm4"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Income from House Property"
                      >
                        <span>
                          4<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          Income from
                          <br />
                          House Property
                        </p>
                      </a>
                    </li>
                    {/* step 5 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm5"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Deduction / Investment"
                      >
                        <span>
                          5<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          Deduction / Investment
                        </p>
                      </a>
                    </li>
                    {/* step 6 title */}
                    <li className="nav-item col-2">
                      <a
                        className="nav-link nm6"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Bank and other information"
                      >
                        <span>
                          6<img src="images/check-w.png" alt="" />
                        </span>
                        <p className="d-none d-sm-block">
                          Bank and other information
                        </p>
                      </a>
                    </li>
                  </ul>

                  <form onSubmit={handleSubmit}>
                    <div className="step-details step-form">
                      {/*Step-1  */}
                      <div className={`step-${number}`}>
                        <div className="row">
                          <div className="col-sm-6 col-lg-4 col-xl-3">
                            {/* name field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Name (as per PAN card)
                              </label>
                              <input
                                type="text"
                                name="namePanCard"
                                className={`form-control ${isName !== undefined
                                  ? isName
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                placeholder="Enter your name"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div className="invalid-feedback">
                                Name should not be null
                              </div>
                            </div>
                            {/* email field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Email address
                              </label>
                              <input
                                type="email"
                                name="email"
                                className={`form-control ${isEmail !== undefined
                                  ? isEmail
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                required
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div className="invalid-feedback">
                                Incorrect email format
                              </div>
                            </div>

                            {/* pan card field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">Pan Card</label>
                              <div>
                                <input
                                  type="text"
                                  name="panNumber"
                                  className={`form-control ${isMobile !== undefined
                                    ? isMobile
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  required
                                  onChange={inputHandle}
                                  onBlur={inputValidation}
                                />
                              </div>
                              <div>
                                <input
                                  type="file"
                                  name="panCardDocument"
                                  className={`form-control ${isPanDocument !== undefined
                                    ? isPanDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add pan card"
                                  onChange={inputHandle}
                                  onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="invalid-feedback">
                                Pan Card is required
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                            {/* passport number field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Passport Number
                              </label>
                              <input
                                type="text"
                                name="passportNumber"
                                className={`form-control ${isPassport !== undefined
                                  ? isPassport
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                placeholder="Enter passport number"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div className="invalid-feedback">
                                Incorrect Passport number format
                              </div>
                            </div>
                            {/* mobile number field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">
                                Mobile Number
                              </label>
                              <input
                                type="number"
                                name="mobileNumber"
                                className={`form-control ${isMobile !== undefined
                                  ? isMobile
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                required
                                onChange={inputHandle}
                                onBlur={inputValidation}
                              />
                              <div className="invalid-feedback">
                                Incorrect Mobile number
                              </div>
                            </div>
                            {/* adhard card field */}
                            <div className="form-group bottom-space">
                              <label className="label-style">Adhar Card</label>
                              <div>
                                <input
                                  type="text"
                                  name="aadharNumber"
                                  className={`form-control ${isMobile !== undefined
                                    ? isMobile
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  required
                                  onChange={inputHandle}
                                  onBlur={inputValidation}
                                />
                              </div>
                              <div>
                                <input
                                  type="file"
                                  name="adharCardDocument"
                                  className={`form-control ${isAdharDocument !== undefined
                                    ? isAdharDocument
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Add pan card"
                                  onChange={inputHandle}
                                  onBlur={inputValidation}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invalid-feedback">
                                Adhar Card is required
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="step-nav-box ">
                          <a
                            
                            className="float-right step-1n"
                            style={{ float: "right" }}
                            onClick={handleSubmit}
                          >
                            Next <img src="images/right-arrow-b.png" />
                          </a>
                        </div> */}
                        {isName !== undefined ||
                          isEmail !== undefined ||
                          isMobile !== undefined ||
                          isPassport !== undefined ||
                          isPanDocument !== undefined ||
                          isAdharDocument !== undefined ? (
                          !isName ||
                            !isEmail ||
                            !isMobile ||
                            !isPassport ||
                            !isPanDocument ||
                            !isAdharDocument ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Please fill all epmty fileds...!
                            </p>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <Button
                          className="nm2"
                          id="saveInformation"
                          variant="contained"
                          style={{
                            borderRadius: "25px",
                            background: "#AE0721",
                          }}
                          onClick={() => checkValidationStep1()}
                          endIcon={<SendIcon />}
                          disabled={IsEnable} // Disable the button when isSendingOTP is true
                        >
                          Save & Next
                        </Button>
                      </div>
                    </div>

                    {/*Step-2 */}
                    <div className="step-2 d-none step-details step-form">
                      <div className="row">
                        <div className="col-sm-6 col-lg-4 col-xl-4">
                          <div className="form-group bottom-space">
                            {/* Radio button */}
                            <div className="custom-control custom-radio lg">
                              <input
                                type="radio"
                                id="customRadio1"
                                name="customRadio"
                                className="custom-control-input"
                                value="radio1"
                                checked={isRadio1Selected}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="custom-control-label"
                                for="customRadio1"
                              >
                                I have Salary Sleep or Form 16
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-9">
                              {/* form 16 field */}
                              <div
                                className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                  }`}
                              >
                                <label className="label-style">Form 16</label>
                                <input
                                  type="file"
                                  name="form16File"
                                  className={`form-control ${isForm16File !== undefined
                                    ? isForm16File
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="From 16"
                                  onChange={inputHandle}
                                  onBlur={inputValidation}
                                  autoComplete="off"
                                />
                                <div className="invalid-feedback">
                                  Form 16 is required
                                </div>
                              </div>
                              {/* Any other exepmtion to be claimed */}
                              <div
                                className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                  }`}
                              >
                                <label className="label-style">
                                  Any other Exemption to be claimed
                                </label>
                                <div className="input-group mb-2 mr-sm-2">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text rsicon">
                                      ₹
                                    </div>
                                  </div>
                                  <input
                                    type="number"
                                    name="anyOtherExemptionToBeClaimed"
                                    className={`form-control ${isanyOtherExemptionToBeClaimed !==
                                      undefined
                                      ? isanyOtherExemptionToBeClaimed
                                        ? "is-valid"
                                        : "is-invalid"
                                      : ""
                                      }`}
                                    id=""
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    placeholder="Enter amount in Rupee"
                                    autoComplete="off"
                                    value={
                                      data.anyOtherExemptionToBeClaimed
                                        ? data.anyOtherExemptionToBeClaimed
                                        : ""
                                    }
                                  />
                                  <br />
                                </div>
                                {isanyOtherExemptionToBeClaimed !== undefined &&
                                  !isanyOtherExemptionToBeClaimed && (
                                    <div style={{ color: "red" }}>
                                      If not enter zero (0) but should not be
                                      empty
                                    </div>
                                  )}
                              </div>
                              {/* Exemption related documentary */}
                              {data.anyOtherExemptionToBeClaimed > 0 && (
                                <div
                                  className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                    }`}
                                >
                                  <label className="label-style">
                                    Exemption related documentary
                                  </label>
                                  <input
                                    type="file"
                                    name="exemptionRelatedDocuments"
                                    className={`form-control ${isexemptionRelatedDocuments !== undefined
                                      ? isexemptionRelatedDocuments
                                        ? "is-valid"
                                        : "is-invalid"
                                      : ""
                                      }`}
                                    placeholder="From 16"
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    autoComplete="off"
                                  />
                                  <div className="invalid-feedback">
                                    Exemption related document is required
                                  </div>
                                </div>
                              )}
                              {/* remark in radio button 1 */}
                              <div
                                className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                  }`}
                              >
                                <label className="label-style">
                                  Remarks if any
                                </label>
                                <input
                                  type="text"
                                  name="remarks"
                                  className={`form-control ${isRemarks !== undefined
                                    ? isRemarks
                                      ? "is-valid"
                                      : "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="use dash ( - ) if nothin"
                                  onChange={inputHandle}
                                  onBlur={inputValidation}
                                  autoComplete="off"
                                  value={data.remarks ? data.remarks : ""}
                                />
                                <div className="invalid-feedback">
                                  use dash ( - ) if nothing
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-4">
                          <div className="form-group bottom-space">
                            {/* radion button 2 */}
                            <div className="custom-control custom-radio lg">
                              <input
                                type="radio"
                                id="customRadio2"
                                name="customRadio"
                                className="custom-control-input"
                                value="radio2"
                                checked={!isRadio1Selected}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="custom-control-label"
                                for="customRadio2"
                              >
                                I don’t have Salary Sleep or Form 16
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-9">
                              {/* Annual Salary Pension filed */}
                              <div
                                className={`form-group bottom-space ${!isRadio1Selected ? "" : "disabled"
                                  }`}
                              >
                                <label className="label-style">
                                  Annual Salary/Pension
                                </label>
                                <div className="input-group mb-2 mr-sm-2">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text rsicon">
                                      ₹
                                    </div>
                                  </div>
                                  <input
                                    type="number"
                                    name="annualSalary"
                                    className={`form-control ${isAnnualSalary !== undefined
                                      ? isAnnualSalary
                                        ? "is-valid"
                                        : "is-invalid"
                                      : ""
                                      }`}
                                    id=""
                                    placeholder="Enter amount in rupee"
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    autoComplete="off"
                                    value={
                                      data.annualSalary ? data.annualSalary : ""
                                    }
                                  />
                                </div>
                                {isAnnualSalary !== undefined &&
                                  !isAnnualSalary && (
                                    <div style={{ color: "red" }}>
                                      If not enter zero (0) but should not be
                                      empty
                                    </div>
                                  )}
                              </div>
                              {/* Name of the employer field */}
                              {data.annualSalary > 0 && (
                                <div
                                  className={`form-group bottom-space ${!isRadio1Selected ? "" : "disabled"
                                    }`}
                                >
                                  <label className="label-style">
                                    Name of the employer
                                  </label>
                                  <input
                                    type="text"
                                    name="nameOfEmployer"
                                    className={`form-control ${isNameOfEmployer !== undefined
                                      ? isNameOfEmployer
                                        ? "is-valid"
                                        : "is-invalid"
                                      : ""
                                      }`}
                                    id=""
                                    placeholder="Enter name of employer"
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    autoComplete="off"
                                    value={
                                      data.nameOfEmployer
                                        ? data.nameOfEmployer
                                        : ""
                                    }
                                  />
                                  <div className="invalid-feedback">
                                    Name should not be null
                                  </div>
                                </div>
                              )}
                              {/* Pan of the employer */}
                              {/* {data.annualSalary>0 &&<div
                                className={`form-group bottom-space ${
                                  !isRadio1Selected ? "" : "disabled"
                                }`}
                              >
                                <label className="label-style">
                                  PAN of the employer
                                </label>
                                
                                  <input
                                    type="text"
                                    name="panOfEmployer"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter amount in rupee"
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    autoComplete="off"
                                  />
                                  <div className="invalid-feedback">
                                Name of the employer is required
                              </div>
                              </div>} */}
                              {/* other Attachments field for pan */}
                              {data.annualSalary > 0 && (
                                <div
                                  className={`form-group bottom-space ${!isRadio1Selected ? "" : "disabled"
                                    }`}
                                >
                                  <label className="label-style">
                                    Pan of Employer
                                  </label>
                                  <input
                                    type="file"
                                    name="panOfEmployer"
                                    className={`form-control ${isPanOfEmployer !== undefined
                                      ? isPanOfEmployer
                                        ? "is-valid"
                                        : "is-invalid"
                                      : ""
                                      }`}
                                    placeholder="From 16"
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    autoComplete="off"
                                  />
                                  <div className="invalid-feedback">
                                    Pan of Employer is required
                                  </div>
                                </div>
                              )}
                              {/* Remark field */}
                              {data.annualSalary > 0 && (
                                <div
                                  className={`form-group bottom-space ${!isRadio1Selected ? "" : "disabled"
                                    }`}
                                >
                                  <label className="label-style">
                                    Remarks (if any)
                                  </label>
                                  <input
                                    type="text"
                                    name="remarks2"
                                    className={`form-control ${isRemarks2 !== undefined
                                      ? isRemarks2
                                        ? "is-valid"
                                        : "is-invalid"
                                      : ""
                                      }`}
                                    placeholder="use dash ( - ) if nothin"
                                    onChange={inputHandle}
                                    onBlur={inputValidation}
                                    autoComplete="off"
                                    value={data.remarks2 ? data.remarks2 : ""}
                                  />
                                  <div className="invalid-feedback">
                                    use dash ( - ) if nothing
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button
                        className="nm3"
                        id="saveInformation"
                        variant="contained"
                        style={{ borderRadius: "25px", background: "#AE0721" }}
                        onClick={() => checkValidationStep2()}
                        endIcon={<SendIcon />}
                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                      >
                        Save & Next
                      </Button>
                    </div>

                    {/*Step-3 */}
                    <div className="step-3 d-none step-details step-form">
                      <div className="row">
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          {/* Saving bank interest field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Saving Bank Interest
                            </label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text rsicon">₹</div>
                              </div>
                              <input
                                type="text"
                                name="savingBankInterest"
                                className={`form-control ${isSavingBankInterest !== undefined
                                  ? isSavingBankInterest
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                id=""
                                placeholder="Enter amount"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                            </div>
                            {isSavingBankInterest !== undefined &&
                              !isSavingBankInterest && (
                                <div style={{ color: "red" }}>
                                  should not be empty
                                </div>
                              )}
                          </div>
                          {/* Interest on fixed deposit field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Interest on fixed deposit
                            </label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text rsicon">₹</div>
                              </div>
                              <input
                                type="text"
                                name="interestOnFD"
                                className={`form-control ${isInterestOnFD !== undefined
                                  ? isInterestOnFD
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                id=""
                                placeholder="Enter amount"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                              />
                            </div>
                            {isInterestOnFD !== undefined &&
                              !isInterestOnFD && (
                                <div style={{ color: "red" }}>
                                  should not be empty
                                </div>
                              )}
                            {/* Interest on fixed fixed deposit file field */}
                            <div className="form-group bottom-space">
                              <input
                                type="file"
                                name="interestOnFDFile"
                                className="form-control"
                                // className={`form-control ${
                                //   isInterestOnFDFile !== undefined
                                //     ? isInterestOnFDFile
                                //       ? "is-valid"
                                //       : "is-invalid"
                                //     : ""
                                // }`}
                                placeholder="interest On FD File"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div
                                style={{ fontSize: "13px", color: "red" }}
                                className="invalid-feedback"
                              >
                                Fd Interest file required
                              </div>
                            </div>
                          </div>
                          {/* Interest on recurring deposit field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Interest on recurring deposit
                            </label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text rsicon">₹</div>
                              </div>
                              <input
                                type="text"
                                name="interestOnRD"
                                className="form-control"
                                id=""
                                placeholder="Enter amount"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                              />
                            </div>
                            {isInterestOnRD !== undefined &&
                              !isInterestOnRD && (
                                <div style={{ color: "red" }}>
                                  should not be empty
                                </div>
                              )}
                            {/* Interest on recurring deposit file field */}
                            <div className="form-group bottom-space">
                              <input
                                type="file"
                                name="interestOnRDFile"
                                className="form-control"
                                // className={`form-control ${
                                //   isInterestOnRDFile !== undefined
                                //     ? isInterestOnRDFile
                                //       ? "is-valid"
                                //       : "is-invalid"
                                //     : ""
                                // }`}
                                placeholder="interest On FD File"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div
                                style={{ fontSize: "13px", color: "red" }}
                                className="invalid-feedback"
                              >
                                RD Interest file required
                              </div>
                            </div>
                          </div>
                          {/* Expense if any in respect of income field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Expense if any in respect of income
                            </label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text rsicon">₹</div>
                              </div>
                              <input
                                type="text"
                                name="expenseIfAnyInRespectOfIncome"
                                className={`form-control ${isExpenseIfAnyInRespectOfIncome !== undefined
                                  ? isExpenseIfAnyInRespectOfIncome
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                id=""
                                placeholder="Enter amount in Rupee"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                            </div>
                            {isExpenseIfAnyInRespectOfIncome !== undefined &&
                              !isExpenseIfAnyInRespectOfIncome && (
                                <div style={{ color: "red" }}>
                                  should not be empty
                                </div>
                              )}
                          </div>
                          {/* Expense if any in respect of above income field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Expense if any in respect of above income
                            </label>
                            <textarea
                              className={`form-control ${isExpenseIfAnyInRespectOfAboveIncome !==
                                undefined
                                ? isExpenseIfAnyInRespectOfAboveIncome
                                  ? "is-valid"
                                  : "is-invalid"
                                : ""
                                }`}
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="expenseIfAnyInRespectOfAboveIncome"
                              placeholder="Description"
                              onChange={inputHandle}
                              onBlur={inputValidation}
                              autoComplete="off"
                            ></textarea>
                            <div
                              style={{ fontSize: "13px", color: "red" }}
                              className="invalid-feedback"
                            >
                              If not use dash ( - ) instead
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                          {/* Add another income field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Add Other Income
                            </label>
                            <Select
                              name="addOtherIncome"
                              options={OtherIncome}
                              onChange={handleDropdown}
                              onBlur={inputValidation}
                              styles={{ width: "100%" }}
                            />
                          </div>
                          {/* If another div required from above selction */}
                          {isAnotherDiv && (
                            <div className="form-group bottom-space">
                              <input
                                type="file"
                                name="anotherIncomeDocument"
                                className={`form-control ${isAnotherIncomeDocument !== undefined
                                  ? isAnotherIncomeDocument
                                    ? "is-valid"
                                    : "is-invalid"
                                  : ""
                                  }`}
                                placeholder="another income document"
                                onChange={inputHandle}
                                onBlur={inputValidation}
                                autoComplete="off"
                              />
                              <div
                                style={{ fontSize: "13px", color: "red" }}
                                className="invalid-feedback"
                              >
                                Another Income document is required
                              </div>
                            </div>
                          )}
                          {/* Remarks field */}
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Remarks if any
                            </label>
                            <input
                              type="text"
                              className={`form-control ${isRemarks3 !== undefined
                                ? isRemarks3
                                  ? "is-valid"
                                  : "is-invalid"
                                : ""
                                }`}
                              placeholder="Type here"
                              name="remarks3"
                              onChange={inputHandle}
                              onBlur={inputValidation}
                              autoComplete="off"
                            />
                            <div
                              style={{ fontSize: "13px", color: "red" }}
                              className="invalid-feedback"
                            >
                              if not use dash ( - ) instead
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button
                        className="nm4"
                        id="saveInformation"
                        variant="contained"
                        style={{ borderRadius: "25px", background: "#AE0721" }}
                        onClick={() => checkValidationStep3()}
                        endIcon={<SendIcon />}
                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                      >
                        Save & Next
                      </Button>
                    </div>

                    {/*Step-4 */}
                    <div className="step-4 d-none step-details step-form">
                      <div className="row" style={{ justifyContent: "center" }}>
                        {/* <div className="col-sm-12 col-md-4 col-lg-5 col-xl-6">
                          {inputHouseProperty === 0 ?
                            <Button
                              id=""
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                background: "#AE0721",
                              }}
                              startIcon={
                                isHouseProperty ? (
                                  <CancelIcon />
                                ) : (
                                  <AddCircleOutlineIcon />
                                )
                              }
                              onClick={() => checkHouseProperty(1)}
                            >
                              {"Do you have House property"}
                            </Button> : 
                            inputHouseProperty === 1 ? 
                            <>
                              <Stack spacing={2} direction="column">
                              <TextField type="number" id="standard-basic" label="Enter house property count" variant="standard" onChange={(e)=>{setIsHouseProperty(false);setHousePropertyMapCount(e.target.value)}}/>
                              </Stack>
                              <br/>
                              <Stack spacing={2} direction="row" sx={{justifyContent:"space-between"}}>
                              <Button
                                id=""
                                variant="outlined"
                                onClick={()=>{setHousePropertyMapCount(0);setIsHouseProperty(false);checkHouseProperty(0)}}
                              >
                                {"Cancel"}
                              </Button>
                              <Button
                                id=""
                                variant="outlined"
                                onClick={()=>{addHouseProperty();checkHouseProperty(0)}}
                              >
                                {"Submit"}
                              </Button>
                              </Stack>
                            </> : ''
                          }
                        </div> */}
                        <div className="row">
                          <div className="col">
                            <input
                              type="radio"
                              id="step4Radio1"
                              name="step4Radio1"
                              className="custom-control-input"
                              value="radio1Step4"
                              checked={isRadio1Step4Selected}
                              onChange={handleRadioStep4Change}
                            />
                            <label
                              className="custom-control-label"
                              for="step4Radio1"
                            >
                              I don't have House Property
                            </label>
                          </div>
                          <div className="col">
                            <input
                              type="radio"
                              id="step4Radio2"
                              name="step4Radio1"
                              className="custom-control-input"
                              value="radio2Step4"
                              checked={!isRadio1Step4Selected}
                              onChange={handleRadioStep4Change}
                            />
                            <label
                              className="custom-control-label"
                              for="step4Radio2"
                            >
                              I have House Property
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        {!isRadio1Step4Selected && (
                          <div className="row">
                            <div className="col">
                              <div className={`form-group bottom-space`}>
                                <label className="label-style">
                                  Enter House Property Count
                                </label>
                                <br />
                                <br />
                                <div>
                                  <span
                                    style={{
                                      border: "1px solid gray",
                                      padding: "10px",
                                    }}
                                  >
                                    <RemoveIcon
                                      onClick={handleHousePropertyMinus}
                                    />
                                  </span>
                                  <input
                                    type="number"
                                    value={housePropertyMapCount}
                                    onChange={handleHouseProperty}
                                    style={{
                                      height: "38px",
                                      width: "50px",
                                      border: "1px solid gray",
                                      textAlign: "center",
                                    }}
                                  />
                                  <span
                                    style={{
                                      border: "1px solid gray",
                                      padding: "10px",
                                    }}
                                  >
                                    <AddIcon
                                      onClick={handleHousePropertyPlus}
                                    />
                                  </span>
                                </div>
                                {/* <div className="input-group mb-2 mr-sm-2" style={{width:"40%"}}>
                                  <input
                                    type="number"
                                    name="HousePropertyInput"
                                    className={`form-control`}
                                    id=""
                                    placeholder="House property count"
                                    onChange={handleHouseProperty}
                                    autoComplete="off"
                                    value={housePropertyMapCount}
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <br />
                      <br />
                      <div className="row">
                        {/* Using a loop to render the div multiple times */}
                        {!isRadio1Step4Selected &&
                          Array.from({ length: housePropertyMapCount }).map(
                            (_, index) => (
                              <div className="col-sm-12 col-lg-6 col-xl-6">
                                <div className="form-group bottom-space">
                                  <h5 className="sub-title-xxs">
                                    House Property
                                    <span style={{ color: "#AE0721" }}>
                                      {" "}
                                      {index + 1}
                                    </span>
                                  </h5>
                                </div>
                                <div className="form-group bottom-space">
                                  {/* for toggle used this class -> imgtoggle in belows anchor tag but it toggles multiple times*/}
                                  <p className="form-title clearfix ">
                                    {/* <img src="images/minus.png" className="minus" />
                              <img
                                src="images/plus.png"
                                className="plus"
                              />{" "} */}
                                    Basic details
                                  </p>
                                  <div className="form-details clearfix displayBlock">
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Address
                                      </label>
                                      <textarea
                                        name={`address${index + 1}`}
                                        className={`form-control`}
                                        id="exampleFormControlTextarea1"
                                        rows="2"
                                        placeholder="Enter address"
                                        onChange={inputHandleHouseProperty}
                                        onBlur={inputValidation}
                                      ></textarea>
                                      {/* <div
                              style={{ fontSize: "13px", color: "red" }}
                              className="invalid-feedback"
                            >
                              Address is required
                            </div> */}
                                    </div>

                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Is the house co-owned?
                                      </label>
                                      <div className="clearfix">
                                        <div className="custom-control custom-radio custom-control-inline xs">
                                          <input
                                            type="radio"
                                            id="customRadioInline1"
                                            name={`coOwned${index + 1}`}
                                            className="custom-control-input"
                                            value="Yes"
                                            onChange={inputHandleHouseProperty}
                                          />
                                          <label
                                            className="custom-control-label"
                                            for="customRadioInline1"
                                          >
                                            Yes
                                          </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline xs">
                                          <input
                                            type="radio"
                                            id="customRadioInline2"
                                            name={`coOwned${index + 1}`}
                                            className="custom-control-input"
                                            value="No"
                                            onChange={inputHandleHouseProperty}
                                          />
                                          <label
                                            className="custom-control-label"
                                            for="customRadioInline2"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Type of house property
                                      </label>
                                      <div className="clearfix">
                                        <div className="custom-control custom-radio custom-control-inline xs">
                                          <input
                                            type="radio"
                                            id="customRadioInline3"
                                            name={`typeOfHouseProperty${index + 1
                                              }`}
                                            className="custom-control-input"
                                            value="Self-Occupied"
                                            onChange={inputHandleHouseProperty}
                                          />
                                          <label
                                            className="custom-control-label"
                                            for="customRadioInline3"
                                          >
                                            Self-Occupied
                                          </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline xs">
                                          <input
                                            type="radio"
                                            id="customRadioInline4"
                                            name={`typeOfHouseProperty${index + 1
                                              }`}
                                            className="custom-control-input"
                                            value="Let Out"
                                            onChange={inputHandleHouseProperty}
                                          />
                                          <label
                                            className="custom-control-label"
                                            for="customRadioInline4"
                                          >
                                            Let Out
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group bottom-space">
                                  {/* for toggle used this class -> imgtoggle in belows anchor tag but it toggles multiple times*/}
                                  <p className="form-title">
                                    {/* <img src="images/minus.png" className="minus" />
                              <img src="images/plus.png" className="plus" /> */}
                                    Rental and tenant details
                                  </p>
                                  <div className="form-details clearfix displayBlock">
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Name of the tenant
                                      </label>
                                      <input
                                        type="text"
                                        name={`nameOfTenant${index + 1}`}
                                        className="form-control"
                                        placeholder="Enter name"
                                        onChange={inputHandleHouseProperty}
                                      />
                                    </div>
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        PAN of the tenant
                                      </label>
                                      <input
                                        type="text"
                                        name={`panOfTenant${index + 1}`}
                                        className="form-control"
                                        placeholder="Enter PAN number"
                                        onChange={inputHandleHouseProperty}
                                      />
                                    </div>
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Annual rent received
                                      </label>
                                      <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text rsicon">
                                            ₹
                                          </div>
                                        </div>
                                        <input
                                          type="number"
                                          name={`annualRent${index + 1}`}
                                          className="form-control"
                                          id=""
                                          placeholder="Enter Amount"
                                          onChange={inputHandleHouseProperty}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Municipal tax paid
                                      </label>
                                      <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text rsicon">
                                            ₹
                                          </div>
                                        </div>
                                        <input
                                          type="number"
                                          name={`municipalTax${index + 1}`}
                                          className="form-control"
                                          id=""
                                          placeholder="Enter Amount"
                                          onChange={inputHandleHouseProperty}
                                        />
                                      </div>
                                      <label
                                        className="btn btn-primary browse-btn btn-min-width"
                                        style={{ width: "100%" }}
                                      >
                                        Attach Documents{" "}
                                        <input
                                          type="file"
                                          name={`municipalTaxPaidFile${index + 1
                                            }`}
                                          style={{ display: "none" }}
                                          onChange={inputHandleHouseProperty}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group bottom-space">
                                  {/* for toggle used this class -> imgtoggle in belows anchor tag but it toggles multiple times*/}
                                  <p className="form-title ">
                                    {/* <img src="images/minus.png" className="minus" />
                              <img src="images/plus.png" className="plus" /> */}
                                    Loan details
                                  </p>

                                  <div className="form-details clearfix displayBlock">
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Interest on housing loan
                                      </label>
                                      <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text rsicon">
                                            ₹
                                          </div>
                                        </div>
                                        <input
                                          type="number"
                                          name={`interestOnHousingLoan${index + 1
                                            }`}
                                          className="form-control"
                                          id=""
                                          placeholder="Enter Amount"
                                          onChange={inputHandleHouseProperty}
                                        />
                                      </div>
                                      <label
                                        className="btn btn-primary browse-btn btn-min-width"
                                        style={{ width: "100%" }}
                                      >
                                        Attach Documents{" "}
                                        <input
                                          type="file"
                                          name={`interestOnHousingLoanFile${index + 1
                                            }`}
                                          style={{ display: "none" }}
                                          onChange={inputHandleHouseProperty}
                                        />
                                      </label>
                                    </div>
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Principal Repaid
                                      </label>
                                      <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text rsicon">
                                            ₹
                                          </div>
                                        </div>
                                        <input
                                          type="number"
                                          name={`princiaplRepaid${index + 1}`}
                                          className="form-control"
                                          id=""
                                          placeholder="Enter Amount"
                                          onChange={inputHandleHouseProperty}
                                        />
                                      </div>
                                      <label
                                        className="btn btn-primary browse-btn btn-min-width"
                                        style={{ width: "100%" }}
                                      >
                                        Attach Documents{" "}
                                        <input
                                          type="file"
                                          name={`principalRepaidFile${index + 1
                                            }`}
                                          style={{ display: "none" }}
                                          onChange={inputHandleHouseProperty}
                                        />{" "}
                                      </label>
                                    </div>

                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Remarks
                                      </label>
                                      <textarea
                                        className="form-control"
                                        name={`remarks4${index + 1}`}
                                        id="exampleFormControlTextarea1"
                                        rows="2"
                                        placeholder="Ad comment if any"
                                        onChange={inputHandleHouseProperty}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    {!isRadio1Step4Selected &&
                      housePropertyMapCount >= 1 &&
                      !isBtn && (
                        <Button
                          className="nm5"
                          id="openDialogueBtn"
                          variant="contained"
                          style={{
                            borderRadius: "25px",
                            background: "#AE0721",
                          }}
                          onClick={() => checkValidationStep4()}
                          endIcon={<SendIcon />}
                          disabled={IsEnable} // Disable the button when isSendingOTP is true
                        >
                          Save & Next
                        </Button>
                      )}
                    {isBtn && (
                      <Button
                        className="nm5"
                        id="openDialogueBtn0"
                        variant="contained"
                        style={{ borderRadius: "25px", background: "#AE0721" }}
                        onClick={() => checkValidationStep4(true)}
                        endIcon={<SendIcon />}
                        disabled={IsEnable} // Disable the button when isSendingOTP is true
                      >
                        Save & Next
                      </Button>
                    )}
                    <br />
                    <br />
                    {/* {isHouseProperty && <Button
                        className="nm4"
                        id="saveInformation"
                        variant="contained"
                        style={{ borderRadius: "25px", background: "#AE0721" }}
                        startIcon={<AddCircleOutlineIcon/>}
                        onClick={() => checkValidationStep3()}
                      >
                        Add another House Property
                      </Button>} */}

                    {/* Dialogue box for house property message */}
                    {/* <Dialog
                      open={isOpenDialogue}
                      onClose={closeDialogue}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          If you have another HOUSE PROPERTY 
                          click on YES and fill it again
                          If NO click on Submit
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={()=>{closeDialogue();addHouseProperty();setHousePropertyMapCount(0);setIsHouseProperty(false)}}>
                          YES</Button>
                        <Button onClick={()=>{setIsOpenDialogue(false)}}>
                          Submit
                        </Button>
                      </DialogActions>
                    </Dialog> */}

                    {/*Step-5 */}
                    <div className="step-5 d-none">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group bottom-space">
                            <label className="label-style">
                              Do you have any other deduction/investments other
                              than from 16?
                            </label>
                            <div className="clearfix">
                              <div className="custom-control custom-radio custom-control-inline xs">
                                <input
                                  type="radio"
                                  id="customRadioInline6"
                                  name="otherDeductions"
                                  className="custom-control-input"
                                  value="Yes"
                                  onChange={inputHandle}
                                />
                                <label
                                  className="custom-control-label"
                                  for="customRadioInline6"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline xs">
                                <input
                                  type="radio"
                                  id="customRadioInline11"
                                  name="otherDeductions"
                                  className="custom-control-input"
                                  value="No"
                                  onChange={inputHandle}
                                />
                                <label
                                  className="custom-control-label"
                                  for="customRadioInline11"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {/* <div className="col-sm-12">
                              <p className="form-big-lab">
                                <img src="images/greencheck.png" /> Excel File
                                has been sent to your registered email id{" "}
                                <a>abc@gmail.com</a> please fill the info and
                                upload here.
                              </p>
                            </div> */}

                            {/* If any other deduction / investments */}
                            {data?.otherDeductions === "Yes" && (
                              <div className="col-sm-12">
                                <form className="form-inline">
                                  <div
                                    className={`form-group bottom-space ${isRadio1Selected ? "" : "disabled"
                                      }`}
                                  >
                                    <input
                                      type="file"
                                      name="excelFile"
                                      className={`form-control ${isExcelFile !== undefined
                                        ? isExcelFile
                                          ? "is-valid"
                                          : "is-invalid"
                                        : ""
                                        }`}
                                      placeholder="From 16"
                                      onChange={inputHandle}
                                      onBlur={inputValidation}
                                      autoComplete="off"
                                    />
                                    <div className="invalid-feedback">
                                      Form 16 is required
                                    </div>
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <br />
                      {/* for display none use this class -> d-none */}
                      <div className="step-nav-box">
                        <Button
                          className="nm6"
                          id="saveInformation"
                          variant="contained"
                          style={{
                            borderRadius: "25px",
                            background: "#AE0721",
                          }}
                          onClick={() => checkValidationStep5()}
                          endIcon={<SendIcon />}
                          disabled={IsEnable} // Disable the button when isSendingOTP is true
                        >
                          Save & Next
                        </Button>
                      </div>
                    </div>

                    {/*Step-6 */}
                    <div className="step-6 d-none">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                              <div className="form-group bottom-space">
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Bank 1
                                </label>
                                <br />
                                <label className="label-style">
                                  Select Bank
                                </label>
                                <Select
                                  name="bank1"
                                  options={Banks}
                                  onChange={handleBankAccountDropdown}
                                />
                              </div>
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Bank Account Number
                                </label>
                                <input
                                  type="number"
                                  name="bankAccountNumber1"
                                  className="form-control"
                                  placeholder="Enter bank account number"
                                  onChange={inputHandleBankAccount}
                                />
                              </div>
                              <div className="form-group">
                                <label className="label-style">IFSC Code</label>
                                <input
                                  type="text"
                                  name="ifscode1"
                                  className="form-control"
                                  placeholder="Enter IFSC code"
                                  onChange={inputHandleBankAccount}
                                  value={bankAccountData?.[`ifscode1`]}
                                />
                                <div className="invalid-feedback">
                                  Can not allowed special characters like
                                  @,#,$...etc.
                                </div>
                              </div>
                              <br />
                            </div>
                            {/* <div className="col-sm-6 col-lg-4 col-xl-3 offset-xl-1">
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Select Bank
                                </label>
                                <select className="form-control" id="">
                                  <option>Select bank from list</option>
                                </select>
                              </div>
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Bank Account Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter bank account number"
                                />
                              </div>
                              <div className="form-group">
                                <label className="label-style">IFCS Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter IFCS code"
                                />
                                <div className="mtp10">
                                  or <b>Find IFSC Code here</b>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          {/* dynamic add bank account */}
                          <div className="row">
                            {bankAccountMapCount > 0 &&
                              Array.from({ length: bankAccountMapCount }).map(
                                (_, index) => (
                                  <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <label
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Bank {index + 2}
                                    </label>
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Select Bank
                                      </label>
                                      <Select
                                        name={`bank${index + 2}`}
                                        options={Banks}
                                        onChange={handleBankAccountDropdown}
                                      />
                                    </div>
                                    <div className="form-group bottom-space">
                                      <label className="label-style">
                                        Bank Account Number
                                      </label>
                                      <input
                                        type="number"
                                        name={`bankAccountNumber${index + 2}`}
                                        className="form-control"
                                        placeholder="Enter bank account number"
                                        onChange={inputHandleBankAccount}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="label-style">
                                        IFSC Code
                                      </label>
                                      <input
                                        type="text"
                                        name={`ifscode${index + 2}`}
                                        className="form-control"
                                        // className={`form-control ${
                                        //   isIFSC !== undefined
                                        //     ? isIFSC
                                        //       ? "is-valid"
                                        //       : "is-invalid"
                                        //     : ""
                                        // }`}
                                        placeholder="Enter IFSC code"
                                        onChange={inputHandleBankAccount}
                                        value={
                                          bankAccountData?.[
                                          `ifscode${index + 2}`
                                          ]
                                        }
                                      />
                                      <div className="invalid-feedback">
                                        Can not allowed special characters like
                                        @,#,$...etc.
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                )
                              )}
                          </div>
                          <div className="row">
                            <div className="col">
                              <a
                                className="add-link mt-md-4 ml-md-4"
                                style={{ cursor: "pointer" }}
                                onClick={AddBankAccount}
                              >
                                <AddIcon />
                                Add Bank Account{" "}
                              </a>
                            </div>
                            {bankAccountMapCount > 0 && (
                              <div className="col">
                                <a
                                  className="add-link mt-md-4 ml-md-4"
                                  style={{ cursor: "pointer" }}
                                  onClick={RemoveBankAccount}
                                >
                                  <RemoveIcon />
                                  Remove Bank Account{" "}
                                </a>
                              </div>
                            )}
                          </div>

                          <hr />
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Are you governed by Portuguese Civil Code as
                                  per section 5A?
                                </label>
                                <div className="clearfix">
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline7"
                                      name="governedByPortugueseCivil"
                                      className="custom-control-input"
                                      value="Yes"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline7"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline8"
                                      name="governedByPortugueseCivil"
                                      className="custom-control-input"
                                      value="No"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline8"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Do you want to claim benefits under section
                                  115H?
                                </label>
                                <div className="clearfix">
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline9"
                                      name="claimBenefits"
                                      className="custom-control-input"
                                      value="Yes"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline9"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline10"
                                      name="claimBenefits"
                                      className="custom-control-input"
                                      value="No"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline10"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Wether you are director in a company at any
                                  time during the previous year?
                                </label>
                                <div className="clearfix">
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline12"
                                      name="directorAtAnyCompany"
                                      className="custom-control-input"
                                      value="Yes"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline12"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline13"
                                      name="directorAtAnyCompany"
                                      className="custom-control-input"
                                      value="No"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline13"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group bottom-space">
                                <label className="label-style">
                                  Wether you are held unlisted at any time
                                  during the previous year?
                                </label>
                                <div className="clearfix">
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline14"
                                      name="heldUnlisted"
                                      className="custom-control-input"
                                      value="Yes"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline14"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline xs">
                                    <input
                                      type="radio"
                                      id="customRadioInline15"
                                      name="heldUnlisted"
                                      className="custom-control-input"
                                      value="No"
                                      onChange={inputHandleBankAccount}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="customRadioInline15"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* for display none use this class -> d-none */}
                      <div className="step-nav-box">
                        <Button
                          id="saveInformation"
                          variant="contained"
                          style={{
                            borderRadius: "25px",
                            background: "#AE0721",
                          }}
                          onClick={() => checkValidationStep6()}
                          endIcon={<SendIcon />}
                          disabled={IsEnable} // Disable the button when isSendingOTP is true
                        >
                          Save & Make Payment
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </section>

              {/* <Container style={{ width: '50%' }}>
                <Row style={{ justifyContent: 'center', backgroundColor: '#7d2ae8' }}>
                  <img src="/images/6iroM6gAT.png" style={{ height: '130px', width: '160px' }} />
                </Row>
                <Row style={{ justifyContent: 'center', color: '#000000',fontSize:'30px'}}>
                  <p>
                    Hii UserName you have successfully purchased the plan.<br />
                  </p>
                </Row>
                <Row>
                  <p style={{display:'flex',justifyContent:'end',padding:'16px 30px 16px 30px'}}>
                  <Button style={{backgroundColor:'#364357',color:'#fff',padding:'13px ',borderRadius: "25px",fontSize:'14ypx'}}>
                    Continue
                  </Button>
                  </p>
                </Row>
              </Container> */}
             




           


              {/* Footer part */}
              <section className="grayad">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-xl-6 border-right mtp5 mbp5">
                      <span>
                        <b>Don’t have Information or documents?</b>
                      </span>
                      <span className="clearfix d-block">
                        No worries! You can edit/submit the info and document
                        later as well
                      </span>
                    </div>
                    <div className="col-sm-6 col-lg-3 col-xl-4 mtp5 mbp5">
                      <div className="pl-0 pl-lg-1 pl-xl-4">
                        <span className="blue-text">
                          <b>{stepName?.data?.planName}</b>
                        </span>
                        <span className="clearfix d-block smalltext">
                          Made for Immovable property purchasers
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 col-xl-2 text-left text-md-right mtp5 mbp5">
                      <a
                        className="btn btn-dark regbtn"
                        onClick={() => displayRazorpay()}
                      >
                        Make Payment
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepForPlanB;
