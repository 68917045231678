import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import '../common/Common.css'
import "./faq.css"
import './Accordianstyle.css'
import DemoHeader from '../demoHeader/DemoHeader'
import $, { data } from "jquery";
import { Col, Container, Row } from 'react-bootstrap'


const MoreFaq = () => {
    const [activeId, setActiveId] = useState(1);
    const [serviceData, setServiceData] = useState("");
    const [serviceId, setServiceId] = useState("");
    const [incom, setIncom] = useState("");
    const [isShow, setIsShow] = useState(true)
    const [isShow2, setIsShow2] = useState(true)
    const [isShow3, setIsShow3] = useState(true)
    const [isShow4, setIsShow4] = useState(true)
    const [isShow5, setIsShow5] = useState(true)


    function showonlyone(thechosenone) {
        $(".newboxes").each(function (index) {
            if ($(this).attr("id") == thechosenone) {
                $(this).fadeIn(0);
            } else {
                $(this).fadeOut(0);
            }
        });
    }

    const handleClick = (e) => {
        setActiveId(e);
    };

    const handleShowServices = (flag) => {
        if (flag) {
            setIsShow(true)
        }
        else {
            setIsShow(false)
        }
    }
    const handleShowServices2 = (flag) => {
        if (flag) {
            setIsShow2(true)
        }
        else {
            setIsShow2(false)
        }
    }
    const handleShowServices3 = (flag) => {
        if (flag) {
            setIsShow3(true)
        }
        else {
            setIsShow3(false)
        }
    }
    const handleShowServices4 = (flag) => {
        if (flag) {
            setIsShow4(true)
        }
        else {
            setIsShow4(false)
        }
    }
    const handleShowServices5 = (flag) => {
        if (flag) {
            setIsShow5(true)
        }
        else {
            setIsShow5(false)
        }
    }
    return (

        <>
            <DemoHeader />
            <div id="wrapper" className="clearfix">
                <div className="bannertop">
                    <div className="container text-left">
                        <div className="banner-vhmian">
                            <div className="vhin">
                                <h1>FAQs</h1>
                            </div>
                        </div>
                    </div>
                </div>
                    <br></br>
                    <br></br>
                <div className="row more-faq-row">
                    <div className="col-4 col-sm-12 col-xs-12">
                        <nav id="navbar-example3" className="h-100 flex-column align-items-stretch pe-4 border-end">
                            <nav className="nav nav-pills flex-column">
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Income Tax
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <a className="nav-link ms-3 my-1" href="#item-1-1">Income Tax Return</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-2">Application for pan</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-3">Advisory on Tax Planning</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-4">Advance Tax Calculation</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-5">TDS on Property purchase (Challan 26QB)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-6">TDS Returns</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-7">TAN Registration</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-8">SFT Filling (Statement of Financial Transaction)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-9">Income Tax Notice</a>
                                            <a className="nav-link ms-3 my-1" href="#item-1-10">Filling of Form 15CA (Foreign Remittance)</a>

                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                GST
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <a className="nav-link ms-3 my-1" href="#item-3-1">GST Registration</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-2">GST Return Filling</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-3">GST Annual Return (GSTR 9)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-4">GST E-way bill service</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-5">Reconciliation of GSTR 2A and GSTR 3B</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-6">GST TDS and GST TCS Return</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-7">ITC 04 - Job Work return</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-8">Letter of Undertaking (LUT)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-9">GST Cancellation or Surrender</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-10">GST Consultancy Services</a>
                                            <a className="nav-link ms-3 my-1" href="#item-3-11">GST Cost Optimization Service</a>


                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                Registartions
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <a className="nav-link ms-3 my-1" href="#item-4-1">Company incorporation</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-2">Limited Liability Partnership (LLP) Incorporation</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-3">Partnership Firm</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-4">Startup Registration (Startup India)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-5">Shop and Establishment (Shop Act License)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-6">Provident Fund Registration</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-7">Employees State Insurance (ESIC)</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-8">Professional Tax Registration</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-9">Import Export Code</a>
                                            <a className="nav-link ms-3 my-1" href="#item-4-10">FSSAI/Food License</a>

                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                Business Support Service
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <a className="nav-link ms-3 my-1" href="#item-5-1">Payroll Processing</a>
                                            <a className="nav-link ms-3 my-1" href="#item-5-2">Accounting Services</a>
                                            <a className="nav-link ms-3 my-1" href="#item-5-3">Virtual CFO</a>
                                            <a className="nav-link ms-3 my-1" href="#item-5-4">Business Valuation Services</a>
                                            <a className="nav-link ms-3 my-1" href="#item-5-5">Drafting and Reviewing Agreements</a>
                                            <a className="nav-link ms-3 my-1" href="#item-5-6">Startup Packages</a>
                                            <a className="nav-link ms-3 my-1" href="#item-5-7">Trainings</a>

                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                ROC Compliances
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <a className="nav-link ms-3 my-1" href="#item-6-1">ROC Filling</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-2">Company Returns</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-3">LLP Returns</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-4">Winding up of Company</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-5">Change Company Name/Registered Office</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-6">Appoint/Resignation of Director</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-7">Changes in Authorised Share Capital</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-3">Changes in memorandum of Association</a>
                                            <a className="nav-link ms-3 my-1" href="#item-6-3">Director KYC</a>

                                        </div>
                                    </div>
                                </div>


                            </nav>
                        </nav>
                    </div>

                    <div className="col-8 col-sm-12 col-xs-12">
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" className="scrollspy-example-2" tabindex="0">
                            <div id="item-1">

                            </div>
                            <div id="item-1-1">
                                <h5>Income Tax Return</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What is a return of income?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                ITR stands for Income Tax Return. It is a prescribed form through which the particulars of income earned by a person in a financial year and taxes paid on such income are communicated to the Income-tax Department. It also allows carry -forward of loss and claim refund from income tax department.Different forms of returns of income are prescribed for filing of returns for different Status and Nature of income. These forms can be downloaded from www.incometaxindia.gov.in
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Is it necessary to attach any documents along with the return of income?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Will I be put to any disadvantage by filing my return?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                No, on the contrary by not filing your return inspite of having taxable income, you will be liable to the penalty and prosecution provisions under the Income-tax Act.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                What are the benefits of filing my return of income?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                Is it necessary to file return of income when I do not have any positive income?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                Will I be penalized on late filing of ITR even if I am not liable to file it?
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                No, late filing fee under section 234F not leviable in case you are not required to file ITR as per section 139 but filing it voluntary though after the due date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                If I fail to furnish my return within the due date, will I be fined or penalized?
                                            </button>
                                        </h2>
                                        <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Yes, if a person who is required to furnish a return of income under section 139 and fails to do so within time prescribed in sub-section (1) , you will have to pay interest on tax due. W.e.f. assessment year 2018-19, fee as per section 234F is required to be paid if return is furnished after due date. Fee for default in furnishing return of income will be as follows:
                                                i.	Rs. 5000 if return is furnished on or before the 31st day of December of the assessment year;
                                                ii.	Rs. 10,000 in any other case
                                                However, late filing fee shall not exceed Rs. 1000 if the total income of an assessee does not exceed Rs. 5 lakh.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                If I have paid excess tax how will it be refunded to me?
                                            </button>
                                        </h2>
                                        <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The excess tax can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. The department has been making efforts to settle refund claims at the earliest.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                If I have committed any mistake in my original return, am I permitted to file a revised return to correct the mistake?
                                            </button>
                                        </h2>
                                        <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                A return of income can be revised at any time during the assessment year or before the assessment made whichever is earlier.
                                                If original return has filed in paper format or manually, then technically it cannot be revised by online mode or electronically.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                There are various deductions that are not reflected in the Form 16 issued by my employer. Can I claim them in my return?
                                            </button>
                                        </h2>
                                        <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Yes, it can be claimed if you are otherwise eligible to claim the same.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                Why is return filing mandatory, even though all my taxes and interests have been paid and there is no refund due to me?
                                            </button>
                                        </h2>
                                        <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Amounts paid as advance tax and withheld in the form of TDS or collected in the form of TCS will take the character of your tax due only on completion of self-assessment of your income. This self-assessment is intimated to the Department by way of filing of the return of income. Only then the Government assumes rights over the taxes paid by you. Filing of return is critical for this process and, hence, has been made mandatory. Failure will attract levy of penalty.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                What is Form 26AS?                                            </button>
                                        </h2>
                                        <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                A taxpayer may pay tax in any of the following forms:
                                                (1) Tax Deducted at Source (TDS)
                                                (2) Tax Collected at Source (TCS)
                                                (3) Advance tax or Self-assessment Tax or Payment of tax on regular assessment.
                                                The Income-tax Department maintains the database of the total tax paid by the taxpayer (i.e., tax credit in the account of a taxpayer).  Form 26AS is an annual statement maintained under Rule 31AB of the Income-tax Rules disclosing the details of tax credit in his account as per the database of Income-tax Department. In other words, Form 26AS will reflect the details of tax credit appearing in the Permanent Account Number of the taxpayer as per the database of the Income-tax Department. The tax credit will cover TDS, TCS and tax paid by the taxpayer in other forms like advance tax, Self-Assessment tax, etc.
                                                Income-tax Department will generally allow a taxpayer to claim the credit of taxes as reflected in his Form 26AS.                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-2">
                                <h5>Application For Pan</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                What is PAN? What is the utility of the PAN?
                                            </button>
                                        </h2>
                                        <div id="collapseThirteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                PAN stands for Permanent Account Number. PAN is a ten-digit unique
                                                alphanumeric number issued by the Income Tax Department. PAN is issued in the
                                                form of a laminated plastic card (commonly known as PAN card). Given below is an
                                                illustrative PAN:ALWPG5809L
                                                PAN enables the department to link all transactions of the assessee with the
                                                department. These transactions include tax payments, TDS/TCS credits, returns of
                                                income, specified transactions, correspondence and so on. It facilitates easy
                                                retrieval of information of assessee and matching of various investments,
                                                borrowings and other business activities of assessee.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                Who has to obtain PAN?
                                            </button>
                                        </h2>
                                        <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                PAN is to be obtained by following persons:
                                                1. Every person if his total income or the total income of any other person in
                                                respect of which he is assessable during the previous year exceeds the
                                                maximum amount which is not chargeable to tax.
                                                2. A charitable trust who is required to furnish return under Section 139(4A)
                                                3. Every person who is carrying on any business or profession whose total sale,
                                                turnover, or gross receipts are or is likely to exceed five lakh rupees in any
                                                previous year
                                                4. Every person who intends to enter into specified financial transactions in which
                                                quoting of PAN is mandatory
                                                Every non-individual resident persons and person associated with them if the
                                                financial transaction entered into by such non-individual resident persons during a
                                                financial year exceeds Rs. 2,50,000.
                                                Note: Persons associated with non-individual resident persons means the managing
                                                director, director, partner, trustee, author, founder, karta, chief executive officer,
                                                principal officer or office bearer of the non-individual resident persons or any person
                                                competent to act on behalf of such persons.
                                                A person not covered in any of the above can voluntarily apply for PAN.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                Can I file my return of income without quoting PAN?
                                            </button>
                                        </h2>
                                        <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                It is mandatory to quote PAN on the return of income. Apart from return of income,
                                                PAN is also to be quoted in all challans for making payment of tax, correspondence
                                                with the Income Tax Department, etc.
                                                However, w.e.f., 01-09-2019, every person who has been allotted a PAN, and who
                                                has linked his Aadhaar number with PAN as per section 139AA, may furnish his
                                                Aadhaar number in lieu of a PAN for all the transactions where quoting of PAN is
                                                mandatory as per Income-tax Act. Thus, with effect from September 1, 2019, an
                                                assessee can file his return of income by quoting his Aadhaar number instead of
                                                quoting PAN.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                How to link Aadhaar number with PAN using e-Filing portal?
                                            </button>
                                        </h2>
                                        <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Taxpayer is required to login on e-Filing portal using his registered username and
                                                password. After login, he will get the “link Aadhaar” option under his ‘Profile
                                                Settings’.
                                                Aadhaar number will be linked with PAN if name and date of birth of taxpayer is
                                                identical to Aadhaar and PAN.
                                                After making the PAN application how can I check the status of my PAN
                                                application?
                                                After applying for PAN, one can check the status of his application by using the
                                                track status facility available at web sites of Income Tax Department or UTIITSL or
                                                NSDL i.e. www.incometaxindia.gov.in or www.utiitsl.com and www.tin-nsdl.com .                                    </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                If my PAN card is lost then what to do?
                                            </button>
                                        </h2>
                                        <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If the PAN card is lost or you wish to have a new PAN card with existing details,
                                                then you can apply for duplicate PAN card by submitting the Form for "Request for
                                                New PAN Card or/ and Changes or Correction in PAN Data" and a copy of FIR may
                                                be submitted along with the form.
                                                If the PAN card is lost and you don't remember your PAN, then in such a case, you
                                                can know you PAN by using the facility of "Know Your PAN" provided by the Income
                                                Tax Department. This facility can be availed of from the website of Income Tax
                                                Department - www.incometaxindiaefiling.gov.in
                                                You can know your PAN online by providing the core details like Name, Father's
                                                Name and Date of Birth.
                                                After knowing the PAN you can apply for duplicate PAN card by submitting the
                                                "Request For New PAN Card Or/ And Changes Or Correction in PAN Data".
                                                Applicant is required to fill all the columns in the form but should not tick any of
                                                the boxes on the left margin.                                           </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                                What is Instant-PAN?
                                            </button>
                                        </h2>
                                        <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                E Income-tax Dept. has launched a new functionality on the e-filing portal which
                                                allots a PAN to the assessee on basis of his Aadhaar Number.
                                                This facility can be used by an assessee only if the following conditions are fulfilled:
                                                1. He has never been allotted a PAN;
                                                2. His mobile number is linked with his Aadhaar number;
                                                3. His complete date of birth is available on the Aadhaar card; and
                                                4. He should not be a minor on the date of application for PAN                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-3">
                                <h5>Advisory on Tax Planning</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNinteen" aria-expanded="true" aria-controls="collapseNinteen">
                                                What is tax planning?
                                            </button>
                                        </h2>
                                        <div id="collapseNinteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Tax planning is the analysis of a financial situation or plan from a tax perspective.
                                                The purpose of tax planning is to ensure tax efficiency. Through tax planning, all
                                                elements of the financial plan work together in the most tax-efficient manner
                                                possible. Tax planning is an essential part of an individual investor's financial
                                                plan. Reduction of tax liability and maximizing the ability to contribute to
                                                retirement plans are crucial for success.                                         </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                                How Tax Planning Works?
                                            </button>
                                        </h2>
                                        <div id="collapseTwenty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Tax planning covers several considerations. Considerations include timing of
                                                income, size, and timing of purchases, and planning for other expenditures. Also,
                                                the selection of investments and types of retirement plans must complement the
                                                tax filing status and deductions to create the best possible outcome.                                       </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                                Types of tax planning
                                            </button>
                                        </h2>
                                        <div id="collapseTwentyOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Tax planning is an integral part of every individual’s financial growth story. Since
                                                paying taxes is mandatory for every individual falling under the purview of the IT
                                                bracket, why not streamline your tax payments in ways that it offers substantial
                                                returns over a period of time with minimum risk? In addition, effective planning
                                                also reduces your tax liability drastically.
                                                The different mindset under which tax planning can be broadly classified are:
                                                1. Purposive tax planning:
                                                Planning taxes with a particular objective in mind
                                                2. Permissive tax planning:
                                                Tax planning that is under the framework of law
                                                3. Long range and Short range tax planning:
                                                Planning done at the start and end of a fiscal year respectively                                         </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-4">
                                <h5>Advance Tax Calculation</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentytwo" aria-expanded="true" aria-controls="collapseTwentytwo">
                                                Can I have an invoice for my subscription?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentytwo" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                As per Section 208 of the Income Tax Act, Every person (individual, firm, company,
                                                etc.) whose estimated tax liability for the year (i.e., for the year in progress such as
                                                FY 2019-20, FY 2020-21, etc.) after TDS (i.e., TDS which is deducted for the
                                                person by its payers/clients/banks, etc.) is Rs. 10,000 or more shall pay its tax for
                                                the year in advance during the same financial year.                                         </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentythree" aria-expanded="false" aria-controls="collapseTwentythree">
                                                What are the due dates for payment of advance tax?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Advance Tax must be paid in four equal installments on or before the following
                                                dates:
                                                Installment Due Date
                                                1st Installment 15th June
                                                2nd Installment 15th September
                                                3rd Installment 15th December
                                                4th Installment 15th March                                    </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyfour" aria-expanded="false" aria-controls="collapseTwentyfour">
                                                How much tax must be paid in each installment of advance tax?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The amount of Advance tax to be paid in each installment must not be less
                                                than prescribed in the following table:
                                                Installments Amount of Advanced Tax
                                                1st
                                                Installment Not less than 15% of advance tax liability.
                                                2nd
                                                Installment
                                                Not less than 45% of advance tax liability as reduced by the amount,
                                                if any, paid in the earlier installment.
                                                3rd
                                                Installment
                                                Not less than 75% of advance tax liability, as reduced by the
                                                amount(s) if any, paid in the earlier installment (s).
                                                4th
                                                Installment
                                                The whole amount of advance tax liability as reduced by the
                                                amount(s) if any, paid in the earlier installment (s).                                    </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyfive" aria-expanded="false" aria-controls="collapseTwentyfive">
                                                What should we do if the bank is closed on the last day for payment of advance tax?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentyfive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Any taxes paid till 31stMarch will be treated as advance tax.
                                                If the last day for the payment of advance tax is the day on which the banks are
                                                closed, then one should pay the advance tax on the immediately following working
                                                day and no interest shall be charged on such payments of advance tax.                               </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                                                Who is not required to pay Advance tax?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentySix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                A resident senior citizen (i.e., an individual of the age of 60 years or above during
                                                the financial year) not having any income from business or profession is not liable
                                                to pay advance tax.
                                                Taxpayer who poted for presumptive taxation scheme of section 44ADor section
                                                44ADA is liable to pay 100% of advance tax by 15th March.                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-5">
                                <h5>TDS on Property purchase (Challan 26QB)</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySeven" aria-expanded="true" aria-controls="collapseTwentySeven">
                                                What is TDS on property?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentySeven" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Finance Bill 2013 has proposed that purchaser of an immovable property
                                                (other than rural agricultural land) worth ₹ 50 lakh or more is required to pay
                                                withholding tax at the rate of 1% from the consideration payable to a resident
                                                transferor.                                         </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
                                                Who is responsible to deduct the TDS on sale of Property?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentyEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                According to rules in respect of tax deducted at source, buyer of the property would
                                                have to deduct the TDS and deposit the same in Government treasury.                                  </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
                                                I am Buyer, do I required to procure TAN to report the TDS on sale of Property?
                                            </button>
                                        </h2>
                                        <div id="collapseTwentyNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Buyer or Purchaser of the property is not required to procure Tax Deduction
                                                Account Number (TAN). The Buyer is required quote his or her PAN and sellers
                                                PAN.                                  </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
                                                What if I don't have the PAN of the seller, is it Mandatory?
                                            </button>
                                        </h2>
                                        <div id="collapseThirty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                PAN of the seller is mandatory. The same may be acquired from the Seller before
                                                effecting the transaction                       </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyone" aria-expanded="false" aria-controls="collapseThirtyone">
                                                What is Form 26QB?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtyone" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The online form available on the TIN website for furnishing information regarding
                                                TDS on property is termed as Form 26QB.                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtytwo" aria-expanded="false" aria-controls="collapseThirtytwo">
                                                What is the due date of payment of TDS on sale of property?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtytwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                As per the CBDT notification no. 30/2016 dated April 29, 2016, the due date of
                                                payment of TDS on transfer of immovable property has been extended to thirty
                                                days (from existing seven days) from the end of the month in which the deduction
                                                is made.
                                                Example: If a taxpayer has made payment of sale consideration in the month of
                                                February, then corresponding TDS should be deposited on or before (thirty days)
                                                March 30th.                           </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtythree" aria-expanded="false" aria-controls="collapseThirtythree">
                                                How will transactions of joint parties (more than one buyer/seller) be filed in Form
                                                26QB?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Online statement cum challan Form/ Form 26QB is to be filled in by each buyer
                                                for unique buyer-seller combination for respective share. E.g. in case of one buyer
                                                and two sellers, two forms have to be filled in and for two buyers and two seller,
                                                four forms have to be filled in for respective property shares.                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyfour" aria-expanded="false" aria-controls="collapseThirtyfour">
                                                What are the forms to be used for filing quarterly TDS/TCS returns?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Following are the forms for TDS/TCS returns and their periodicity:Form No. Particulars Periodicity Form
                                                No.24Q Quarterly statement for tax deducted at source from 'Salaries' Quarterly Form No.26Q Quarterly statement
                                                of tax deducted at source in respect of all payments other than 'Salaries' Quarterly Form No.27Q
                                                Quarterly statement of deduction of tax from interest, dividend or any other sum payable to non-residents Quarterly
                                                Form No.27EQ Quarterly statement of collection of tax at source Quarterly                         </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-6">
                                <h5>TDS Returns</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyfive" aria-expanded="true" aria-controls="collapseThirtyfive">
                                                Who is required to file e-TDS/TCS return?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtyfive" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                As per Income Tax Act, 1961, all corporate and government deductors/ collectors
                                                are compulsorily required to file their TDS/TCS returns on electronic media (i.e. eTDS/TCS returns). However, deductors/collectors other than
                                                corporate/government can file either in physical or in electronic form.                                      </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtysix" aria-expanded="false" aria-controls="collapseThirtysix">
                                                What are the due dates for filing quarterly TDS Returns?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtysix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The due dates for filing quarterly TDS returns, both electronic and paper are as per: Quarunter Due date for TDS statements
                                                (all Deductors) April to June 31 July July to September 31 October October to December 31 January
                                                January to March 31 May                             </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyseven" aria-expanded="false" aria-controls="collapseThirtyseven">
                                                What is the significance if the status of challan is ‘Booked’?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtyseven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If the challan is in Booked status, credit of tax deducted will be reflected in the
                                                annual tax statement (Form 26AS) of all the underlying deductees with a valid
                                                PAN.
                                                Correction in challan details is not allowed once a challan is booked. Correction
                                                can be made on underlying deductee records of a booked challan.                               </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyeight" aria-expanded="false" aria-controls="collapseThirtyeight">
                                                Is PAN mandatory for deductors and employees/deductees?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtyeight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                PAN of the deductors has to be given by non-Government deductors. It is essential
                                                to quote PAN of all deductees.                       </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-7">
                                <h5>TAN Registration</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyNine" aria-expanded="true" aria-controls="collapseThirtyNine">
                                                What is TAN?
                                            </button>
                                        </h2>
                                        <div id="collapseThirtyNine" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                TAN i.e. Tax Deduction and Collection Account Number is a 10 digit alpha
                                                numeric number required to be obtained by all persons who are responsible for
                                                deducting tax at source or collecting tax at source. It is compulsory to quote TAN in
                                                TDS/TCS return (including any e-TDS/TCS return), any TDS/TCS payment
                                                challan, TDS/TCS certificates, Annual Information Return and other documents as
                                                may be prescribed.                                    </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourty" aria-expanded="false" aria-controls="collapseFourty">
                                                Who must apply for TAN?
                                            </button>
                                        </h2>
                                        <div id="collapseFourty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Every person liable to deduct tax at source or collect tax at source is required to
                                                obtain TAN. However, a person required to deduct tax under section
                                                194IA or section 194-IB or section 194M can use PAN in place of TAN as such
                                                person is not required to obtain TAN.
                                                (1) Section 194IA provides for deduction of tax at source from payment on transfer
                                                of certain immovable property other than agricultural land.
                                                (2) section 194-IB provides for deduction of tax at source at the rate of 5% from
                                                payment of rent. The tax shall be deducted if the rent paid or payable exceeds Rs.
                                                50,000 per month or part of the month. This provision is applicable on individual
                                                and HUF, who is not liable to tax audit under Section 44AB in the immediately
                                                preceding financial year.
                                                (3) section 194M provide for deduction of tax at source at the rate of 5% on the
                                                sum paid or credited, to a resident, on account of contractual work, commission
                                                (not being insurance commission referred to in section 194D), brokerage or
                                                professional fees, by an individual or a HUF, if aggregate of such sum exceeds Rs.
                                                50 lakhs in a financial year. This provision is applicable on individual and HUF,
                                                who are not covered under section 194C, section 194H and section 194J.                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyone" aria-expanded="false" aria-controls="collapseFourtyone">
                                                Why is it necessary to have TAN?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtyone" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                As per section 203A of the Income-tax Act, 1961, every person who deducts or
                                                collects tax at source has to apply for the allotment of TAN. Section 203A also
                                                makes it mandatory to quote TAN in TDS/TCS return (including any e-TDS/TCS
                                                return), any TDS/TCS payment challan, TDS/TCS certificates and other
                                                documents as may be prescribed. As per section 272BB, failure to apply for TAN or
                                                not quoting the TAN in the specified documents attracts a penalty of Rs. 10,000/-.                               </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtytwo" aria-expanded="false" aria-controls="collapseFourtytwo">
                                                Is it necessary to apply for different TAN if a deductor has to deduct tax from
                                                different types of payments like salary, interest, dividend, commission, etc.?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtytwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                No. TAN once allotted can be used for all type of deductions. It can also be used in
                                                case tax is being collected at source.                       </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtythree" aria-expanded="false" aria-controls="collapseFourtythree">
                                                Is a separate TAN required to be obtained for the purpose of tax collection at source?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                In case a TAN has already been allotted for the purpose of tax deduction at source
                                                (TDS), then no separate application needs to be made for obtaining separate TAN
                                                for the purpose of tax collection at source (TCS). The same number can be quoted
                                                in all returns, challans and certificates for TCS. However, if no TAN has been
                                                allotted, a duly filled in Form 49B, along with the application fees is to be
                                                submitted at any TIN-FC.                       </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyfour" aria-expanded="false" aria-controls="collapseFourtyfour">
                                                Can an e-TDS return be filed without TAN or in case TAN has not been alloted?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Quoting of TAN is mandatory in TDS and TCS returns, whether filed in paper or
                                                electronic format. The returns, whether in paper or electronic format, will not be
                                                received in case TAN is not quoted.                       </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyfive" aria-expanded="false" aria-controls="collapseFourtyfive">
                                                Can we quote Permanent Account Number (PAN) in place of TAN?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtyfive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                No. PAN should never be quoted in the field where TAN is required to be quoted.
                                                The purposes for which PAN and TAN are allotted are different. TAN is a unique
                                                identification number which is allotted to persons who are required to deduct tax
                                                at source or collect tax at source.                      </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtysix" aria-expanded="false" aria-controls="collapseFourtysix">
                                                How can any change in address or details on the basis of which TAN was allotted be rectified?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtysix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If there is any change in the data provided in the application for allotment of TAN
                                                (i.e. data provide at the time of allotment of TAN), then such change is to be
                                                communicated to the Income Tax Department by intimating the change in , the
                                                "Form for Change or Correction in TAN data". Duly filed form should be submitted
                                                to the TIN Facilitation Centres or an online application can be made
                                                at https://www.tin-nsdl.com                       </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-8">
                                <h5>SFT Filling (Statement of Financial Transaction)</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyseven" aria-expanded="true" aria-controls="collapseFourtyseven">
                                                What is statement of financial transaction or reportable account previously called
                                                as ‘Annual Information Return (AIR)’?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtyseven" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                As per section 285BA of the Income-tax Act, 1961 (as substituted by Finance Act,
                                                2014 w.e.f. 01-04-2015), specified entities (Filers) are required to furnish a
                                                statement of financial transaction or reportable account (hereinafter referred to as
                                                ‘statement’) in respect of specified financial transactions or any reportable account
                                                registered/recorded/maintained by them during the financial year to the incometax authority or such other prescribed authority.                                    </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyeight" aria-expanded="false" aria-controls="collapseFourtyeight">
                                                Who is required to furnish statement of financial transactions and reportable accounts?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtyeight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Following persons shall be required to furnish statement of financial transactions
                                                or reportable accounts registered or recorded or maintained by them during a
                                                financial year to the prescribed authority:
                                                (a) an assessee;
                                                (b) the prescribed person in the case of an office of Government;
                                                (c) a local authority or other public body or association;
                                                (d) the Registrar or Sub-Registrar appointed under section 6 of the Registration
                                                Act, 1908 (16 of 1908);
                                                (e) the registering authority empowered to register motor vehicles under Chapter IV
                                                of the Motor Vehicles Act, 1988 (59 of 1988);
                                                (f) the Post Master General as referred to in clause (j) of section 2 of the Indian Post
                                                Office Act, 1898 (6 of 1898);
                                                (g) the Collector referred to in clause (g) of section 3 of the Right to Fair
                                                Compensation and Transparency in Land Acquisition, Rehabilitation and
                                                Resettlement Act, 2013 (30 of 2013);
                                                (h) the recognised stock exchange referred to in clause (f) of section 2 of the
                                                Securities Contracts (Regulation) Act, 1956 (42 of 1956);
                                                (i) an officer of the Reserve Bank of India, constituted under section 3 of the
                                                Reserve Bank of India Act, 1934 (2 of 1934);
                                                (j) a depository referred to in clause (e) of sub-section (1) of section 2 of the
                                                Depositories Act, 1996 (22 of 1996); or
                                                (k) a prescribed reporting financial institutions
                                                (i) a person, other than those referred to in clause (a) to (k), as may be prescribed.                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtynine" aria-expanded="false" aria-controls="collapseFourtynine">
                                                What is the due date for filing of statement of financial transaction or reportable
                                                account by prescribed reporting financial institution?
                                            </button>
                                        </h2>
                                        <div id="collapseFourtynine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                In order to facilitate effective exchange of information in respect of resident and
                                                non-resident, Section 285BA also provides for furnishing of statement by a
                                                prescribed reporting financial institution in respect of specified financial
                                                transaction or reportable account. The statement shall be furnished for each
                                                calendar year in Form No. 61B on or before 31st May of the next year. [For more
                                                details, see rules 114F, 114G and 114H of the Income-tax Rules, 1962]                               </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifty" aria-expanded="false" aria-controls="collapseFifty">
                                                What are the consequences of not filing statement of financial transaction or
                                                reportable account?
                                            </button>
                                        </h2>
                                        <div id="collapseFifty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Non-furnishing of statement of financial transaction or reportable account will
                                                attract penalty under section 271FA. Penalty can be levied of Rs. 500 per day of
                                                default.
                                                However, section 285BA(5) (as discussed earlier) empower the tax authorities to
                                                issue a notice to the person directing him to file the statement within a period not
                                                exceeding 30 days from the date of service of such notice and in such a case
                                                person shall furnish the statement within the time specified in the notice. If person
                                                fails to file the statement within the specified time, then a penalty of Rs. 1,000 per
                                                day will be levied from the day immediately following the day on which the time
                                                specified in such notice for furnishing the statement expires.                      </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-9">
                                <h5>Income Tax Notice</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyone" aria-expanded="true" aria-controls="collapseFiftyone">
                                                What is meant by assessment?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftyone" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Every taxpayer has to furnish the income and investment details to the Income-tax
                                                Department. These details are to be furnished by filing return. Once the return is
                                                filed by the taxpayer, the next step is the processing of the return by the Incometax Department. The Income-tax Department examines the return. The process of
                                                examining the return of by the Income-tax Department is called “Assessment”.
                                                Assessment also includes re-assessment or best judgment assessment.                                    </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftytwo" aria-expanded="false" aria-controls="collapseFiftytwo">
                                                What are the major assessments under the Income-tax Law?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftytwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Under the Income-tax Law, there are four major assessments as given below:
                                                1. Section 143(1), i.e., Summary assessment without calling the
                                                assessee i.e. taxpayer.
                                                2. Assessment under section 143(3), i.e., Scrutiny assessment.
                                                3. Assessment under section 144, i.e., Best judgment assessment.
                                                4. Assessment under section 147, i.e., Income escaping assessment                         </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftythree" aria-expanded="false" aria-controls="collapseFiftythree">
                                                What is assessment under section 143(1)?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Scope of assessment under section 143(1)
                                                Assessment under section 143(1) is like preliminary checking of the return of
                                                income. At this stage no detailed scrutiny of the return of income is carried out. At
                                                this stage, the total income or loss is computed after making the following
                                                adjustments (if any), namely:-
                                                (i) any arithmetical error in the return; or
                                                (ii) an incorrect claim, if such incorrect claim is apparent from any information in
                                                the return;
                                                (iii) disallowance of loss claimed, if return of the previous year for which set off of
                                                loss is claimed was furnished beyond the due date specified under sub-section (1)
                                                of section 139
                                                (iv) disallowance of expenditure indicated in the audit report but not taken into
                                                account in computing the total income in the return;
                                                (v) disallowance of deduction claimed under sections 10AA, 80-IA, 80-IB, 80-
                                                IC, 80-ID or section 80-IE, if the return is furnished beyond the due date specified
                                                under sub-section (1) of section 139; or
                                                (vi) addition of income appearing in Form 26AS or Form 16A or Form 16 which has
                                                not been included in computing the total income in the return.                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyfour" aria-expanded="false" aria-controls="collapseFiftyfour">
                                                What is the time limit for making the assessment under section 143(1)?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Assessment under section 143(1) can be made within a period of one year from the
                                                end of the financial year in which the return of income is filed.                      </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyfive" aria-expanded="false" aria-controls="collapseFiftyfive">
                                                How to file response to notice issued under section 143(2)?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftyfive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If a return has been furnished under section 139 or in response to notice
                                                under section 142(1), the Assessing Officer or the prescribed income tax authority,
                                                as the case may be, if, considers it necessary to ensure that the assessee has not
                                                understated the income or has not computed excessive loss or has not under-paid
                                                the tax in any manner, shall serve a notice on the assessee requiring him on a date
                                                specified in the notice, either to attend the office of the Assessing Officer or to
                                                produce before the Assessing Officer any evidence on which the assessee may rely
                                                in support of the return.
                                                Provided that no notice under this sub section shall be served on the assessee
                                                after the expiry of 6 months from the end of the financial year in which the return
                                                is furnished.                     </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="item-1-10">
                                <h5>Filling of Form 15CA (Foreign Remittance)</h5>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftysix" aria-expanded="true" aria-controls="collapseFiftysix">
                                                What is the need of 15CA and 15CB ?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftysix" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Earlier, the person making a remittance to Non-Resident was required to
                                                furnish a certificate in specified format circulated by RBI.
                                                1. Basic purpose was to collect the taxes at a stage when the remittance is made
                                                as it may not be possible to collect the tax from the NR at a later stage.
                                                2. Thus to monitor and track the transactions in an efficient manner, it was
                                                proposed to introduce e-filling of information in the certificates.
                                                3. Section 195 of Income tax act,1961 mandates the deduction of Income tax from
                                                payments made to Non Resident. The person making the remittance to non –
                                                resident needs to furnish an undertaking (in form 15CA) accompanied by a
                                                Chartered Accountant Certificate in Form 15CB.                                  </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyseven" aria-expanded="false" aria-controls="collapseFiftyseven">
                                                Do individuals have to furnish Form 15CA and 15CB?
                                            </button>
                                        </h2>
                                        <div id="collapseFiftyseven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Form 15CA and 15CB will be NOT be required to be furnished by an individual for remittance which does not require RBI approval.                       </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                {/* For Mobile view */}
                <Container>
                    <Row>
                <div className='more-faq-mob'>
                        <Col>
                            <nav id="navbar-example3" className="h-100 flex-column align-items-stretch pe-4 border-end">
                                <nav className="nav nav-pills flex-column">
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Income Tax
                                                </button>
                                            </h2>
                                            {isShow && <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive" id="flush-collapseOne" class="accordion-collapse collapse more-faq-custom" data-bs-parent="#accordionFlushExample">
                                                <a
                                                    className={`service-box-mob ${activeId === 1 ? "active" : ""}`}
                                                    id="myHeader1"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes1");
                                                        handleClick(1);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            Income Tax Return{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>


                                                <a
                                                    className={`service-box-mob position-set ${activeId === 2 ? "active" : ""
                                                        }`}
                                                    id="myHeader2"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes2");
                                                        handleClick(2);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            Application For Pan{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob position-set ${activeId === 3 ? "active" : ""
                                                        }`}
                                                    id="myHeader3"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes3");
                                                        handleClick(3);
                                                        handleShowServices(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            Advisory on Tax Planning{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob ${activeId === 4 ? "active" : ""}`}
                                                    id="myHeader4"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes4");
                                                        handleClick(4);
                                                        handleShowServices(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            Advance Tax Calculation{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob ${activeId === 5 ? "active" : ""}`}
                                                    id="myHeader5"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes5");
                                                        handleClick(5);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            TDS on Property purchase (Challan 26QB){" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>





                                                <a
                                                    className={`service-box-mob ${activeId === 6 ? "active" : ""}`}
                                                    id="myHeader6"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes6");
                                                        handleClick(4);
                                                        handleShowServices(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            TDS Returns{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob ${activeId === 7 ? "active" : ""}`}
                                                    id="myHeader7"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes7");
                                                        handleClick(4);
                                                        handleShowServices(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            TAN Registration{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob ${activeId === 8 ? "active" : ""}`}
                                                    id="myHeader8"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes8");
                                                        handleClick(4);
                                                        handleShowServices(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            SFT Filling (Statement of Financial Transaction){" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob ${activeId === 9 ? "active" : ""}`}
                                                    id="myHeader9"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes9");
                                                        handleClick(4);
                                                        handleShowServices(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            Income Tax Notice{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob ${activeId === 10 ? "active" : ""}`}
                                                    id="myHeader10"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes10");
                                                        handleClick(4);
                                                        handleShowServices(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            Filling of Form 15CA (Foreign Remittance){" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>
                                            </div>}
                                            {<div className={`col-lg-4 col-xl-5 ${isShow ? 'd-none' : ''}`}>
                                                <div className="rightboxmain">
                                                    <div className="newboxes" id="newboxes1"
                                                        style={{ display: "block" }}
                                                    >
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-1" className='innerItem'>
                                                            <h5>Income Tax Return</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            What is a return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR stands for Income Tax Return. It is a prescribed form through which the particulars of income earned by a person in a financial year and taxes paid on such income are communicated to the Income-tax Department. It also allows carry -forward of loss and claim refund from income tax department.Different forms of returns of income are prescribed for filing of returns for different Status and Nature of income. These forms can be downloaded from www.incometaxindia.gov.in
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            Is it necessary to attach any documents along with the return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Will I be put to any disadvantage by filing my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, on the contrary by not filing your return inspite of having taxable income, you will be liable to the penalty and prosecution provisions under the Income-tax Act.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            What are the benefits of filing my return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                            Is it necessary to file return of income when I do not have any positive income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                            Will I be penalized on late filing of ITR even if I am not liable to file it?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, late filing fee under section 234F not leviable in case you are not required to file ITR as per section 139 but filing it voluntary though after the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                            If I fail to furnish my return within the due date, will I be fined or penalized?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, if a person who is required to furnish a return of income under section 139 and fails to do so within time prescribed in sub-section (1) , you will have to pay interest on tax due. W.e.f. assessment year 2018-19, fee as per section 234F is required to be paid if return is furnished after due date. Fee for default in furnishing return of income will be as follows:
                                                                            i.	Rs. 5000 if return is furnished on or before the 31st day of December of the assessment year;
                                                                            ii.	Rs. 10,000 in any other case
                                                                            However, late filing fee shall not exceed Rs. 1000 if the total income of an assessee does not exceed Rs. 5 lakh.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                            If I have paid excess tax how will it be refunded to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The excess tax can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. The department has been making efforts to settle refund claims at the earliest.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                            If I have committed any mistake in my original return, am I permitted to file a revised return to correct the mistake?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A return of income can be revised at any time during the assessment year or before the assessment made whichever is earlier.
                                                                            If original return has filed in paper format or manually, then technically it cannot be revised by online mode or electronically.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                            There are various deductions that are not reflected in the Form 16 issued by my employer. Can I claim them in my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, it can be claimed if you are otherwise eligible to claim the same.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                            Why is return filing mandatory, even though all my taxes and interests have been paid and there is no refund due to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Amounts paid as advance tax and withheld in the form of TDS or collected in the form of TCS will take the character of your tax due only on completion of self-assessment of your income. This self-assessment is intimated to the Department by way of filing of the return of income. Only then the Government assumes rights over the taxes paid by you. Filing of return is critical for this process and, hence, has been made mandatory. Failure will attract levy of penalty.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                            What is Form 26AS?                                            </button>
                                                                    </h2>
                                                                    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A taxpayer may pay tax in any of the following forms:
                                                                            (1) Tax Deducted at Source (TDS)
                                                                            (2) Tax Collected at Source (TCS)
                                                                            (3) Advance tax or Self-assessment Tax or Payment of tax on regular assessment.
                                                                            The Income-tax Department maintains the database of the total tax paid by the taxpayer (i.e., tax credit in the account of a taxpayer).  Form 26AS is an annual statement maintained under Rule 31AB of the Income-tax Rules disclosing the details of tax credit in his account as per the database of Income-tax Department. In other words, Form 26AS will reflect the details of tax credit appearing in the Permanent Account Number of the taxpayer as per the database of the Income-tax Department. The tax credit will cover TDS, TCS and tax paid by the taxpayer in other forms like advance tax, Self-Assessment tax, etc.
                                                                            Income-tax Department will generally allow a taxpayer to claim the credit of taxes as reflected in his Form 26AS.                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            {/* <Link to="/incometaxreadmore"> */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark regbtn md"
                                                                onclick="location.href='income-tax.html';"
                                                            >
                                                                READ MORE
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes2">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-2" className='innerItem'>
                                                            <h5>Application For Pan</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                                            What is PAN? What is the utility of the PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN stands for Permanent Account Number. PAN is a ten-digit unique
                                                                            alphanumeric number issued by the Income Tax Department. PAN is issued in the
                                                                            form of a laminated plastic card (commonly known as PAN card). Given below is an
                                                                            illustrative PAN:ALWPG5809L
                                                                            PAN enables the department to link all transactions of the assessee with the
                                                                            department. These transactions include tax payments, TDS/TCS credits, returns of
                                                                            income, specified transactions, correspondence and so on. It facilitates easy
                                                                            retrieval of information of assessee and matching of various investments,
                                                                            borrowings and other business activities of assessee.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                                            Who has to obtain PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN is to be obtained by following persons:
                                                                            1. Every person if his total income or the total income of any other person in
                                                                            respect of which he is assessable during the previous year exceeds the
                                                                            maximum amount which is not chargeable to tax.
                                                                            2. A charitable trust who is required to furnish return under Section 139(4A)
                                                                            3. Every person who is carrying on any business or profession whose total sale,
                                                                            turnover, or gross receipts are or is likely to exceed five lakh rupees in any
                                                                            previous year
                                                                            4. Every person who intends to enter into specified financial transactions in which
                                                                            quoting of PAN is mandatory
                                                                            Every non-individual resident persons and person associated with them if the
                                                                            financial transaction entered into by such non-individual resident persons during a
                                                                            financial year exceeds Rs. 2,50,000.
                                                                            Note: Persons associated with non-individual resident persons means the managing
                                                                            director, director, partner, trustee, author, founder, karta, chief executive officer,
                                                                            principal officer or office bearer of the non-individual resident persons or any person
                                                                            competent to act on behalf of such persons.
                                                                            A person not covered in any of the above can voluntarily apply for PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                                            Can I file my return of income without quoting PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            It is mandatory to quote PAN on the return of income. Apart from return of income,
                                                                            PAN is also to be quoted in all challans for making payment of tax, correspondence
                                                                            with the Income Tax Department, etc.
                                                                            However, w.e.f., 01-09-2019, every person who has been allotted a PAN, and who
                                                                            has linked his Aadhaar number with PAN as per section 139AA, may furnish his
                                                                            Aadhaar number in lieu of a PAN for all the transactions where quoting of PAN is
                                                                            mandatory as per Income-tax Act. Thus, with effect from September 1, 2019, an
                                                                            assessee can file his return of income by quoting his Aadhaar number instead of
                                                                            quoting PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                                            How to link Aadhaar number with PAN using e-Filing portal?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Taxpayer is required to login on e-Filing portal using his registered username and
                                                                            password. After login, he will get the “link Aadhaar” option under his ‘Profile
                                                                            Settings’.
                                                                            Aadhaar number will be linked with PAN if name and date of birth of taxpayer is
                                                                            identical to Aadhaar and PAN.
                                                                            After making the PAN application how can I check the status of my PAN
                                                                            application?
                                                                            After applying for PAN, one can check the status of his application by using the
                                                                            track status facility available at web sites of Income Tax Department or UTIITSL or
                                                                            NSDL i.e. www.incometaxindia.gov.in or www.utiitsl.com and www.tin-nsdl.com .                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                                            If my PAN card is lost then what to do?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If the PAN card is lost or you wish to have a new PAN card with existing details,
                                                                            then you can apply for duplicate PAN card by submitting the Form for "Request for
                                                                            New PAN Card or/ and Changes or Correction in PAN Data" and a copy of FIR may
                                                                            be submitted along with the form.
                                                                            If the PAN card is lost and you don't remember your PAN, then in such a case, you
                                                                            can know you PAN by using the facility of "Know Your PAN" provided by the Income
                                                                            Tax Department. This facility can be availed of from the website of Income Tax
                                                                            Department - www.incometaxindiaefiling.gov.in
                                                                            You can know your PAN online by providing the core details like Name, Father's
                                                                            Name and Date of Birth.
                                                                            After knowing the PAN you can apply for duplicate PAN card by submitting the
                                                                            "Request For New PAN Card Or/ And Changes Or Correction in PAN Data".
                                                                            Applicant is required to fill all the columns in the form but should not tick any of
                                                                            the boxes on the left margin.                                           </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                                                            What is Instant-PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            E Income-tax Dept. has launched a new functionality on the e-filing portal which
                                                                            allots a PAN to the assessee on basis of his Aadhaar Number.
                                                                            This facility can be used by an assessee only if the following conditions are fulfilled:
                                                                            1. He has never been allotted a PAN;
                                                                            2. His mobile number is linked with his Aadhaar number;
                                                                            3. His complete date of birth is available on the Aadhaar card; and
                                                                            4. He should not be a minor on the date of application for PAN                                          </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes3">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-3">
                                                            <h5>Advisory on Tax Planning</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNinteen" aria-expanded="true" aria-controls="collapseNinteen">
                                                                            What is tax planning?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseNinteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Tax planning is the analysis of a financial situation or plan from a tax perspective.
                                                                            The purpose of tax planning is to ensure tax efficiency. Through tax planning, all
                                                                            elements of the financial plan work together in the most tax-efficient manner
                                                                            possible. Tax planning is an essential part of an individual investor's financial
                                                                            plan. Reduction of tax liability and maximizing the ability to contribute to
                                                                            retirement plans are crucial for success.                                         </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                                                            How Tax Planning Works?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwenty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Tax planning covers several considerations. Considerations include timing of
                                                                            income, size, and timing of purchases, and planning for other expenditures. Also,
                                                                            the selection of investments and types of retirement plans must complement the
                                                                            tax filing status and deductions to create the best possible outcome.                                       </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                                                            Types of tax planning
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentyOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Tax planning is an integral part of every individual’s financial growth story. Since
                                                                            paying taxes is mandatory for every individual falling under the purview of the IT
                                                                            bracket, why not streamline your tax payments in ways that it offers substantial
                                                                            returns over a period of time with minimum risk? In addition, effective planning
                                                                            also reduces your tax liability drastically.
                                                                            The different mindset under which tax planning can be broadly classified are:
                                                                            1. Purposive tax planning:
                                                                            Planning taxes with a particular objective in mind
                                                                            2. Permissive tax planning:
                                                                            Tax planning that is under the framework of law
                                                                            3. Long range and Short range tax planning:
                                                                            Planning done at the start and end of a fiscal year respectively                                         </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes4">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-4">
                                                            <h5>Advance Tax Calculation</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentytwo" aria-expanded="true" aria-controls="collapseTwentytwo">
                                                                            Can I have an invoice for my subscription?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentytwo" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            As per Section 208 of the Income Tax Act, Every person (individual, firm, company,
                                                                            etc.) whose estimated tax liability for the year (i.e., for the year in progress such as
                                                                            FY 2019-20, FY 2020-21, etc.) after TDS (i.e., TDS which is deducted for the
                                                                            person by its payers/clients/banks, etc.) is Rs. 10,000 or more shall pay its tax for
                                                                            the year in advance during the same financial year.                                         </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentythree" aria-expanded="false" aria-controls="collapseTwentythree">
                                                                            What are the due dates for payment of advance tax?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The Advance Tax must be paid in four equal installments on or before the following
                                                                            dates:
                                                                            Installment Due Date
                                                                            1st Installment 15th June
                                                                            2nd Installment 15th September
                                                                            3rd Installment 15th December
                                                                            4th Installment 15th March                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyfour" aria-expanded="false" aria-controls="collapseTwentyfour">
                                                                            How much tax must be paid in each installment of advance tax?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The amount of Advance tax to be paid in each installment must not be less
                                                                            than prescribed in the following table:
                                                                            Installments Amount of Advanced Tax
                                                                            1st
                                                                            Installment Not less than 15% of advance tax liability.
                                                                            2nd
                                                                            Installment
                                                                            Not less than 45% of advance tax liability as reduced by the amount,
                                                                            if any, paid in the earlier installment.
                                                                            3rd
                                                                            Installment
                                                                            Not less than 75% of advance tax liability, as reduced by the
                                                                            amount(s) if any, paid in the earlier installment (s).
                                                                            4th
                                                                            Installment
                                                                            The whole amount of advance tax liability as reduced by the
                                                                            amount(s) if any, paid in the earlier installment (s).                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyfive" aria-expanded="false" aria-controls="collapseTwentyfive">
                                                                            What should we do if the bank is closed on the last day for payment of advance tax?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentyfive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Any taxes paid till 31stMarch will be treated as advance tax.
                                                                            If the last day for the payment of advance tax is the day on which the banks are
                                                                            closed, then one should pay the advance tax on the immediately following working
                                                                            day and no interest shall be charged on such payments of advance tax.                               </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                                                                            Who is not required to pay Advance tax?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentySix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A resident senior citizen (i.e., an individual of the age of 60 years or above during
                                                                            the financial year) not having any income from business or profession is not liable
                                                                            to pay advance tax.
                                                                            Taxpayer who poted for presumptive taxation scheme of section 44ADor section
                                                                            44ADA is liable to pay 100% of advance tax by 15th March.                                </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes5">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-5">
                                                            <h5>TDS on Property purchase (Challan 26QB)</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySeven" aria-expanded="true" aria-controls="collapseTwentySeven">
                                                                            What is TDS on property?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentySeven" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The Finance Bill 2013 has proposed that purchaser of an immovable property
                                                                            (other than rural agricultural land) worth ₹ 50 lakh or more is required to pay
                                                                            withholding tax at the rate of 1% from the consideration payable to a resident
                                                                            transferor.                                         </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
                                                                            Who is responsible to deduct the TDS on sale of Property?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentyEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            According to rules in respect of tax deducted at source, buyer of the property would
                                                                            have to deduct the TDS and deposit the same in Government treasury.                                  </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
                                                                            I am Buyer, do I required to procure TAN to report the TDS on sale of Property?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwentyNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Buyer or Purchaser of the property is not required to procure Tax Deduction
                                                                            Account Number (TAN). The Buyer is required quote his or her PAN and sellers
                                                                            PAN.                                  </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
                                                                            What if I don't have the PAN of the seller, is it Mandatory?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN of the seller is mandatory. The same may be acquired from the Seller before
                                                                            effecting the transaction                       </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyone" aria-expanded="false" aria-controls="collapseThirtyone">
                                                                            What is Form 26QB?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtyone" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The online form available on the TIN website for furnishing information regarding
                                                                            TDS on property is termed as Form 26QB.                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtytwo" aria-expanded="false" aria-controls="collapseThirtytwo">
                                                                            What is the due date of payment of TDS on sale of property?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtytwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            As per the CBDT notification no. 30/2016 dated April 29, 2016, the due date of
                                                                            payment of TDS on transfer of immovable property has been extended to thirty
                                                                            days (from existing seven days) from the end of the month in which the deduction
                                                                            is made.
                                                                            Example: If a taxpayer has made payment of sale consideration in the month of
                                                                            February, then corresponding TDS should be deposited on or before (thirty days)
                                                                            March 30th.                           </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtythree" aria-expanded="false" aria-controls="collapseThirtythree">
                                                                            How will transactions of joint parties (more than one buyer/seller) be filed in Form
                                                                            26QB?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Online statement cum challan Form/ Form 26QB is to be filled in by each buyer
                                                                            for unique buyer-seller combination for respective share. E.g. in case of one buyer
                                                                            and two sellers, two forms have to be filled in and for two buyers and two seller,
                                                                            four forms have to be filled in for respective property shares.                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyfour" aria-expanded="false" aria-controls="collapseThirtyfour">
                                                                            What are the forms to be used for filing quarterly TDS/TCS returns?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Following are the forms for TDS/TCS returns and their periodicity:Form No. Particulars Periodicity Form
                                                                            No.24Q Quarterly statement for tax deducted at source from 'Salaries' Quarterly Form No.26Q Quarterly statement
                                                                            of tax deducted at source in respect of all payments other than 'Salaries' Quarterly Form No.27Q
                                                                            Quarterly statement of deduction of tax from interest, dividend or any other sum payable to non-residents Quarterly
                                                                            Form No.27EQ Quarterly statement of collection of tax at source Quarterly                         </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="newboxes" id="newboxes6">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-6">
                                                            <h5>TDS Returns</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyfive" aria-expanded="true" aria-controls="collapseThirtyfive">
                                                                            Who is required to file e-TDS/TCS return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtyfive" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            As per Income Tax Act, 1961, all corporate and government deductors/ collectors
                                                                            are compulsorily required to file their TDS/TCS returns on electronic media (i.e. eTDS/TCS returns). However, deductors/collectors other than
                                                                            corporate/government can file either in physical or in electronic form.                                      </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtysix" aria-expanded="false" aria-controls="collapseThirtysix">
                                                                            What are the due dates for filing quarterly TDS Returns?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtysix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The due dates for filing quarterly TDS returns, both electronic and paper are as per: Quarunter Due date for TDS statements
                                                                            (all Deductors) April to June 31 July July to September 31 October October to December 31 January
                                                                            January to March 31 May                             </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyseven" aria-expanded="false" aria-controls="collapseThirtyseven">
                                                                            What is the significance if the status of challan is ‘Booked’?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtyseven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If the challan is in Booked status, credit of tax deducted will be reflected in the
                                                                            annual tax statement (Form 26AS) of all the underlying deductees with a valid
                                                                            PAN.
                                                                            Correction in challan details is not allowed once a challan is booked. Correction
                                                                            can be made on underlying deductee records of a booked challan.                               </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyeight" aria-expanded="false" aria-controls="collapseThirtyeight">
                                                                            Is PAN mandatory for deductors and employees/deductees?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtyeight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN of the deductors has to be given by non-Government deductors. It is essential
                                                                            to quote PAN of all deductees.                       </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="newboxes" id="newboxes7">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-7">
                                                            <h5>TAN Registration</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyNine" aria-expanded="true" aria-controls="collapseThirtyNine">
                                                                            What is TAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirtyNine" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            TAN i.e. Tax Deduction and Collection Account Number is a 10 digit alpha
                                                                            numeric number required to be obtained by all persons who are responsible for
                                                                            deducting tax at source or collecting tax at source. It is compulsory to quote TAN in
                                                                            TDS/TCS return (including any e-TDS/TCS return), any TDS/TCS payment
                                                                            challan, TDS/TCS certificates, Annual Information Return and other documents as
                                                                            may be prescribed.                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourty" aria-expanded="false" aria-controls="collapseFourty">
                                                                            Who must apply for TAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Every person liable to deduct tax at source or collect tax at source is required to
                                                                            obtain TAN. However, a person required to deduct tax under section
                                                                            194IA or section 194-IB or section 194M can use PAN in place of TAN as such
                                                                            person is not required to obtain TAN.
                                                                            (1) Section 194IA provides for deduction of tax at source from payment on transfer
                                                                            of certain immovable property other than agricultural land.
                                                                            (2) section 194-IB provides for deduction of tax at source at the rate of 5% from
                                                                            payment of rent. The tax shall be deducted if the rent paid or payable exceeds Rs.
                                                                            50,000 per month or part of the month. This provision is applicable on individual
                                                                            and HUF, who is not liable to tax audit under Section 44AB in the immediately
                                                                            preceding financial year.
                                                                            (3) section 194M provide for deduction of tax at source at the rate of 5% on the
                                                                            sum paid or credited, to a resident, on account of contractual work, commission
                                                                            (not being insurance commission referred to in section 194D), brokerage or
                                                                            professional fees, by an individual or a HUF, if aggregate of such sum exceeds Rs.
                                                                            50 lakhs in a financial year. This provision is applicable on individual and HUF,
                                                                            who are not covered under section 194C, section 194H and section 194J.                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyone" aria-expanded="false" aria-controls="collapseFourtyone">
                                                                            Why is it necessary to have TAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtyone" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            As per section 203A of the Income-tax Act, 1961, every person who deducts or
                                                                            collects tax at source has to apply for the allotment of TAN. Section 203A also
                                                                            makes it mandatory to quote TAN in TDS/TCS return (including any e-TDS/TCS
                                                                            return), any TDS/TCS payment challan, TDS/TCS certificates and other
                                                                            documents as may be prescribed. As per section 272BB, failure to apply for TAN or
                                                                            not quoting the TAN in the specified documents attracts a penalty of Rs. 10,000/-.                               </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtytwo" aria-expanded="false" aria-controls="collapseFourtytwo">
                                                                            Is it necessary to apply for different TAN if a deductor has to deduct tax from
                                                                            different types of payments like salary, interest, dividend, commission, etc.?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtytwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No. TAN once allotted can be used for all type of deductions. It can also be used in
                                                                            case tax is being collected at source.                       </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtythree" aria-expanded="false" aria-controls="collapseFourtythree">
                                                                            Is a separate TAN required to be obtained for the purpose of tax collection at source?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            In case a TAN has already been allotted for the purpose of tax deduction at source
                                                                            (TDS), then no separate application needs to be made for obtaining separate TAN
                                                                            for the purpose of tax collection at source (TCS). The same number can be quoted
                                                                            in all returns, challans and certificates for TCS. However, if no TAN has been
                                                                            allotted, a duly filled in Form 49B, along with the application fees is to be
                                                                            submitted at any TIN-FC.                       </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyfour" aria-expanded="false" aria-controls="collapseFourtyfour">
                                                                            Can an e-TDS return be filed without TAN or in case TAN has not been alloted?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Quoting of TAN is mandatory in TDS and TCS returns, whether filed in paper or
                                                                            electronic format. The returns, whether in paper or electronic format, will not be
                                                                            received in case TAN is not quoted.                       </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyfive" aria-expanded="false" aria-controls="collapseFourtyfive">
                                                                            Can we quote Permanent Account Number (PAN) in place of TAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtyfive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No. PAN should never be quoted in the field where TAN is required to be quoted.
                                                                            The purposes for which PAN and TAN are allotted are different. TAN is a unique
                                                                            identification number which is allotted to persons who are required to deduct tax
                                                                            at source or collect tax at source.                      </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtysix" aria-expanded="false" aria-controls="collapseFourtysix">
                                                                            How can any change in address or details on the basis of which TAN was allotted be rectified?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtysix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If there is any change in the data provided in the application for allotment of TAN
                                                                            (i.e. data provide at the time of allotment of TAN), then such change is to be
                                                                            communicated to the Income Tax Department by intimating the change in , the
                                                                            "Form for Change or Correction in TAN data". Duly filed form should be submitted
                                                                            to the TIN Facilitation Centres or an online application can be made
                                                                            at https://www.tin-nsdl.com                       </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="newboxes" id="newboxes8">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-8">
                                                            <h5>SFT Filling (Statement of Financial Transaction)</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyseven" aria-expanded="true" aria-controls="collapseFourtyseven">
                                                                            What is statement of financial transaction or reportable account previously called
                                                                            as ‘Annual Information Return (AIR)’?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtyseven" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            As per section 285BA of the Income-tax Act, 1961 (as substituted by Finance Act,
                                                                            2014 w.e.f. 01-04-2015), specified entities (Filers) are required to furnish a
                                                                            statement of financial transaction or reportable account (hereinafter referred to as
                                                                            ‘statement’) in respect of specified financial transactions or any reportable account
                                                                            registered/recorded/maintained by them during the financial year to the incometax authority or such other prescribed authority.                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtyeight" aria-expanded="false" aria-controls="collapseFourtyeight">
                                                                            Who is required to furnish statement of financial transactions and reportable accounts?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtyeight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Following persons shall be required to furnish statement of financial transactions
                                                                            or reportable accounts registered or recorded or maintained by them during a
                                                                            financial year to the prescribed authority:
                                                                            (a) an assessee;
                                                                            (b) the prescribed person in the case of an office of Government;
                                                                            (c) a local authority or other public body or association;
                                                                            (d) the Registrar or Sub-Registrar appointed under section 6 of the Registration
                                                                            Act, 1908 (16 of 1908);
                                                                            (e) the registering authority empowered to register motor vehicles under Chapter IV
                                                                            of the Motor Vehicles Act, 1988 (59 of 1988);
                                                                            (f) the Post Master General as referred to in clause (j) of section 2 of the Indian Post
                                                                            Office Act, 1898 (6 of 1898);
                                                                            (g) the Collector referred to in clause (g) of section 3 of the Right to Fair
                                                                            Compensation and Transparency in Land Acquisition, Rehabilitation and
                                                                            Resettlement Act, 2013 (30 of 2013);
                                                                            (h) the recognised stock exchange referred to in clause (f) of section 2 of the
                                                                            Securities Contracts (Regulation) Act, 1956 (42 of 1956);
                                                                            (i) an officer of the Reserve Bank of India, constituted under section 3 of the
                                                                            Reserve Bank of India Act, 1934 (2 of 1934);
                                                                            (j) a depository referred to in clause (e) of sub-section (1) of section 2 of the
                                                                            Depositories Act, 1996 (22 of 1996); or
                                                                            (k) a prescribed reporting financial institutions
                                                                            (i) a person, other than those referred to in clause (a) to (k), as may be prescribed.                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtynine" aria-expanded="false" aria-controls="collapseFourtynine">
                                                                            What is the due date for filing of statement of financial transaction or reportable
                                                                            account by prescribed reporting financial institution?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourtynine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            In order to facilitate effective exchange of information in respect of resident and
                                                                            non-resident, Section 285BA also provides for furnishing of statement by a
                                                                            prescribed reporting financial institution in respect of specified financial
                                                                            transaction or reportable account. The statement shall be furnished for each
                                                                            calendar year in Form No. 61B on or before 31st May of the next year. [For more
                                                                            details, see rules 114F, 114G and 114H of the Income-tax Rules, 1962]                               </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifty" aria-expanded="false" aria-controls="collapseFifty">
                                                                            What are the consequences of not filing statement of financial transaction or
                                                                            reportable account?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFifty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Non-furnishing of statement of financial transaction or reportable account will
                                                                            attract penalty under section 271FA. Penalty can be levied of Rs. 500 per day of
                                                                            default.
                                                                            However, section 285BA(5) (as discussed earlier) empower the tax authorities to
                                                                            issue a notice to the person directing him to file the statement within a period not
                                                                            exceeding 30 days from the date of service of such notice and in such a case
                                                                            person shall furnish the statement within the time specified in the notice. If person
                                                                            fails to file the statement within the specified time, then a penalty of Rs. 1,000 per
                                                                            day will be levied from the day immediately following the day on which the time
                                                                            specified in such notice for furnishing the statement expires.                      </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="newboxes" id="newboxes9">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-9">
                                                            <h5>Income Tax Notice</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyone" aria-expanded="true" aria-controls="collapseFiftyone">
                                                                            What is meant by assessment?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftyone" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Every taxpayer has to furnish the income and investment details to the Income-tax
                                                                            Department. These details are to be furnished by filing return. Once the return is
                                                                            filed by the taxpayer, the next step is the processing of the return by the Incometax Department. The Income-tax Department examines the return. The process of
                                                                            examining the return of by the Income-tax Department is called “Assessment”.
                                                                            Assessment also includes re-assessment or best judgment assessment.                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftytwo" aria-expanded="false" aria-controls="collapseFiftytwo">
                                                                            What are the major assessments under the Income-tax Law?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftytwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Under the Income-tax Law, there are four major assessments as given below:
                                                                            1. Section 143(1), i.e., Summary assessment without calling the
                                                                            assessee i.e. taxpayer.
                                                                            2. Assessment under section 143(3), i.e., Scrutiny assessment.
                                                                            3. Assessment under section 144, i.e., Best judgment assessment.
                                                                            4. Assessment under section 147, i.e., Income escaping assessment                         </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftythree" aria-expanded="false" aria-controls="collapseFiftythree">
                                                                            What is assessment under section 143(1)?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftythree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Scope of assessment under section 143(1)
                                                                            Assessment under section 143(1) is like preliminary checking of the return of
                                                                            income. At this stage no detailed scrutiny of the return of income is carried out. At
                                                                            this stage, the total income or loss is computed after making the following
                                                                            adjustments (if any), namely:-
                                                                            (i) any arithmetical error in the return; or
                                                                            (ii) an incorrect claim, if such incorrect claim is apparent from any information in
                                                                            the return;
                                                                            (iii) disallowance of loss claimed, if return of the previous year for which set off of
                                                                            loss is claimed was furnished beyond the due date specified under sub-section (1)
                                                                            of section 139
                                                                            (iv) disallowance of expenditure indicated in the audit report but not taken into
                                                                            account in computing the total income in the return;
                                                                            (v) disallowance of deduction claimed under sections 10AA, 80-IA, 80-IB, 80-
                                                                            IC, 80-ID or section 80-IE, if the return is furnished beyond the due date specified
                                                                            under sub-section (1) of section 139; or
                                                                            (vi) addition of income appearing in Form 26AS or Form 16A or Form 16 which has
                                                                            not been included in computing the total income in the return.                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyfour" aria-expanded="false" aria-controls="collapseFiftyfour">
                                                                            What is the time limit for making the assessment under section 143(1)?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftyfour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Assessment under section 143(1) can be made within a period of one year from the
                                                                            end of the financial year in which the return of income is filed.                      </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyfive" aria-expanded="false" aria-controls="collapseFiftyfive">
                                                                            How to file response to notice issued under section 143(2)?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftyfive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If a return has been furnished under section 139 or in response to notice
                                                                            under section 142(1), the Assessing Officer or the prescribed income tax authority,
                                                                            as the case may be, if, considers it necessary to ensure that the assessee has not
                                                                            understated the income or has not computed excessive loss or has not under-paid
                                                                            the tax in any manner, shall serve a notice on the assessee requiring him on a date
                                                                            specified in the notice, either to attend the office of the Assessing Officer or to
                                                                            produce before the Assessing Officer any evidence on which the assessee may rely
                                                                            in support of the return.
                                                                            Provided that no notice under this sub section shall be served on the assessee
                                                                            after the expiry of 6 months from the end of the financial year in which the return
                                                                            is furnished.                     </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="newboxes" id="newboxes10">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-10">
                                                            <h5>Filling of Form 15CA (Foreign Remittance)</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftysix" aria-expanded="true" aria-controls="collapseFiftysix">
                                                                            What is the need of 15CA and 15CB ?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftysix" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Earlier, the person making a remittance to Non-Resident was required to
                                                                            furnish a certificate in specified format circulated by RBI.
                                                                            1. Basic purpose was to collect the taxes at a stage when the remittance is made
                                                                            as it may not be possible to collect the tax from the NR at a later stage.
                                                                            2. Thus to monitor and track the transactions in an efficient manner, it was
                                                                            proposed to introduce e-filling of information in the certificates.
                                                                            3. Section 195 of Income tax act,1961 mandates the deduction of Income tax from
                                                                            payments made to Non Resident. The person making the remittance to non –
                                                                            resident needs to furnish an undertaking (in form 15CA) accompanied by a
                                                                            Chartered Accountant Certificate in Form 15CB.                                  </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiftyseven" aria-expanded="false" aria-controls="collapseFiftyseven">
                                                                            Do individuals have to furnish Form 15CA and 15CB?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFiftyseven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Form 15CA and 15CB will be NOT be required to be furnished by an individual for remittance which does not require RBI approval.                       </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    GST
                                                </button>
                                            </h2>
                                            {isShow2 && <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive" id="flush-collapseTwo" class="accordion-collapse collapse more-faq-custom" data-bs-parent="#accordionFlushExample">
                                                <a
                                                    className={`service-box-mob ${activeId === 1 ? "active" : ""}`}
                                                    id="myHeader1"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes1");
                                                        handleClick(1);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices2(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Registration{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>


                                                <a
                                                    className={`service-box-mob position-set ${activeId === 2 ? "active" : ""
                                                        }`}
                                                    id="myHeader2"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes2");
                                                        handleClick(2);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices2(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Return Filling{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                            </div>}
                                            {<div className={`col-lg-4 col-xl-5 ${isShow2 ? 'd-none' : ''}`}>
                                                <div className="rightboxmain">
                                                    <div className="newboxes" id="newboxes1"
                                                        style={{ display: "block" }}
                                                    >
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices2(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-1">
                                                            <h5>Income Tax Return</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            What is a return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR stands for Income Tax Return. It is a prescribed form through which the particulars of income earned by a person in a financial year and taxes paid on such income are communicated to the Income-tax Department. It also allows carry -forward of loss and claim refund from income tax department.Different forms of returns of income are prescribed for filing of returns for different Status and Nature of income. These forms can be downloaded from www.incometaxindia.gov.in
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            Is it necessary to attach any documents along with the return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Will I be put to any disadvantage by filing my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, on the contrary by not filing your return inspite of having taxable income, you will be liable to the penalty and prosecution provisions under the Income-tax Act.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            What are the benefits of filing my return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                            Is it necessary to file return of income when I do not have any positive income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                            Will I be penalized on late filing of ITR even if I am not liable to file it?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, late filing fee under section 234F not leviable in case you are not required to file ITR as per section 139 but filing it voluntary though after the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                            If I fail to furnish my return within the due date, will I be fined or penalized?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, if a person who is required to furnish a return of income under section 139 and fails to do so within time prescribed in sub-section (1) , you will have to pay interest on tax due. W.e.f. assessment year 2018-19, fee as per section 234F is required to be paid if return is furnished after due date. Fee for default in furnishing return of income will be as follows:
                                                                            i.	Rs. 5000 if return is furnished on or before the 31st day of December of the assessment year;
                                                                            ii.	Rs. 10,000 in any other case
                                                                            However, late filing fee shall not exceed Rs. 1000 if the total income of an assessee does not exceed Rs. 5 lakh.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                            If I have paid excess tax how will it be refunded to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The excess tax can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. The department has been making efforts to settle refund claims at the earliest.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                            If I have committed any mistake in my original return, am I permitted to file a revised return to correct the mistake?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A return of income can be revised at any time during the assessment year or before the assessment made whichever is earlier.
                                                                            If original return has filed in paper format or manually, then technically it cannot be revised by online mode or electronically.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                            There are various deductions that are not reflected in the Form 16 issued by my employer. Can I claim them in my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, it can be claimed if you are otherwise eligible to claim the same.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                            Why is return filing mandatory, even though all my taxes and interests have been paid and there is no refund due to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Amounts paid as advance tax and withheld in the form of TDS or collected in the form of TCS will take the character of your tax due only on completion of self-assessment of your income. This self-assessment is intimated to the Department by way of filing of the return of income. Only then the Government assumes rights over the taxes paid by you. Filing of return is critical for this process and, hence, has been made mandatory. Failure will attract levy of penalty.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                            What is Form 26AS?                                            </button>
                                                                    </h2>
                                                                    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A taxpayer may pay tax in any of the following forms:
                                                                            (1) Tax Deducted at Source (TDS)
                                                                            (2) Tax Collected at Source (TCS)
                                                                            (3) Advance tax or Self-assessment Tax or Payment of tax on regular assessment.
                                                                            The Income-tax Department maintains the database of the total tax paid by the taxpayer (i.e., tax credit in the account of a taxpayer).  Form 26AS is an annual statement maintained under Rule 31AB of the Income-tax Rules disclosing the details of tax credit in his account as per the database of Income-tax Department. In other words, Form 26AS will reflect the details of tax credit appearing in the Permanent Account Number of the taxpayer as per the database of the Income-tax Department. The tax credit will cover TDS, TCS and tax paid by the taxpayer in other forms like advance tax, Self-Assessment tax, etc.
                                                                            Income-tax Department will generally allow a taxpayer to claim the credit of taxes as reflected in his Form 26AS.                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            {/* <Link to="/incometaxreadmore"> */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark regbtn md"
                                                                onclick="location.href='income-tax.html';"
                                                            >
                                                                READ MORE
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes2">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices2(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-2">
                                                            <h5>Application For Pan</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                                            What is PAN? What is the utility of the PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN stands for Permanent Account Number. PAN is a ten-digit unique
                                                                            alphanumeric number issued by the Income Tax Department. PAN is issued in the
                                                                            form of a laminated plastic card (commonly known as PAN card). Given below is an
                                                                            illustrative PAN:ALWPG5809L
                                                                            PAN enables the department to link all transactions of the assessee with the
                                                                            department. These transactions include tax payments, TDS/TCS credits, returns of
                                                                            income, specified transactions, correspondence and so on. It facilitates easy
                                                                            retrieval of information of assessee and matching of various investments,
                                                                            borrowings and other business activities of assessee.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                                            Who has to obtain PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN is to be obtained by following persons:
                                                                            1. Every person if his total income or the total income of any other person in
                                                                            respect of which he is assessable during the previous year exceeds the
                                                                            maximum amount which is not chargeable to tax.
                                                                            2. A charitable trust who is required to furnish return under Section 139(4A)
                                                                            3. Every person who is carrying on any business or profession whose total sale,
                                                                            turnover, or gross receipts are or is likely to exceed five lakh rupees in any
                                                                            previous year
                                                                            4. Every person who intends to enter into specified financial transactions in which
                                                                            quoting of PAN is mandatory
                                                                            Every non-individual resident persons and person associated with them if the
                                                                            financial transaction entered into by such non-individual resident persons during a
                                                                            financial year exceeds Rs. 2,50,000.
                                                                            Note: Persons associated with non-individual resident persons means the managing
                                                                            director, director, partner, trustee, author, founder, karta, chief executive officer,
                                                                            principal officer or office bearer of the non-individual resident persons or any person
                                                                            competent to act on behalf of such persons.
                                                                            A person not covered in any of the above can voluntarily apply for PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                                            Can I file my return of income without quoting PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            It is mandatory to quote PAN on the return of income. Apart from return of income,
                                                                            PAN is also to be quoted in all challans for making payment of tax, correspondence
                                                                            with the Income Tax Department, etc.
                                                                            However, w.e.f., 01-09-2019, every person who has been allotted a PAN, and who
                                                                            has linked his Aadhaar number with PAN as per section 139AA, may furnish his
                                                                            Aadhaar number in lieu of a PAN for all the transactions where quoting of PAN is
                                                                            mandatory as per Income-tax Act. Thus, with effect from September 1, 2019, an
                                                                            assessee can file his return of income by quoting his Aadhaar number instead of
                                                                            quoting PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                                            How to link Aadhaar number with PAN using e-Filing portal?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Taxpayer is required to login on e-Filing portal using his registered username and
                                                                            password. After login, he will get the “link Aadhaar” option under his ‘Profile
                                                                            Settings’.
                                                                            Aadhaar number will be linked with PAN if name and date of birth of taxpayer is
                                                                            identical to Aadhaar and PAN.
                                                                            After making the PAN application how can I check the status of my PAN
                                                                            application?
                                                                            After applying for PAN, one can check the status of his application by using the
                                                                            track status facility available at web sites of Income Tax Department or UTIITSL or
                                                                            NSDL i.e. www.incometaxindia.gov.in or www.utiitsl.com and www.tin-nsdl.com .                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                                            If my PAN card is lost then what to do?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If the PAN card is lost or you wish to have a new PAN card with existing details,
                                                                            then you can apply for duplicate PAN card by submitting the Form for "Request for
                                                                            New PAN Card or/ and Changes or Correction in PAN Data" and a copy of FIR may
                                                                            be submitted along with the form.
                                                                            If the PAN card is lost and you don't remember your PAN, then in such a case, you
                                                                            can know you PAN by using the facility of "Know Your PAN" provided by the Income
                                                                            Tax Department. This facility can be availed of from the website of Income Tax
                                                                            Department - www.incometaxindiaefiling.gov.in
                                                                            You can know your PAN online by providing the core details like Name, Father's
                                                                            Name and Date of Birth.
                                                                            After knowing the PAN you can apply for duplicate PAN card by submitting the
                                                                            "Request For New PAN Card Or/ And Changes Or Correction in PAN Data".
                                                                            Applicant is required to fill all the columns in the form but should not tick any of
                                                                            the boxes on the left margin.                                           </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                                                            What is Instant-PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            E Income-tax Dept. has launched a new functionality on the e-filing portal which
                                                                            allots a PAN to the assessee on basis of his Aadhaar Number.
                                                                            This facility can be used by an assessee only if the following conditions are fulfilled:
                                                                            1. He has never been allotted a PAN;
                                                                            2. His mobile number is linked with his Aadhaar number;
                                                                            3. His complete date of birth is available on the Aadhaar card; and
                                                                            4. He should not be a minor on the date of application for PAN                                          </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Registartions
                                                </button>
                                            </h2>
                                            {isShow3 && <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive" id="flush-collapseThree" class="accordion-collapse collapse more-faq-custom" data-bs-parent="#accordionFlushExample">
                                                <a
                                                    className={`service-box-mob ${activeId === 1 ? "active" : ""}`}
                                                    id="myHeader1"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes1");
                                                        handleClick(1);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices3(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Registration{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>


                                                <a
                                                    className={`service-box-mob position-set ${activeId === 2 ? "active" : ""
                                                        }`}
                                                    id="myHeader2"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes2");
                                                        handleClick(2);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices3(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Return Filling{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>
                                            </div>}
                                            {<div className={`col-lg-4 col-xl-5 ${isShow3 ? 'd-none' : ''}`}>
                                                <div className="rightboxmain">
                                                    <div className="newboxes" id="newboxes1"
                                                        style={{ display: "block" }}
                                                    >
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices3(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-1">
                                                            <h5>Income Tax Return</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            What is a return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR stands for Income Tax Return. It is a prescribed form through which the particulars of income earned by a person in a financial year and taxes paid on such income are communicated to the Income-tax Department. It also allows carry -forward of loss and claim refund from income tax department.Different forms of returns of income are prescribed for filing of returns for different Status and Nature of income. These forms can be downloaded from www.incometaxindia.gov.in
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            Is it necessary to attach any documents along with the return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Will I be put to any disadvantage by filing my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, on the contrary by not filing your return inspite of having taxable income, you will be liable to the penalty and prosecution provisions under the Income-tax Act.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            What are the benefits of filing my return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                            Is it necessary to file return of income when I do not have any positive income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                            Will I be penalized on late filing of ITR even if I am not liable to file it?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, late filing fee under section 234F not leviable in case you are not required to file ITR as per section 139 but filing it voluntary though after the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                            If I fail to furnish my return within the due date, will I be fined or penalized?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, if a person who is required to furnish a return of income under section 139 and fails to do so within time prescribed in sub-section (1) , you will have to pay interest on tax due. W.e.f. assessment year 2018-19, fee as per section 234F is required to be paid if return is furnished after due date. Fee for default in furnishing return of income will be as follows:
                                                                            i.	Rs. 5000 if return is furnished on or before the 31st day of December of the assessment year;
                                                                            ii.	Rs. 10,000 in any other case
                                                                            However, late filing fee shall not exceed Rs. 1000 if the total income of an assessee does not exceed Rs. 5 lakh.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                            If I have paid excess tax how will it be refunded to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The excess tax can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. The department has been making efforts to settle refund claims at the earliest.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                            If I have committed any mistake in my original return, am I permitted to file a revised return to correct the mistake?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A return of income can be revised at any time during the assessment year or before the assessment made whichever is earlier.
                                                                            If original return has filed in paper format or manually, then technically it cannot be revised by online mode or electronically.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                            There are various deductions that are not reflected in the Form 16 issued by my employer. Can I claim them in my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, it can be claimed if you are otherwise eligible to claim the same.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                            Why is return filing mandatory, even though all my taxes and interests have been paid and there is no refund due to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Amounts paid as advance tax and withheld in the form of TDS or collected in the form of TCS will take the character of your tax due only on completion of self-assessment of your income. This self-assessment is intimated to the Department by way of filing of the return of income. Only then the Government assumes rights over the taxes paid by you. Filing of return is critical for this process and, hence, has been made mandatory. Failure will attract levy of penalty.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                            What is Form 26AS?                                            </button>
                                                                    </h2>
                                                                    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A taxpayer may pay tax in any of the following forms:
                                                                            (1) Tax Deducted at Source (TDS)
                                                                            (2) Tax Collected at Source (TCS)
                                                                            (3) Advance tax or Self-assessment Tax or Payment of tax on regular assessment.
                                                                            The Income-tax Department maintains the database of the total tax paid by the taxpayer (i.e., tax credit in the account of a taxpayer).  Form 26AS is an annual statement maintained under Rule 31AB of the Income-tax Rules disclosing the details of tax credit in his account as per the database of Income-tax Department. In other words, Form 26AS will reflect the details of tax credit appearing in the Permanent Account Number of the taxpayer as per the database of the Income-tax Department. The tax credit will cover TDS, TCS and tax paid by the taxpayer in other forms like advance tax, Self-Assessment tax, etc.
                                                                            Income-tax Department will generally allow a taxpayer to claim the credit of taxes as reflected in his Form 26AS.                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            {/* <Link to="/incometaxreadmore"> */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark regbtn md"
                                                                onclick="location.href='income-tax.html';"
                                                            >
                                                                READ MORE
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes2">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices3(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-2">
                                                            <h5>Application For Pan</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                                            What is PAN? What is the utility of the PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN stands for Permanent Account Number. PAN is a ten-digit unique
                                                                            alphanumeric number issued by the Income Tax Department. PAN is issued in the
                                                                            form of a laminated plastic card (commonly known as PAN card). Given below is an
                                                                            illustrative PAN:ALWPG5809L
                                                                            PAN enables the department to link all transactions of the assessee with the
                                                                            department. These transactions include tax payments, TDS/TCS credits, returns of
                                                                            income, specified transactions, correspondence and so on. It facilitates easy
                                                                            retrieval of information of assessee and matching of various investments,
                                                                            borrowings and other business activities of assessee.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                                            Who has to obtain PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN is to be obtained by following persons:
                                                                            1. Every person if his total income or the total income of any other person in
                                                                            respect of which he is assessable during the previous year exceeds the
                                                                            maximum amount which is not chargeable to tax.
                                                                            2. A charitable trust who is required to furnish return under Section 139(4A)
                                                                            3. Every person who is carrying on any business or profession whose total sale,
                                                                            turnover, or gross receipts are or is likely to exceed five lakh rupees in any
                                                                            previous year
                                                                            4. Every person who intends to enter into specified financial transactions in which
                                                                            quoting of PAN is mandatory
                                                                            Every non-individual resident persons and person associated with them if the
                                                                            financial transaction entered into by such non-individual resident persons during a
                                                                            financial year exceeds Rs. 2,50,000.
                                                                            Note: Persons associated with non-individual resident persons means the managing
                                                                            director, director, partner, trustee, author, founder, karta, chief executive officer,
                                                                            principal officer or office bearer of the non-individual resident persons or any person
                                                                            competent to act on behalf of such persons.
                                                                            A person not covered in any of the above can voluntarily apply for PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                                            Can I file my return of income without quoting PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            It is mandatory to quote PAN on the return of income. Apart from return of income,
                                                                            PAN is also to be quoted in all challans for making payment of tax, correspondence
                                                                            with the Income Tax Department, etc.
                                                                            However, w.e.f., 01-09-2019, every person who has been allotted a PAN, and who
                                                                            has linked his Aadhaar number with PAN as per section 139AA, may furnish his
                                                                            Aadhaar number in lieu of a PAN for all the transactions where quoting of PAN is
                                                                            mandatory as per Income-tax Act. Thus, with effect from September 1, 2019, an
                                                                            assessee can file his return of income by quoting his Aadhaar number instead of
                                                                            quoting PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                                            How to link Aadhaar number with PAN using e-Filing portal?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Taxpayer is required to login on e-Filing portal using his registered username and
                                                                            password. After login, he will get the “link Aadhaar” option under his ‘Profile
                                                                            Settings’.
                                                                            Aadhaar number will be linked with PAN if name and date of birth of taxpayer is
                                                                            identical to Aadhaar and PAN.
                                                                            After making the PAN application how can I check the status of my PAN
                                                                            application?
                                                                            After applying for PAN, one can check the status of his application by using the
                                                                            track status facility available at web sites of Income Tax Department or UTIITSL or
                                                                            NSDL i.e. www.incometaxindia.gov.in or www.utiitsl.com and www.tin-nsdl.com .                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                                            If my PAN card is lost then what to do?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If the PAN card is lost or you wish to have a new PAN card with existing details,
                                                                            then you can apply for duplicate PAN card by submitting the Form for "Request for
                                                                            New PAN Card or/ and Changes or Correction in PAN Data" and a copy of FIR may
                                                                            be submitted along with the form.
                                                                            If the PAN card is lost and you don't remember your PAN, then in such a case, you
                                                                            can know you PAN by using the facility of "Know Your PAN" provided by the Income
                                                                            Tax Department. This facility can be availed of from the website of Income Tax
                                                                            Department - www.incometaxindiaefiling.gov.in
                                                                            You can know your PAN online by providing the core details like Name, Father's
                                                                            Name and Date of Birth.
                                                                            After knowing the PAN you can apply for duplicate PAN card by submitting the
                                                                            "Request For New PAN Card Or/ And Changes Or Correction in PAN Data".
                                                                            Applicant is required to fill all the columns in the form but should not tick any of
                                                                            the boxes on the left margin.                                           </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                                                            What is Instant-PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            E Income-tax Dept. has launched a new functionality on the e-filing portal which
                                                                            allots a PAN to the assessee on basis of his Aadhaar Number.
                                                                            This facility can be used by an assessee only if the following conditions are fulfilled:
                                                                            1. He has never been allotted a PAN;
                                                                            2. His mobile number is linked with his Aadhaar number;
                                                                            3. His complete date of birth is available on the Aadhaar card; and
                                                                            4. He should not be a minor on the date of application for PAN                                          </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Business Support Service
                                                </button>
                                            </h2>
                                            {isShow4 && <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive" id="flush-collapseFour" class="accordion-collapse collapse more-faq-custom" data-bs-parent="#accordionFlushExample">
                                                <a
                                                    className={`service-box-mob ${activeId === 1 ? "active" : ""}`}
                                                    id="myHeader1"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes1");
                                                        handleClick(1);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices4(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Registration{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob position-set ${activeId === 2 ? "active" : ""
                                                        }`}
                                                    id="myHeader2"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes2");
                                                        handleClick(2);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices4(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Return Filling{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>
                                            </div>}
                                            {<div className={`col-lg-4 col-xl-5 ${isShow4 ? 'd-none' : ''}`}>
                                                <div className="rightboxmain">
                                                    <div className="newboxes" id="newboxes1"
                                                        style={{ display: "block" }}
                                                    >
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices4(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-1">
                                                            <h5>Income Tax Return</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            What is a return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR stands for Income Tax Return. It is a prescribed form through which the particulars of income earned by a person in a financial year and taxes paid on such income are communicated to the Income-tax Department. It also allows carry -forward of loss and claim refund from income tax department.Different forms of returns of income are prescribed for filing of returns for different Status and Nature of income. These forms can be downloaded from www.incometaxindia.gov.in
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            Is it necessary to attach any documents along with the return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Will I be put to any disadvantage by filing my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, on the contrary by not filing your return inspite of having taxable income, you will be liable to the penalty and prosecution provisions under the Income-tax Act.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            What are the benefits of filing my return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                            Is it necessary to file return of income when I do not have any positive income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                            Will I be penalized on late filing of ITR even if I am not liable to file it?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, late filing fee under section 234F not leviable in case you are not required to file ITR as per section 139 but filing it voluntary though after the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                            If I fail to furnish my return within the due date, will I be fined or penalized?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, if a person who is required to furnish a return of income under section 139 and fails to do so within time prescribed in sub-section (1) , you will have to pay interest on tax due. W.e.f. assessment year 2018-19, fee as per section 234F is required to be paid if return is furnished after due date. Fee for default in furnishing return of income will be as follows:
                                                                            i.	Rs. 5000 if return is furnished on or before the 31st day of December of the assessment year;
                                                                            ii.	Rs. 10,000 in any other case
                                                                            However, late filing fee shall not exceed Rs. 1000 if the total income of an assessee does not exceed Rs. 5 lakh.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                            If I have paid excess tax how will it be refunded to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The excess tax can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. The department has been making efforts to settle refund claims at the earliest.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                            If I have committed any mistake in my original return, am I permitted to file a revised return to correct the mistake?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A return of income can be revised at any time during the assessment year or before the assessment made whichever is earlier.
                                                                            If original return has filed in paper format or manually, then technically it cannot be revised by online mode or electronically.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                            There are various deductions that are not reflected in the Form 16 issued by my employer. Can I claim them in my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, it can be claimed if you are otherwise eligible to claim the same.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                            Why is return filing mandatory, even though all my taxes and interests have been paid and there is no refund due to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Amounts paid as advance tax and withheld in the form of TDS or collected in the form of TCS will take the character of your tax due only on completion of self-assessment of your income. This self-assessment is intimated to the Department by way of filing of the return of income. Only then the Government assumes rights over the taxes paid by you. Filing of return is critical for this process and, hence, has been made mandatory. Failure will attract levy of penalty.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                            What is Form 26AS?                                            </button>
                                                                    </h2>
                                                                    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A taxpayer may pay tax in any of the following forms:
                                                                            (1) Tax Deducted at Source (TDS)
                                                                            (2) Tax Collected at Source (TCS)
                                                                            (3) Advance tax or Self-assessment Tax or Payment of tax on regular assessment.
                                                                            The Income-tax Department maintains the database of the total tax paid by the taxpayer (i.e., tax credit in the account of a taxpayer).  Form 26AS is an annual statement maintained under Rule 31AB of the Income-tax Rules disclosing the details of tax credit in his account as per the database of Income-tax Department. In other words, Form 26AS will reflect the details of tax credit appearing in the Permanent Account Number of the taxpayer as per the database of the Income-tax Department. The tax credit will cover TDS, TCS and tax paid by the taxpayer in other forms like advance tax, Self-Assessment tax, etc.
                                                                            Income-tax Department will generally allow a taxpayer to claim the credit of taxes as reflected in his Form 26AS.                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            {/* <Link to="/incometaxreadmore"> */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark regbtn md"
                                                                onclick="location.href='income-tax.html';"
                                                            >
                                                                READ MORE
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes2">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices4(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-2">
                                                            <h5>Application For Pan</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                                            What is PAN? What is the utility of the PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN stands for Permanent Account Number. PAN is a ten-digit unique
                                                                            alphanumeric number issued by the Income Tax Department. PAN is issued in the
                                                                            form of a laminated plastic card (commonly known as PAN card). Given below is an
                                                                            illustrative PAN:ALWPG5809L
                                                                            PAN enables the department to link all transactions of the assessee with the
                                                                            department. These transactions include tax payments, TDS/TCS credits, returns of
                                                                            income, specified transactions, correspondence and so on. It facilitates easy
                                                                            retrieval of information of assessee and matching of various investments,
                                                                            borrowings and other business activities of assessee.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                                            Who has to obtain PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN is to be obtained by following persons:
                                                                            1. Every person if his total income or the total income of any other person in
                                                                            respect of which he is assessable during the previous year exceeds the
                                                                            maximum amount which is not chargeable to tax.
                                                                            2. A charitable trust who is required to furnish return under Section 139(4A)
                                                                            3. Every person who is carrying on any business or profession whose total sale,
                                                                            turnover, or gross receipts are or is likely to exceed five lakh rupees in any
                                                                            previous year
                                                                            4. Every person who intends to enter into specified financial transactions in which
                                                                            quoting of PAN is mandatory
                                                                            Every non-individual resident persons and person associated with them if the
                                                                            financial transaction entered into by such non-individual resident persons during a
                                                                            financial year exceeds Rs. 2,50,000.
                                                                            Note: Persons associated with non-individual resident persons means the managing
                                                                            director, director, partner, trustee, author, founder, karta, chief executive officer,
                                                                            principal officer or office bearer of the non-individual resident persons or any person
                                                                            competent to act on behalf of such persons.
                                                                            A person not covered in any of the above can voluntarily apply for PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                                            Can I file my return of income without quoting PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            It is mandatory to quote PAN on the return of income. Apart from return of income,
                                                                            PAN is also to be quoted in all challans for making payment of tax, correspondence
                                                                            with the Income Tax Department, etc.
                                                                            However, w.e.f., 01-09-2019, every person who has been allotted a PAN, and who
                                                                            has linked his Aadhaar number with PAN as per section 139AA, may furnish his
                                                                            Aadhaar number in lieu of a PAN for all the transactions where quoting of PAN is
                                                                            mandatory as per Income-tax Act. Thus, with effect from September 1, 2019, an
                                                                            assessee can file his return of income by quoting his Aadhaar number instead of
                                                                            quoting PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                                            How to link Aadhaar number with PAN using e-Filing portal?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Taxpayer is required to login on e-Filing portal using his registered username and
                                                                            password. After login, he will get the “link Aadhaar” option under his ‘Profile
                                                                            Settings’.
                                                                            Aadhaar number will be linked with PAN if name and date of birth of taxpayer is
                                                                            identical to Aadhaar and PAN.
                                                                            After making the PAN application how can I check the status of my PAN
                                                                            application?
                                                                            After applying for PAN, one can check the status of his application by using the
                                                                            track status facility available at web sites of Income Tax Department or UTIITSL or
                                                                            NSDL i.e. www.incometaxindia.gov.in or www.utiitsl.com and www.tin-nsdl.com .                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                                            If my PAN card is lost then what to do?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If the PAN card is lost or you wish to have a new PAN card with existing details,
                                                                            then you can apply for duplicate PAN card by submitting the Form for "Request for
                                                                            New PAN Card or/ and Changes or Correction in PAN Data" and a copy of FIR may
                                                                            be submitted along with the form.
                                                                            If the PAN card is lost and you don't remember your PAN, then in such a case, you
                                                                            can know you PAN by using the facility of "Know Your PAN" provided by the Income
                                                                            Tax Department. This facility can be availed of from the website of Income Tax
                                                                            Department - www.incometaxindiaefiling.gov.in
                                                                            You can know your PAN online by providing the core details like Name, Father's
                                                                            Name and Date of Birth.
                                                                            After knowing the PAN you can apply for duplicate PAN card by submitting the
                                                                            "Request For New PAN Card Or/ And Changes Or Correction in PAN Data".
                                                                            Applicant is required to fill all the columns in the form but should not tick any of
                                                                            the boxes on the left margin.                                           </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                                                            What is Instant-PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            E Income-tax Dept. has launched a new functionality on the e-filing portal which
                                                                            allots a PAN to the assessee on basis of his Aadhaar Number.
                                                                            This facility can be used by an assessee only if the following conditions are fulfilled:
                                                                            1. He has never been allotted a PAN;
                                                                            2. His mobile number is linked with his Aadhaar number;
                                                                            3. His complete date of birth is available on the Aadhaar card; and
                                                                            4. He should not be a minor on the date of application for PAN                                          </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    ROC Compliances
                                                </button>
                                            </h2>
                                            {isShow5 && <div className="col-lg-8 col-xl-7 activelink position-relative ourservices-reponsive" id="flush-collapseFive" class="accordion-collapse collapse more-faq-custom" data-bs-parent="#accordionFlushExample">
                                                <a
                                                    className={`service-box-mob ${activeId === 1 ? "active" : ""}`}
                                                    id="myHeader1"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes1");
                                                        handleClick(1);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices5(false)
                                                    }}
                                                >

                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Registration{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                <a
                                                    className={`service-box-mob position-set ${activeId === 2 ? "active" : ""
                                                        }`}
                                                    id="myHeader2"
                                                    onClick={(e) => {
                                                        showonlyone("newboxes2");
                                                        handleClick(2);
                                                        //   getSubServices(data.serviceId);
                                                        handleShowServices5(false)
                                                    }}
                                                >
                                                    <div className="ser-img-text">
                                                        <div className="left-part">
                                                            GST Return Filling{" "}
                                                        </div>
                                                    </div>
                                                    <div className="for-mobile d-none d-md-block"></div>
                                                </a>

                                                
                                            </div>}
                                            {<div className={`col-lg-4 col-xl-5 ${isShow5 ? 'd-none' : ''}`}>
                                                <div className="rightboxmain">
                                                    <div className="newboxes" id="newboxes1"
                                                        style={{ display: "block" }}
                                                    >
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices5(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-1">
                                                            <h5>Income Tax Return</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            What is a return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR stands for Income Tax Return. It is a prescribed form through which the particulars of income earned by a person in a financial year and taxes paid on such income are communicated to the Income-tax Department. It also allows carry -forward of loss and claim refund from income tax department.Different forms of returns of income are prescribed for filing of returns for different Status and Nature of income. These forms can be downloaded from www.incometaxindia.gov.in
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            Is it necessary to attach any documents along with the return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Will I be put to any disadvantage by filing my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, on the contrary by not filing your return inspite of having taxable income, you will be liable to the penalty and prosecution provisions under the Income-tax Act.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            What are the benefits of filing my return of income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                            Is it necessary to file return of income when I do not have any positive income?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If you have sustained a loss in the financial year, which you propose to carry forward to the subsequent year for adjustment against subsequent year(s) positive income, you must make a claim of loss by filing your return before the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                            Will I be penalized on late filing of ITR even if I am not liable to file it?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            No, late filing fee under section 234F not leviable in case you are not required to file ITR as per section 139 but filing it voluntary though after the due date.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                            If I fail to furnish my return within the due date, will I be fined or penalized?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, if a person who is required to furnish a return of income under section 139 and fails to do so within time prescribed in sub-section (1) , you will have to pay interest on tax due. W.e.f. assessment year 2018-19, fee as per section 234F is required to be paid if return is furnished after due date. Fee for default in furnishing return of income will be as follows:
                                                                            i.	Rs. 5000 if return is furnished on or before the 31st day of December of the assessment year;
                                                                            ii.	Rs. 10,000 in any other case
                                                                            However, late filing fee shall not exceed Rs. 1000 if the total income of an assessee does not exceed Rs. 5 lakh.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                            If I have paid excess tax how will it be refunded to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            The excess tax can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. The department has been making efforts to settle refund claims at the earliest.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                            If I have committed any mistake in my original return, am I permitted to file a revised return to correct the mistake?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A return of income can be revised at any time during the assessment year or before the assessment made whichever is earlier.
                                                                            If original return has filed in paper format or manually, then technically it cannot be revised by online mode or electronically.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                            There are various deductions that are not reflected in the Form 16 issued by my employer. Can I claim them in my return?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Yes, it can be claimed if you are otherwise eligible to claim the same.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                            Why is return filing mandatory, even though all my taxes and interests have been paid and there is no refund due to me?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Amounts paid as advance tax and withheld in the form of TDS or collected in the form of TCS will take the character of your tax due only on completion of self-assessment of your income. This self-assessment is intimated to the Department by way of filing of the return of income. Only then the Government assumes rights over the taxes paid by you. Filing of return is critical for this process and, hence, has been made mandatory. Failure will attract levy of penalty.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                            What is Form 26AS?                                            </button>
                                                                    </h2>
                                                                    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            A taxpayer may pay tax in any of the following forms:
                                                                            (1) Tax Deducted at Source (TDS)
                                                                            (2) Tax Collected at Source (TCS)
                                                                            (3) Advance tax or Self-assessment Tax or Payment of tax on regular assessment.
                                                                            The Income-tax Department maintains the database of the total tax paid by the taxpayer (i.e., tax credit in the account of a taxpayer).  Form 26AS is an annual statement maintained under Rule 31AB of the Income-tax Rules disclosing the details of tax credit in his account as per the database of Income-tax Department. In other words, Form 26AS will reflect the details of tax credit appearing in the Permanent Account Number of the taxpayer as per the database of the Income-tax Department. The tax credit will cover TDS, TCS and tax paid by the taxpayer in other forms like advance tax, Self-Assessment tax, etc.
                                                                            Income-tax Department will generally allow a taxpayer to claim the credit of taxes as reflected in his Form 26AS.                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            {/* <Link to="/incometaxreadmore"> */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark regbtn md"
                                                                onclick="location.href='income-tax.html';"
                                                            >
                                                                READ MORE
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="newboxes" id="newboxes2">
                                                        <a className="close-icon d-block d-lg-none" onClick={() => handleShowServices5(true)}>
                                                            <img src="images/close-icon.png" alt="" />{" "}
                                                        </a>
                                                        <div id="item-1-2">
                                                            <h5>Application For Pan</h5>
                                                            <div className="accordion accordion-custom-mob" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                                            What is PAN? What is the utility of the PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThirteen" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN stands for Permanent Account Number. PAN is a ten-digit unique
                                                                            alphanumeric number issued by the Income Tax Department. PAN is issued in the
                                                                            form of a laminated plastic card (commonly known as PAN card). Given below is an
                                                                            illustrative PAN:ALWPG5809L
                                                                            PAN enables the department to link all transactions of the assessee with the
                                                                            department. These transactions include tax payments, TDS/TCS credits, returns of
                                                                            income, specified transactions, correspondence and so on. It facilitates easy
                                                                            retrieval of information of assessee and matching of various investments,
                                                                            borrowings and other business activities of assessee.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                                            Who has to obtain PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            PAN is to be obtained by following persons:
                                                                            1. Every person if his total income or the total income of any other person in
                                                                            respect of which he is assessable during the previous year exceeds the
                                                                            maximum amount which is not chargeable to tax.
                                                                            2. A charitable trust who is required to furnish return under Section 139(4A)
                                                                            3. Every person who is carrying on any business or profession whose total sale,
                                                                            turnover, or gross receipts are or is likely to exceed five lakh rupees in any
                                                                            previous year
                                                                            4. Every person who intends to enter into specified financial transactions in which
                                                                            quoting of PAN is mandatory
                                                                            Every non-individual resident persons and person associated with them if the
                                                                            financial transaction entered into by such non-individual resident persons during a
                                                                            financial year exceeds Rs. 2,50,000.
                                                                            Note: Persons associated with non-individual resident persons means the managing
                                                                            director, director, partner, trustee, author, founder, karta, chief executive officer,
                                                                            principal officer or office bearer of the non-individual resident persons or any person
                                                                            competent to act on behalf of such persons.
                                                                            A person not covered in any of the above can voluntarily apply for PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                                            Can I file my return of income without quoting PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            It is mandatory to quote PAN on the return of income. Apart from return of income,
                                                                            PAN is also to be quoted in all challans for making payment of tax, correspondence
                                                                            with the Income Tax Department, etc.
                                                                            However, w.e.f., 01-09-2019, every person who has been allotted a PAN, and who
                                                                            has linked his Aadhaar number with PAN as per section 139AA, may furnish his
                                                                            Aadhaar number in lieu of a PAN for all the transactions where quoting of PAN is
                                                                            mandatory as per Income-tax Act. Thus, with effect from September 1, 2019, an
                                                                            assessee can file his return of income by quoting his Aadhaar number instead of
                                                                            quoting PAN.                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                                            How to link Aadhaar number with PAN using e-Filing portal?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            Taxpayer is required to login on e-Filing portal using his registered username and
                                                                            password. After login, he will get the “link Aadhaar” option under his ‘Profile
                                                                            Settings’.
                                                                            Aadhaar number will be linked with PAN if name and date of birth of taxpayer is
                                                                            identical to Aadhaar and PAN.
                                                                            After making the PAN application how can I check the status of my PAN
                                                                            application?
                                                                            After applying for PAN, one can check the status of his application by using the
                                                                            track status facility available at web sites of Income Tax Department or UTIITSL or
                                                                            NSDL i.e. www.incometaxindia.gov.in or www.utiitsl.com and www.tin-nsdl.com .                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                                            If my PAN card is lost then what to do?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            If the PAN card is lost or you wish to have a new PAN card with existing details,
                                                                            then you can apply for duplicate PAN card by submitting the Form for "Request for
                                                                            New PAN Card or/ and Changes or Correction in PAN Data" and a copy of FIR may
                                                                            be submitted along with the form.
                                                                            If the PAN card is lost and you don't remember your PAN, then in such a case, you
                                                                            can know you PAN by using the facility of "Know Your PAN" provided by the Income
                                                                            Tax Department. This facility can be availed of from the website of Income Tax
                                                                            Department - www.incometaxindiaefiling.gov.in
                                                                            You can know your PAN online by providing the core details like Name, Father's
                                                                            Name and Date of Birth.
                                                                            After knowing the PAN you can apply for duplicate PAN card by submitting the
                                                                            "Request For New PAN Card Or/ And Changes Or Correction in PAN Data".
                                                                            Applicant is required to fill all the columns in the form but should not tick any of
                                                                            the boxes on the left margin.                                           </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                                                            What is Instant-PAN?
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            E Income-tax Dept. has launched a new functionality on the e-filing portal which
                                                                            allots a PAN to the assessee on basis of his Aadhaar Number.
                                                                            This facility can be used by an assessee only if the following conditions are fulfilled:
                                                                            1. He has never been allotted a PAN;
                                                                            2. His mobile number is linked with his Aadhaar number;
                                                                            3. His complete date of birth is available on the Aadhaar card; and
                                                                            4. He should not be a minor on the date of application for PAN                                          </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix text-right btnbottom">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-dark regbtn md"
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </nav>
                            </nav>
                        </Col>
                </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default MoreFaq