import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Table, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
// import Table from '@mui/material/Table';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { makeStyles } from "@material-ui/core";
import "./UserDetails.css";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import Loader from "../loader/Loader";
import imgeurl from '../../utils/constant';

// Create custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  customContainer: {
    padding: 0, // Override the default padding
    // Add more custom styles if needed
    marginTop: "7rem",
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState("2024-2025");
  const [selectSubService, setSelectSubService] = useState();
  const [selectService, setSelectService] = useState();
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    getAllData();
  }, [selectedYear]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    console.log(e.target.value);
  };
  const handleSubServiceChange = (e) => {
    setSelectSubService(e.target.value);
    console.log(e.target.value);
  };
  const handleServiceChange = (e) => {
    console.log(e.target.value);
  };
  const getAllData = () => {
    const token = sessionStorage.getItem("token");
    const method = "document/";
    axios
      .get(REACT_APP_BASE_URL + method + selectedYear, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("History",res.data);
        setHistory(res.data.data);
        const docName = res.data.data.map((data) => data.documentName);
        console.log(docName);
        props.handleLoader()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleData = (data) => {
    navigate("/detailedhistory", { state: data });
  };

  return (
    <div>
      {isLoading && <Loader/>}
      <Container
        maxWidth="xl"
        style={{ padding: 0 }}
        className={classes.customContainer}
      >
        <Card sx={{ paddingBottom: 3 }}>
          <CardContent>
            <div className="row">
              <div className="col-5">
                <Typography variant="h5" color="text.secondary">
                  Purchase History
                </Typography>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <div className="card-body">
                  <select
                    className="form-select my-3"
                    aria-label="Default select example"
                    value={selectService}
                    onChange={handleServiceChange}
                  >
                    <option value="Income Tax ">Income Tax </option>
                    <option value="GST">GST</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card-body">
                  <select
                    className="form-select my-3"
                    aria-label="Default select example"
                    value={selectSubService}
                    onChange={handleSubServiceChange}
                  >
                    <option value="IncomeTax Return">IncomeTax Return</option>
                    <option value="TDS return">TDS return</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card-body">
                  <select
                    className="form-select my-3"
                    aria-label="Default select example"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    <option value="2025-2026">2025-2026</option>
                    <option value="2024-2025">2024-2025</option>
                    <option value="2023-2024">2023-2024</option>
                    <option value="2022-2023">2022-2023</option>
                    <option value="2021-2022">2021-2022</option>
                    <option value="2020-2021">2021-2022</option>
                  </select>
                </div>
              </div>
            </div>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr No.</TableCell>
                    <TableCell>Plan Name</TableCell>
                    <TableCell>Plan Cost</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Acknowledged File</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history && (
                    history.map((data, i) => {
                      return (
                        <TableRow
                          className="plan-css"
                          
                        >
                          <TableCell onClick={() => handleData(data)}>{i + 1}</TableCell>
                          <TableCell onClick={() => handleData(data)}>{data?.documentName}</TableCell>
                          <TableCell onClick={() => handleData(data)}>{data?.planInformation.planFee}</TableCell>
                          <TableCell >{new Date(data?.date).toLocaleDateString('en-GB')}</TableCell>
                          <TableCell className="text-center">
                            {data.acknowledgedFile ? (
                               <a 
                               href={imgeurl+ data.acknowledgedFile} 
                               target="_blank" 
                               rel="noopener noreferrer" 
                               
                           >
                            <DownloadIcon > </DownloadIcon>
                           </a>
                              
                            ) : (
                              <FileDownloadOffIcon />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
                  { history.length<=0 && <div style={{justifyContent: "center", display: "flex", color: "black", fontWeight: "600"}}>
                    No Data Found
                  </div>}
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default UserDetails;
