import React from 'react'
import "./DoYouKnow.css"
import "../../components/common/Common.css"

const DoYouKnow = () => {
  return (
    <>
    <section className="tb-space do-you-know-outer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h5 className="main-title red-row mbp15 d-inline-block"># Do You Know</h5>
                    </div>
                    <div className="col-sm-12 mtp10">
                        <div className="row">
                            <div className="col-sm-6 mtp20">
                                <div className="media newsbox">
                                    <img src="images/news-01.png" className="align-self-start mr-3 do-you-img" alt=""/>
                                    <div className="media-body">
                                        <a href="#">
                                            <h5 className="mt-0 do-you-title">Top-aligned media</h5>
                                        </a>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                                        <span className="date-time text-right">Apr 6, 2020 12:40 pm</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mtp20">
                                <div className="media newsbox">
                                    <img src="images/news-02.png" className="align-self-start mr-3 do-you-img" alt=""/>
                                    <div className="media-body">
                                        <a href="#">
                                            <h5 className="mt-0 do-you-title">Top-aligned media</h5>
                                        </a>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                                        <span className="date-time text-right">Apr 6, 2020 12:40 pm</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 mtp20">
                                <div className="media newsbox">
                                    <img src="images/news-03.png" className="align-self-start mr-3 do-you-img" alt=""/>
                                    <div className="media-body">
                                        <a href="#">
                                            <h5 className="mt-0 do-you-title">Top-aligned media</h5>
                                        </a>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                                        <span className="date-time text-right">Apr 6, 2020 12:40 pm</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 d-block d-md-none text-right mtp15">
                                <button className="btn btn-outline-dark regbtn" type="button">VIEW ALL</button>
                            </div>
                            <div className="col-sm-6 mtp20 d-none d-md-block">
                                <div className="media newsbox">
                                    <img src="images/news-04.png" className="align-self-start mr-3" alt=""/>
                                    <div className="media-body">
                                        <a href="#">
                                            <h5 className="mt-0 do-you-title">Top-aligned media</h5>
                                        </a>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                                        <span className="date-time text-right">Apr 6, 2020 12:40 pm</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 mtp20 d-none d-md-block">
                                <div className="media newsbox">
                                    <img src="images/news-05.png" className="align-self-start mr-3" alt=""/>
                                    <div className="media-body">
                                        <a href="#">
                                            <h5 className="mt-0">Top-aligned media</h5>
                                        </a>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                                        <span className="date-time text-right">Apr 6, 2020 12:40 pm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        
   </>
  )
}

export default DoYouKnow