import React, { useEffect, useState } from 'react'
import './IncomeTaxCss.css'
import "./PlanANewCss.css"
import Header from '../../header/Header'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'
import DemoHeader from '../../demoHeader/DemoHeader'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import axios from 'axios'



const AdvancedTaxCalculation = () => {
    const [data, setData] = useState();
    const [idData, setIdData] = useState();
    const [documentId, setDocumentId] = useState();
    const [docId, setDocId] = useState();
    const [token, setToken] = useState();
    const [allData, setAllData] = useState();
    const refreshData = 'refreshData';


    const Navigate = useNavigate();
    const Location = useLocation();
    const plan = Location.state;
    const planId = Location?.state?.data?.planId;
    console.log(plan);

    const getDocumentId = () => {
        if (documentId === null || documentId === undefined) {
            const method = "document/generate/";
            axios
                .post(REACT_APP_BASE_URL + method + planId, null, {
                    headers: {
                        Authorization: `Bearer ${token ? token : sessionStorage.getItem("token")
                            }`,
                    },
                })
                .then(
                    (res) => {
                        console.log("response : ", res);
                        setDocId(res.data.data.documentId);
                        sessionStorage.setItem("documentId", res.data.data.documentId);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    };

    useEffect(() => {
        sessionStorage.setItem("planFee", plan?.data?.fee);
        if (plan) {
            setData(plan);

            let tokenData = sessionStorage.getItem("token");
            setToken(tokenData);
            setDocumentId(sessionStorage.getItem("documentId"));
            getDocumentId();
        }
        getId();
    }, [plan]);

    React.useEffect(() => {
        // setAllData(data)
        setAllData(Location.state);
    }, []);

    const getId = () => {
        const dataid = sessionStorage.getItem("userId");
        setIdData(dataid);
        console.log(dataid);
    };
    const navigateStep = () => {
        // console.log("data of planId : ", data.data.pageLink);
        // sessionStorage.setItem("planId", data.data.planId);
        // sessionStorage.setItem("subServiceIds", data.subServiceIds);
        Navigate("/advancedtaxcalculation-form",
            // { state: { data, docId } }
        );
    };

    useEffect(() => {
        const smoothScroll = (targetId) => {
            const target = document.getElementById(targetId);
            if (target) {
                window.scrollTo({
                    top: target.offsetTop - 100,
                    behavior: 'smooth',
                });
            }
        };

        const handleHashChange = () => {
            if (window.location.hash) {
                smoothScroll(window.location.hash.substring(1));
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <>
            {/* <Header/> */}
            <DemoHeader />
            {/* <div className="container-fluid advancedTax-outer" style={{ backgroundColor: '#5136e6' }}>
                <div className="row align-items-center vh-100 headerbackground">
                    <div className="col-sm-12 col-md-10  col-lg-8">
                        <div className="banner-text banner-text-xl advisory-banner">
                            <div>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" style={{ color: '#FFFFFF' }} to="/">
                                        Home
                                    </Link>
                                    <Typography style={{ color: '#FFFFFF' }}>Advance Tax Calculation    </Typography>
                                </Breadcrumbs>
                            </div>
                            <h2 style={{ color: '#fff' }}>Advance Tax Calculation</h2>
                            <div className="banner-text-bottom">
                                <span>Key Highlights of Plan</span>
                                <ul>
                                    <li>Analysis on applicability of advance tax</li>
                                    <li>Detail discussion on provisions related to advance tax and calculation of the same</li>
                                    <li>Dedicated CA assigned on your case, to advise on how much advance tax to be paid</li>
                                    <li>Continuous follow up to ensure compliance with respect to due dates for payment of advance tax and avoid interest cost</li>
                                    <li>Advisory on queries pertaining to advance tax</li>
                                </ul>
                                {
                                    idData === null ? (
                                        <Link>
                                            <button
                                                type="button"
                                                className="btn btn-light regbtn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                BUY NOW
                                            </button>
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-light regbtn"
                                            type="button"
                                            onClick={() => navigateStep()}
                                        >
                                            BUY NOW
                                        </button>
                                    )
                                }
                                <div
                                    className="modal fade sign-resp"
                                    id="exampleModal"
                                    tabindex="-1"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog" style={{ marginTop: '15rem', width: '93%' }}>
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    TaxSavvy
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="C lose"
                                                ></button>
                                            </div>
                                            <div className="modal-body" style={{ padding: '2rem' }}>
                                                <div style={{ fontSize: "23px", paddingBottom: '2rem' }}>
                                                    Please{" "} Sign In {" "}
                                                   
                                                    to continue...
                                                </div>
                                                <div className="modal-SignIn-btn">
                                                    <Link to="/signup" state={{ refreshData }}>
                                                        <button className="no-login-buy"
                                                            type="button"
                                                        >
                                                            Sign In
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-main">
                <div className="container-main-inner">
                    <div className="container-div-left">
                        <div>
                            <div>
                                <Breadcrumbs aria-label="breadcrumb" style={{ color: '#fff' }}>
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        to="/"
                                        style={{ textDecoration: 'underline', color: 'inherit' }}
                                    >
                                        HOME
                                    </Link>
                                    <Typography color="text.primary" style={{ color: 'inherit' }}>
                                        Advance Tax Calculation
                                    </Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2 className="planName">Advance Tax Calculation</h2>
                            </div>
                        </div>
                        <div className="container-innerdiv-3">
                            <div className="div3-div1"><p className="div3-div1-p">Made for Immovable property purchasers</p></div>
                            <div className="banner-text-bottom">
                                <span className="container-innerdiv-3-span">Key Highlights of Plan</span>
                                <ul className="container-innerdiv-3-ul">
                                    <li>Dedicated CA assigned on your case</li>
                                    <li>Prior Consultation on maximum tax benefits</li>
                                    <li>Suggestions on New Tax Saving avenues for next year</li>
                                    <li>Tracking and updating the status of refund, if any</li>
                                    <li>Advisory on queries pertaining to the Income tax return filing</li>
                                </ul>
                                {idData === null ? (
                                    <Link>
                                        <button
                                            type="button"
                                            className="btn btn-light regbtn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                        >
                                            BUY NOW
                                        </button>
                                    </Link>
                                ) : (
                                    <button
                                        className="btn btn-light regbtn"
                                        type="button"
                                        onClick={() => navigateStep()}
                                    >
                                        BUY NOW
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="container-div-right">
                        <img src="images/plan-img.png" style={{ maxWidth: '100%', height: '100%' }} />
                    </div>
                </div>
            </div>

            <div className="planbox planbox-outer" id="Header2">
                <div className="planbox-title">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-5 col-md-6 col-lg-9 col-xl-9">
                                <h4>Advance Tax Calculation</h4>
                            </div>
                            <div className="col-7 col-md-6 col-lg-3 col-xl-3 text-right justify-content-end" style={{ display: 'flex' }}>
                                <div className="dropdown d-inline-block arrowbtn d-sm-none">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="images/down-arrow.svg" />
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#aboutplan">About the Plan</a>
                                        <a className="dropdown-item" href="#howitdone">How it’s done</a>
                                        <a className="dropdown-item" href="#docreq">Documents required</a>
                                        <a className="dropdown-item" href="#faqs">FAQs</a>
                                    </div>
                                </div>
                                <div className="priceboxin">
                                    <span>Just for</span>
                                    <h6>₹1499</h6>
                                </div>
                                {
                                    idData === null ? (
                                        <Link>
                                            <button
                                                type="button"
                                                className="btn btn-dark btnblack "
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                BUY NOW
                                            </button>
                                        </Link>
                                    ) : (
                                        // <Link to="/step" >
                                        <button
                                            className="btn btn-dark btnblack"
                                            type="button"
                                            onClick={() => navigateStep()}
                                        // onclick="window.location.href='step.html';"
                                        >
                                            BUY NOW
                                        </button>
                                    )
                                    // </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plannav d-none d-sm-block mtp5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 text-left fixnav">
                                <ul className="nav nav-pills nav-fill" id="top-menu">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#aboutplan">About the Plan</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#howitdone">How it’s done</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#docreq">Documents required</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#faqs">FAQs</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="tb-space aboutPlan-outer" id="aboutplan">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">About the Plan</h5>
                        </div>
                    </div>
                    <div className="row mtp15">
                        <div className="col-sm-6 col-xl-7">
                            <h5 className="sub-title-xs mbp10">Plan details</h5>
                            <p className="black-smalltext">This tax product is designed to comply with advance tax provision for a salaried as well as business person whose tax payable is above Rs. 10,000 after TDS. Advance tax is payable to government by the individual on the prescribed due dates. Such periodic payment reduces burden of lump sum payment along with saving on interest cost. Advance tax calculation shall be done considering reasonable assumptions. Any delay in payment of advance tax will lead to excess payment in terms of interest.
                                A dedicated expert shall ensure that your advance tax shall be computed after considering all the provisions of Income tax law updated time to time. Therefore, an individual shall be guided to best of his/her interest and be made aware of tax implications applicable to him/her.
                            </p>
                            <div className="col-sm-12">
                                <table className="table table-bordered plantable table-striped">
                                    <thead>
                                        <tr>
                                            <th width="50%" className="blue-bg text-white text-center">Due Date</th>
                                            <th width="50%" className="green-bg text-white text-center">Advance Tax Payable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>On or before 15th June</td>
                                            <td className="text-center align-middle">15% of advance tax</td>
                                        </tr>
                                        <tr>
                                            <td>On or before 15th September </td>
                                            <td className="text-center align-middle">45% of advance tax less advance tax already paid</td>
                                        </tr>
                                        <tr>
                                            <td>On or before 15th December</td>
                                            <td className="text-center align-middle">75% of advance tax less advance tax already paid</td>
                                        </tr>
                                        <tr>
                                            <td>On or before 15th March</td>
                                            <td className="text-center align-middle">100% of advance tax less advance tax already paid</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="text-xs"> <i>Note: This plan covers advance tax calculation for one quarter only</i></p>



                            <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
                            <p className="black-smalltext">1. Salaried individual earning income from interest or capital gain whose estimated tax payable is more than Rs 10,000 after TDS</p>
                            <p className="black-smalltext">2. Business person whose estimated tax payable is more than Rs 10,000 after TDS</p>



                        </div>
                        <div className="col-sm-6  col-xl-5">
                            <img src="images/about-img.png" alt="" className="img-fluid m-auto float-md-right" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space graybg howitdone-outer" id="howitdone">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">
                                How it’s Done
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center  ">
                            <div className="hid-list-main howItDone-bg-img howItDone-bg-img1">
                                <ul className="hid-list six ">

                                    <li className="hid-list-li-resp">
                                        <div className="hid-list-li-div1-resp">
                                            <div className="hid-img">
                                                <img src="images/cart-icon.png" />
                                            </div>
                                            <div className="hid-text">Purchase of Plan</div>
                                        </div>
                                    </li>

                                    <li className="hid-list-li-resp">
                                        <div className="hid-list-li-div2-resp">
                                            <div className="hid-img">
                                                <img src="images/upload-icon.png" />
                                            </div>
                                            <div className="hid-text">Upload Documents</div>
                                        </div>
                                    </li>

                                    <li className="hid-list-li-resp">
                                        <div className="hid-list-li-div1-resp">
                                            <div className="hid-img">
                                                <img src="images/file-search-icon.png" />
                                            </div>
                                            <div className="hid-text">Case overview with expert</div>
                                        </div>
                                    </li>

                                    <li className="hid-list-li-resp">
                                        <div className="hid-list-li-div2-resp">
                                            <div className="hid-img">
                                                <img src="images/filling-form-icon.png" />
                                            </div>
                                            <div className="hid-text">
                                                Review & Approval of Computation of Income
                                            </div>
                                        </div>
                                    </li>

                                    <li className="hid-list-li-resp">
                                        <div className="hid-list-li-div1-resp">
                                            <div className="hid-img">
                                                <img src="images/generation-form-icon.png" />
                                            </div>
                                            <div className="hid-text">
                                                Filling of Income Tax Return with Acknowledgment
                                            </div>
                                        </div>
                                    </li>

                                    <li className="hid-list-li-resp">
                                        <div className="hid-list-li-div2-resp">
                                            <div className="hid-img">
                                                <img src="images/filling-form-icon.png" />
                                            </div>
                                            <div className="hid-text">Feedback</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="estimated-row">
                                <span>3 Days Estimated</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space blackbg document-outer" id="docreq">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title white-row mbp15 d-inline-block white-color">Documents required</h5>
                        </div>
                    </div>
                    <div className="row mtp25 gutter-20">
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">01</div>
                                <div className="doc-details">Income Details</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">02</div>
                                <div className="doc-details">Expected income details on estimated basis</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">03</div>
                                <div className="doc-details">Details of Investment, if any</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">04</div>
                                <div className="doc-details">Any additional information, as communicated by Tax Savvy Experts</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="tb-space" id="faqs">
                <FaqSection />
            </section>
            <Footer />
        </>
    )
}

export default AdvancedTaxCalculation