import React, { useState } from "react";
import {
  FaBars,
  FaDashboard,
  FaInfoCircle,
  FaChartLine,
  FaComment,
  FaCube,
  FaList,
} from "react-icons/fa";
import { NavLink, Route, Routes } from "react-router-dom";
import "../components/pages/Sidebar1.css";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import ProductList from "./pages/ProductList";
import Header from "./header/Header";
import UserProfile from "./profile/UserProfile";
import DemoHeader from "./demoHeader/DemoHeader";

const Sidebar = ({ children }) => {
  const [isopen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isopen);
  };
  const menuItem = [
    {
      path: "/profilee",
      name: "User Profile",
      // icon: <FaDashboard />,
    },
    // {
    //   path: "/about",
    //   name: "Documents",
    //   // icon: <FaInfoCircle />,
    // },
    {
      path: "/product-list",
      name: "Purchase History",
      // icon: <FaList />,
    },
  ];

  return (
    <>
      {/* <Header/> */}
      
      {/* <div className="demo-hed-bg">
        <DemoHeader/>
      </div> */}
      <div
        className="container-flex"
        style={{ paddingTop: "7rem", display: "flex",justifyContent:'center' }}
      >
        <div style={{ width: isopen ? "300px" : "50px" }} className="sidebar">
          <div className="top_section">
            <h1
              style={{ display: isopen ? "block" : "none" }}
              className="logo"
            ></h1>
            <div
              style={{ marginLeft: isopen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars onClick={toggle} style={{display:"none"}}/>
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              // activeClassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isopen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
