import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import AskExpert from "../../askexpert/AskExpert";
import FileItr from "./FileItr";
import Footer from "../../footer/Footer";
import GetRsOff from "../../getrsoff/GetRsOff";
import BenefitsOfFillingItr from "./BenefitsOfFillingItr";
import IncomeTaxFilling from "../../incometaxfilling/IncomeTaxFilling";
import FaqSection from "../../FAQ-section/Faq-section";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import DemoHeader from "../../demoHeader/DemoHeader";
import { Breadcrumbs, Typography } from "@material-ui/core";
import Loader from "../../loader/Loader";

const TdsReturn = () => {
  const [planData, setPlanData] = useState();
  const [subServiceId, setSubServiceId] = useState();
  const location = useLocation();
  const datas = location.state;
  const [isLoading, setIsLoading] = useState(false);

  // console.log(datas);
  useEffect(() => {
    if (datas && datas.subServiceId) {
      setSubServiceId(datas.subServiceId);
    }
  }, [datas]);

  useEffect(() => {
    if (subServiceId) {
      setIsLoading(true);
      getpanCardPlan();
    }
  }, [subServiceId]);

  const getpanCardPlan = () => {
    const subserviceId = subServiceId;
    const method = "plans/";
    axios
      .get(REACT_APP_BASE_URL + method + subserviceId)
      .then((res) => {
        // console.log("TDS Returns",res.data.data);
        setPlanData(res.data.data);
        setIsLoading(false);  
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      {/* <Header /> */}
      <DemoHeader/>
      {isLoading && <Loader/>}
      <div className="bannertop">
        <div className="container text-left">
          <div className="banner-vhmian itr-breadcrumb">
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" style={{color:'#FFFFFF'}} to="/">
                  Home
                </Link>
                <Typography style={{color:'#FFFFFF'}}>TDS Services</Typography>
              </Breadcrumbs>
            </div>
            <div className="vhin">
              <h1>TDS Services</h1>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="whitebox">
            <div className="row">
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* <div className="row">
                      <div className="col-sm-12">
                        <div className="row mtp30">
                          <div className="col-sm-6 col-lg-4">
                            <div className="darkocean-bg radius-shadow probox-main">
                              <div className="proimg-box">
                                <h4>TDS Return on Salary Payment (Form 24Q)</h4>
                              </div>
                              <div className="prodetails">
                                <div className="prolist-scroll">
                                  <ul>
                                    <li>Generation of Form 16 for employees</li>
                                    <li>No limit on number of employees</li>
                                    <li>Registration on TRACES</li>
                                    <li>Online PAN & Challan verification</li>
                                    <li>Quarterly filing of Form 24Q</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="price-box">
                                    <span>Just for</span>
                                    <div className="rupee">
                                      <img src="images/rupee-icon.png" alt="" />
                                      1499{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <Link to="/tdssalarypayment">
                                    <a
                                      href="tds-salarypayment.html"
                                      className="probtn border-white"
                                    >
                                      GET PLAN
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="corolblue-bg radius-shadow probox-main">
                              <div className="proimg-box">
                                <h4>
                                  TDS Return on Payment other than Salary (Form
                                  26Q)
                                </h4>
                              </div>
                              <div className="prodetails">
                                <div className="prolist-scroll">
                                  <ul>
                                    <li>Generation of Form 16A</li>
                                    <li>No limit on number of transactions</li>
                                    <li>Registration on TRACES</li>
                                    <li>Online PAN & Challan verification</li>
                                    <li>Quarterly filing of Form 26Q</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="price-box">
                                    <span>Just for</span>
                                    <div className="rupee">
                                      <img src="images/rupee-icon.png" alt="" />
                                      1499{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <Link to="/tdsotherthansalary">
                                    <a className="probtn border-white">
                                      GET PLAN
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="royalblue-bg radius-shadow probox-main">
                              <div className="proimg-box">
                                <h4>
                                  TDS Return on Salary and other payments (Form
                                  24Q and 26Q)
                                </h4>
                              </div>
                              <div className="prodetails">
                                <div className="prolist-scroll">
                                  <ul>
                                    <li>Generation of Form 16 & 16A</li>
                                    <li>No limit on number of transactions</li>
                                    <li>Registration on TRACES</li>
                                    <li>Online PAN & Challan verification</li>
                                    <li>Quarterly filing of Form 24Q & 26Q</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="price-box">
                                    <span>Just for</span>
                                    <div className="rupee">
                                      <img src="images/rupee-icon.png" alt="" />
                                      2499{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <Link to="/tdssalaryandotherpayment">
                                    <a
                                      href="tds-salary-otherpayment.html"
                                      className="probtn border-white"
                                    >
                                      GET PLAN
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="row mtp30">
                                  {planData &&
                                    planData.map((data, i) => {
                                      return (
                                        <div className="col-sm-6 col-lg-4">
                                          <div
                                            className={`${
                                              ((i + 1) % 2 == 0 &&
                                                "magic-bg") ||
                                              ((i + 1) % 3 == 0 &&
                                                "eveningsunshine-bg") ||
                                              "metapolish-bg"
                                            } radius-shadow probox-main`}
                                          >
                                            <div className="proimg-box">
                                              <img
                                                src={`data:image/jpeg;base64,${data.image}`}
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                            <div className="prodetails">
                                              <h4>{data.planName}</h4>
                                              <div className="prolist-scroll">
                                              <ul dangerouslySetInnerHTML={{ __html: data?.description }}>
                                                </ul>
                                                {/* <ul>
                                                    <li>Generation of Form 16 </li>
                                                    <li>
                                                      No limit on number of transactions
                                                    </li>
                                                    <li>Registration on TRACES</li>
                                                    <li>
                                                      Online PAN & Challan verification
                                                    </li>
                                                    <li>
                                                      Quarterly filing of Form 27Q
                                                    </li>
                                                  </ul> */}
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <div className="price-box">
                                                  <span>Just for</span>
                                                  <div className="rupee">
                                                    <img
                                                      src="images/rupee-icon.png"
                                                      alt=""
                                                    />
                                                    {data.fee}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-6">
                                                <Link
                                                  to="/tdsoutsideindia"
                                                  state={data}
                                                >
                                                  <a
                                                    href="tds-outside-india.html"
                                                    className="probtn border-white"
                                                  >
                                                    GET PLAN
                                                  </a>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {/* <div className="col-sm-6 col-lg-4">
                                    <div className="magic-bg radius-shadow probox-main">
                                      <div className="proimg-box">
                                        <img
                                          src="images/plan-01.png"
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="prodetails">
                                        <h4>
                                          TDS Returns on payments outside India
                                          (Form 27Q)
                                        </h4>
                                        <div className="prolist-scroll">
                                          <ul>
                                            <li>Generation of Form 16 </li>
                                            <li>
                                              No limit on number of transactions
                                            </li>
                                            <li>Registration on TRACES</li>
                                            <li>
                                              Online PAN & Challan verification
                                            </li>
                                            <li>
                                              Quarterly filing of Form 27Q
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="price-box">
                                            <span>Just for</span>
                                            <div className="rupee">
                                              <img
                                                src="images/rupee-icon.png"
                                                alt=""
                                              />
                                              1499{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <Link to="/tdsoutsideindia">
                                            <a
                                              href="tds-outside-india.html"
                                              className="probtn border-white"
                                            >
                                              GET PLAN
                                            </a>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-lg-4">
                                    <div className="eveningsunshine-bg radius-shadow probox-main">
                                      <div className="proimg-box">
                                        <img
                                          src="images/plan-02.png"
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="prodetails">
                                        <h4>
                                          TDS Returns on rent payment excedding
                                          Rs 50,000 per month (Form 26QC)
                                        </h4>
                                        <div className="prolist-scroll">
                                          <ul>
                                            <li>Generation of Form 16A</li>
                                            <li>
                                              No limit on number of transactions
                                            </li>
                                            <li>Registration on TRACES</li>
                                            <li>
                                              Online PAN & Challan verification
                                            </li>
                                            <li>
                                              Quarterly filing of Form 26Q
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="price-box">
                                            <span>Just for</span>
                                            <div className="rupee">
                                              <img
                                                src="images/rupee-icon.png"
                                                alt=""
                                              />
                                              1499{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <Link to="/tdsrentpayment">
                                            <a className="probtn border-white">
                                              GET PLAN
                                            </a>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-lg-4">
                                    <div className="metapolish-bg radius-shadow probox-main">
                                      <div className="proimg-box">
                                        <img
                                          src="images/plan-01.png"
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="prodetails">
                                        <h4>TCS Returns (Form 27EQ)</h4>
                                        <div className="prolist-scroll">
                                          <ul>
                                            <li>Generation of Form 27D</li>
                                            <li>
                                              No limit on number of transactions
                                            </li>
                                            <li>Registration on TRACES</li>
                                            <li>
                                              Online PAN & Challan verification
                                            </li>
                                            <li>
                                              Quarterly filing of Form 27Q
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="price-box">
                                            <span>Just for</span>
                                            <div className="rupee">
                                              <img
                                                src="images/rupee-icon.png"
                                                alt=""
                                              />
                                              1499{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <a
                                            href="tds-return.html"
                                            className="probtn border-white"
                                          >
                                            GET PLAN
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AskExpert />
      <FileItr />
      <GetRsOff />
      <BenefitsOfFillingItr />
      <IncomeTaxFilling />
      <FaqSection />
      <Footer />
    </>
  );
};

export default TdsReturn;
