import React, { useEffect, useState } from "react";
import "./Carousel.css";
import "../../components/common/Common.css";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import Loader from "../loader/Loader";

export default function Carousel() {
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getBanner();
  }, []);

  const getBanner = () => {
    const method = "banner/images?subServiceId=Home&position=0";
    axios.get(REACT_APP_BASE_URL + method).then((res) => {
      console.log("Banner ", res.data.data);
      setBannerData(res.data.data);
      setIsLoading(false);
    }).catch((err)=>{
      console.log(err)
    })
  };
  return (
    <>
    {isLoading && <Loader/>}
      <div
        id="carouselExampleCaptions"
        className="carousel slide banner"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div>
        <div className="carousel-inner">
          {bannerData.map((item, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <img
                src={`data:image/jpeg;base64,${item.image}`}
                className="heightwidth carousel-img"
                alt="..."
                
              />
              <div className="carousel-caption d-none1 d-md-block text">
                <h2>
                  {/* Experts Handling <br />
                  The Issue, Behind <br />
                  The Curtain{" "} */}
                  {item.title}
                </h2>
                <br></br>
                <button className="btn btn-light regbtn" type="button">
                  TRY NOW
                </button>
              </div>
            </div>
          ))}
          {/* <div className="carousel-item active">
            <img src='images/banner-01.jpg' className="heightwidth" alt="..." style={{ opacity: '0.3' }} />
            <div className="carousel-caption d-none1 d-md-block text">
              <h2>Experts Handling <br />The Issue, Behind <br />The Curtain </h2><br></br>
              <button className="btn btn-light regbtn" type="button">TRY NOW</button>
            </div>
          </div>
          <div className="carousel-item">
            <img src='images/banner-02.jpg' className="heightwidth" alt="..." style={{ opacity: '0.3' }} />
            <div className="carousel-caption d-none d-md-block">
              <h2 >Non-Stop Service Till <br />You Receive Refund In<br /> Your Bank Account </h2><br></br>
              <button className="btn btn-light regbtn mtp10" type="button">TRY NOW</button>
            </div>
          </div>
          <div className="carousel-item">
            <img src='images/banner-03.png' className="heightwidth" alt="..." style={{ opacity: '0.3' }} />
            <div className="carousel-caption d-none d-md-block">
              <h2>Global Presence </h2><br></br>
              <button className="btn btn-light regbtn mtp10" type="button">TRY NOW</button>
            </div>
          </div>
          <div className="carousel-item">
            <img src='images/banner-04.png' className="heightwidth" alt="..." style={{ opacity: '0.3' }} />
            <div className="carousel-caption d-none d-md-block">
              <h2>Pocket Friendly Packaged<br /> Services</h2><br></br>
              <button className="btn btn-light regbtn mtp10" type="button">TRY NOW</button>
            </div>
          </div>
          <div className="carousel-item">
            <img src='images/banner-05.png' className="heightwidth" alt="..." style={{ opacity: '0.3' }} />
            <div className="carousel-caption d-none d-md-block">
              <h2>User Friendly Portal </h2><br></br>
              <button className="btn btn-light regbtn mtp10" type="button">TRY NOW</button>
            </div>
          </div> */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
            style={{ display: "none" }}
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
            style={{ display: "none" }}
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
