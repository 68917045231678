import React from 'react'

const Powerfull = () => {
  return (
<>
<section className="tb-space software-bg">
            <div className="container">
                <div className="whitebgbox">
                    <div className="row">
                        <div className="col-sm-12">
                            <h5 className="sub-title mbp10">Powerful and easy to use Software</h5>
                            <p className="smalltext-black">Thousands of CA firms are saving a lot of time using this solution.</p>
                        </div>
                        <div className="col-md-6">
                            <ul className="software-list">
                                <li>
                                    <div className="soft-icon"><img src="images/backup-icon.png" alt=""/></div>
                                    <div className="soft-details">
                                        <h5>Automatic Backup</h5>
                                        <p>Easy and secure auto backup of all your Income tax and TDS data.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="soft-icon"><img src="images/collaboration-icon.png" alt=""/></div>
                                    <div className="soft-details">
                                        <h5>Easy Collaboration</h5>
                                        <p>Communication becomes easy, multiple people can collaborate.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="soft-icon"><img src="images/form-icon.png" alt=""/></div>
                                    <div className="soft-details">
                                        <h5>Quick Form Selection</h5>
                                        <p>We select your ITR form based on the inputs you shared.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="soft-icon"><img src="images/income-file.png" alt=""/></div>
                                    <div className="soft-details">
                                        <h5>Support for all Income heads and ITR Forms</h5>
                                        <p>Salary, House Property, PGBP, Capital Gains etc…</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-sm-12  text-center">
                                    <img src="images/computer-img.png" alt="" className="m-auto img-fluid"/>
                                </div>
                                <div className="col-sm-12 text-center text-md-right">
                                    <a href="#"><img src="images/app-store-btn.png" alt="" className="img-fluid imgrp"/> </a>
                                </div>



                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
</>
  )
}

export default Powerfull