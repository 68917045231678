// import "./";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineWallet } from "react-icons/ai";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { FaBars } from "react-icons/fa";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
//   import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdPermPhoneMsg } from "react-icons/md";
import "../demoHeader/Demohed.css";
import "./ProfileHeader.css"

const ProfileHeder = (props) => {
  // const [refresh,setRefresh] = useState(false)
  // setRefresh(data)
  const [navSize, setnavSize] = useState("6.1rem");
  const [navColor, setnavColor] = useState("#f5dac5");
  const [navTextColor, setnavTextColor] = useState("#FFFFFF");
  const [loginbtn, setLoginBTN] = useState("#FFFFFF");
  const [scroll, setScroll] = useState(false);
  const [show, setshow] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const [applyToken, setApplyToken] = useState();
  const [getData, setGetData] = useState({});


  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#FFFFFF") : setnavColor("#f5dac5");
    //   window.scrollX > 10 ? setnavColor("#FFFFFF") : setnavColor("transparent");
    window.scrollY > 10
      ? setnavTextColor("#FFFFFF")
      : setnavTextColor("#FFFFFF");
    window.scrollY > 10 ? setLoginBTN("#002480") : setLoginBTN("#FFFFFF");
    window.scrollY > 10 ? setnavSize("6.1rem") : setnavSize("6.1rem");
  };

  //   const listenScrollEvent = () => {
  //     scrollY > 10 ? setnavColor("#FFFFFF") : setnavColor("transparent");
  //     scrollY > 10 ? setnavTextColor("#002480") : setnavTextColor("#FFFFFF");
  //     scrollY > 10 ? setLoginBTN("#002480") : setLoginBTN("#FFFFFF");
  //     scrollY > 10 ? setnavSize("5.3rem") : setnavSize("5.3rem");
  //   };

  useEffect(() => {
    console.log("props : ", props)
    getToken();

    getWalletBalance();
    getAllData();

    const userId = sessionStorage.getItem("userId");
    if (userId) {
      setshow(true);
    } else {
      setshow(false);
    }
    window.scrollTo(0, 0);
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [scroll]);

  const getAllData = () => {
    const token = sessionStorage.getItem("token");

    const method = "user/profile";
    axios
      .get(REACT_APP_BASE_URL + method, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("User Profile :", res.data);
        setGetData(res.data.data);
      })
      .catch((err) => {
        console.log("User Profile :", err);
      });
  };
  const handleScroll = () => {
    setScroll(!scroll);
  };

  // React.useEffect(()=>{

  // },[])
  const handleLogOut = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("token");
    window.location.href = "/";
  };

  const getToken = () => {
    const token = sessionStorage.getItem("token");
    setApplyToken(token);
    // console.log(token);
  };
  const getWalletBalance = () => {
    console.log(applyToken);
    const method = "user/wallet/balance";

    const tokan = applyToken;
    axios
      .get(REACT_APP_BASE_URL + method, {
        headers: {
          Authorization: tokan ? tokan : sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setWalletBalance(res.data.data.balance);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="" style={{ background: "blue" }}>
        <nav
          class="navbar navbar-expand-lg user-history-header "
          style={{
            backgroundColor: `${navColor}`,
            height: navSize,
            transition: "all 0s",
          }}
        >
          {/* className="container-md container-sm container-xl container-lg container-xxl" */}
          <div class="container-fluid ">
            {/* <a class="navbar-brand" href="#">Navbar</a> */}
            <Link to="/">
              <img src="../images/logo.jpg" onClick={handleScroll} />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" id="phone"><path fill="none" d="M0 0h48v48H0z"></path><path d="M40 31c-2.49 0-4.9-.4-7.14-1.14-.69-.22-1.48-.06-2.03.49l-4.4 4.41a30.171 30.171 0 0 1-13.18-13.17l4.4-4.42c.55-.55.71-1.34.49-2.03C17.4 12.9 17 10.49 17 8c0-1.11-.89-2-2-2H8a2 2 0 0 0-2 2c0 18.78 15.22 34 34 34 1.11 0 2-.89 2-2v-7c0-1.11-.89-2-2-2zM24 6v20l6-6h12V6H24z"></path></svg> */}
            </Link>

            <button
              class="navbar-toggler demo-hed-toggle-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContents"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon demo-hed-toggle-btn">
                <FaBars />
              </span>
            </button>
            <div
              class="collapse navbar-collapse drop-new"
              id="navbarSupportedContent"
              // style={{ marginLeft: "30rem" }}
            >
              <ul class="navbar-nav main-menu">
                {/* <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li> */}
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: navTextColor }}
                  >
                    SALARIED
                  </a>
                  <ul
                    class="dropdown-menu demohed-drop-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link to="/applicationforpan">
                      <li>
                        <a className="dropdown-item li-txt">
                          Application for PAN
                        </a>
                      </li>
                    </Link>
                    <Link to="/incometaxreturn">
                      <li>
                        <a className="dropdown-item" href="#">
                          Income Tax Returns
                        </a>
                      </li>
                    </Link>
                    <Link to="/tdsonproperty">
                      <li>
                        <a className="dropdown-item" href="#">
                          TDS on property purchase (Form 26QB)
                        </a>
                      </li>
                    </Link>
                    <Link to="/tdsreturn">
                      <li>
                        <a className="dropdown-item" href="#">
                          Other TDS Returns
                        </a>
                      </li>
                    </Link>
                    <Link to={"/advancedtaxcalculation"}>
                      <li>
                        <a className="dropdown-item" href="#">
                          Advance Tax Calculation
                        </a>
                      </li>
                    </Link>
                    <Link to="/advisorytaxplanning">
                      <li>
                        <a className="dropdown-item" href="#">
                          Adviosry on Tax Planning
                        </a>
                      </li>
                    </Link>
                    <Link to="/incometaxnotice">
                      <li className="demp-hed-lc">
                        <a className="dropdown-item" href="#">
                          Income Tax Notice (Litigations and Scrutiny)
                        </a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: navTextColor }}
                  >
                    BUSINESS
                  </a>
                  <ul
                    class="dropdown-menu demohed-drop-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Company Incorporation
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Limited Liability Partnership (LLP)
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Partnership Firm
                      </a>
                    </li>
                    <li className="demp-hed-lc">
                      <a className="dropdown-item" href="#">
                        Proprieter
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item two-drop">
                  {!show ? (
                    <Link to="/signup">
                      <li className="nav-item dropdown ">
                        <a
                          className="btn btn-light btn-sm loginbtn"
                          //   style={{ color: loginbtn, borderColor: loginbtn }}
                          id="signin"
                          type="submit"
                        >
                          SIGN IN
                        </a>
                      </li>
                    </Link>
                  ) : (
                    <>
                      {/* <li
                        className="nav-item dropdow"
                        // style={{ paddingTop: "24px", color: navTextColor }}
                      >
                        <a
                          className="btn btn-light btn-s loginbt"
                          style={{
                            background: "transparent",
                            borderRadius: "30px",
                            color: navTextColor,
                          }}
                          id="signin"
                          type="submit"
                        >
                          <AiOutlineWallet
                            className="wallet"
                            style={{ color: navTextColor }}
                          />
                          <span
                            className="ps-3"
                            style={{ color: navTextColor }}
                          >
                            {walletBalance}
                          </span>
                        </a>
                      </li> */}
                      {/* <div
                      className="collapse navbar-collaps"
                      id="navbar-list-4"
                      //   style={{ marginLeft: "35px" }}
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
                              width="40"
                              height="40"
                              className="rounded-circle"
                              //   style={{ color: navTextColor }}
                            />
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdownMenuLink"
                          >
                            <Link to="/profilee">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Profile
                                </a>
                              </li>
                            </Link>
                            <li onClick={handleLogOut}>
                              <a className="dropdown-item" href="#">
                                Log Out
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div> */}
                      <li class="nav-item dropdown ms-5">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              getData?.avatar ? `data:image/jpeg;base64,${getData.avatar}`:
                              "../../images/user-white.jpg"
                            }
                            className="demoHed-img"
                            style={{ color: navTextColor }}
                          />
                        </a>
                        <ul
                          class="dropdown-menu demohed-drop-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link to="/profilee">
                            <li>
                              <a className="dropdown-item" href="#">
                                Profile
                              </a>
                            </li>
                          </Link>
                          <li onClick={handleLogOut} className="demp-hed-lc">
                            <a className="dropdown-item ">Log Out</a>
                          </li>
                        </ul>
                      </li>
                    </>
                    // <li className="nav-item dropdown sbtn" onClick={handleLogOut}>
                    //   <a className="btn btn-light btn-sm loginbtn" style={{color: loginbtn, borderColor: loginbtn}} id='signin' type="submit">SIGN OUT</a>
                    // </li>
                  )}
                </li>
              </ul>
            </div>

            <div className="collapse demo-hed-res-dropdow drop-new" id="navbarSupportedContents"
        // style={{ marginLeft: "30rem" }}
        >
          <ul className="navbar-nav main-menu">
            {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Link</a>
        </li> */}
            <li className="nav-item dropdown new-dropdown-css">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              // style={{ color: navTextColor }}
              >
                SALARIED
              </a>
              <ul
                className="dropdown-menu demohed-drop-menu"
                aria-labelledby="navbarDropdown"
              >
                <Link to="/applicationforpan">
                  <li>
                    <a className="dropdown-item li-txt">
                      Application for PAN
                    </a>
                  </li>
                </Link>
                {/* <Link to="/incometaxreturn">
                    <li>
                      <a className="dropdown-item" href="#">
                        Income Tax Returns
                      </a>
                    </li>
                  </Link> */}
                <Link to="/tdsonproperty">
                  <li>
                    <a className="dropdown-item" href="#">
                      TDS on property purchase (Form 26QB)
                    </a>
                  </li>
                </Link>
                <Link to="/tdsreturn">
                  <li>
                    <a className="dropdown-item" href="#">
                      Other TDS Returns
                    </a>
                  </li>
                </Link>
                <Link to={"/advancedtaxcalculation"}>
                  <li>
                    <a className="dropdown-item" href="#">
                      Advance Tax Calculation
                    </a>
                  </li>
                </Link>
                <Link to="/advisorytaxplanning">
                  <li>
                    <a className="dropdown-item" href="#">
                      Adviosry on Tax Planning
                    </a>
                  </li>
                </Link>
                <Link to="/incometaxnotice">
                  <li className="demp-hed-lc">
                    <a className="dropdown-item" href="#">
                      Income Tax Notice (Litigations and Scrutiny)
                    </a>
                  </li>
                </Link>
              </ul>
            </li>
            <li className="nav-item dropdown new-dropdown-css">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              // style={{ color: navTextColor }}
              >
                BUSINESS
              </a>
              <ul
                className="dropdown-menu demohed-drop-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Company Incorporation
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Limited Liability Partnership (LLP)
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Partnership Firm
                  </a>
                </li>
                <li className="demp-hed-lc">
                  <a className="dropdown-item" href="#">
                    Proprieter
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default ProfileHeder;
