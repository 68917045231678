import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import UserProfile from "../profile/UserProfile";
import DemoHeader from "../demoHeader/DemoHeader";
import ProfileHeder from "../profileHed/ProfileHeder";
import Footer from "../footer/Footer";

const Dashboard = () => {
  const [refresh, setRefresh] = useState(false);
  const handleRefreshChange = () => {
    console.log("call from user profile")
    setRefresh(!refresh)
  }
  useEffect(()=>{
    console.log("useEffect called")
  },[refresh])
  return (
    <div>
      <div>
        <ProfileHeder refresh={refresh}/>
      </div>

      <div className="container-fluid sidebardesign">
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-9">
            <UserProfile handleRefreshChange={handleRefreshChange}/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
