import React from 'react'
import '../../common/Common.css'
import Header from '../../header/Header'
import FaqSection from '../../FAQ-section/Faq-section'
import Footer from '../../footer/Footer'
import { Link } from 'react-router-dom'
import DemoHeader from '../../demoHeader/DemoHeader'

const PlanC = () => {
    return (
        <>
        <DemoHeader/>
            {/* <div className="position-relative">
                <div className="owl-carousel owl-theme banner">
                    <div className="item green-nav-bg">

                        <div className="d-none d-md-block" style="background: url(images/plan-img.png) no-repeat bottom right; opacity: 0.8; position:absolute;  z-index:0; right:0; bottom:0; height:100%; width: 50%; background-size: 100% 100%;"></div>

                        <div className="container-fluid">
                            <div className="row align-items-center vh-100">
                                <div className="col-sm-12 col-md-10 col-lg-8">
                                    <div className="banner-text  banner-text-xl">
                                        <h2>Plan C - Foreign Income</h2>
                                        <div className="banner-text-bottom">
                                            <span>Key Highlights of Plan</span>
                                            <ul>
                                                <li>Detailed discussion on saving in taxes via exemptions or deduction in respect of Double Taxation Avoidance Agreement(DTAA)</li>
                                                <li>Suggestions on possible saving while converting or dealing in foreign exchanges</li>
                                                <li>All taxation needs of an NRI are covered</li>
                                                <li>Dedicated CA assigned on your case</li>
                                                <li>Suggestions on New Tax Saving avenues for next year</li>
                                                <li>Tracking and updating the status of refund, if any</li>
                                                <li>Advisory on queries pertaining to the Income tax return filing</li>
                                            </ul>
                                            <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

<div className="container-fluid" style={{backgroundColor:'#5136e6'}}>
                            <div className="row align-items-center vh-100 headerbackground">
                                <div className="col-sm-12 col-md-10 col-lg-8">
                                    <div className="banner-text  banner-text-xl">
                                        <h2>Plan C - Foreign Income</h2>
                                        <div className="banner-text-bottom">
                                            <span>Key Highlights of Plan</span>
                                            <ul>
                                                <li>Detailed discussion on saving in taxes via exemptions or deduction in respect of Double Taxation Avoidance Agreement(DTAA)</li>
                                                <li>Suggestions on possible saving while converting or dealing in foreign exchanges</li>
                                                <li>All taxation needs of an NRI are covered</li>
                                                <li>Dedicated CA assigned on your case</li>
                                                <li>Suggestions on New Tax Saving avenues for next year</li>
                                                <li>Tracking and updating the status of refund, if any</li>
                                                <li>Advisory on queries pertaining to the Income tax return filing</li>
                                            </ul>
                                            <Link to="/step">
                                                
                                            <button className="btn btn-light regbtn" type="button" onclick="window.location.href='step.html';">BUY NOW</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

            <div className="planbox" id="Header2">
                <div className="planbox-title">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-9 col-md-8" style={{display:'flex'}}>
                                <h4>Plan C - Foreign Income</h4>
                            </div>
                            <div className="col-3 col-md-4 text-right">
                                <div className="dropdown d-inline-block arrowbtn d-sm-none">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="images/down-arrow.svg" />
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#aboutplan">About the Plan</a>
                                        <a className="dropdown-item" href="#howitdone">How it’s done</a>
                                        <a className="dropdown-item" href="#docreq">Documents required</a>
                                        <a className="dropdown-item" href="#faqs">FAQs</a>
                                    </div>
                                </div>
                                <div className="priceboxin">
                                    <span>Just for</span>
                                    <h6>₹1499</h6>
                                </div>
                                <Link to="/step">

                                <a className="btn btn-dark btnblack float-right" href="step.html">BUY NOW</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plannav d-none mtp5 d-sm-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 text-left mtp5 fixnav">
                                <ul className="nav nav-pills nav-fill" id="top-menu">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#aboutplan">About the Plan</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#howitdone">How it’s done</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#docreq">Documents required</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#faqs">FAQs</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="tb-space" id="aboutplan">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">About the Plan</h5>
                        </div>
                    </div>
                    <div className="row mtp15">
                        <div className="col-sm-6 col-xl-7">
                            <h5 className="sub-title-xs mbp10">Plan details</h5>
                            <p className="black-smalltext">This Tax product is designed to safeguard an individual from possible double taxation when income is earned or generated in foreign country. Even when DTAA is not applicable income earned in foreign country may lead to unnecessary future litigation if not properly dealt with relevant procedure and provisions of income tax act. Also, an individual having status of NRI has to pay taxes for income earned in India, such cases are also covered in this plan.
                                This plan covers all your taxation need when an individual has on-sight deployment for few months or years. Further, all the benefits of Plan B are available in this product in addition to above mentioned services. Therefore, an individual shall be guided to best of his/her interest and be made aware of tax implications applicable to him/her.</p>
                            <p className="text-xs"> <i>This plan covers the Income tax return filing for an individual who has source of income from any one, two or all of the following heads of income.</i></p>
                            <ul className="list01 mbp15">
                                <li>Income earned in foreign country whether or not in foreign exchange</li>
                                <li>Income generated in foreign country and repatriated in India</li>
                                <li>Income earned by NRI in India</li>
                                <li>Income from sale of any asset like land, property, shares etc. (Capital gain)</li>
                                <li>Income from Salaries (One or multiple form 16, all are covered)</li>
                                <li>Income from House Property (One or multiple, all are covered)</li>
                                <li>Income from interest, gifts, dividend, agriculture, etc.</li>
                                <li>Income from winnings from lottery, awards, crossword puzzles, etc</li>
                            </ul>
                            <p className="text-xs"> <i>Note: In case of any business income please refer succeeding products</i></p>



                            <h5 className="sub-title-xs mtp25 mbp10">Who should buy?</h5>
                            <p className="black-smalltext">1. An individual who had any on sight opportunity for any period of time during the financial year</p>
                            <p className="black-smalltext">2. Individual who own a bank account or any other asset in foreign country</p>
                            <p className="black-smalltext">3. NRI earning income in India or having foreign asset in India</p>
                            <p className="black-smalltext">4. Individual having Salary /House Rent /Interest /Capital Gain Income along with foreign Income</p>
                            <p className="black-smalltext">5. An individual earning income from free lancing from foreign countries is NOT covered under this product, kindly refer our ‘Business Section” for the same</p>
                        </div>
                        <div className="col-sm-6  col-xl-5">
                            <img src="images/about-img.png" alt="" className="img-fluid m-auto float-md-right" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space graybg" id="howitdone">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block">How it’s Done</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="hid-list-main">
                                <ul className="hid-list six">
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/cart-icon.png" /></div>
                                            <div className="hid-text">Purchase of Plan</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/upload-icon.png" /></div>
                                            <div className="hid-text">Upload Documents</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/file-search-icon.png" /></div>
                                            <div className="hid-text">Case overview with expert</div>
                                        </div>
                                    </li>

                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Review & Approval of Computation of Income</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/generation-form-icon.png" /></div>
                                            <div className="hid-text">Filling of Income Tax Return with Acknowledgment</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div className="hid-img"><img src="images/filling-form-icon.png" /></div>
                                            <div className="hid-text">Feedback</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="estimated-row">
                                <span>5 Days Estimated</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tb-space blackbg" id="docreq">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title white-row mbp15 d-inline-block white-color">Documents required</h5>
                        </div>
                    </div>
                    <div className="row mtp25 gutter-20">
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">01</div>
                                <div className="doc-details">Details of Income earned outside India</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">02</div>
                                <div className="doc-details">Details of foreign bank account or foreign asset, if any</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">03</div>
                                <div className="doc-details">PAN & Aadhar Card</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">04</div>
                                <div className="doc-details">Form 16/ Salary Slips</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">05</div>
                                <div className="doc-details">Interest Certificate (Bank Deposits, Housing Loan etc)</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">06</div>
                                <div className="doc-details">Capital Gain Income Details</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="doc-box">
                                <div className="number-box">07</div>
                                <div className="doc-details">Investment Details</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqSection/>
            <Footer/>
        </>
    )
}

export default PlanC