import React, { useEffect, useState } from "react";
import "./Header.css";
import "../../components/common/Common.css";
import { Link } from "react-router-dom";
import { AiOutlineWallet } from "react-icons/ai";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { error } from "jquery";

const Header = () => {
  const [navSize, setnavSize] = useState("5.3rem");
  const [navColor, setnavColor] = useState("transparent");
  const [navTextColor, setnavTextColor] = useState("#FFFFFF");
  const [loginbtn, setLoginBTN] = useState("#FFFFFF");
  const [scroll, setScroll] = useState(false);
  const [show, setshow] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const [applyToken, setApplyToken] = useState();

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#FFFFFF") : setnavColor("transparent");
    window.scrollY > 10
      ? setnavTextColor("#002480")
      : setnavTextColor("#FFFFFF");
    window.scrollY > 10 ? setLoginBTN("#002480") : setLoginBTN("#FFFFFF");
    window.scrollY > 10 ? setnavSize("5.3rem") : setnavSize("5.3rem");
  };
  useEffect(() => {
    getToken();

    getWalletBalance();

    const userId = sessionStorage.getItem("userId");
    if (userId) {
      setshow(true);
    } else {
      setshow(false);
    }
    window.scrollTo(0, 0);
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [scroll]);

  const handleScroll = () => {
    setScroll(!scroll);
  };

  // React.useEffect(()=>{

  // },[])
  const handleLogOut = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("token");
    window.location.href = "/";
  };

  const getToken = () => {
    const token = sessionStorage.getItem("token");
    setApplyToken(token);
    // console.log(token);
  };
  const getWalletBalance = () => {
    console.log(applyToken);
    const method = "user/wallet/balance";

    const tokan = applyToken;
    axios
      .get(REACT_APP_BASE_URL + method, {
        headers: {
          Authorization: tokan ? tokan : sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setWalletBalance(res.data.data.balance);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <header
      className=""
      id="myHeader"
      style={{
        backgroundColor: navColor,
        height: navSize,
        transition: "all 0s",
      }}
    >
      <div className="container-fluid">
        <nav className="navbar navbar-expand-sm navigation navbar-light bg-light">
          <Link to="/">
            <a className="navbar-brand" href="#">
              <img
                onClick={handleScroll}
                src={process.env.PUBLIC_URL + "/images/logo.jpg"}
                alt=""
                width="100% "
                height="100%"
                className="d-inline-block align-text-top"
                style={{ backgroundColor: "transparent" }}
              />
            </a>
          </Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
          <div
            className="collapse navbar-collapse headerdropdown"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle salaried"
                  style={{ color: navTextColor }}
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  SALARIED
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link to="/applicationforpan">
                    <li>
                      <a className="dropdown-item">Application for PAN</a>
                    </li>
                  </Link>
                  <Link to="/incometaxreturn">
                    <li>
                      <a className="dropdown-item" href="#">
                        Income Tax Returns
                      </a>
                    </li>
                  </Link>
                  <Link to="/tdsonproperty">
                    <li>
                      <a className="dropdown-item" href="#">
                        TDS on property purchase (Form 26QB)
                      </a>
                    </li>
                  </Link>
                  <Link to="/tdsreturn">
                    <li>
                      <a className="dropdown-item" href="#">
                        Other TDS Returns
                      </a>
                    </li>
                  </Link>
                  <Link to={"/advancedtaxcalculation"}>
                    <li>
                      <a className="dropdown-item" href="#">
                        Advance Tax Calculation
                      </a>
                    </li>
                  </Link>
                  <Link to="/advisorytaxplanning">
                    <li>
                      <a className="dropdown-item" href="#">
                        Adviosry on Tax Planning
                      </a>
                    </li>
                  </Link>
                  <Link to="/incometaxnotice">
                    <li>
                      <a className="dropdown-item" href="#">
                        Income Tax Notice (Litigations and Scrutiny)
                      </a>
                    </li>
                  </Link>
                </ul>
              </li>
              {/* <li><a href="#">Portfolio</a>
      <ul>
        <li><a href="#">Graphic</a></li>
        <li><a href="#">Web</a></li>
      </ul>
    </li> */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle business"
                  style={{ color: navTextColor }}
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  BUSINESS
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Company Incorporation
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Limited Liability Partnership (LLP)
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Partnership Firm
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Proprieter
                    </a>
                  </li>
                </ul>
              </li>

              {!show ? (
                <Link to="/signup">
                  <li className="nav-item dropdown sbtn">
                    <a
                      className="btn btn-light btn-sm loginbtn"
                      style={{ color: loginbtn, borderColor: loginbtn }}
                      id="signin"
                      type="submit"
                    >
                      SIGN IN
                    </a>
                  </li>
                </Link>
              ) : (
                <>
                  <li
                    className="nav-item dropdow"
                    style={{ paddingTop: "24px", color: navTextColor }}
                  >
                    <a
                      className="btn btn-light btn-s loginbt"
                      style={{
                        background: "transparent",
                        borderRadius: "30px",
                        color: navTextColor,
                      }}
                      id="signin"
                      type="submit"
                    >
                      <AiOutlineWallet
                        className="wallet"
                        style={{ color: navTextColor }}
                      />
                      <span className="ps-3" style={{ color: navTextColor }}>
                        {walletBalance}
                      </span>
                    </a>
                  </li>
                  <div
                    className="collapse navbar-collapse"
                    id="navbar-list-4"
                    style={{ marginLeft: "35px" }}
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
                            width="40"
                            height="40"
                            className="rounded-circle"
                            style={{ color: navTextColor }}
                          />
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <Link to="/profilee">
                            <li>
                              <a className="dropdown-item" href="#">
                                Profile
                              </a>
                            </li>
                          </Link>
                          <li onClick={handleLogOut}>
                            <a className="dropdown-item" href="#">
                              Log Out
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
                // <li className="nav-item dropdown sbtn" onClick={handleLogOut}>
                //   <a className="btn btn-light btn-sm loginbtn" style={{color: loginbtn, borderColor: loginbtn}} id='signin' type="submit">SIGN OUT</a>
                // </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
