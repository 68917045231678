import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import UserDetails from '../profile/UserDetails'
import ProfileHeder from '../profileHed/ProfileHeder'
import Footer from '../footer/Footer'
import Loader from '../loader/Loader'

const ProductList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoader = () => {
    setIsLoading(false);
  }

  return (
    <div>
      {isLoading && <Loader/>}
      <ProfileHeder/>
      <div className='container-fluid sidebardesign'>
        <div className='row' >
          <div className='col-md-3 col-sm-12'><Sidebar /></div>
          <div className='col-md-9 col-sm-12' ><UserDetails handleLoader={handleLoader}/></div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ProductList