import React, { useEffect, useState } from "react";
import "../common/Common.css";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { error } from "jquery";
import './GetRsOff.css'
import Container from "react-bootstrap/esm/Container";
import { Col, Row } from "react-bootstrap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GetRsOff = () => {
  const [bannerData, setBannerData] = useState([]);
  const [img, setImg] = useState(""); // Add a new state for the image
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [referEmail, setReferEmail] = useState();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [applyToken, setApplyToken] = useState();

  useEffect(() => {
    getBanner();
    getToken();
  }, []);

  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  // const modalRef = useRef(null);

  const getBanner = () => {
    const method = "banner/images?subServiceId=Home&position=4";
    axios.get(REACT_APP_BASE_URL + method).then((res) => {
      console.log("GET RS OFF ", res.data.data);
      setBannerData(res.data.data);
      const imgData = res?.data?.data[0]?.image;
      //   console.log(`res.data.data:img/jpeg;base64,${imgData}`);
      setImg(imgData); // Update the image state
    }).catch((error) => {
      console.log(error);
    })
  };

  const getToken = () => {
    const token = sessionStorage.getItem("token");
    setApplyToken(token);
    // console.log(token);
  };
  const handleInputChange = (e) => {
    // console.log(e.target.value)
    setReferEmail(e.target.value);
  };
  // const handleReferSubmit = (e) => {
  //   e.preventDefault();
  //   handleClose();
  //   setSubmitClicked(true);
  //   const data = {
  //     friendEmail: referEmail,
  //   };
  //   // console.log(data);

  //   const method = "user/referral-link";

  //   axios
  //     .post(REACT_APP_BASE_URL + method, data, {
  //       headers: {
  //         Authorization: applyToken,
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.data.status === 1) {
  //         toast.success("Refer successfully!", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       } else {
  //         toast.error("Please check entered email!", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //       if (
  //         error.response.data.message === "Friend email is already registered"
  //       ) {
  //         toast.warn("Friend email is already registered!", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     });
  // };


  const handleReferSubmit = (e) => {
    e.preventDefault();
    handleClose();
    setSubmitClicked(true);
  
    // Check if the email is empty
    if (!referEmail) {
      toast.error("Email cannot be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
  
    // Check if the email matches the basic pattern
    if (!isValidEmail(referEmail)) {
      toast.error("Invalid email format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
  
    // If all checks pass, send the referral request
    const data = {
      friendEmail: referEmail,
    };
  
    const method = "user/referral-link";
  
    axios
      .post(REACT_APP_BASE_URL + method, data, {
        headers: {
          Authorization: applyToken,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          toast.success("Refer successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Please check entered email!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Friend email is already registered") {
          toast.warn("Friend email is already registered!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  return (
    <>
      <section
        className="ReferaFriend-bo GetRsOff-WebView"
        style={{
          backgroundImage: `url(data:image/jpeg;base64,${bannerData[0]?.image})`,
          height: "12rem", backgroundSize: 'cover'
        }}
      >
        <div className="container position-relative">
          <div className="cove d-xl-none">
            <img src="images/cove-md.png" />
          </div>
          <div className="row getrsoff-text-row">
            <div className="col-7 col-sm-8 col-md-6 offset1-md-6 col-lg-9 offset-lg-3 col-xl-10 offset-xl-2 mobile-space getrsoff-text-col">
              <div className="row">
                <div className="col-5 col-sm-4 d-md-none d-block mh-100  align-items-center yellowbg">
                  <img
                    src="images/Refer-a-Friend-left.png"
                    alt=""
                    className="img-fluid align-middle"
                  />
                </div>
                <div className="col-sm-12">
                  <h4 className="white-title white-title-mob pl25 mt-5">
                    {/* Get ₹ 00 Off */}
                    {bannerData[0]?.title}
                  </h4>
                </div>
              </div>
              <div className="row mtp15  align-items-center" style={{ marginTop: '0px' }}>
                <div className="col-lg-8">
                  <p className="white-smalltext mbp0 pl25">
                    {/* Refer a friend and on any of our product */}
                    {bannerData[0]?.text}
                  </p>
                </div>
                <div className="col-lg-4 text-left text-lg-right">
                  {applyToken === null ? (
                    <button
                      className="btn btn-light regbtn"
                      type="button"
                      onClick={handleOpens}
                    >
                      {/* Refer Now */}
                      {bannerData[0]?.buttonText}
                    </button>
                  ) : (
                    <button
                      className="btn btn-light regbtn"
                      type="button"
                      onClick={handleOpen}
                    >
                      {/* Refer Now */}
                      {bannerData[0]?.buttonText}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="mainContainer GetRsOff-MobView">
        <div className="mainContainer-inner">
          <div className="row">
            <div className="col-6 row-col-left">
              <div className="left-img">
                <img src="/images/Refer-a-Friend-left.png" />
              </div>
            </div>
            <div className="col-6 row-col-right">
              <div className="inner-data" style={{ padding: '1rem' }}>
                <div>
                  <h4 className="white-title white-title-mob pl25 mt-5">
                    {bannerData[0]?.title}
                  </h4>
                </div>
                <div>
                  <p className="white-smalltext mbp0 pl25">
                    {bannerData[0]?.text}
                  </p>
                </div>
                <div>{applyToken === null ? (
                  <button
                    className="btn btn-light regbtn"
                    type="button"
                    onClick={handleOpens}
                  >
                    {bannerData[0]?.buttonText}
                  </button>
                ) : (
                  <button
                    className="btn btn-light regbtn"
                    type="button"
                    onClick={handleOpen}
                  >
                    {bannerData[0]?.buttonText}
                  </button>
                )}</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Container className="mainContainer GetRsOff-MobView">
        <Row style={{backgroundColor:"#ff9144"}}>
          <Col className="row-col-left" style={{alignSelf:'center'}}>
            <div className="left-img">
              <img src="/images/Refer-a-Friend-left.png" />
            </div>
          </Col>
          <Col className="row-col-right">
            <div className="inner-data" style={{ padding: '1rem' }}>
              <div>
                <h4 className="white-title white-title-mob pl25 mt-5">
                  {bannerData[0]?.title}
                </h4>
              </div>
              <div>
                <p className="white-smalltext mbp0 pl25">
                  {bannerData[0]?.text}
                </p>
              </div>
              <div>{applyToken === null ? (
                <button
                  className="btn btn-light regbtn"
                  type="button"
                  onClick={handleOpens}
                >
                  {/* Refer Now */}
                  {bannerData[0]?.buttonText}
                </button>
              ) : (
                <button
                  className="btn btn-light regbtn"
                  type="button"
                  onClick={handleOpen}
                >
                  {/* Refer Now */}
                  {bannerData[0]?.buttonText}
                </button>
              )}</div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ border: "none", borderRadius: '10px' }} className="modal-outer-Refer">
          <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 25 }}>
            Refer a Friend
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            id="outlined-password-input"
            label="Enter friends Email"
            type="email"
            // autoComplete="current-password"
            onChange={handleInputChange}
            value={referEmail}
            inputProps={{
              pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
            }}
            error={submitClicked && !isValidEmail(referEmail)} // Show error only after Submit click
            helperText={
              submitClicked && !isValidEmail(referEmail)
                ? "Invalid email format"
                : ""
            }
          />
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              style={{ background: "brown", padding: "8px 20px", color: "white", borderRadius: "18px" }}
              onClick={(e) => handleReferSubmit(e)}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      {/* <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Link to="/signup">
            <Typography
              id="modal-modal-description"
              sx={{ mt: 0.1 }}
              style={{ fontSize: "24px" }}
            >
              Please{" "}
              <span
                style={{
                  color: "orange",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Log In
              </span>{" "}
              to continue ...
            </Typography>
          </Link>
        </Box>
      </Modal> */}

      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-dialog" style={{ marginTop: '15rem', width: '93%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                TaxSavvy
              </h5>
              {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
            </div>
            <div className="modal-body" style={{ padding: '2rem' }}>
              <div style={{ fontSize: "23px", paddingBottom: '2rem' }}>
                  {" "} Sign In {" "}
                to continue...
              </div>
              <div className="modal-SignIn-btn">
                <Link to="/signup">
                  <button className="no-login-buy"
                    type="button"
                  >
                    Sign In
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default GetRsOff;
