import React, { useEffect, useState } from 'react'
import '../common/Common.css'
import Header from '../header/Header'
import Carousel from '../carousel/Carousel'
import ContactUs from '../coontactus/ContactUs'
import OurServices from '../ourservices/OurServices'
import IncomeTaxFilling from '../incometaxfilling/IncomeTaxFilling'
import WhyTaxSavvy from '../whytaxsavvy/WhyTaxSavvy'
import AskExpert from '../askexpert/AskExpert'
import ChoosebestPlan from '../choosebestplan/ChoosebestPlan'
import GstFilling from '../gstfilling/GstFilling'
import DoYouKnow from '../doyouknow/DoYouKnow'
import GetRsOff from '../getrsoff/GetRsOff'
import StayTuned from '../staytuned/StayTuned'
import Testimonial from '../testimonial/Testimonial'
import Powerfull from '../powerfull/Powerfull'
import FaqSection from '../FAQ-section/Faq-section'
import Footer from '../footer/Footer'
import MostPopularPlan from '../MostPopularPlan/MostPopularPlan'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUplogin from '../signuplogin/SignUplogin'
import BasicCard from '../signuplogin/SignUplogin'
import Profile from '../profile/Profile'
import FormDetail from '../formDetails/FormDetail'
import UserProfile from '../profile/UserProfile'
import GetOtp from '../getOtp/GetOtp'
import MyDoc from '../profile/MyDoc'
import PurchaseHistory from '../profile/PurchaseHistory'
import DemoHeader from '../demoHeader/DemoHeader'

const HomePage = () => {
    const [serviceId, setSubServiceId] = useState();
    useEffect(()=>{
        console.log("In useEffect")
        console.log(sessionStorage.getItem("reload"))

        if(sessionStorage.getItem("reload") === '1')
        {   
            console.log("reload")
            sessionStorage.setItem("reload",0)
            window.location.reload();
        }
        
    },[serviceId])
    const setPlanData = (data) => {
        setSubServiceId(data)
    }
    return (
        <>
        {/* <PurchaseHistory/>
        <MyDoc/>
        <UserProfile/>
        <FormDetail/> */}
            <Carousel />
            {/* <Header /> */}
            <DemoHeader/>
            <ContactUs />
            <OurServices setPlanData={setPlanData}/>
            <IncomeTaxFilling/>
            <WhyTaxSavvy/>
            <AskExpert/>
            <ChoosebestPlan data={serviceId}/>
            <GstFilling/>
            <DoYouKnow/>
            <GetRsOff/>
            <MostPopularPlan/>
            <StayTuned/>
            <Testimonial/>
            <Powerfull/>
            <FaqSection/>
            <Footer/>
            {/* <GetOtp/> */}

        </>

    )
}

export default HomePage