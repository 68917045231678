import React, { useEffect, useState } from "react";
import "../../common/Common.css";
import "../../header/Header.js";
import "../../footer/Footer.js";
import Header from "../../header/Header.js";
import "../../askexpert/AskExpert.js";
import Footer from "../../footer/Footer.js";
import AskExpert from "../../askexpert/AskExpert.js";
import "../../services/incometax/FileItr.js";
import FileItr from "../../services/incometax/FileItr.js";
import "../../getrsoff/GetRsOff.js";
import GetRsOff from "../../getrsoff/GetRsOff.js";
import "../../services/incometax/BenefitsOfFillingItr.js";
import BenefitsOfFillingItr from "../../services/incometax/BenefitsOfFillingItr.js";
import "../../incometaxfilling/IncomeTaxFilling.js";
import "../../FAQ-section/Faq-section.js";
import IncomeTaxFilling from "../../incometaxfilling/IncomeTaxFilling.js";
import FaqSection from "../../FAQ-section/Faq-section.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../service/Constant";
import DemoHeader from '../../demoHeader/DemoHeader'
import { toast } from "react-toastify";
import { Breadcrumbs, Typography } from "@material-ui/core";

const IncometaxReturn = () => {
  const [activeId, setActiveId] = useState(1);
  const [planData, setPlanData] = useState();
  const [subService, setSubServiceId] = useState("");
  const [refresh, setRefresh] = useState();
  const Location = useLocation();
  const [salaryData, setSalaryData] = useState();
  const [businessData, setBusinessData] = useState();
  const [planImage, setPlanImage] = useState();
  const [imageData, setImageData] = useState();
  const [subServiceIds, setSubServiceIds] = useState();

  const Navigate = useNavigate()

  const HandleActive = (id) => {
    setActiveId(id);
  };

  function showonlyone(thechosenone) {
    $(".tab-pane").each(function (index) {
      if ($(this).attr("id") == thechosenone) {
        $(this).fadeIn(0);
      } else {
        $(this).fadeOut(0);
      }
    });
  }

  const getPlans = () => {
    let id;
    if (Location?.state?.subServiceId !== null || Location?.state?.subServiceId !== undefined) {
      id = Location?.state?.subServiceId
    } else if (localStorage.getItem("SubServiceId")) {
      id = localStorage.getItem("SubServiceId")
    }
    const method = "plans/";
    if (id) {
      axios
        .get(REACT_APP_BASE_URL + method + id, {})
        .then(
          (response) => {
            console.log("response : ", response.data.data);
            setPlanData(response.data.data);

            if (response?.data?.data) {
              setRefresh(true);
            }

            const salaryPlan = response.data.data.filter((plan) => {
              if (plan.type && plan.type.includes("Salaried")) {
                return true;
              }
              return false;
            });

            console.log("salaryPlan", salaryPlan);
            setSalaryData(salaryPlan);

            const businessPlan = response.data.data.filter((plan) => {
              if (plan.type && plan.type.includes("Business")) {
                return true;
              }
              return false;
            });
            console.log("businessPlan", businessPlan);
            setBusinessData(businessPlan);
            // const images = businessData.map((item) => {
            //   const imageBase64 = item.image;
            //   const imageDataUrl = `data:image/jpeg;base64,${imageBase64}`;
            //   return imageDataUrl;
            // });
            // setImageData(images);
          },
          (error) => {
            console.log(error);
          }
        );
    }
    else {
      toast.error("Something went wrong..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      Navigate("/")
    }

  };

  useEffect(() => {
    if (refresh === false || refresh === null || refresh === undefined) {
      getPlans();
      const subService = Location.state
      console.log("data : ", subService)
      setSubServiceIds(subService?.subServiceId)
      localStorage.setItem("SubServiceId", subService?.subServiceId)
    }
  }, [activeId, refresh]);
  return (
    <>
      {/* <Header /> */}
      <DemoHeader />

      <div className="bannertop">
        <div className="container text-left">
          <div className="banner-vhmian itr-breadcrumb">
            <div>
              <Breadcrumbs aria-label="breadcrumb" style={{color:'#FFF'}}>
                <Link underline="hover" style={{color:'#FFFFFF'}} to="/">
                  Home
                </Link>
                <Typography color="text.primary" className="breadcrumb-color">Income Tax Return</Typography>
              </Breadcrumbs>
            </div>
            <div className="vhin">
              <h1>Income Tax Return</h1>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="whitebox sal-bus-outer">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="tab-btn-box ">
                  <ul className="nav nav-pills" style={{ marginBottom: '-9px' }}>
                    <li
                      className="nav-item itr-btn"
                      onClick={() => {
                        showonlyone("newboxes1");
                        HandleActive(1);
                      }}
                    >
                      <a
                        className={`nav-link ${activeId === 1 ? "active pill-name" : ""} `}
                        id="pills-home-tab"
                        data-toggle="pill"
                        // href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        For Salaried
                      </a>
                    </li>
                    <li
                      className="nav-item  itr-btn"
                      onClick={() => {
                        showonlyone("newboxes2");
                        HandleActive(2);
                      }}
                    >
                      <a
                        className={`nav-link  ${activeId === 2 ? "active pill-name " : ""}`}
                        id="pills-profile-tab"
                        data-toggle="pill"
                        // href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        For Business
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${activeId === 1 ? "show active" : ""
                      }`}
                    id="newboxes1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-sm-12">

                        {/* Salaried Dynamic Plans */}
                        <div className="row mtp30">
                          {salaryData &&
                            salaryData.map((data, index) => {
                              return (
                                <div
                                  key={data.planId}
                                  className="col-sm-6 col-lg-4"
                                >
                                  <div
                                    className={`${((index + 1) % 2 == 0 && "blue-bg") ||
                                      ((index + 1) % 3 == 0 && "orange-bg") ||
                                      "green-bg"
                                      } radius-shadow probox-main`}
                                  >
                                    <div className="proimg-box">
                                      <img
                                        src={`data:image/jpeg;base64,${data?.image}`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="prodetails">
                                      <h4>{data?.planName}</h4>
                                      <div className="prolist-scroll">
                                        <ul dangerouslySetInnerHTML={{ __html: data?.description }}>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-5 col-md-6">
                                        <div className="price-box">
                                          <span>Just for</span>
                                          <div className="rupee">
                                            {"₹" + data?.fee}{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-7 col-md-6">
                                        <Link to="/plana" state={{ data, subServiceIds }}>
                                          <a
                                            href="planA-product.html"
                                            className="probtn border-white"
                                          >
                                            GET PLAN
                                          </a>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      {/* All Plans Description div */}
                      <div className="col-sm-12 mtp30">
                        <div className="all-plan-box">
                          <h4>All Plans have bellow services</h4>
                          <div className="row mtp20 gutter-5 row-cols-1 row-cols-sm-3 row-cols-xl-5">
                            <div className="col">
                              <img
                                src="images/check.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span>Dedicated CA assigned on your case</span>
                            </div>
                            <div className="col">
                              <img
                                src="images/check.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span>
                                Prior consultant on maximum tax benefit
                              </span>
                            </div>

                            <div className="col">
                              <img
                                src="images/check.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span>
                                Suggestion on New Tax Saving avenues for next
                                year
                              </span>
                            </div>
                            <div className="col">
                              <img
                                src="images/check.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span>
                                Tracking and updating the status of refund, if
                                any
                              </span>
                            </div>
                            <div className="col">
                              <img
                                src="images/check.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span>
                                Advisory to queries pertaining to the income tax
                                filing
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Salaried Plan specification table */}
                      <div className="col-sm-12">
                        <table className="table table-bordered plantable table-striped">
                          <thead>
                            <tr>
                              <th width="40%" className="graythbg">
                                Plan Specification
                              </th>
                              <th
                                width="20%"
                                className="blue-bg text-white text-center"
                              >
                                Plan A
                              </th>
                              <th
                                width="20%"
                                className="orange-bg text-white text-center"
                              >
                                Plan B
                              </th>
                              <th
                                width="20%"
                                className="green-bg text-white text-center"
                              >
                                Plan C
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Single Form-16</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Multiple Form-16 (Same financial year)</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>House Property</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Interest on saving A/c and fixed deposit</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Other Form of Interest</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Agriculture Income</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Capital gain from Sale of land Property
                                Including residential and commercial
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Capital gain from Sale of shares, Mutual Funds,
                                F&O
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Foreign Income</td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>NRE A/c, NRO A/c</td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Brought Forward Losses</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${activeId === 2 ? "show active" : ""
                      }`}
                    id="newboxes2"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row">

                      {/* Business Plan */}

                      <div className="col-sm-12">
                        <div className="row mtp30">
                          {businessData &&
                            businessData.map((data, index) => {
                              return (
                                <div
                                  className="col-sm-6 col-lg-4"
                                  key={data?.planId}
                                >
                                  <div
                                    className={`${((index + 1) % 2 == 0 && "blue-bg") ||
                                      ((index + 1) % 3 == 0 && "orange-bg") ||
                                      "green-bg"
                                      } radius-shadow probox-main`}
                                  >
                                    <div className="proimg-box">
                                      <img
                                        src={`data:image/jpeg;base64,${data?.image}`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="prodetails">
                                      <h4>{data?.planName}</h4>
                                      <div className="prolist-scroll">
                                        <ul dangerouslySetInnerHTML={{ __html: data?.description }}>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="price-box">
                                          <span>Just for</span>
                                          <div className="rupee">
                                            {"₹" + data?.fee}{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <Link to="/plana" state={{ data, subServiceIds }}>
                                          <a
                                            href="planD-product.html"
                                            className="probtn border-white"
                                          >
                                            GET PLAN
                                          </a>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                        </div>
                        <div className="row mtp30 ">
                          <div className="col-sm-12">
                            <div className="all-plan-box all-plan-have">
                              <h4>All Plans have bellow services</h4>
                              <div className="row mtp20 gutter-5 row-cols-1 row-cols-sm-3 row-cols-xl-5">
                                <div className="col">
                                  <img
                                    src="images/check.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <span>
                                    Dedicated CA assigned on your case
                                  </span>
                                </div>
                                <div className="col">
                                  <img
                                    src="images/check.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <span>
                                    Prior consultant on maximum tax benefit
                                  </span>
                                </div>

                                <div className="col">
                                  <img
                                    src="images/check.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <span>
                                    Suggestion on New Tax Saving avenues for
                                    next year
                                  </span>
                                </div>
                                <div className="col">
                                  <img
                                    src="images/check.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <span>
                                    Tracking and updating the status of refund,
                                    if any
                                  </span>
                                </div>
                                <div className="col">
                                  <img
                                    src="images/check.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <span>
                                    Advisory to queries pertaining to the income
                                    tax filing
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Business plan specification table */}

                      <div className="col-sm-12">
                        <table className="table table-bordered plantable table-striped">
                          <thead>
                            <tr>
                              <th width="40%" className="graythbg">
                                Plan Specification
                              </th>
                              <th
                                width="20%"
                                className="pink-bg text-white text-center"
                              >
                                Plan D
                              </th>
                              <th
                                width="20%"
                                className="purple-bg text-white text-center"
                              >
                                Plan E
                              </th>
                              <th
                                width="20%"
                                className="darkblue-bg text-white text-center"
                              >
                                Plan F
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Income From Salary</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Income from Capital Gain</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>House Property</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Rent, Interest or any other income</td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Futures & options (F&O) or Intraday Trading
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Profession (like Doctor, Architect, freelancers
                                etc.) having turnover less than Rs 50 lakhs
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Profession (like Doctor, Architect, freelancers
                                etc.) having turnover more than Rs 50 lakhs
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Commission or Brokerage or Agency Income</td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Businesses having annual turnover less than Rs 2
                                crore
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Businesses having annual turnover more than Rs 2
                                crore
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Preparation of Financial Statement (Balance
                                Sheet, Profit & Loss A/c)
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/check.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Includes Audit Fees & DSC Charges</td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                              <td className="text-center align-middle">
                                <img src="images/cross.png" alt="" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AskExpert />
      <FileItr />
      <GetRsOff />
      <BenefitsOfFillingItr />
      <IncomeTaxFilling />
      <FaqSection />
      <Footer />
    </>
  );
};

export default IncometaxReturn;
