import React, { useEffect, useState } from "react";
import './ChoosebestPlan.css'
import { Link } from 'react-router-dom'
import { REACT_APP_BASE_URL } from "../../service/Constant";
import axios from 'axios';



const ChoosebestPlan = (props) => {
    const [salaryData, setSalaryData] = useState();
    const [businessData, setBusinessData] = useState();
    const [planData, setPlanData] = useState();
    const [refresh, setRefresh] = useState(false);
    const [sessionId, setsessionId] = useState();

    const SubServiceId = '64eee9bc9e22723d1fa81d04'


    useEffect(() => {
        if (!salaryData) {
            getData()
        }
    }, [refresh])

    const getData = () => {
        const method = "plans/";
        axios
            .get(REACT_APP_BASE_URL + method + '64eee9bc9e22723d1fa81d04', {})
            .then(
                (response) => {
                    console.log("response : ", response.data.data);
                    setPlanData(response.data.data);

                    if (response.data.data) {
                        setRefresh(!refresh);
                    }

                    const salaryPlan = response.data.data.filter((plan) => {
                        if (plan.type && plan.type.includes("Salaried")) {
                            return true;
                        }
                        return false;
                    });

                    console.log("salaryPlan", salaryPlan);
                    setSalaryData(salaryPlan);


                    // const businessPlan = response.data.data.filter((plan) => {
                    //   if (plan.type && plan.type.includes("Business")) {
                    //     return true;
                    //   }
                    //   return false;
                    // });
                    // console.log("businessPlan", businessPlan);
                    // setBusinessData(businessPlan);
                    // const images = businessData.map((item) => {
                    //   const imageBase64 = item.image;
                    //   const imageDataUrl = `data:image/jpeg;base64,${imageBase64}`;
                    //   return imageDataUrl;
                    // });
                    // setImageData(images);
                },
                (error) => {
                    console.log(error);
                }
            );

    }

    return (
        <>

            <section className="tb-space graybg choose-best-plan-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h5 className="main-title red-row mbp15 d-inline-block choose-main-title">Choose the Best Plan</h5>
                            {/* <p className="smalltext-black mtp20">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p> */}
                        </div>
                        <div className="col-sm-12">
                            <div className="row mtp30">
                                {salaryData && salaryData.map((data, index) => {
                                    return (<div key={data.planId} className="col-sm-6 col-lg-4 custom">
                                        <div className={`${((index + 1) % 2 == 0 && "blue-bg") ||
                                            ((index + 1) % 3 == 0 && "orange-bg") ||
                                            "green-bg"
                                            } radius-shadow probox-main`}>
                                            <div className="proimg-box">
                                                <img
                                                    src={`data:image/jpeg;base64,${data?.image}`}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="prodetails">
                                                <h4>{data?.planName}</h4>
                                                <div className="prolist-scroll">
                                                    <ul dangerouslySetInnerHTML={{ __html: data?.description }}>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 col-md-6">
                                                    <div className="price-box">
                                                        <span>Just for</span>
                                                        <div className="rupee"> {"₹" + data?.fee}{" "}</div>
                                                    </div>
                                                </div>
                                                <div className="col-7 col-md-6">
                                                    <Link to="/plana" state={{ data, SubServiceId }}>
                                                        <a href="planA-product.html" className="probtn">GET PLAN</a>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>)
                                })
                                }
                                {/* <div className="col-sm-6 col-lg-4 custom">
                                    <div className="orange-bg radius-shadow probox-main">
                                        <div className="proimg-box">
                                            <img src="images/plan-02.png" alt="" className="img-fluid" />
                                        </div>
                                        <div className="prodetails">
                                            <h4>Plan B - Capital Gain</h4>
                                            <div className="prolist-scroll">
                                                <ul>
                                                    <li>Everything is Plan A </li>
                                                    <li> Income from Sales of Shares and Mutuals Fund </li>
                                                    <li>Income from Sales of Property (Land, House Property or Commercial Property)</li>
                                                    <li>Income from Lottery, Awards and Crossward Puzzles</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="price-box">
                                                    <span>Just for</span>
                                                    <div className="rupee"><img src="images/rupee-icon.png" alt="" />1499 </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <Link to="/planb">
                                                    <a href="planB-product.html" className="probtn">GET PLAN</a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 custom">
                                    <div className="green-bg radius-shadow probox-main">
                                        <div className="proimg-box">
                                            <img src="images/plan-01.png" alt="" className="img-fluid" />
                                        </div>
                                        <div className="prodetails">
                                            <h4>Plan C - Foreign Income</h4>
                                            <div className="prolist-scroll">
                                                <ul>
                                                    <li>Everything in Plan B </li>
                                                    <li>Individual having Foreign Income </li>
                                                    <li>Income earned in India for NRE, NRI A/C</li>
                                                    <li>Consultation of Double Taxation Avoidance Agreement(DTAA) </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="price-box">
                                                    <span>Just for</span>
                                                    <div className="rupee"><img src="images/rupee-icon.png" alt="" />3999 </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <Link to="/planc">
                                                    <a href="planC-product.html" className="probtn">GET PLAN</a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row mtp30">
                                <div className="col-sm-12">
                                    <div className="all-plan-box all-plan-have">
                                        <h4>All Plans have bellow services</h4>
                                        <div className="row mtp20 gutter-5 row-cols-1 row-cols-sm-3 row-cols-xl-5">


                                            <div className="col">
                                                <img src="images/check.png" className="img-fluid" alt="" />
                                                <span>Dedicated CA assigned on your case</span>
                                            </div>
                                            <div className="col">
                                                <img src="images/check.png" className="img-fluid" alt="" />
                                                <span>Prior consultant on maximum tax benefit</span>
                                            </div>



                                            <div className="col">
                                                <img src="images/check.png" className="img-fluid" alt="" />
                                                <span>Suggestion on New Tax Saving avenues for next year</span>
                                            </div>
                                            <div className="col">
                                                <img src="images/check.png" className="img-fluid" alt="" />
                                                <span>Tracking and updating the status of refund, if any</span>
                                            </div>
                                            <div className="col">
                                                <img src="images/check.png" className="img-fluid" alt="" />
                                                <span>Advisory to queries pertaining to the income tax filing</span>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChoosebestPlan