import React, { useState } from "react";
import "./SignUplogin.css";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import { error } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";

const SignUp = () => {
  const Navigate = useNavigate();
  const Location = useLocation()
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [logindata, setLoginData] = useState();
  const handleCountryCodeChange = (selectedOption) => {
    if (selectedOption === null) {
      setSelectedCountryCode({ value: "+91", label: "+91 (India)" });
    } else {
      setSelectedCountryCode(selectedOption);
    }
    console.log(selectedOption);
  };
  const [phone, setPhone] = useState("+91");
  const [phoneLastTenDigits, setPhoneLastTenDigits] = useState("");
  const [phoneRemainingDigits, setPhoneRemainingDigits] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneChange = (value) => {
    // Remove any non-digit characters from the phone number
    const digitsOnly = value.replace(/\D/g, "");

    // Extract the last 10 digits
    const lastTenDigits = digitsOnly.slice(-10);

    // Extract the remaining digits (if any)
    const remainingDigits = digitsOnly.slice(0, -10);

    setPhoneLastTenDigits(lastTenDigits);
    setPhoneRemainingDigits(
      remainingDigits.length > 0 ? `+${remainingDigits}` : ""
    );
    
    // console.log(lastTenDigits)
    // console.log(remainingDigits)
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value)
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(formData)
    const data = {
      phoneNumber: phoneLastTenDigits,
      countryCode: phoneRemainingDigits,
      email: formData.email,
      lastName: formData.lastName,
      firstName: formData.firstName,
    };
    // console.log(data)

    const method = "user/register";
    axios
      .post(REACT_APP_BASE_URL + method, data)
      .then((res) => {
        console.log(res);
        toast.success("User register successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        })
      })
      .catch((error) => {
        console.log("error", error); // Fixed typo here (changed "erroer" to "error")
        if (
          error.response.data.message ==
          "User with same credentials already exists"
        ) {
          // Fixed typo here (changed "erroer" to "error")
          toast.warn("User with same credentials already exists!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading back to false after the API call is completed
      });
  };

  const handleLogin = (e) => {
    setIsLoading(true);
    e.preventDefault();
    console.log(logindata);
    const data = { data: logindata };
    const method = "user/login/otp-verification";

    axios
      .post(REACT_APP_BASE_URL + method, data)
      .then((res) => {
        console.log(res.data.data);
        sessionStorage.setItem("userId", res.data.data.userId);
        toast.success("OTP send successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false)
        Navigate("/getotp");
      })
      .catch((error) => {
        console.log(error);
        if (logindata.includes("@")) {
          toast.warn("Please Re-Enter Your email!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.warn(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading back to false after the API call is completed
      });
  };

  const navigateToHome = () => {
    console.log("link clicked")
    sessionStorage.setItem("reload", 1)
    Navigate('/')
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="body1">
        <div className="container1" style={{ width: "75%" }}>
          <input type="checkbox" id="flip" />
          <div className="cover">
            <div className="front">
              <img src="images/frontImg.jpg" alt="" />
              <div className="text">
                <span className="text-1">
                Taxation is the price which civilized communities pay for the opportunity of remaining civilized.
                </span>
                {/* <span className="text-2">Let's get connected</span> */}
              </div>
            </div>
            <div className="back">
              <img className="backImg" src="images/backImg.jpg" alt="" />
              <div className="text">
                <span className="text-1">
                  Complete miles of journey <br /> with one step
                </span>
                <span className="text-2">Let's get started</span>
              </div>
            </div>
          </div>
          <div className="forms">
            <div className="form-content">
              <div className="login-form">
                <div className="title">Sign In</div>
                <form action="#">
                  <div className="input-boxes">
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        name="input"
                        onChange={(e) => setLoginData(e.target.value)}
                        id="outlined-basic"
                        label="Email Or Mobile No"
                        variant="outlined"
                        fullWidth

                      />
                    </Box>
                    {/* <Link to='/getotp'> */}
                    <div className="button input-box">
                      <input
                        type="submit"
                        value="Send OTP"
                        onClick={handleLogin}
                        disabled={isLoading}
                      />
                    </div>
                    {/* </Link> */}
                    <div className="text sign-up-text">
                      Don't have an account? <label for="flip">Sigup now</label>
                    </div>
                    <div className="text sign-up-text">
                      {" "}
                      <label onClick={navigateToHome}>
                        <Link>Skip For Now</Link>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div className="signup-form">
                <div className="title">Signup</div>
                <form action="#" style={{ marginTop: "10px" }}>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      fullWidth
                      name="firstName"
                      onChange={handleInputChange}
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      value={formData?.firstName}
                    />
                    <TextField
                      fullWidth
                      name="lastName"
                      onChange={handleInputChange}
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      value={formData?.lastName}
                    />
                    <TextField
                      fullWidth
                      name="email"
                      onChange={handleInputChange}
                      id="outlined-basic"
                      label="Email Address"
                      variant="outlined"
                      value={formData?.email}
                    />

                    <div>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <PhoneInput
                          fullWidth
                          defaultCountry={"in"}
                          value={phone+formData?.phoneNumber}
                          onChange={handlePhoneChange}
                          inputComponent={TextField}
                          inputProps={{
                            id: "outlined-basic",
                            label: "Phone Number",
                            variant: "outlined",
                          }}
                          disableDropdown
                          placeholder="Mobile Number"
                          countryCodeEditable={false}
                        />
                      </span>
                    </div>
                    <div className="button input-box">
                      <input
                        type="submit"
                        value="Submit"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      />
                    </div>
                    <div
                      className="text sign-up-text"
                      style={{ width: "42ch" }}
                    >
                      Already have an account?{" "}
                      <label for="flip">Sign In now</label>
                    </div>
                    <div className="text sign-up-text">
                      {" "}
                      <label onClick={navigateToHome}>
                        <Link >Skip For Now</Link>
                      </label>
                    </div>
                  </Box>
                  {/* <div className="input-boxes">
                    <div className="input-box">
                      <i className="fas fa-user"></i>
                      <input
                        type="text"
                        placeholder="Enter your FirstName"
                        required
                      />
                      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-user"></i>
                      <input
                        type="text"
                        placeholder="Enter your LastName"
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-envelope"></i>
                      <input
                        type="text"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div  style={{
    marginTop: "28px"}}>
      <span style={{display:"flex"}}>
                      <i className="fas fa-mobile-alt"></i>

                      <PhoneInput
      country="us"
      value={phone}
      onChange={handlePhoneChange}
    />
    </span>
                    </div>
                    
                    <div className="input-box">
                      <i className="fas fa-mobile-alt"></i>
                      <input
                        type="munber"
                        placeholder="Enter your mobile number"
                        required
                      />
                    </div>
                    <div className="button input-box">
                      <input type="submit" value="Sumbit" />
                    </div>
                    <div className="text sign-up-text">
                      Already have an account?{" "}
                      <label for="flip">Login now</label>
                    </div>
                    <div className="text sign-up-text">
                      {" "}
                      <label for="flip">
                        <Link to="/">Skip For Now</Link>
                      </label>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
