import React from 'react'
import "./UnderLine.css"

const UnderLine = () => {
  return (
    <div class="loading">
    <svg class="svgCustom">
        <polyline points="0,0 64,0" id="back" strokeWidth="1" />
        <polyline points="0,0 64,0" id="front" strokeWidth="1" />
    </svg>
</div>
  )
}

export default UnderLine