import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_BASE_URL } from "../../service/Constant";
import "./GetOtp.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Loader from "../loader/Loader";


const GetOtp = () => {
  const [counter, setCounter] = useState(40);
  const [otp, setOtp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  let minutes = Math.floor(counter / 60); // Calculate minutes
  let seconds = counter % 60; // Calculate seconds

  const Navigate = useNavigate();
  const handleOtp = (e) => {
    const inputOtp = e.target.value;
    const formattedOtp = inputOtp.replace(/\D/g, ""); // Remove non-digit characters

    if (formattedOtp.length <= 6) {
      setOtp(formattedOtp);
    }
  };

  const sendOTP = (e) => {
    e.preventDefault();
    console.log(otp);

    const otpData = { OTP: otp }; // Wrap the otp value in an object

    const storedUserId = localStorage.getItem("userId");
    console.log(storedUserId);
    const method = "user/register/verify-otp/";
    axios
      .post(REACT_APP_BASE_URL + method + "/" + storedUserId, otpData)
      .then((res) => {
        console.log(res);

        if (res.data.status === 1) {
          toast.success("OTP verified successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          Navigate("/SignUplogin");
        } else {
          alert("OTP verification failed. Please enter the correct OTP.");
        }
      })
      .catch((error) => {
        console.error("OTP verification failed", error);
        // alert("OTP verification failed");
        toast.warn("OTP verification failed!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    //   Navigate("/SignUplogin")
  };
  const getreGeneratedOtp = (e) => {
    // e.preventDefault();
    const id = localStorage.getItem("userId");
    console.log(id);
    axios
      .post(`https://apibase.taxespro.in/user/register/regenrate-otp/${id}`)
      .then((res) => {
        console.log(res);
        alert("otp send to your Email");
      });
  };

  const verifyMobile = (e) => {
    setIsLoading(true);

    e.preventDefault();
    // console.log(otp);
    const otpData = { OTP: otp };
    const storedUserId = sessionStorage.getItem("userId");
    // console.log(storedUserId);
    const method = "user/register/verify-otp/";
    axios
      .post(REACT_APP_BASE_URL + method + storedUserId, otpData)
      .then((res) => {
        console.log(res.data.data.token);
        sessionStorage.setItem("token", res.data.data.token)

        if (res.data.status === 1) {
          toast.success("OTP verified successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          sessionStorage.setItem("reload", 1)
          setIsLoading(false);
          Navigate("/");
        } else {
          alert("OTP verification failed. Please enter the correct OTP.");
        }
      });
  };

  //  for Resend OTP
  const sendOtp = (e) => {
    setIsLoading(true);
    setCounter(120);
    e.preventDefault();
    const storedUserId = sessionStorage.getItem("userId");

    const method = "user/register/regenrate-otp/";

    axios
      .post(REACT_APP_BASE_URL + method + storedUserId)
      .then((res) => {
        console.log(res);
    setIsLoading(false);
        setCounter(120);
      })
      .catch((error) => {
        console.error("Login failed", error);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="body1">
        <div className="container1">
          <input type="checkbox" id="flip" />
          <div className="cover">
            <div className="front">
              <img src="images/frontImg.jpg" alt="" />
              <div className="text">
                <span className="text-1">
                  Every new friend is a <br /> new adventure
                </span>
                <span className="text-2">Let's get connected</span>
              </div>
            </div>
            <div className="back">
              <img className="backImg" src="images/backImg.jpg" alt="" />
              <div className="text">
                <span className="text-1">
                  Complete miles of journey <br /> with one step
                </span>
                <span className="text-2">Let's get started</span>
              </div>
            </div>
          </div>
          <div className="forms">
            <div className="form-content getOtp-form-resp">
              <div >
                <div className="title">Get Otp</div>
                <form action="#" onSubmit={(e) => sendOTP(e)}>
                  <div className="input-boxes">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="input-box">
                        <input
                          type="number"
                          name="OTP"
                          onChange={handleOtp}
                          placeholder="Enter OTP"
                          pattern="[0-9]{6}"
                          maxLength={6}
                          required
                        />
                      </div>

                      <div
                        className="button input-box"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {/* <input type="submit" value="Sumbit" style={{width:"50%"}} /> */}
                        {counter === 0 ? (
                          <Button
                            onClick={sendOtp}
                            variant="contained"
                            style={{
                              borderRadius: "25px",
                              background: "#7d2ae8",
                            }}
                          >
                            Resend OTP
                          </Button>
                        ) : (
                          <Button
                            onClick={verifyMobile}
                            variant="contained"
                            style={{
                              borderRadius: "25px",
                              background: "#7d2ae8",
                            }}
                          >
                            verify OTP
                          </Button>
                        )}
                      </div>
                    </div>


                    <Typography
                      style={{ textAlign: "center", padding: "2rem" }}
                    >
                      <span>
                        Re-send OTP in {minutes} min {seconds} sec
                      </span>
                    </Typography>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetOtp;
