import React from 'react'
import '../../common/Common.css'

const BenefitsOfFillingItr = () => {
  return (
    <>
     <section className="tb-space">
            <div className="container benefits-container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h5 className="main-title red-row mbp15 d-inline-block">Benefits of Filling ITR</h5>
                    </div>
                </div>
                <div className="row mtp30 itrbox gutter-0">
                    <div className="col-sm-4">
                        <div className="vhmian">
                            <div className="vhin">
                                <div className="imgbox">
                                    <img src="images/late-fees-icon.png" className="m-auto"/>
                                </div>
                                <h6>Avoid Late fees</h6>
                                <p>Avoid maximum penalty of Rs. 10000/- if your Total income exceeds Rs. 5 lakh</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="vhmian">
                            <div className="vhin">
                                <img src="images/tax-notice-icon.png" className="m-auto"/>
                                <h6>Avoid Tax Notice</h6>
                                <p>Taxpayers often served notices from the IT department for delayed & missed return Submission</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="vhmian">
                            <div className="vhin">
                                <img src="images/document-icon.png" className="m-auto"/>
                                <h6>Financial Documentation</h6>
                                <p>Your tax return documents are proof of your financial Investments and will be useful when you apply for Loan or visa.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="vhmian">
                            <div className="vhin">
                                <img src="images/cfyl-icon.png" className="m-auto"/>
                                <h6>Carry forward your losses</h6>
                                <p>You can carry forward losses against house property and depreciation.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="vhmian">
                            <div className="vhin">
                                <img src="images/tax-refund.png" className="m-auto"/>
                                <h6>Tax Refund</h6>
                                <p>You can claim tax refund, the earlier You file the sooner you will get refund.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="vhmian">
                            <div className="vhin">
                                <img src="images/quick-visa-icon.png" className="m-auto"/>
                                <h6>Quick Visa Processing</h6>
                                <p>Most Embassies and consulates require you To submit IT returns for last 3 years.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default BenefitsOfFillingItr