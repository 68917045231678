import React from "react";
import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Sidebar from "../Sidebar";
import Header from "../header/Header";

const ViewPlanDetails = () => {
  const goBack = () => {
    window.history.go(-1);
  };
  return (
    <>
      <Header />
      <div>
        <Container maxWidth="xl" style={{ marginTop: "7rem" }}>
          <Card sx={{ paddingBottom: 3 }}>
            <CardContent>
              <div className="row">
                <div className="col-10">
                  <Typography sx={{ fontSize: 45 }} color="text.secondary">
                    Purchase History Details
                  </Typography>
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-dark regbtn md "
                    onClick={goBack}
                  >
                    back
                  </button>
                </div>
              </div>

              <Grid style={{ paddingTop: "3rem" }}>
                <div className="row">
                  <div className="col mb-2">
                    <div className="row">
                      <div className="col mb-2">
                        <label
                          for="exampleInputEmail2"
                          className="form-label"
                          style={{ fontSize: "16px" }}
                        >
                          Plan Name :-
                        </label>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div className="col mb-2">
                        <span>Plan A</span>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-2">
                    <div className="row">
                      <div className="col mb-2">
                        <label
                          for="exampleInputEmail2"
                          className="form-label"
                          style={{ fontSize: "16px" }}
                        >
                          Last Name :-
                        </label>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div className="col mb-2">
                        <span>More</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-2">
                    <div className="row">
                      <div className="col mb-2">
                        <label
                          for="exampleInputEmail2"
                          className="form-label"
                          style={{ fontSize: "16px" }}
                        >
                          Email Address :-
                        </label>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div className="col mb-2">
                        <span>saurabhmoreals4@gmail.com</span>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-2">
                    <div className="row">
                      <div className="col mb-2">
                        <label
                          for="exampleInputEmail2"
                          className="form-label"
                          style={{ fontSize: "16px" }}
                        >
                          Mobile Number :-
                        </label>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div className="col mb-2">
                        <span>8261066766</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile-ud-list"></div>
              </Grid>

              <div
                class="modal fade mt-5"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Edit Details
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body" style={{ display: "flex" }}>
                      <div>
                        <label>Email Address</label>
                        <input
                          className="form-control"
                          style={{ width: "90%" }}
                        />
                      </div>
                      <div>
                        <label>Mobile Number</label>
                        <input
                          className="form-control"
                          style={{ width: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default ViewPlanDetails;
